var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "top-bar",
      attrs: {
        color: "#ffffff",
        "clipped-left": "",
        "clipped-right": "",
        app: "",
        fixed: "",
        flat: "",
      },
    },
    [
      _vm._t("title", function () {
        return [
          _c("fluent-full-logo"),
          _c("app-name", { attrs: { name: _vm.appName } }),
        ]
      }),
      _c(
        "v-row",
        { attrs: { justify: "end", align: "center" } },
        [
          _vm._t("items"),
          _vm.defaultItems
            ? _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("MService"),
                  _c("AccountMenu", {
                    attrs: { "user-name": _vm.userName },
                    on: {
                      logout: function ($event) {
                        return _vm.$emit("logout")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }