import Vue from "vue";

function createNamespacedDispatchAndCommit(namespace) {
  return {
    dispatch(name, payload) {
      return this.$store.dispatch(`${namespace}/${name}`, payload);
    },
    commit(name, payload) {
      return this.$store.commit(`${namespace}/${name}`, payload);
    }
  };
}

export const OrganizationService = Vue.extend({
  methods: {
    ...createNamespacedDispatchAndCommit("organizationAF"),
    create(name) {
      return this.dispatch("create", { partner_name: name });
    },
    join(joinCode) {
      return this.dispatch("join", { joinCode });
    },
    list() {
      return this.dispatch("list");
    },
    update(organizationCode, newName) {
      return this.dispatch("update", { organizationCode, newName });
    },
    generateNewCode(organizationCode) {
      return this.dispatch("generateCode", { organizationCode });
    },
    getUsers(organizationCode) {
      return this.dispatch("getUsers", { partner_code: organizationCode });
    },
    removeUser(partner_code, userId) {
      return this.dispatch("removeUser", { partner_code, userId });
    }
  },
  computed: {
    organizations() {
      return this.$store.state.organizationAF.partners;
    },
    users() {
      return this.$store.state.organizationAF.users;
    }
  }
});

export const JoinRequestService = Vue.extend({
  methods: {
    ...createNamespacedDispatchAndCommit("organizationAF/joinRequest"),
    listIncomingRequests() {
      this.dispatch("listIncomingRequests");
    },
    approve(request) {
      this.dispatch("approve", request);
    },
    decline(request) {
      this.dispatch("decline", request);
    },
    updateMemberRole(request) {
      this.dispatch("updateMemberRole", request);
    }
  },
  computed: {
    incomingRequests() {
      return this.$store.state.organizationAF.joinRequest.incomingRequests;
    }
  }
});
