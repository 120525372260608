var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-container",
        [
          _c(
            "v-btn",
            {
              staticClass: "pa-2 ma-2",
              staticStyle: { background: "white" },
              attrs: {
                outlined: "",
                color: "primary",
                rounded: "",
                to: "/students",
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("arrow_back")]),
              _vm._v(_vm._s(!_vm.$language ? "My Courses" : "আমার কোর্সগুলি")),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.courseId
        ? _c("m-browse-courses-p")
        : _c(
            "lazy-load",
            { attrs: { item: _vm.course } },
            [
              _c("m-course-details", {
                key: _vm.courseId,
                attrs: { course: _vm.course },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }