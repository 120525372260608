var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-7 py-5",
      class: { "mt-4": _vm.$vuetify.breakpoint.xsOnly },
      style: `width: ${_vm.style.wholeWidth}px; background: white; align-self: baseline;`,
    },
    [
      _c(
        "p",
        { style: `font-size: ${_vm.style.title1Size}px; font-weight: 600;` },
        [_vm._v(" What You Will Be Getting In This Package ")]
      ),
      _c("div", { style: `height: ${_vm.style.gap1}px;` }),
      _c("hr", { staticStyle: { color: "#f1f1f1" } }),
      _c("div", { style: `height: ${_vm.style.gap2}px;` }),
      _c(
        "p",
        { style: `font-size: ${_vm.style.title2Size}px; font-weight: 600;` },
        [_vm._v(" Advanced All Feature ")]
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" Everything in the Basic Plan "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v("30000+ Question Bank"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" Create Exams or assignments using Question Bank "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "v-icon",
            { staticClass: "mb-xl-4 mb-8 mr-2", attrs: { size: "20" } },
            [_vm._v("check")]
          ),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(
              " Announcement Board with interactive communication with students "
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v("Certification System"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v("Instant SMS support"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" Parental Communication via SMS "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" Payment Management System "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" Monthly Webinar on Product Demonstration "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" Direct Product Onboarding Training "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" Teacher/Institution Branding and Advertising "),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-icon", { staticClass: "mb-4 mr-2", attrs: { size: "20" } }, [
            _vm._v("check"),
          ]),
          _c("p", { style: `font-size: ${_vm.style.textSize}px;` }, [
            _vm._v(" (New Features Coming Soon) "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }