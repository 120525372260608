<template>
  <div>
    <div
      v-if="isActive"
      class="pa-2 rounded d-flex align-center justify-space-between overflow-hidden border border-solid border-decline"
      :class="cardColor"
    >
      <div
        style="-webkit-font-smoothing: antialiased"
        class="sm:text-xs d-inline-block"
        :class="textClass"
      >
        {{ message }}
      </div>
      <v-btn id="close-button" icon @click="close">
        <v-icon :color="closeColor">mdi-close-thick</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { scrollToTop } from "/global/utils/helpers.js";

const defaultColor = "card-color";
const flashingColor = "bg-decline";
const textDefaultClass = "text";
const textFlashingClass = "white--text shake";
const closeDefaultColor = "text";
const closeFlashingColor = "white";

export default {
  name: "SubmissionAlertMessage",
  props: {
    message: {
      type: String,
      required: true,
      default: "Fields must be valid",
    },
    activate: {
      type: Boolean,
      default: false,
    },
    shouldScrollToTop: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "activate",
    event: "change",
  },
  data() {
    return {
      isActive: false,
      cardColor: defaultColor,
      textClass: textDefaultClass,
      closeColor: closeDefaultColor,
    };
  },
  watch: {
    activate: {
      handler(value) {
        if(this.shouldScrollToTop) scrollToTop();
        this.isActive = value;
      },
    },
  },
  methods: {
    async flash() {
      if(this.shouldScrollToTop) scrollToTop();
      await this.flairUp();
      this.turnOff();
    },
    flairUp() {
      this.cardColor = flashingColor;
      this.textClass = textFlashingClass;
      this.closeColor = closeFlashingColor;
      return new Promise((res) => setTimeout(res, 1000));
    },
    turnOff() {
      this.cardColor = defaultColor;
      this.textClass = textDefaultClass;
      this.closeColor = closeDefaultColor;
    },
    close() {
      this.isActive = false;
      this.$emit("change", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-color {
  background-color: #fdf3f1;
}
.flash-color {
  background-color: #d0342c;
}

.shake {
  animation: horizontal-shaking 0.33s infinite;
}
@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
}

#close-button::before {
  // display: none;
  border-radius: 4px;
}
</style>