<template>
  <div
    :style="
      `width: ${style.wholeWidth}px; background: white; align-self: baseline;`
    "
    class="px-7 py-5"
    :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
  >
    <p :style="`font-size: ${style.title1Size}px; font-weight: 600;`">
      What You Will Be Getting In This Package
    </p>
    <div :style="`height: ${style.gap1}px;`"></div>
    <hr style="color: #f1f1f1;" />
    <div :style="`height: ${style.gap2}px;`"></div>
    <p :style="`font-size: ${style.title2Size}px; font-weight: 600;`">
      Advanced All Feature
    </p>

    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Everything in the Basic Plan
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">30000+ Question Bank</p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Create Exams or assignments using Question Bank
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-xl-4 mb-8 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Announcement Board with interactive communication with students
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">Certification System</p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">Instant SMS support</p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Parental Communication via SMS
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Payment Management System
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Monthly Webinar on Product Demonstration
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Direct Product Onboarding Training
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        Teacher/Institution Branding and Advertising
      </p>
    </div>
    <div class="d-flex">
      <v-icon size="20" class="mb-4 mr-2">check</v-icon>
      <p :style="`font-size: ${style.textSize}px;`">
        (New Features Coming Soon)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Plans",
  computed: {
    style() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return {
            wholeWidth: 330,
            title1Size: 14,
            title2Size: 16,
            textSize: 10,
            gap1: 0,
            gap2: 10
          };
        case "sm":
          return {
            wholeWidth: 250,
            title1Size: 14,
            title2Size: 16,
            textSize: 10,
            gap1: 0,
            gap2: 15
          };
        case "md":
          return {
            wholeWidth: 250,
            title1Size: 14,
            title2Size: 16,
            textSize: 10,
            gap1: 0,
            gap2: 15
          };
        case "lg":
          return {
            wholeWidth: 400,
            title1Size: 16,
            title2Size: 18,
            textSize: 12,
            gap1: 0,
            gap2: 25
          };
        case "xl":
          return {
            wholeWidth: 520,
            title1Size: 20,
            title2Size: 22,
            textSize: 15,
            gap1: 0,
            gap2: 35
          };
        default:
          return {};
      }
    }
  }
};
</script>

<style scoped></style>
