<template>
  <v-navigation-drawer
    app
    clipped
    color="#ffff"
    floating
    permanent
    class="mk-navigation-drawer"
  >
    <div class="d-flex flex-column justify-center align-center mt-5 mb-2">
      <user-avatar
        :user-name="userName"
        color="primary"
        size="64"
      ></user-avatar>
      <div class="title mt-4">{{ userName }}</div>
    </div>
    <slot name="drawer-items"> </slot>
    <v-divider v-if="$slots['extra-items']"></v-divider>
    <slot name="extra-items"> </slot>
  </v-navigation-drawer>
</template>

<script>
import UserAvatar from "./UserAvatar";
export default {
  name: "MSidebar",
  components: { UserAvatar },
  props: {
    userName: String
  }
};
</script>

<style lang="scss" scoped>
@import "/global/styles/scss/variables.scss";
.mk-navigation-drawer {
  .v-list-item--active {
    color: $primary;
    font-weight: 500;
    .v-icon {
      color: #fc7f44 !important;
    }
    .v-list-item__icon.v-list-group__header__append-icon {
      color: #000 !important;
    }
  }
}
</style>
