var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          staticClass: "m-base-card-info d-flex align-start",
          class: { "flex-column": _vm.$vuetify.breakpoint.mdAndUp },
        },
        "div",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("span", { staticClass: "info-label" }, [
        _vm._v(_vm._s(`${_vm.label}${_vm.suffix}`)),
      ]),
      _vm._t("default", function () {
        return [
          _c("span", { staticClass: "info-text ml-1" }, [
            _vm._v(_vm._s(_vm.value)),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }