<template>
  <v-menu transition="slide-y-transition" bottom offset-y>
    <template v-slot:activator="{ on }">
      <user-avatar
        color="primary"
        size="32"
        :user-name="userName"
        class="user-icon clickable mx-1"
        v-on="on"
      >
      </user-avatar>
    </template>
    <v-list class="account-menu__option-list">
      <!--<v-list-item>-->
      <!--<v-list-item-title-->
      <!--&gt;<v-icon>settings</v-icon> &nbsp;Settings</v-list-item-title-->
      <!--&gt;-->
      <!--</v-list-item>-->
      <v-list-item :href="authUrl">
        <v-list-item-icon><v-icon>person</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            >Account Details <v-icon>open_in_new</v-icon></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$emit('logout')">
        <v-list-item-icon><v-icon>power_settings_new</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import UserAvatar from "./UserAvatar";
export default {
  name: "AccountMenu",
  components: { UserAvatar },
  props: {
    userName: String
  },
  computed: {
    authUrl() {
      return process.env.VUE_APP_ACCOUNTS_FRONTEND_URL;
    }
  }
};
</script>

<style>
.account-menu__option-list {
  min-width: 300px;
}
</style>
