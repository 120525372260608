import { Logger, download, loadDataWithContent } from "/global/utils/helpers";
import { checkStatus } from "/global/utils/helpers";
import { Backend } from "~/utils/backend";
import questionMapper from "../mappers/question-mapper";
import essayMapper from "../mappers/essay-mapper";
import { eq, zip } from "lodash";

const backend = new Backend(process.env.VUE_APP_BACKEND_BOLT_TEACHERS_URL);
// Todo: Flatten question.questionDetails
export const QuestionApi = {
  UPLOAD_URI: "uploads/",
  get(questionId, owner_id, course_id) {
    return backend
      .get("teachers_get_question", {
        params: { id: questionId, owner_id: owner_id, course_id: course_id }
      })
      .then(checkStatus)
      .then(loadDataWithContent)
      .then(content => questionMapper.remoteToLocal(content.question));
  },
  find(params) {
    return backend
      .get("teachers_list_topic_questions", { params })
      .then(checkStatus)
      .then(({ data }) => {
        var totalQuestionCount = data.content.totalQuestionCount;
        var questions = data.content.questions
          ? data.content.questions.map(questionMapper.remoteToLocal)
          : [];
        var essays = data.content.essays
          ? data.content.essays.map(essayMapper.remoteToLocal)
          : [];
        return {
          totalQuestionCount: totalQuestionCount,
          questions: questions,
          essays: essays
        };
      });
  },
  findLinkable(params) {
    return backend
      .get("teachers_list_linkable_essays", { params })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getQuestionsForEssay(params) {
    return backend
      .get("teachers_list_essay_questions", { params })
      .then(checkStatus)
      .then(loadDataWithContent)
      .then(data => {
        data.questions = data.questions.map(questionMapper.remoteToLocal);
        data.question_group?.sort((a, b) => {
          return a.order - b.order;
        });
        return data;
      });
  },
  async create(questionData, course_id, owner_id) {
    const { images, question, topics } = questionMapper.localToRemote(
      questionData
    );
    Logger.log(images);
    question.course_id = course_id;
    question.owner_id = owner_id;
    const response = await backend
      .post("teachers_create_question", question)
      .then(checkStatus)
      .then(({ data }) => data.content);
    const tasks = [];
    let topic_names = topics.map(t => {
      return t.text;
    });
    tasks.push(
      this._attachTopics(response.id, topic_names, question.owner_id, course_id)
    );
    tasks.push(this._uploadQuestionImage(images.question, response.file_info));
    // tasks.push(
    //   this._uploadExplanationImage(
    //     images.explanation,
    //     response.explanation.file_info,
    //   ),
    // );
    tasks.push(...this._uploadAnswerImages(images.answers, response.answers));

    await Promise.all(tasks);

    return response;
  },
  _uploadQuestionImage(image, imageInfo) {
    return imageInfo ? backend.upload_to_s3(imageInfo, image) : null;
  },
  _uploadAnswerImages(answerImages, answerResponses) {
    return zip(answerImages, answerResponses).map(([image, resp]) => {
      return resp.file_info
        ? backend.upload_to_s3(resp.file_info, image)
        : null;
    });
  },
  _attachTopics(questionId, topics, owner_id, course_id) {
    const data = {
      topics: topics,
      object_id: questionId,
      object_type: "question",
      owner_id: owner_id,
      course_id: course_id
    };
    return backend.post("teachers_attach_topics", data).then(checkStatus);
  },
  detachTopic(questionId, topics) {
    const data = {
      topics: topics,
      object_id: questionId,
      object_type: "QUESTION"
    };
    return backend.post("teachers_delete_topics", data).then(checkStatus);
  },
  async update(
    questionId,
    newQuestion,
    course_id,
    owner_id,
    imageUpdated = false
  ) {
    // return backend.put("teachers_edit_question", data).then(checkStatus);
    const { images, question, topics } = questionMapper.localToRemote(
      newQuestion,
      questionId
    );
    question.course_id = course_id;
    question.owner_id = owner_id;
    Logger.log(images);

    const response = await backend
      .post("teachers_edit_question", question)
      .then(checkStatus)
      .then(({ data }) => data.content);
    const tasks = [];
    let topic_names = topics.map(t => {
      return t.text;
    });
    tasks.push(
      this._attachTopics(questionId, topic_names, question.owner_id, course_id)
    );
    tasks.push(this._uploadQuestionImage(images.question, response.file_info));
    tasks.push(...this._uploadAnswerImages(images.answers, response.answers));

    await Promise.all(tasks);
    return response;
  },
  delete(questionId, course_id, owner_id) {
    return backend
      .delete("teachers_delete_question", {
        data: { id: questionId, course_id: course_id, owner_id: owner_id }
      })
      .then(checkStatus);
  },
  detach(questionId) {
    return backend
      .post("teachers_detach_from_essay", { id: questionId })
      .then(checkStatus);
  },
  async duplicate(questionData, course_id, owner_id) {
    const {
      images,
      question,
      topics
    } = await questionMapper.duplicateForRemote(questionData);
    question.course_id = course_id;
    question.owner_id = owner_id;
    const response = await backend
      .post("teachers_create_question", question)
      .then(checkStatus)
      .then(({ data }) => data.content);
    const tasks = [];
    let topic_names = topics.map(t => {
      return t.text;
    });
    tasks.push(
      this._attachTopics(response.id, topic_names, question.owner_id, course_id)
    );
    tasks.push(this._uploadQuestionImage(images.question, response.file_info));
    tasks.push(...this._uploadAnswerImages(images.answers, response.answers));

    await Promise.all(tasks);
    return response;
  },
  async getEquationForQuestion({ question_id, index }) {
    const resp = await backend
      .post("teachers_get_question_equation", {
        question_id,
        index
      })
      .then(checkStatus)
      .then(loadDataWithContent);
    return download(resp.url);
  },
  async saveEquationsForQuestion({ partner_code, question_id, equations }) {
    let count = equations.length;
    const resp = await backend
      .post("teachers_save_question_equation", {
        partner_code,
        question_id,
        count
      })
      .then(checkStatus)
      .then(loadDataWithContent);
    for (let i = 0; i < equations.length; i++) {
      await backend.upload_to_s3(
        resp.upload_info[i],
        new Blob([equations[i]], { type: "plain/text" })
      );
    }
  }
};

export default QuestionApi;
