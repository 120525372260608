var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: `width: ${_vm.style.wholeWidth}px;` },
    [
      _c(
        "p",
        { style: `color: #0099dc; font-size: ${_vm.style.stepText}px;` },
        [_vm._v(" Step 1 Of 3 ")]
      ),
      _c(
        "p",
        { style: `font-size: ${_vm.style.pickText}px; font-weight: bold;` },
        [_vm._v(" Pick a subscription ")]
      ),
      _c(
        "div",
        {
          staticStyle: {
            background: "white",
            border: "1px solid #f1f1f1",
            "border-radius": "5px",
            padding: "15px 0 15px 25px",
          },
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c("img", {
              attrs: {
                src: require("root-src/assets/mouchak_favicon.svg"),
                alt: "logo",
                width: "21.5",
              },
            }),
            _c(
              "span",
              {
                staticClass: "ml-2",
                style: `font-size: ${_vm.style.advcText}px; font-weight: bold;`,
              },
              [_vm._v("Advanced All Feature")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "mt-2", style: `font-size: ${_vm.style.smText}px;` },
            [
              _vm._v(
                " In this package, you will be getting all the features unlocked. You will be charged "
              ),
              _c("span", { staticStyle: { color: "#0099dc" } }, [
                _vm._v("BDT 20/per student"),
              ]),
              _vm._v(" in this package. "),
            ]
          ),
        ]
      ),
      _c("submission-alert-message", {
        ref: "banner",
        staticClass: "pt-3",
        attrs: { "should-scroll-to-top": "", message: _vm.bannerMessage },
        model: {
          value: _vm.showBanner,
          callback: function ($$v) {
            _vm.showBanner = $$v
          },
          expression: "showBanner",
        },
      }),
      _c(
        "span",
        {
          style: `font-weight: 600; font-size: ${_vm.style.smText}px; position: relative; top: ${_vm.style.instructionPosition}%`,
        },
        [_vm._v("Please Enter How many students you wish to enroll")]
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "align-items": "baseline" } },
        [
          _c(
            "div",
            {
              style: `width: ${_vm.style.textFieldWidth}px; margin-top: ${_vm.style.textmt}px;`,
            },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      type: "number",
                      label: "Students to be enrolled",
                      placeholder: "Ex: 100",
                      rules: [_vm.required, (v) => _vm.minValue(v, 10)],
                    },
                    model: {
                      value: _vm.formData.numOfStudents,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "numOfStudents", $$v)
                      },
                      expression: "formData.numOfStudents",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "p",
            {
              style: `font-size: ${
                _vm.$vuetify.breakpoint.xs ? 16 : _vm.style.pickText
              }px; color: #0099DC; font-weight: bold; margin-left: ${
                _vm.style.tkleft
              }px;`,
            },
            [_vm._v(" X 20.00 TK ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          { style: `width: ${_vm.style.textFieldWidth}px;` },
          [
            _c("v-text-field", {
              attrs: {
                outlined: "",
                label: "Coupon",
                placeholder: "Ex: june20",
              },
              on: { change: _vm.getCoupon },
              model: {
                value: _vm.formData.coupon,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "coupon", $$v)
                },
                expression: "formData.coupon",
              },
            }),
          ],
          1
        ),
        _vm.formData.discount > 0
          ? _c(
              "div",
              {
                class: {
                  "m-discount-card-xl": _vm.$vuetify.breakpoint.xlOnly,
                  "m-discount-card-lg": _vm.$vuetify.breakpoint.lgOnly,
                  "m-discount-card-tab":
                    _vm.$vuetify.breakpoint.smOnly ||
                    _vm.$vuetify.breakpoint.mdOnly,
                  "m-discount-card-mobile": _vm.$vuetify.breakpoint.xsOnly,
                },
              },
              [
                _c("div", [_vm._v("SPECIAL OFFER")]),
                _c("div", [
                  _vm._v(
                    " OFF " +
                      _vm._s(_vm.formData.discount) +
                      _vm._s(
                        _vm.formData.discountType == "percentage" ? "%" : "TK"
                      ) +
                      " "
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "span",
        {
          style: `font-weight: 600; font-size: ${_vm.style.smText}px; position: relative; bottom: 1%`,
        },
        [_vm._v("Please select your subscription")]
      ),
      _c("div", { staticClass: "d-flex flex-wrap" }, [
        _c(
          "div",
          {
            class: {
              "m-selected": _vm.formData.selectedPlan === "monthly",
              "m-xlonly-plan-card": _vm.$vuetify.breakpoint.xlOnly,
              "m-lgonly-plan-card": _vm.$vuetify.breakpoint.lgOnly,
              "m-tabonly-plan-card":
                _vm.$vuetify.breakpoint.smOnly ||
                _vm.$vuetify.breakpoint.mdOnly,
              "m-mobile-plan-card": _vm.$vuetify.breakpoint.xsOnly,
              "m-stretch-card-xl":
                _vm.$vuetify.breakpoint.xlOnly && _vm.formData.discount > 0,
              "m-stretch-card-lg":
                _vm.$vuetify.breakpoint.lgOnly && _vm.formData.discount > 0,
              "m-stretch-card-tab":
                (_vm.$vuetify.breakpoint.smOnly ||
                  _vm.$vuetify.breakpoint.mdOnly) &&
                _vm.formData.discount > 0,
              "m-stretch-card-mobile":
                _vm.$vuetify.breakpoint.xsOnly && _vm.formData.discount > 0,
              "border-decline":
                _vm.formData.selectedPlan === undefined && _vm.validating,
            },
            on: {
              click: function ($event) {
                _vm.formData.selectedPlan = "monthly"
              },
            },
          },
          [
            _c("div", { staticClass: "monthly-plan" }, [
              _vm._v("Monthly Plan"),
            ]),
            _c("div", { staticClass: "billed" }, [_vm._v("Billed monthly")]),
            _vm.formData.discount > 0
              ? _c(
                  "div",
                  {
                    staticClass: "actual-amount",
                    class: {
                      "mt-6": _vm.$vuetify.breakpoint.xlOnly,
                      "mt-5":
                        _vm.$vuetify.breakpoint.lgOnly ||
                        _vm.$vuetify.breakpoint.smOnly ||
                        _vm.$vuetify.breakpoint.mdOnly,
                    },
                  },
                  [
                    _vm._v(
                      " BDT " + _vm._s(_vm.formData.numOfStudents * 20) + " TK"
                    ),
                    _c("span", { staticClass: "small" }, [_vm._v("/mo")]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: {
                  "mt-1": _vm.formData.discount > 0,
                  "amount-shrinked":
                    _vm.formData.discount > 0 && _vm.$vuetify.breakpoint.xsOnly,
                  amount: !(
                    _vm.formData.discount > 0 && _vm.$vuetify.breakpoint.xsOnly
                  ),
                },
              },
              [
                _vm._v(
                  " BDT " +
                    _vm._s(
                      _vm.getDiscountedPrice(_vm.formData.numOfStudents * 20)
                    ) +
                    " TK"
                ),
                _c("span", { staticClass: "small" }, [_vm._v("/mo")]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            class: {
              "m-selected": _vm.formData.selectedPlan === "annually",
              "m-xlonly-plan-card mx-5": _vm.$vuetify.breakpoint.xlOnly,
              "m-lgonly-plan-card mx-4": _vm.$vuetify.breakpoint.lgOnly,
              "m-tabonly-plan-card ml-2":
                _vm.$vuetify.breakpoint.smOnly ||
                _vm.$vuetify.breakpoint.mdOnly,
              "m-mobile-plan-card my-2": _vm.$vuetify.breakpoint.xsOnly,
              "m-stretch-card-xl":
                _vm.$vuetify.breakpoint.xlOnly && _vm.formData.discount > 0,
              "m-stretch-card-lg":
                _vm.$vuetify.breakpoint.lgOnly && _vm.formData.discount > 0,
              "m-stretch-card-tab":
                (_vm.$vuetify.breakpoint.smOnly ||
                  _vm.$vuetify.breakpoint.mdOnly) &&
                _vm.formData.discount > 0,
              "m-stretch-card-mobile":
                _vm.$vuetify.breakpoint.xsOnly && _vm.formData.discount > 0,
              "border-decline":
                _vm.formData.selectedPlan === undefined && _vm.validating,
            },
            on: {
              click: function ($event) {
                _vm.formData.selectedPlan = "annually"
              },
            },
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "billed" }, [_vm._v("Billed annually")]),
            _vm.formData.discount > 0
              ? _c(
                  "div",
                  {
                    staticClass: "actual-amount",
                    class: {
                      "mt-6": _vm.$vuetify.breakpoint.xl,
                      "mt-5":
                        _vm.$vuetify.breakpoint.lgOnly ||
                        _vm.$vuetify.breakpoint.smOnly ||
                        _vm.$vuetify.breakpoint.mdOnly,
                    },
                  },
                  [
                    _vm._v(
                      " BDT " + _vm._s(_vm.formData.numOfStudents * 240) + " TK"
                    ),
                    _c("span", { staticClass: "small" }, [_vm._v("/mo")]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: {
                  "mt-1": _vm.formData.discount > 0,
                  "amount-shrinked":
                    _vm.formData.discount > 0 && _vm.$vuetify.breakpoint.xsOnly,
                  amount: !(
                    _vm.formData.discount > 0 && _vm.$vuetify.breakpoint.xsOnly
                  ),
                },
              },
              [
                _vm._v(
                  " BDT " +
                    _vm._s(
                      _vm.getDiscountedPrice(_vm.formData.numOfStudents * 240)
                    ) +
                    " TK"
                ),
                _c("span", { staticClass: "small" }, [_vm._v("/yr")]),
              ]
            ),
            _c("div", { staticClass: "m-info" }, [
              _vm._v(
                "(BDT " + _vm._s(_vm.formData.numOfStudents * 20) + " TK/mo)"
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            class: {
              "m-selected": _vm.formData.selectedPlan === "quarterly",
              "m-xlonly-plan-card": _vm.$vuetify.breakpoint.xlOnly,
              "m-lgonly-plan-card": _vm.$vuetify.breakpoint.lgOnly,
              "m-tabonly-plan-card mt-2":
                _vm.$vuetify.breakpoint.smOnly ||
                _vm.$vuetify.breakpoint.mdOnly,
              "m-mobile-plan-card": _vm.$vuetify.breakpoint.xsOnly,
              "m-stretch-card-xl":
                _vm.$vuetify.breakpoint.xlOnly && _vm.formData.discount > 0,
              "m-stretch-card-lg":
                _vm.$vuetify.breakpoint.lgOnly && _vm.formData.discount > 0,
              "m-stretch-card-tab":
                (_vm.$vuetify.breakpoint.smOnly ||
                  _vm.$vuetify.breakpoint.mdOnly) &&
                _vm.formData.discount > 0,
              "m-stretch-card-mobile":
                _vm.$vuetify.breakpoint.xsOnly && _vm.formData.discount > 0,
              "border-decline":
                _vm.formData.selectedPlan === undefined && _vm.validating,
            },
            on: {
              click: function ($event) {
                _vm.formData.selectedPlan = "quarterly"
              },
            },
          },
          [
            _vm._m(1),
            _c("div", { staticClass: "billed" }, [_vm._v("Billed quarterly")]),
            _vm.formData.discount > 0
              ? _c(
                  "div",
                  {
                    staticClass: "actual-amount",
                    class: {
                      "mt-6": _vm.$vuetify.breakpoint.xl,
                      "mt-5":
                        _vm.$vuetify.breakpoint.lgOnly ||
                        _vm.$vuetify.breakpoint.smOnly ||
                        _vm.$vuetify.breakpoint.mdOnly,
                    },
                  },
                  [
                    _vm._v(
                      " BDT " + _vm._s(_vm.formData.numOfStudents * 60) + " TK"
                    ),
                    _c("span", { staticClass: "small" }, [_vm._v("/mo")]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: {
                  "mt-1": _vm.formData.discount > 0,
                  "amount-shrinked":
                    _vm.formData.discount > 0 && _vm.$vuetify.breakpoint.xsOnly,
                  amount: !(
                    _vm.formData.discount > 0 && _vm.$vuetify.breakpoint.xsOnly
                  ),
                },
              },
              [
                _vm._v(
                  " BDT " +
                    _vm._s(
                      _vm.getDiscountedPrice(_vm.formData.numOfStudents * 60)
                    ) +
                    " TK"
                ),
                _c("span", { staticClass: "small" }, [_vm._v("/qt")]),
              ]
            ),
            _c("div", { staticClass: "m-info" }, [
              _vm._v(
                "(BDT " + _vm._s(_vm.formData.numOfStudents * 20) + " TK/mo)"
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "pt-2 px-3", staticStyle: { height: "24px" } },
        [
          _c("v-slide-y-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.formData.selectedPlan === undefined && _vm.validating,
                    expression:
                      "formData.selectedPlan === undefined && validating",
                  },
                ],
                staticClass:
                  "decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs text-compress",
              },
              [_vm._v(" Subscription plan is required ")]
            ),
          ]),
        ],
        1
      ),
      !_vm.$vuetify.breakpoint.xsOnly
        ? _c(
            "span",
            {
              style: `font-weight: 600; font-size: ${_vm.style.smText}px; position: relative; top: ${_vm.style.instructionPosition}%`,
            },
            [
              _vm._v(
                'Press "CONTINUE" to proceed to the payment method selection'
              ),
            ]
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndUp
        ? _c("div", [
            _c(
              "button",
              {
                class: {
                  "payment-back-xl": _vm.$vuetify.breakpoint.xlOnly,
                  "payment-back-lg": _vm.$vuetify.breakpoint.lgOnly,
                  "payment-back-tab":
                    _vm.$vuetify.breakpoint.smOnly ||
                    _vm.$vuetify.breakpoint.mdOnly,
                },
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v(" BACK ")]
            ),
            _c(
              "button",
              {
                class: {
                  "payment-continue-xl": _vm.$vuetify.breakpoint.xlOnly,
                  "payment-continue-lg": _vm.$vuetify.breakpoint.lgOnly,
                  "payment-continue-tab":
                    _vm.$vuetify.breakpoint.smOnly ||
                    _vm.$vuetify.breakpoint.mdOnly,
                },
                on: { click: _vm.continueHandler },
              },
              [_vm._v(" CONTINUE ")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "monthly-plan" }, [
      _vm._v(" Annual Plan "),
      _c("span", { staticClass: "m-duration" }, [_vm._v("(12 month)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "monthly-plan" }, [
      _vm._v(" Quarterly Plan "),
      _c("span", { staticClass: "m-duration" }, [_vm._v("(3 month)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }