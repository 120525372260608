<template>
  <div class="mk-large-small-screen-side-bar">
    <div v-if="!smallScreen" class="mk-large-screen-side-bar">
      <v-list shaped>
        <v-list-item-group>
          <v-list-item class="mt-5 mb-10" exact to="/teachers/courses">
            <v-list-item-action>
              <v-icon>keyboard_arrow_left</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="white-space: normal"
                ><div>{{ courseTitle }}</div></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'batches' }">
            <v-list-item-action>
              <v-icon>group_work</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Batches</v-list-item-title>
              <v-list-item-title v-else>ব্যাচ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'lectures' }">
            <v-list-item-action>
              <v-icon>collections_bookmark</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Lectures</v-list-item-title>
              <v-list-item-title v-else>লেকচার</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'assignments' }">
            <v-list-item-action>
              <v-icon>library_books</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language"
                >Assignments</v-list-item-title
              >
              <v-list-item-title v-else>অ্যাসাইনমেন্ট</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'projects' }">
            <v-list-item-action>
              <v-icon>mdi-projector-screen-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Projects</v-list-item-title>
              <v-list-item-title v-else>প্রজেক্ট</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'exams' }">
            <v-list-item-action>
              <v-icon>assignment_turned_in</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Exams</v-list-item-title>
              <v-list-item-title v-else>পরীক্ষা</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'question-list' }">
            <v-list-item-action>
              <v-icon>assignment</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language"
                >Question Bank</v-list-item-title
              >
              <v-list-item-title v-else>প্রশ্ন ব্যাংক</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item :to="{ name: 'students' }">
            <v-list-item-action>
              <v-icon>people_alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Students</v-list-item-title>
              <v-list-item-title v-else>শিক্ষার্থী</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item :to="{ name: 'grading' }">
            <v-list-item-action>
              <v-icon>school</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Grading</v-list-item-title>
              <v-list-item-title v-else>গ্রেডিং</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'fluent-design-course' }">
            <v-list-item-action>
              <v-icon>school</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Course design</v-list-item-title>
              <v-list-item-title v-else>কোর্স রূপরেখা</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'course-details' }">
            <v-list-item-action>
              <v-icon>info</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language"
                >About Course</v-list-item-title
              >
              <v-list-item-title v-else
                >কোর্স সম্পর্কে বিস্তারিত</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <div
      v-else
      :class="['mk-small-screen-side-bar', collapseOrNonCollapseSidebar]"
    >
      <v-list shaped>
        <v-list-item-group>
          <!-- <v-list-item
          :ripple="!shouldCollapse"
          class="mt-3 mb-8"
          exact
          @click="collapseOrNonCollapseHandler"
          to="/courses"
        > -->
          <v-list-item
            :ripple="!shouldCollapse"
            class="mt-3 mb-8"
            @click="collapseOrNonCollapseHandler"
          >
            <v-list-item-action>
              <v-list-item-icon>
                <v-icon>mdi-menu</v-icon>
              </v-list-item-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            :ripple="!shouldCollapse"
            class="mb-5"
            exact
            to="/courses"
          >
            <v-list-item-action>
              <v-icon>keyboard_arrow_left</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="white-space: normal"
                ><div>{{ courseTitle }}</div></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="!shouldCollapse" :to="{ name: 'batches' }">
            <v-list-item-action>
              <v-icon>group_work</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Batches</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="!shouldCollapse" :to="{ name: 'lectures' }">
            <v-list-item-action>
              <v-icon>collections_bookmark</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Lectures</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="!shouldCollapse" :to="{ name: 'assignments' }">
            <v-list-item-action>
              <v-icon>library_books</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Assignments</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="!shouldCollapse" :to="{ name: 'projects' }">
            <v-list-item-action>
              <v-icon>mdi-projector-screen-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Projects</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :ripple="!shouldCollapse" :to="{ name: 'exams' }">
            <v-list-item-action>
              <v-icon>assignment_turned_in</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Exams</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="!shouldCollapse"
            :to="{ name: 'question-list' }"
          >
            <v-list-item-action>
              <v-icon>assignment</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Question Bank</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item :ripple="!shouldCollapse" :to="{ name: 'students' }">
            <v-list-item-action>
              <v-icon>people_alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Students</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item :ripple="!shouldCollapse" :to="{ name: 'grading' }">
            <v-list-item-action>
              <v-icon>school</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Grading</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'fluent-design-course' }">
            <v-list-item-action>
              <v-icon>school</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="!$language">Course design</v-list-item-title>
              <v-list-item-title v-else>কোর্স রূপরেখা</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="!shouldCollapse"
            :to="{ name: 'course-details' }"
          >
            <v-list-item-action>
              <v-icon>info</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>About Course</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseNavItemList",
  props: {
    partner_code: String,
    courseTitle: String,
    course_id: String
  },
  data() {
    return {
      shouldCollapse: true
    };
  },
  computed: {
    auth_url() {
      return process.env.VUE_APP_ACCOUNTS_FRONTEND_URL;
    },
    smallScreen() {
      return this.$vuetify.breakpoint.width < 800 ? true : false;
    },
    collapseOrNonCollapseSidebar() {
      return this.shouldCollapse
        ? "mk-small-screen-sidebar-collapse"
        : "mk-small-screen-sidebar-non-collapse";
    }
  },
  methods: {
    collapseOrNonCollapseHandler() {
      this.shouldCollapse = !this.shouldCollapse;
      this.$emit("collapseOrNonCollapse", this.shouldCollapse);
    }
  }
};
</script>

<style lang="scss" scoped>
.mk-large-screen-side-bar,
.mk-large-small-screen-side-bar {
  height: 100%;
}

.mk-small-screen-side-bar {
  height: 100%;
}

.mk-small-screen-sidebar-collapse {
  width: 65px;
  transition: 0.5s;
}

.mk-small-screen-sidebar-non-collapse {
  width: 256px;
  transition: 0.5s;
}

.nav-item-text {
  @media screen and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }
}
</style>
