var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "118",
        height: "118",
        viewBox: "0 0 118 118",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", { attrs: { cx: "59", cy: "59", r: "59", fill: "#F59E19" } }),
      _c("path", {
        attrs: {
          d: "M88.0544 74.1876L65.1124 33.6044C64.493 32.5085 63.602 31.5985 62.529 30.9658C61.4561 30.3331 60.239 30 59 30C57.761 30 56.5439 30.3331 55.471 30.9658C54.398 31.5985 53.507 32.5085 52.8876 33.6044L29.9456 74.1876C29.3261 75.2834 29 76.5264 29 77.7917C29 79.057 29.3261 80.2999 29.9455 81.3957C30.5649 82.4915 31.4559 83.4015 32.5287 84.0342C33.6016 84.6668 34.8187 85 36.0576 85H81.9416C83.1805 85.0001 84.3977 84.6671 85.4707 84.0345C86.5437 83.4019 87.4348 82.4919 88.0543 81.3961C88.6738 80.3003 89 79.0572 89 77.7919C89 76.5265 88.6739 75.2834 88.0544 74.1876ZM85.0088 79.6004C84.7008 80.1529 84.2543 80.6116 83.7153 80.9294C83.1762 81.2473 82.564 81.4129 81.9416 81.4091H36.0576C35.4356 81.4101 34.8243 81.2435 34.2854 80.9263C33.7465 80.6091 33.299 80.1525 32.9879 79.6023C32.6769 79.0522 32.5134 78.4281 32.5138 77.7928C32.5142 77.1576 32.6786 76.5336 32.9904 75.9839L55.9332 35.3998C56.2435 34.8493 56.6903 34.392 57.2287 34.074C57.7671 33.756 58.3781 33.5886 59 33.5886C59.6219 33.5886 60.2329 33.756 60.7713 34.074C61.3097 34.392 61.7566 34.8493 62.0668 35.3998L85.0088 75.9831C85.3232 76.5317 85.489 77.156 85.489 77.7917C85.489 78.4275 85.3232 79.0518 85.0088 79.6004Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.759 47.9232H57.2422V65.8804H60.759V47.9232Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.9983 69.472C58.5348 69.472 58.0816 69.6123 57.6962 69.8753C57.3108 70.1383 57.0104 70.5121 56.8329 70.9494C56.6554 71.3867 56.6089 71.868 56.6992 72.3323C56.7895 72.7966 57.0126 73.2232 57.3402 73.5581C57.6679 73.8929 58.0854 74.1211 58.5399 74.2137C58.9945 74.3062 59.4658 74.2591 59.8941 74.0781C60.3224 73.8972 60.6886 73.5907 60.9464 73.1972C61.2042 72.8038 61.3419 72.3411 61.3423 71.8677C61.3418 71.2328 61.0948 70.6239 60.6553 70.1748C60.2159 69.7257 59.62 69.4729 58.9983 69.472Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }