var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "container-xl": _vm.$vuetify.breakpoint.xlOnly,
        "container-lg": _vm.$vuetify.breakpoint.lgOnly,
        "container-tab":
          _vm.$vuetify.breakpoint.smOnly || _vm.$vuetify.breakpoint.mdOnly,
        "container-mobile": _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c("div", { staticClass: "step-2" }, [_vm._v("Step 2 Of 3")]),
      _c("div", { staticClass: "payment-method" }, [_vm._v("Payment Method")]),
      _c("submission-alert-message", {
        ref: "banner",
        staticClass: "pt-3",
        attrs: { "should-scroll-to-top": "", message: _vm.bannerMessage },
        model: {
          value: _vm.showBanner,
          callback: function ($$v) {
            _vm.showBanner = $$v
          },
          expression: "showBanner",
        },
      }),
      _c(
        "div",
        {
          style: `font-weight: 600; font-size: ${_vm.instructionFont}px; position: relative; top: ${_vm.instructionPosition.top}%`,
        },
        [_vm._v(" Please select your preffered payment method from below ")]
      ),
      _c(
        "div",
        { staticClass: "d-flex bkash-and-nagad" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { rules: [_vm.required] },
                  model: {
                    value: _vm.formData.method,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "method", $$v)
                    },
                    expression: "formData.method",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "bkash" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [_c("div", [_c("strong", [_vm._v("Bkash")])])]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "nagad" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [_c("div", [_c("strong", [_vm._v("Nagad")])])]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "debit/credit" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", [
                              _c("strong", [
                                _vm._v("Other Credit/Debit Cards"),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "internet" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", [
                              _c("strong", [_vm._v("Internet Banking")]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "mobile" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", [
                              _c("strong", [_vm._v("Other Mobile Banking")]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          style: `font-weight: 600; font-size: ${_vm.instructionFont}px; position: relative; top: ${_vm.instructionPosition.bottom}%`,
        },
        [
          _vm._v(
            " Please review our policies and click on the checkboxes to continue "
          ),
        ]
      ),
      _c("div", { staticClass: "m-agreement" }, [
        _c("div", { staticClass: "agree-to-pay" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.agreeToPay,
                expression: "formData.agreeToPay",
              },
            ],
            staticStyle: { color: "red" },
            attrs: { type: "checkbox", name: "agreetopay", id: "agreetopay" },
            domProps: {
              checked: Array.isArray(_vm.formData.agreeToPay)
                ? _vm._i(_vm.formData.agreeToPay, null) > -1
                : _vm.formData.agreeToPay,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.formData.agreeToPay,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.formData, "agreeToPay", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.formData,
                        "agreeToPay",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.formData, "agreeToPay", $$c)
                }
              },
            },
          }),
          _c("label", { attrs: { for: "agreetopay" } }, [
            _vm._v(
              'By clicking "Continue", you agree: You will be charged BDT ' +
                _vm._s(_vm.amount) +
                " (plus VAT) " +
                _vm._s(_vm.plan) +
                ". At the end of your one-term, you will have to renew your subscription (price may subject to change). No annual commitment required after the first year. If our product has not meet the promises we make, you can cancel anytime via Customer Support. You must cancel your subscription within 30 days after purchase to get a full refund and avoid fee."
            ),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.agreeWithTerms,
                expression: "formData.agreeWithTerms",
              },
            ],
            attrs: { type: "checkbox", name: "terms", id: "terms" },
            domProps: {
              checked: Array.isArray(_vm.formData.agreeWithTerms)
                ? _vm._i(_vm.formData.agreeWithTerms, null) > -1
                : _vm.formData.agreeWithTerms,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.formData.agreeWithTerms,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.formData,
                        "agreeWithTerms",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.formData,
                        "agreeWithTerms",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.formData, "agreeWithTerms", $$c)
                }
              },
            },
          }),
          _c("label", { attrs: { for: "terms" } }, [
            _c("span", [_vm._v("Yes,")]),
            _vm._v(" I agree with Mouchak "),
            _c("a", { attrs: { href: _vm.termsURL } }, [
              _vm._v("Terms of Condition"),
            ]),
            _vm._v(", "),
            _c("a", { attrs: { href: _vm.policyURL } }, [
              _vm._v("Privacy Policy"),
            ]),
            _vm._v(" and "),
            _c("a", { attrs: { href: _vm.refundURL } }, [
              _vm._v("Refund Policy"),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pt-2 px-3", staticStyle: { height: "24px" } },
        [
          _c("v-slide-y-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.policyOk && _vm.validating,
                    expression: "!policyOk && validating",
                  },
                ],
                staticClass:
                  "decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs text-compress",
              },
              [_vm._v(" User agreement is required ")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          style: `font-weight: 600; font-size: ${_vm.instructionFont}px; position: relative; top: ${_vm.instructionPosition.top}%`,
        },
        [_vm._v(' Press "CONTINUE" to stat the payment process ')]
      ),
      !_vm.$vuetify.breakpoint.xsOnly
        ? _c("div", { staticClass: "d-flex action-buttons" }, [
            _c(
              "button",
              {
                staticClass: "back-button",
                on: {
                  click: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              },
              [_vm._v("BACK")]
            ),
            _c(
              "button",
              {
                staticClass: "continue-button",
                on: { click: _vm.checkPaymentMethod },
              },
              [_vm._v(" CONTINUE ")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }