var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isActive
      ? _c(
          "div",
          {
            staticClass:
              "pa-2 rounded d-flex align-center justify-space-between overflow-hidden border border-solid border-decline",
            class: _vm.cardColor,
          },
          [
            _c(
              "div",
              {
                staticClass: "sm:text-xs d-inline-block",
                class: _vm.textClass,
                staticStyle: { "-webkit-font-smoothing": "antialiased" },
              },
              [_vm._v(" " + _vm._s(_vm.message) + " ")]
            ),
            _c(
              "v-btn",
              {
                attrs: { id: "close-button", icon: "" },
                on: { click: _vm.close },
              },
              [
                _c("v-icon", { attrs: { color: _vm.closeColor } }, [
                  _vm._v("mdi-close-thick"),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }