import _ from "lodash";
export function addNamespace(namespace, types) {
  Object.keys(types).forEach(k => {
    types[k] = `${namespace}/${types[k]}`;
  });
}

/**
 *
 * @param {AxiosResponse} res
 * @return {*}
 */
export function checkStatus(res) {
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    throw new Error(res.data.message);
  }
}

export function delayPromise(t) {
  return new Promise(function(resolve) {
    setTimeout(resolve.bind(null), t);
  });
}

export function initials(name) {
  return name
    .toUpperCase()
    .split(" ")
    .map(w => w[0])
    .join("");
}

export function firstLetterUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shouldRefreshCache(now, lastFetched) {
  return true;
  // const diff = (now - lastFetched) / 1000 > 1800;
  // Logger.log(
  //   "Should Refresh: [now, lastFetched, diff]",
  //   now,
  //   lastFetched,
  //   diff,
  // );
  // return diff;
}

export const Logger = {
  debug: process.env.VUE_APP_DEBUG,
  log(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.log(...args);
    }
  },
  dir(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.dir(...args);
    }
  },
  error(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.error(...args);
    }
  },
  info(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.info(...args);
    }
  }
};
