var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "mk-navigation-drawer",
      attrs: {
        app: "",
        clipped: "",
        color: "#ffff",
        floating: "",
        permanent: "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column justify-center align-center mt-5 mb-2",
        },
        [
          _c("user-avatar", {
            attrs: { "user-name": _vm.userName, color: "primary", size: "64" },
          }),
          _c("div", { staticClass: "title mt-4" }, [
            _vm._v(_vm._s(_vm.userName)),
          ]),
        ],
        1
      ),
      _vm._t("drawer-items"),
      _vm.$slots["extra-items"] ? _c("v-divider") : _vm._e(),
      _vm._t("extra-items"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }