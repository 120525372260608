var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mk-page-title", staticStyle: { "max-width": "1115px" } },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _vm._t("left"),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _vm.previousLocation
                        ? _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: "black",
                                    icon: "",
                                    large: "",
                                  },
                                  on: { click: _vm.previousPage },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Go Back")])]
          ),
          _vm._t("title", function () {
            return [
              _c("div", { staticClass: "page-title" }, [
                _vm._v(_vm._s(_vm.pageTitle)),
              ]),
            ]
          }),
          _vm._t("action"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }