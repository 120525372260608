<template>
  <v-app-bar
    color="#ffffff"
    class="top-bar"
    clipped-left
    clipped-right
    app
    fixed
    flat
  >
    <slot name="title">
      <fluent-full-logo></fluent-full-logo>
      <app-name :name="appName"></app-name>
    </slot>
    <v-row justify="end" align="center">
      <slot name="items"></slot>
      <div v-if="defaultItems" class="d-flex align-center">
        <MService></MService>
        <AccountMenu
          :user-name="userName"
          @logout="$emit('logout')"
        ></AccountMenu>
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
import FluentFullLogo from "./FluentFullLogo.vue";
import AppName from "./AppName";
import MService from "./MService";
import AccountMenu from "./AccountMenu";
export default {
  name: "TopBar",
  components: { AccountMenu, MService, AppName, FluentFullLogo },
  props: {
    appName: String,
    userName: String,
    defaultItems: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";
.top-bar {
  border-bottom: 1px solid $orange !important;
}
</style>
