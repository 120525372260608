var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-6 rounded" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "align-center justify-center flex-column",
              staticStyle: { background: "#FAFBFD" },
            },
            [
              _c("img", { attrs: { src: _vm.icon, alt: "icon" } }),
              _c(
                "div",
                {
                  staticClass:
                    "pt-4 ft-medium xl:text-base lg:text-base md:text-base sm:text-base",
                },
                [_vm._v(" " + _vm._s(_vm.header) + " ")]
              ),
            ]
          ),
          _c(
            "v-row",
            {
              staticClass: "px-4",
              attrs: { justify: "center", align: "center", "no-gutters": "" },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "ma-0 xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                },
                [_vm._v(" " + _vm._s(_vm.message) + " ")]
              ),
            ]
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-12 py-4" },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "rounded primary white--text",
                      attrs: { text: "", height: "48" },
                      on: { click: _vm.buttonAction },
                    },
                    [_vm._v(_vm._s(_vm.buttonText) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }