import Vue from "vue";
import sortBy from "lodash/sortBy";
import CourseApi from "@ecf/modules/course/CourseApi";
import get from "lodash/get";
import { Logger, shouldRefreshCache } from "@ecf/global/helpers";
export default {
  namespaced: true,
  state: {
    courseMap: {},
    currentCourseOwner: {},
    lastFetched: {},
    category: {}
  },
  getters: {
    courses: state => {
      const partner_codes = Object.keys(state.courseMap);
      const mapToList = courseMap =>
        sortBy(Object.values(courseMap), "course_key");
      return partner_codes.reduce((prev, code) => {
        prev[code] = mapToList(state.courseMap[code]);
        return prev;
      }, {});
    }
  },
  mutations: {
    setCourses(state, { courses, partner_code }) {
      const courseMap = courses.reduce((prev, org) => {
        prev[org.course_id] = org;
        return prev;
      }, {});
      Vue.set(state.courseMap, partner_code, courseMap);
    },
    setCurrentOwner(state, { currentOwnerId }) {
      state.currentCourseOwner = currentOwnerId;
    },
    setCategoryList(state, { items }) {
      state.category = items;
    }
  },
  actions: {
    async list(ctx, { partner_code, refresh = false }) {
      const now = new Date().getTime();
      const lastFetched = get(ctx.state.lastFetched, partner_code, -1);
      if (refresh || shouldRefreshCache(now, lastFetched)) {
        const courses = await CourseApi.find({ partner_code });
        ctx.state.lastFetched[partner_code] = now;
        ctx.commit("setCourses", { courses, partner_code });
        return courses;
      } else {
        Logger.log("Returning from cache");
        return ctx.getters["courses"][partner_code];
      }
    },
    async setOwner(ctx, { currentOwnerId }) {
      ctx.commit("setCurrentOwner", { currentOwnerId });
    },
    async create(ctx, { partner_code, courseData }) {
      await CourseApi.save(partner_code, courseData);
      ctx.dispatch("list", { partner_code, refresh: true });
    },
    async update(ctx, { partner_code, course_id, course_data }) {
      await CourseApi.update(partner_code, course_id, course_data);
    },
    async bulkEnroll(ctx, { studentIds, batches, courseId }) {
      return CourseApi.bulkEnroll(studentIds, batches, courseId);
    },
    async bulkEnrollv2(
      ctx,
      { fileName, batches, courseId, partnerCode, presignedKey }
    ) {
      return CourseApi.bulkEnrollv2(
        fileName,
        batches,
        courseId,
        partnerCode,
        presignedKey
      );
    },
    async uploadEnrollFile(ctx, { policy, fileBlob }) {
      return CourseApi.uploadEnrollFile(policy, fileBlob);
    },
    async bulkEnrollForward(ctx, { url, data }) {
      const response = await CourseApi.bulkEnrollForwardStepOne(url, data);
      // This request returns user_id and other info from the data in xl file
      // also creates the new users if they do not have an existing account

      return await CourseApi.bulkEnrollForwardStepTwo(response);
      // Inserts the users in the designated course
    },
    async catagories(ctx, { partner_code }) {
      if (!ctx.state.category) {
        const items = await CourseApi.retrieveCategorySubCategory(partner_code);
        ctx.commit("setCategoryList", items);
      }
      return ctx.state.category;
    }
  }
};
