var render = function render(_c, _vm) {
  return _c(
    "span",
    { staticClass: "app-name caption text-uppercase font-weight-bold ml-1" },
    [_vm._v(_vm._s(_vm.props.name))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }