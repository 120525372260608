var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.step === 1
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-wrap",
          class: {
            "payment-padding-xl": _vm.$vuetify.breakpoint.xlOnly,
            "pt-6": _vm.$vuetify.breakpoint.lgOnly,
            "pt-8":
              _vm.$vuetify.breakpoint.smOnly || _vm.$vuetify.breakpoint.mdOnly,
            "pt-5": _vm.$vuetify.breakpoint.xsOnly,
          },
          staticStyle: {
            background: "#fcfcfc",
            "justify-content": "center",
            height: "max(100vh, 100%)",
          },
        },
        [
          _c("payment-form", {
            on: {
              continue: function ($event) {
                return _vm.goToStep(2)
              },
            },
            model: {
              value: _vm.formData,
              callback: function ($$v) {
                _vm.formData = $$v
              },
              expression: "formData",
            },
          }),
          _c("div", { style: `width: ${_vm.gap}px;` }),
          _c("plans"),
          _vm.$vuetify.breakpoint.xsOnly
            ? _c(
                "p",
                {
                  style: `font-weight: 600; font-size: ${_vm.instructionFont}px; position: relative; top: 2%`,
                },
                [
                  _vm._v(
                    ' Press "CONTINUE" to proceed to payment selection page '
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "d-flex flex-wrap",
          class: {
            "payment-padding-xl": _vm.$vuetify.breakpoint.xlOnly,
            "pt-6": _vm.$vuetify.breakpoint.lgOnly,
            "pt-8":
              _vm.$vuetify.breakpoint.smOnly || _vm.$vuetify.breakpoint.mdOnly,
            "pt-5": _vm.$vuetify.breakpoint.xsOnly,
          },
          staticStyle: {
            background: "#fcfcfc",
            "justify-content": "center",
            height: "max(100vh, 100%)",
          },
        },
        [
          _c("payment-method-chooser", {
            attrs: {
              amount: _vm.overAllAmount,
              plan: _vm.formData.selectedPlan,
            },
            on: {
              back: function ($event) {
                return _vm.goToStep(1)
              },
              continue: _vm.executePayment,
            },
            model: {
              value: _vm.paymentData,
              callback: function ($$v) {
                _vm.paymentData = $$v
              },
              expression: "paymentData",
            },
          }),
          _c("div", { style: `width: ${_vm.gap}px;` }),
          _c("order-summary", { attrs: { info: _vm.formData } }),
          _vm.$vuetify.breakpoint.xsOnly
            ? _c(
                "p",
                {
                  style: `font-weight: 600; font-size: ${_vm.instructionFont}px; position: relative; top: 10%`,
                },
                [_vm._v(' Press "CONTINUE" to start the payment process ')]
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "500" },
              model: {
                value: _vm.waiting,
                callback: function ($$v) {
                  _vm.waiting = $$v
                },
                expression: "waiting",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-4" },
                [
                  _c("v-card-title", { staticClass: "justify-center" }, [
                    _vm._v("Please Wait"),
                  ]),
                  _c("div", { staticStyle: { height: "20px" } }),
                  _c(
                    "v-card-text",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }