import Vue from "vue";
import { Backend } from "~/utils/backend";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";

const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
const $paymentBackend = new Backend(process.env.VUE_APP_BACKEND_EVENTS_URL);

export default {
  getSubscription() {
    return $backend
      .post("teachers_get_subscription")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getSMS() {
    return $backend
      .post("teachers_get_sms_subscription")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getSMSSubscriptionHistory() {
    return $backend
      .post("teachers_get_sms_subscription_history")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getIncomeInformation() {
    return $backend
      .post("teacher_get_income_data")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getDisbersements(page) {
    return $backend
      .post("teachers_get_disbrersements", { page })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getOwnTransactionPages() {
    return $backend
      .post("teachers_get_own_transaction_pages")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getOwnTransactions(page) {
    return $backend
      .post("teachers_get_own_transactions", { page })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getStudentTransactionPages() {
    return $backend
      .post("teachers_get_student_transaction_pages")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getStudentTransactions(page) {
    return $backend
      .post("teachers_get_student_transactions", { page })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getTotalDueStudents() {
    return $backend
      .post("teachers_get_due_students_count")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getDueStudentPages() {
    return $backend
      .post("teachers_get_due_students_pages")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getDueStudents(page) {
    return $backend
      .post("teachers_get_due_students", { page })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  toggleAutoRenew(status) {
    return $backend
      .post("teachers_update_subscription_auto_renewal", { status })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  purchaseSubscription(items, method) {
    return $backend
      .post("teachers_buy_subscription", { route: 'teachers', items, method })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  updateSubsciption(items, method) {
    return $backend
      .post("teachers_update_subscription", { route: 'teachers', items, method })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  buySMS(items, method) {
    return $backend
      .post("teachers_buy_sms", {route: 'teachers', items, method })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  updatePurchaseStatus(data) {
    return $backend
      .post("teachers_update_payment_status", { data })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  updateActiveStatus(data) {
    return $backend
      .post("teachers_activate_deactivate_students", { data })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  verifySubscriptionPurchase(transaction_id, transaction_status) {
    return $paymentBackend
      .get("payment_verify_subscription", {
        params: { transaction_id, transaction_status }
      })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  verifySMSSubscriptionPurchase(transaction_id, transaction_status) {
    return $paymentBackend
      .get("payment_verify_sms_subscription", {
        params: { transaction_id, transaction_status },
      })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  // payment_verify_subscription_upgrade
  verifySubscriptionUpgrade(transaction_id, transaction_status) {
    return $paymentBackend
      .get("payment_verify_subscription_upgrade", {
        params: { transaction_id, transaction_status }
      })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getReceipt(transaction_id, download) {
    return $backend
      .post("teachers_get_receipt", {
        transaction_id,
        download
      })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
};
