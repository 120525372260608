<template>
  <v-container>
    <v-container>
      <v-btn
        class="pa-2 ma-2"
        outlined
        color="primary"
        style="background: white"
        rounded
        to="/students"
        ><v-icon left>arrow_back</v-icon
        >{{ !$language ? "My Courses" : "আমার কোর্সগুলি" }}</v-btn
      >
    </v-container>
    <m-browse-courses-p v-if="!courseId"></m-browse-courses-p>
    <lazy-load v-else :item="course">
      <m-course-details :key="courseId" :course="course"></m-course-details>
    </lazy-load>
  </v-container>
</template>

<script>
import MBrowseCoursesP from "#ef/course/components/MBrowseCoursesP";
import MCourseDetails from "#ef/course/components/MCourseDetails";
import courseService from "#ef/course/services/CourseService";

export default {
  name: "MBrowseCourses",
  components: { MCourseDetails, MBrowseCoursesP },
  props: {
    courseId: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    courseData: undefined,
    course: undefined,
    batches: [],
  }),
  computed: {},
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    if (this.courseId) {
      const courses = await courseService.getCourse(this.courseId);
      this.course = courses[0];
    }
  },
};
</script>
