<template>
  <div
    :class="{
      'container-xl': $vuetify.breakpoint.xlOnly,
      'container-lg': $vuetify.breakpoint.lgOnly,
      'container-tab': $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
      'container-mobile': $vuetify.breakpoint.xsOnly,
    }"
  >
    <div class="step-2">Step 2 Of 3</div>
    <div class="payment-method">Payment Method</div>
    <submission-alert-message
      should-scroll-to-top
      class="pt-3"
      ref="banner"
      v-model="showBanner"
      :message="bannerMessage"
    ></submission-alert-message>
    <div
      :style="`font-weight: 600; font-size: ${instructionFont}px; position: relative; top: ${instructionPosition.top}%`"
    >
      Please select your preffered payment method from below
    </div>
    <div class="d-flex bkash-and-nagad">
      <v-form ref="form">
        <v-radio-group :rules="[required]" v-model="formData.method">
          <v-radio value="bkash">
            <template v-slot:label>
              <div>
                <strong>Bkash</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="nagad">
            <template v-slot:label>
              <div>
                <strong>Nagad</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="debit/credit">
            <template v-slot:label>
              <div>
                <strong>Other Credit/Debit Cards</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="internet">
            <template v-slot:label>
              <div>
                <strong>Internet Banking</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="mobile">
            <template v-slot:label>
              <div>
                <strong>Other Mobile Banking</strong>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-form>

      <!-- <img
        :src="require('@af/modules/payment/assets/Bkash-logo.png')"
        alt="bkash"
        :class="{ 'm-selected': formData.method === 'bkash' }"
        @click="formData.method = 'bkash'"
      />
      <img
        :src="require('@af/modules/payment/assets/Nagad_Logo.svg')"
        alt="nagad"
        :class="{ 'm-selected': formData.method === 'nagad' }"
        @click="formData.method = 'nagad'"
      /> -->
    </div>

    <!-- <div
      class="other-cards"
      :class="{ 'm-selected': formData.method === 'debit/credit' }"
      @click="formData.method = 'debit/credit'"
    >
      <img :src="require('@af/modules/payment/assets/visa-logo.jpg')" alt="" />
      <img :src="require('@af/modules/payment/assets/master-card.png')" alt="" />
      <img
        :src="require('@af/modules/payment/assets/american-express.png')"
        alt=""
      />
      <div class="text">Other Credit/Debit Cards</div>
    </div> -->

    <!-- <div class="other-banking">
      <div
        class="internet-banking"
        :class="{ 'm-selected': formData.method === 'internet' }"
        @click="formData.method = 'internet'"
      >
        <img
          :src="require('@af/modules/payment/assets/internet-banking.png')"
          alt=""
        />
        <div class="text">Internet Banking</div>
      </div>
      <div
        class="mobile-banking"
        :class="{ 'm-selected': formData.method === 'mobile' }"
        @click="formData.method = 'mobile'"
      >
        <img
          :src="require('@af/modules/payment/assets/mobile-banking.png')"
          alt=""
        />
        <div class="text">Other Mobile Banking</div>
      </div>
    </div> -->
    <div
      :style="`font-weight: 600; font-size: ${instructionFont}px; position: relative; top: ${instructionPosition.bottom}%`"
    >
      Please review our policies and click on the checkboxes to continue
    </div>
    <div class="m-agreement">
      <div class="agree-to-pay">
        <input
          type="checkbox"
          v-model="formData.agreeToPay"
          name="agreetopay"
          id="agreetopay"
          style="color: red"
        />
        <label for="agreetopay"
          >By clicking "Continue", you agree: You will be charged BDT
          {{ amount }} (plus VAT) {{ plan }}. At the end of your one-term, you
          will have to renew your subscription (price may subject to change). No
          annual commitment required after the first year. If our product has
          not meet the promises we make, you can cancel anytime via Customer
          Support. You must cancel your subscription within 30 days after
          purchase to get a full refund and avoid fee.</label
        >
        <input
          type="checkbox"
          name="terms"
          id="terms"
          v-model="formData.agreeWithTerms"
        />
        <label for="terms"
          ><span>Yes,</span> I agree with Mouchak
          <a :href="termsURL">Terms of Condition</a>,
          <a :href="policyURL">Privacy Policy</a> and
          <a :href="refundURL">Refund Policy</a></label
        >
      </div>
    </div>
    <div class="pt-2 px-3" style="height: 24px">
      <v-slide-y-transition>
        <div
          v-show="!policyOk && validating"
          class="decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs text-compress"
        >
          User agreement is required
        </div>
      </v-slide-y-transition>
    </div>
    <div
      :style="`font-weight: 600; font-size: ${instructionFont}px; position: relative; top: ${instructionPosition.top}%`"
    >
      Press "CONTINUE" to stat the payment process
    </div>
    <div class="d-flex action-buttons" v-if="!$vuetify.breakpoint.xsOnly">
      <button @click="$emit('back')" class="back-button">BACK</button
      ><button @click="checkPaymentMethod" class="continue-button">
        CONTINUE
      </button>
    </div>
  </div>
</template>

<script>
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "PaymentMethodChooser",
  components: { SubmissionAlertMessage },
  mixins: [FieldValidations],
  props: {
    amount: { type: Number, required: true },
    plan: { type: String, required: true },
    value: { type: Object, required: true },
  },
  watch: {
    formData: {
      handler(value) {
        this.$emit("input", value);
      },
      deep: true,
    },
    policyOk: {
      handler(value) {
        if (value && this.policyAlert) this.policyAlert = false;
      },
    },
    ok: {
      handler(value) {
        if (value) this.showBanner = false;
      },
    }
  },
  computed: {
    instructionPosition() {
      let bp = this.$vuetify.breakpoint.name;
      switch (bp) {
        case "xs":
          return { top: 1, bottom: 1 };
        case "sm":
          return { top: 5, bottom: 2 };
        case "md":
          return { top: 1, bottom: 2 };
        case "lg":
          return { top: 2, bottom: 3 };
        case "xl":
          return { top: 2, bottom: 6 };
        default:
          return {};
      }
    },
    instructionFont() {
      let bp = this.$vuetify.breakpoint.name;
      switch (bp) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 11;
        case "lg":
          return 12;
        case "xl":
          return 14;
        default:
          return 10;
      }
    },
    termsURL() {
      return process.env.VUE_APP_FRONTEND_URL + "/terms-of-use";
    },
    policyURL() {
      return process.env.VUE_APP_FRONTEND_URL + "/privacy-policy";
    },
    refundURL() {
      return (
        process.env.VUE_APP_FRONTEND_URL +
        "/return-refund-and-subscription-policy"
      );
    },
    policyOk() {
      return this.formData.agreeToPay && this.formData.agreeWithTerms;
    },
    ok(){
      let amountOk = true;
      let methodOk = false;
      if (this.formData.method) {
        methodOk = true;
        if (
          ["internet", "mobile", "debit/credit"].includes(
            this.formData.method
          ) &&
          this.amount < 100
        ) {
          amountOk = false;
        }
      }

      return amountOk && methodOk && this.policyOk;
    },
    bannerMessage() {
      if (
        ["internet", "mobile", "debit/credit"].includes(this.formData.method) &&
        this.amount < 100
      )
        return "Can not pay less than 100 bdt for the selected method";
      else return "Must do necessary steps";
    },
  },
  data() {
    return {
      formData: { method: undefined, agreeToPay: false, agreeWithTerms: false },
      showBanner: false,
      validating: false,
    };
  },
  mounted() {
    this.formData.method = this.value.method;
    this.formData.agreeToPay = this.value.agreeToPay;
    this.formData.agreeWithTerms = this.value.agreeWithTerms;
  },
  methods: {
    checkPaymentMethod() {
      if (this.ok) {
        this.$emit("continue");
      } else {
        this.validating = true;
        this.$refs.form.validate();
        if (!this.showBanner) this.showBanner = true;
        else {
          this.$refs.banner.flash();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@af/modules/payment/styles/payment-method-chooser";
</style>
