import { IeltsStudentsApi } from "./dashboardService";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    courseSections: {},
    courseModules: {},
    moduleContents: {}
  },
  mutations: {
    set(state, { data, courseId }) {
      Vue.set(state.courseSections, courseId, data.sections || []);
      Vue.set(state.courseModules, courseId, data.modules);
      let contents = data.object_details;
      let exams = data.exams;
      Vue.set(state.moduleContents, courseId, { ...contents, ...exams });
    }
  },
  actions: {
    async getCourseDesign(ctx, { courseId, studentId }) {
      let data = await IeltsStudentsApi.getCourseDesign(courseId, studentId);
      ctx.commit("set", { data, courseId });
    }
  }
};
