var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "m-app-bar align-center",
      attrs: {
        color: "#ffffff",
        "clipped-left": "",
        app: "",
        fixed: "",
        flat: "",
      },
    },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideMouchakLogo,
            expression: "!hideMouchakLogo",
          },
        ],
        staticClass: "clickable ml-sm-8 ml-md-16",
        attrs: {
          height: "49",
          width: "79",
          src: require("@/assets/ielts/ielts-logo.svg"),
          alt: "IELTS Logo",
        },
        on: {
          click: function ($event) {
            return _vm.$router.push("/")
          },
        },
      }),
      _vm._t("options"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }