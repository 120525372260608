import Vue from "vue";
import { Backend } from "~/utils/backend";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";

const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
const $paymentBackend = new Backend(process.env.VUE_APP_BACKEND_EVENTS_URL);

export default {
  getSubscriptionPlans() {
    return $paymentBackend
      .post("payment_get_available_subscription_plans")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getSubscriptionPlanFeatures(plan_id) {
    return $paymentBackend
      .post("payment_get_subscription_plan_features", { plan_id })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getOwnTransactionPages() {
    return $backend
      .post("student_get_transaction_pages")
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getOwnTransactions(page) {
    return $backend
      .post("students_get_transactions", { page })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  makePayment(type, package_id, course_id, batch_id, method, amount) {
    let data = {};
    if (type === "package") {
      data = { route: "students", type, package_id, method, amount };
    } else {
      data = { route: "students", type, course_id, batch_id, method, amount };
    }
    return $backend
      .post("students_make_payment", data)
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  purchasePlan(plan_id, content_url) {
    return $paymentBackend
      .post("payment_init_update_subscription_plan", { plan_id, content_url })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  verifyPlanPurchase(transaction_id, transaction_type, transaction_status) {
    return $paymentBackend
      .post("payment_verify_update_subscription_plan", {
        transaction_id,
        transaction_type,
        transaction_status
      })
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getReceipt(transaction_id, download) {
    return $backend
      .post("students_get_receipt", {
        transaction_id,
        download
      })
      .then(checkStatus)
      .then(loadDataWithContent);
  }
};
