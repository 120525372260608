import Vue from "vue";
import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";

const convertTo24Hour = time =>
  time.amPm === "AM" ? time.hour : time.hour + 12;
const BatchMapper = {
  fromLocalToRemote(batchData) {
    const batch = {
      batch_title: batchData.title,
      summary: batchData.summary,
      description: batchData.description,
      teacher_info: batchData.teacher_id,
      batch_type: batchData.batch_type,
      price: batchData.price,
      payment_type: batchData.payment_type,
      begin_date: batchData.begin_date,
      end_date: batchData.end_date
    };

    const days = [];
    for (const [day, _class] of Object.entries(batchData.schedule)) {
      if (_class) {
        const startTime =
          `${convertTo24Hour(_class.from)}`.padStart(2, "0") +
          ":" +
          `${_class.from.minute}`.padStart(2, "0");
        const endTime =
          `${convertTo24Hour(_class.to)}`.padStart(2, "0") +
          ":" +
          `${_class.to.minute}`.padStart(2, "0");
        days.push(`${day}#${startTime}#${endTime}`);
      }
    }
    batch.days = days;
    if (!days.length) Vue.delete(batch, "days");

    return batch;
  }
};

export default {
  URL: "batches/",

  /**
   * @return Promise<AxiosResponse>
   * @param courseId
   * @param opts
   */
  find(courseId, partner_code, opts = { shouldSendPartnerCode: false }) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("teachers_get_batches", {
        params: { course_id: courseId, partner_code: partner_code },
        ...opts
      })
      .then(checkStatus)
      .then(r => r.data.content.batches);
  },

  /**
   * @return Promise
   * @param courseId
   * @param batch
   * @param headers
   */
  save(courseId, batch, headers = {}) {
    return $backend
      .post(
        "teachers_post_batches",
        { course_id: courseId, ...BatchMapper.fromLocalToRemote(batch) },
        headers
      )
      .then(checkStatus)
      .then(r => r.data.content);
  },

  delete(model, courseId, opts = {}) {
    const data = {
      batch_id: model.batch_id,
      course_id: courseId
    };

    let config = { data, ...opts };
    return $backend.delete("teachers_delete_batches", config).then(checkStatus);
  },

  async update(courseId, batchId, batch, opts = {}) {
    await $backend
      .post("teachers_edit_batches", {
        course_id: courseId,
        batch_id: batchId,
        ...BatchMapper.fromLocalToRemote(batch)
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async removeStudent(data) {
    await $backend.post("teachers_remove_enrollment", data).then(checkStatus);
  },

  async retrieveBatchDetails(partner_code, courseId, batchId) {
    return $backend
      .get("teachers_get_batch", {
        params: {
          course_id: courseId,
          partner_code: partner_code,
          batch_id: batchId
        }
      })
      .then(checkStatus)
      .then(r => r.data.content.batch);
  },

  async retrieveStudentInfo(partner_code, batch_Id, course_Id) {
    return $backend
      .get("teachers_get_batch_student_info", {
        params: {
          course_id: course_Id,
          partner_code: partner_code,
          batch_id: batch_Id
        }
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async retrieveOrganizationStudentList(partner_code, course_Id) {
    return $backend
      .get("teachers_get_org_students_info", {
        params: { partner_code: partner_code }
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async retrieveClassDateList(partner_code, course_id, batch_id) {
    return $backend
      .get("teacher_get_class_dates", {
        params: {
          course_id: course_id,
          partner_code: partner_code,
          batch_id: batch_id
        }
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async updateAttendance(partner_code, course_id, batch_id, student_ids, date) {
    return $backend
      .post("teachers_insert_update_attendance", {
        partner_code: partner_code,
        course_id: course_id,
        batch_id: batch_id,
        student_ids: student_ids,
        date: date
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async retrieveAttendance(partner_code, course_id, batch_id, date) {
    return $backend
      .post("teachers_get_attendance", {
        partner_code: partner_code,
        course_id: course_id,
        batch_id: batch_id,
        date: date
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async updateBatchStudentList(partner_code, course_id, batch_id, data) {
    return $backend
      .post("teachers_add_remove_multiple_students", {
        partner_code: partner_code,
        course_id: course_id,
        batch_id: batch_id,
        student_ids: data
      })
      .then(checkStatus)
      .then(response => response.data.content.message);
  }
};
