var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-main",
        { class: [_vm.collapseOrNonCollapseSidebar] },
        [
          _c("the-top-bar"),
          _c("AppNavigationDrawer", {
            attrs: { "user-name": _vm.user.name },
            scopedSlots: _vm._u([
              {
                key: "drawer-items",
                fn: function () {
                  return [
                    _c("CourseNavItemList", {
                      attrs: {
                        "course-title": _vm.course.course_title,
                        partner_code: _vm.partner_code,
                      },
                      on: {
                        collapseOrNonCollapse: _vm.collapseOrNonCollapseHandler,
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "pa-3" },
            [
              _vm.renderNow
                ? _c("router-view", { key: _vm.$route.fullPath })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }