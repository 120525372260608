//const Details = () => import("./views/MUserDetails");
const EmailVerify = () => import("./views/EmailVerify");
// const StudentAccountDetails = () =>
//   import("./views/studentaccount/StudentAccountDetails");
// const TeacherAccountDetails = () =>
//   import("./views/teacheraccount/TeacherAccountDetails");
const AccountDetails = () => import("./views/Accounts.vue");
export default [
  {
    path: "/accounts",
    name: "account",
    component: AccountDetails
  },
  {
    path: "/verifyemail",
    component: EmailVerify,
    props: route => ({ token: route.query.token })
  }
];
