<template>
  <v-main v-if="renderNow" :style="styles.main[mainStyle]">
    <the-top-bar :services="services" @logout="logout" ></the-top-bar>
    <teacher-app-navigation-drawer
      v-if="isTeacher"
      @collapse="collapseHandler"
      @logout="logout"
    ></teacher-app-navigation-drawer>
    <new-app-navigation-drawer v-else @logout="logout" @collapse="handleCollapse"></new-app-navigation-drawer>
    <router-view></router-view>
  </v-main>
</template>

<script>
import TheTopBar from "@af/app-shell/components/TheTopBarAccounts";
import TeacherAppNavigationDrawer from "@af/app-shell/components/TeacherAppNavigationDrawer.vue";
import NewAppNavigationDrawer from "@ef/app-shell/components/NewAppNavigationDrawer.vue";
import { expireAuthCookie, expireRoleCookie } from "/global/utils/helpers";
// import authService from "@af/modules/auth/services/AuthService";

export default {
  name: "Authenticated",
  components: {
    TheTopBar,
    TeacherAppNavigationDrawer,
    NewAppNavigationDrawer
  },
  // async created() {
  //   await this.getUser();
  // },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    userFromStore() {
      return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
    },
    isTeacher() {
      if (this.userFromStore.authorized_views.includes("TEACHER_VIEW")) {
        return true;
      } else {
        return false;
      }
    },
    isStudent() {
      if (this.userFromStore.authorized_views.includes("STUDENT_VIEW")) {
        return true;
      } else {
        return false;
      }
    },
    styles() {
      return {
        main: ["", "padding-left: 56px"],
      };
    },
  },
  created() {
    this.renderNow = true;
  },
  data: () => ({
    renderNow: false,
    services: [],
    collapsedStyle: false,
    mainStyle: 0,
  }),

  methods: {
    logout() {
      expireAuthCookie();
      expireRoleCookie();
      window.location.href = process.env.VUE_APP_FRONTEND_URL;
    },
    handleCollapse(payload) {
      this.mainStyle = payload || this.$vuetify.breakpoint.width < 850 ? 1 : 0;
    },
  }
};
</script>
