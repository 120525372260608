var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _vm.item ? _vm._t("default") : _vm._e(),
      !_vm.item
        ? _vm._t("placeholder", function () {
            return [
              _c(
                "m-row",
                { staticClass: "mt-5", attrs: { justify: "center" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" },
                  }),
                ],
                1
              ),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }