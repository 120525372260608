import { Backend } from "@af/utils/backend";

import backend from "@af/utils/backend";
import { checkStatus, loadData } from "/global/utils/helpers";
import authService from "@af/modules/auth/services/AuthService";

const backendAsync = new Backend(
  process.env.VUE_APP_BACKEND_ACCT_URL + "/events"
);

function loadNewToken(res) {
  let token = res.new_token;
  if (token) {
    authService.saveAuthCookie(token);
    authService.setAuthorizationHeader(token);
  }
}

export default {
  PARTNER_URL: "partners",
  JOIN_URL: "partners/join",
  USER_URL: "partners/users",
  CODE_URL: "partners/code",
  search(query) {
    return backendAsync
      .get("search_organization", { params: { query: query } })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  list() {
    return backend
      .get(this.PARTNER_URL)
      .then(checkStatus)
      .then(({ data }) => data);
  },
  update(partner_code, partner_name) {
    //TODO: Move checkStatus call to interceptor
    return backend
      .put(this.PARTNER_URL, { partner_code, partner_name })
      .then(checkStatus)
      .then(loadNewToken);
  },
  create(partner_name) {
    return backend
      .post(this.PARTNER_URL, { partner_name })
      .then(checkStatus)
      .then(loadNewToken);
  },
  generateCode(partner_code) {
    return backend.post(this.CODE_URL, { partner_code }).then(checkStatus);
  },
  join(partner_code, role) {
    return backend
      .post(this.JOIN_URL, { partner_code, role })
      .then(checkStatus);
  },
  requestSent() {
    const headers = { "Channel-Name": "self-sent-requests" };
    return backend.get(this.JOIN_URL, { headers }).then(checkStatus);
  },
  getJoinedOrganizations() {
    const headers = { "Channel-Name": "get-joined-organizations" };
    return backend
      .get(this.USER_URL, { headers })
      .then(checkStatus)
      .then(loadData);
  },
  listIncomingJoinRequests() {
    const headers = { "Channel-Name": "owner-pending-requests" };
    return backend
      .get(this.JOIN_URL, { headers })
      .then(checkStatus)
      .then(loadData);
  },
  resolveJoinRequest(partner_code, user_id, access, status) {
    const headers = { "Channel-Name": "owner-pending-requests" };
    return backend
      .put(
        this.JOIN_URL,
        { user_id, partner_code, status, access: [access] },
        { headers }
      )
      .then(checkStatus);
  },
  updateMemberRole(partner_code, user_id, access, status) {
    const headers = { "Channel-Name": "owner-update-member-role" };
    return backend
      .put(
        this.JOIN_URL,
        { user_id, partner_code, access: access, status: "Accept" },
        { headers }
      )
      .then(checkStatus);
  },
  getUsers(partner_code) {
    const headers = { "Channel-Name": "get-users" };
    return backend
      .get(this.USER_URL, { headers }, { params: { partner_code } })
      .then(checkStatus)
      .then(loadData);
  },
  removeUser(partner_code, user_id) {
    return backend
      .delete(this.USER_URL, { data: { partner_code, user_id } })
      .then(checkStatus)
      .then(loadData);
  },
  leave(partner_code, user_id) {
    return backend
      .delete(this.USER_URL, { data: { partner_code, user_id } })
      .then(checkStatus)
      .then(loadData);
  }
};
