import { Backend } from "@af/utils/backend";
import { backendAsync } from "@af/utils/backend";
import { checkStatus } from "/global/utils/helpers";

const paymentBackend = new Backend(
  process.env.VUE_APP_BACKEND_ACCT_URL + "/events"
);

export const PaymentApi = {
  getTransactions() {
    return backendAsync
      .get("students_get_transactions", {
        baseUrl: process.env.VUE_APP_BACKEND_BOLT_STUDENTS_URL,
        path: ""
      })
      .then(checkStatus)
      .then(({ data }) => data.content.transactions);
  },
  queryCoupon(coupon_code, payment_coupon_query) {
    return paymentBackend
      .post("payment_coupon_query", { coupon_code: coupon_code, payment_coupon_query: payment_coupon_query })
      .then(checkStatus)
      .then(r => r.data.content.coupon);
  },
  initTransaction(data) {
    data['portal'] = 'teachers';
    return paymentBackend
      .get("payment_init_subscription", { params: data })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  verifyTransaction(transaction_id, transaction_time) {
    return paymentBackend
      .get("payment_verify_subscription", {
        params: { transaction_id, transaction_time }
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  verify(transaction_id, transaction_time) {
    return paymentBackend
      .post("payment_verify", { transaction_id, transaction_time })
      .then(checkStatus)
      .then(r => r.data.content);
  }
};
