<template>
  <button
    class="m-base-card-button text-center clickable d-flex align-center justify-center text-decoration-underline black--text"
    :class="{ 'grey--text': disabled }"
    :style="styleObject"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "MBaseCardButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    styleObject() {
      return {
        cursor: !this.disabled ? "pointer" : "not-allowed",
      };
    },
  },
  created() {},
  methods: {},
};
</script>

<style>
.m-base-card-button {
  background-color: transparent;
}
</style>
