var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "118",
        height: "118",
        viewBox: "0 0 118 118",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", { attrs: { cx: "59", cy: "59", r: "59", fill: "#FF5252" } }),
      _c("path", {
        attrs: {
          d: "M41.585 82.0027C41.585 85.2352 44.2428 87.88 47.4913 87.88H71.1164C74.3649 87.88 77.0227 85.2352 77.0227 82.0027V46.7387H41.585V82.0027ZM79.9758 37.9227H69.6398L66.6867 34.984H51.921L48.9678 37.9227H38.6318V43.8H79.9758V37.9227Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }