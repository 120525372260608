import authService from "@af/modules/auth/services/AuthService";
import { _types } from "@af/modules/auth/auth-types";
import GlobalTypes from "@af/app-shell/store-types";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg"

export default {
  namespaced: true,
  state: {
    authenticated: false,
    token: ""
  },
  getters: {
    [_types.IS_AUTHENTICATED]: state => state.authenticated,
    [_types.LOGIN_PAGE_URL]: () => process.env.VUE_APP_AUTH_DOMAIN + "/auth",
    [_types.SIGNUP_PAGE_URL]: () =>
      process.env.VUE_APP_AUTH_DOMAIN + "/auth/signup"
  },
  mutations: {
    [_types.SET_AUTHENTICATED](state, status) {
      state.authenticated = status;
    },
    [_types.SET_TOKEN](state, token) {
      state.token = token;
    }
  },
  actions: {
    async doesSessionExist(ctx) {
      const token =
        process.env.VUE_APP_AUTH_TOKEN || authService.loadAuthToken();
      if (!token) {
        return false;
      } else {
        ctx.state.token = token;
        return true;
      }
    },
    async refreshSessionData(ctx) {
      let token = ctx.state.token;
      try {
        token = await authService.refreshSessionData(token);
        ctx.state.token = token;
      } catch (e) {
        Swal.fire({
          title: "Alert",
          text: e.message,
          imageUrl: alertIcon
        });
      }
    },
    async getLoggedInUser(ctx) {
      let token = ctx.state.token;
      const user = await authService.getUser(token);
      // ctx.commit("setUser", user);
      // ctx.commit("setAuthenticated", true);
      // UserApi.setAuthorizationHeader(token);
      return user;
    },
    async [_types.AUTHENTICATE](ctx, payload) {
      authService.expireAuthCookie();
      // eslint-disable-next-line prettier/prettier
      let userDetails;
      let token;
      if (payload.token) {
        userDetails = await authService.getUser(payload.token);
        authService.saveRole(JSON.stringify(userDetails.authorized_views));
        token = payload.token;
      } else {
        userDetails = await authService.login(
          payload.data,
          payload.type,
          payload.provider
        );
        authService.saveRole(JSON.stringify(userDetails.authorized_views));
        token = userDetails.token;
      }
      ctx.commit(GlobalTypes.SET_USER_DETAILS, userDetails, { root: true });
      ctx.commit(_types.SET_AUTHENTICATED, true);
      ctx.commit(_types.SET_TOKEN, token);
      authService.saveAuthCookie(token);
      authService.setAuthorizationHeader(token);
      return userDetails;
    },
    [_types.GO_TO_AUTH_PAGE](ctx) {
      window.location.href = ctx.getters[_types.LOGIN_PAGE_URL];
    },
    async [_types.SIGNUP](ctx, payload) {
      await authService.signup(
        payload.data,
        payload.type,
        payload.provider,
        payload.token
      );
      return true;
    }
  }
};
