<template functional>
  <img
    :src="require('@/assets/ielts/ielts-logo.svg')"
    class="mouchak-logo"
    alt="Fluent Logo"
  />
</template>

<script>
export default {
  name: "FluentFullLogo"
};
</script>

<style>
.mouchak-logo {
  height: 24px;
}
</style>
