import Vue from "vue";
import Router from "vue-router";
import authRoutes from "../modules/auth/routes";
import Authenticated from "@af/app-shell/layouts/Authenticated";
import serviceRoutes from "@af/modules/service/routes";
import userRoutes from "@af/modules/user/routes";
import organizationRoutes from "@af/modules/organization/routes";
import paymentRoutes from "@af/modules/payment/routes";

// Vue.use(Router);

export const routesAF = [
  // { path: "", redirect: "account" },
  ...serviceRoutes,
  ...userRoutes,
  ...organizationRoutes,
  ...paymentRoutes
];

// router.afterEach((to, _) => {
//   document.title = to.meta.title || "Mouchak Accounts";
// });

// export default router;
