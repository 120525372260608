import { Backend } from "~/utils/backend";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";

const backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);


export const IeltsStudentsApi = {
    async getCourseDesign(course_id, student_id){
        return await backend
        .post('students_get_course_design', {"course_id": course_id, "student_id": student_id})
        .then(checkStatus)
        .then(response => response.data.content);
    }
}