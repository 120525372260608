var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-3",
      staticStyle: { "box-shadow": "none !important" },
      attrs: {
        "min-height": _vm.sizes.courseCardSize.height,
        width: _vm.sizes.courseCardSize.width,
      },
      on: {
        click: function ($event) {
          return _vm.seeCourseDetails(
            _vm.course.course_id,
            _vm.course.partner_code
          )
        },
      },
    },
    [
      _c(
        "v-row",
        {
          style: `
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
      border-radius: 10px !important;
      border: solid #0099dc 1px;`,
          attrs: { "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: _vm.cols.cardImage } }, [
            _vm.course.image_url
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      background: "#377dff",
                      filter: "blur(0.6px) brightness(120%)",
                      "border-radius": "10px 10px 10px 10px !important",
                      opacity: "0.7",
                    },
                  },
                  [
                    _c("v-img", {
                      style: `border-radius: ${_vm.sizes.imageSize.br1}px ${_vm.sizes.imageSize.br2}px ${_vm.sizes.imageSize.br3}px ${_vm.sizes.imageSize.br4}px;`,
                      attrs: {
                        height: `${_vm.sizes.imageSize.height}px`,
                        width: `${_vm.sizes.imageSize.width}px`,
                        src: _vm.course.image_url,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    style: `background: #666666;
          width: ${_vm.sizes.imageSize.width}px;
          height: ${_vm.sizes.imageSize.height}px;
          border-radius: ${_vm.sizes.imageSize.br1}px ${_vm.sizes.imageSize.br2}px ${_vm.sizes.imageSize.br3}px ${_vm.sizes.imageSize.br4}px;
          display: flex;
          align-items: center;
          justify-content: center;`,
                  },
                  [
                    _c(
                      "div",
                      {
                        style: `border: 2px solid #fff;
              border-radius: 50%;
              width: ${_vm.sizes.titleImage.width}px;
              height: ${_vm.sizes.titleImage.height}px;
              background: #ffffff;
              color: #333;
              text-align: center;
              font-size: ${_vm.sizes.titleImage.fontSize}px;
              line-height: ${_vm.sizes.titleImage.lineHeight}rem;`,
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getCourseImageFromTitle(
                                _vm.course.course_title
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
          ]),
          _c("v-col", { attrs: { cols: _vm.cols.infoSection } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "min-height": "190px",
                  display: "flex",
                  "flex-direction": "column",
                },
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "pa-2",
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                    },
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c("h3", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v(_vm._s(_vm.course.course_title)),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "mt-1 mb-0",
                        staticStyle: { "font-size": "10px" },
                      },
                      [
                        _c("b", [_vm._v("Course Description: ")]),
                        _c("span", {
                          staticStyle: {
                            "text-overflow": "ellipsis",
                            height: "auto",
                            overflow: "hidden",
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                            "-webkit-line-clamp": "2",
                            "white-space": "pre-wrap",
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.course.course_description),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "pa-2",
                    attrs: { "no-gutters": "", justify: "space-between" },
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "0" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _vm.course.teacher.avatar
                              ? _c(
                                  "v-avatar",
                                  {
                                    staticClass: "pa-2",
                                    attrs: { size: "40", rounded: "8" },
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: { src: _vm.course.teacher.avatar },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("user-avatar", {
                              staticClass: "pa-4 mt-1",
                              attrs: {
                                "user-name": _vm.course.teacher.name,
                                size: "29px",
                              },
                            }),
                            _c(
                              "p",
                              {
                                staticClass: "pa-2",
                                staticStyle: {
                                  color: "#0074a7",
                                  "margin-bottom": "0px",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.course.teacher.name) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-row",
            {
              staticStyle: { display: "flex", "flex-direction": "column" },
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-row",
                {
                  staticStyle: { display: "flex", "flex-direction": "column" },
                  attrs: { "no-gutters": "", justify: "end" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.cols.enrollButton } },
                    [
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              staticStyle: { "border-radius": "5px" },
                              attrs: {
                                width: "120px",
                                height: "35px",
                                color: "primary",
                                elevation: "0",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.seeCourseDetails(
                                    _vm.course.course_id,
                                    _vm.course.partner_code,
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v("Enroll ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }