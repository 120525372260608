
// const IeltsRoot = () => import("./ielts/IeltsTopbar.vue");
// const NotFound = () => import("./NotFound.vue");

const IeltsHome = () => import("../routes/ielts/IeltsHome.vue");
const CourseDetails = () => import("../routes/ielts/IeltsCourseDetails.vue");
// /home/zhrifad/vueProject/education-web-frontend/portals/public-portal/src/routes/ielts/IeltsHome.vue

const routesFrontendIelts = [
    {
      path: "/",
      name: "ielts",
      component: IeltsHome
    },
    {
      path: "/ielts/courseDetails",
      name: "courseDetails",
      component: CourseDetails
    },
    // {
    //   path: "*",
    //   name: "NotFound",
    //   component: NotFound,
    // },
  ];
  export default routesFrontendIelts;