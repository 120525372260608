var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.maxWidth, persistent: _vm.persist },
      scopedSlots: _vm._u(
        [
          _vm.$scopedSlots.activator
            ? {
                key: "activator",
                fn: function (scope) {
                  return [_vm._t("activator", null, null, scope)]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "mk-card-radius pa-4" },
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center" },
            [
              _vm._t("title", function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      _c("v-img", { attrs: { src: _vm.icon.name } }),
                      _c("div", { staticStyle: { height: "10px" } }),
                      _c("p", { staticClass: "alert-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-card-text",
            { staticClass: "alert-text" },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.subTitle))]
              }),
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _vm.showCancel
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("action", 0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.cancelText))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { rounded: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("action", 1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.okText))]
              ),
              _vm.toSignup
                ? _c(
                    "v-btn",
                    {
                      staticClass: "px-5",
                      attrs: { rounded: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.redirectToSignup()
                        },
                      },
                    },
                    [
                      _vm._v("Signup "),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("mdi-arrow-right"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }