import axios from "axios";
import { checkStatus, delayPromise, Logger } from "/global/utils/helpers";
import { isEmpty, get } from "lodash";

const common = { partner_code: undefined };

const transformToMsgPack = function transformToMsgPack(data) {
  return !isEmpty(data) ? data : "";
};

export class Backend {
  static authToken;
  static partner_code;
  constructor(baseUrl) {
    this.$axios = axios.create({
      baseURL: baseUrl,
      timeout: 100000,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Backend.authToken}`,
        common: {
          Authorization: `Bearer ${Backend.authToken}`,
        },
      },
    });
    this.headers = this.$axios.defaults.headers;
    this.interceptors = this.$axios.interceptors;
  }
  async _call_backend(channel, data, options) {
    if(!window.navigator.onLine) throw new Error("There might be an internet connection issue on your system");
    if (get(options, "shouldSendPartnerCode", true)) {
      data.partner_code = Backend.partner_code;
    }
    const timeout = get(options, "timeout", 3000);
    Logger.log(`[${channel} Payload: `, data);
    const rn = global.crypto.getRandomValues(new Uint32Array(1))[0];
    const requestId = `${channel}-${rn}`;
    return await this._make_request(channel, requestId, data);
    // await delayPromise(timeout);
    // return await this._get_status(channel, requestId);
  }
  _make_request(channel, request_id, data = "") {
    const requestConfig = {
      headers: {
        "Channel-Name": channel,
        "Request-Id": request_id,
        "Content-Type": "application/json",
        // "Allow-Block": 1,
        Authorization: `Bearer ${Backend.authToken}`,
      },
      transformRequest: data => JSON.stringify(data),
    };

    return this.$axios.post("", data, requestConfig);
  }

  _get_status(channel, request_id) {
    return this.$axios.get("", {
      headers: {
        "Channel-Name": channel,
        "Request-Id": request_id,
        Authorization: `Bearer ${Backend.authToken}`,
      },
    });
  }
  post(channel, data = {}, _opts = {}) {
    return this._call_backend(channel, data, _opts);
  }
  get(channel, data = {}) {
    return this._call_backend(channel, get(data, "params", {}), data);
  }
  put(channel, data, _opts = {}) {
    return this._call_backend(channel, data, _opts);
  }
  delete(channel, data = {}) {
    return this._call_backend(channel, data.data, data);
  }
  customRequest(method, url, data) {
    const config = {
      headers: {
        Authorization: `Bearer ${Backend.authToken}`,
        "Content-Type": "application/json",
      },
      transformRequest: data => JSON.stringify(data),
    };

    return this.$axios[method](url, data, config);
  }
  async upload_to_s3(file_info, file) {
    if (file.size >= 20971520) {
      //multipart upload if file larger than 20 MB
      let uploadId;
      try {
        let key = file_info.fields.key;
        uploadId = await this.getUploadId(key);
        const chunkSize = 10485760; // 10 MB
        const chunkCount = Math.floor(file.size / chunkSize) + 1;
        let parts = [];
        for (let index = 1; index <= chunkCount; index++) {
          let start = (index - 1) * chunkSize;
          let end = index * chunkSize;
          let blob =
            index < chunkSize ? file.slice(start, end) : file.slice(start);

          let presignedUrlb64 = await this.getUploadResp(key, index, uploadId);
          let presignedUrl = atob(presignedUrlb64);

          let etag = await fetch(presignedUrl, {
            method: "PUT",
            body: blob,
          }).then(response => {
            return response.headers.get("ETag");
          });

          parts.push({
            ETag: etag,
            PartNumber: index,
          });
        }

        await this.finishUpload(key, JSON.stringify(parts), uploadId);
      } catch (e) {
        await this.abortMultipartUpload(file_info.fields.key, uploadId);
        throw new Error(JSON.stringify(e) + " Aborted");
      }
    } else {
      const fields = file_info.fields;
      const formData = new FormData();
      formData.append("key", fields.key);
      Object.entries(fields).forEach(entry => {
        if (entry[0] !== "key") formData.append(entry[0], entry[1]);
      });
      formData.append("file", file);
      return await axios.post(file_info.url, formData).then(checkStatus);
    }
  }
  async getUploadId(keyfile) {
    var payload = {
      action: "start",
      key: keyfile,
    };
    return await this.post("teachers_multipart_upload", payload)
      .then(checkStatus)
      .then(r => r.data.content.Id);
  }
  async getUploadResp(key, partNumber, upload_Id) {
    var payload = {
      action: "get_url",
      key: key,
      upload_id: upload_Id,
      partno: partNumber,
    };
    return await this.post("teachers_multipart_upload", payload)
      .then(checkStatus)
      .then(r => r.data.content.url);
  }
  async finishUpload(key, parts, upload_id) {
    var payload = {
      action: "complete",
      key: key,
      parts: parts,
      upload_id: upload_id,
    };
    return await this.post("teachers_multipart_upload", payload).then(
      checkStatus,
    );
  }
  async abortMultipartUpload(key, upload_id) {
    var payload = {
      action: "abort",
      key: key,
      upload_id: upload_id,
    };
    return await this.post("teachers_multipart_upload", payload).then(
      checkStatus,
    );
  }
}
