var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { transition: "slide-y-transition", bottom: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "user-avatar",
                _vm._g(
                  {
                    staticClass: "user-icon clickable mx-1",
                    attrs: {
                      color: "primary",
                      size: "32",
                      "user-name": _vm.userName,
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "account-menu__option-list" },
        [
          _c(
            "v-list-item",
            { attrs: { href: _vm.authUrl } },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v("person")])], 1),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    [
                      _vm._v("Account Details "),
                      _c("v-icon", [_vm._v("open_in_new")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("logout")
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("power_settings_new")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Logout")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }