<template>
  <v-card class="pt-10">
    <v-card-title>
      Join an Organization
    </v-card-title>
    <v-card-text>
      <v-form ref="joinForm">
        <v-text-field
          class="mt-8"
          label="Code"
          placeholder="Enter the organization code..."
          outlined
          :rules="rules"
          v-model="joinCode"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row justify="end">
          <v-btn outlined rounded color="red" @click="cancel">Cancel</v-btn>
          <v-btn
            rounded
            color="primary"
            class="ml-3"
            :loading="joining"
            @click="join"
            >Join</v-btn
          >
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";


export default {
  name: "JoinOrganizationForm",
  props: {
    joining: Boolean
  },
  created() {},
  mixins: [FieldValidations],
  data() {
    return {
      joinCode: undefined,
      rules: [v=>this.required(v, "Code")]
    };
  },
  methods: {
    join() {
      if (this.$refs.joinForm.validate()) {
        this.$emit("join", this.joinCode);
        this.joinCode = undefined;
      }
    },
    cancel() {
      this.joinCode = undefined;
      this.$emit("cancel");
    }
  },
  computed: {}
};
</script>

<style></style>
