import { keyBy } from "lodash";
import moment from "moment";
import { Backend } from "~/utils/backend";
import { checkStatus } from "/global/utils/helpers";
import ExamCardImg from "#ef/exam/assets/img_exam.svg";
import ExamPackageCardImg from "#ef/exam/assets/img_exam_package.jpg";

const backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
const accounts_backend = new Backend(
  process.env.VUE_APP_BACKEND_ACCT_URL + "/events",
);

const ExamMapper = {
  fromRemote(data) {
    data.image_url = data.image_url ?? ExamCardImg;
    data.duration = data.total_duration;
    data.points = data.total_points;
    return data;
  },
  toRemote(exam) {
    const details = exam.examDetails;
    return {
      ...(details.id ? { id: details.id } : {}),
      title: details.title,
      instruction: details.instruction,
      total_points: details.points,
      total_duration: details.duration,
      categories: exam.categories.map(cat => {
        return {
          ...(cat.id ? { id: cat.id } : {}),
          title: cat.title,
          instruction: cat.instruction,
          points: cat.points,
          duration: cat.duration,
          is_required: cat.is_required,
        }; // explicitly mention the information sent
      }),
      questions: exam.categories.map(cat => {
        return cat.questions
          ? cat.questions.map(q => ({ q_id: q.question_id, point: 1 }))
          : [];
      }),
    };
  },
};

const ExamPackageMapper = {
  fromRemote(data) {
    data.startDate = data.start_date
      ? moment(data.start_date, "YYYY-MM-DD")
      : undefined;
    data.endDate = data.end_date
      ? moment(data.end_date, "YYYY-MM-DD")
      : undefined;
    data.image_url = ExamPackageCardImg;
    data.cost = data.price;
    data.exams = data.exams ? data.exams.map(ExamMapper.fromRemote) : [];
    return data;
  },
  toRemote(examPackage) {
    const details = examPackage.packageDetails;
    return {
      ...(details.id ? { id: details.id } : {}),
      title: details.title,
      price: details.price,
      start_date: details.startDate,
      end_date: details.endDate,
      exams: examPackage.exams
        ? examPackage.exams.map(e => ({ id: e.id }))
        : [],
    };
  },
};

export const CartApi = {
  addItem(item) {
    return accounts_backend
      .post("payment_add_to_cart", item, { shouldSendPartnerCode: false })
      .then(checkStatus);
  },
  removeItem(item_id) {
    return accounts_backend
      .post("payment_remove_from_cart", { item_id: item_id })
      .then(checkStatus);
  },
  get() {
    return accounts_backend
      .get("payment_get_cart_items")
      .then(checkStatus)
      .then(({ data }) => data.content)
      .then(({ items, prices }) => {
        const priceMap = keyBy(prices, "item_id");
        for (let item of items) {
          const price = priceMap[item.item_id];
          item.price = price.amount;
          item.currency = price.currency;
        }
        return items;
      });
  },
  checkout(coupon_code) {
    return accounts_backend
      .post("payment_checkout", { coupon_code: coupon_code })
      .then(checkStatus)
      .then(({ data }) => data.content.order_id);
  },
};

export const PaymentApi = {
  createSession(method, order_id, agent) {
    return accounts_backend
      .post("payment_initiate_payment", { method, order_id, agent, portal: 'students' })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  queryCoupon(code, coupon_inserted_from) {
    return accounts_backend
      .post("payment_coupon_query", { coupon_code: code, coupon_inserted_from: coupon_inserted_from })
      .then(checkStatus)
      .then(r => r.data.content.coupon);
  },
  getOrder(order_id) {
    return accounts_backend
      .get("payment_get_order", { params: { order_id } })
      .then(checkStatus)
      .then(({ data }) => data.content.order);
  },
  getAllOrders() {
    return accounts_backend
      .get("payment_get_all_orders")
      .then(checkStatus)
      .then(({ data }) => data.content.orders);
  },
  verify(transaction_id, transaction_status) {
    return accounts_backend
      .post("payment_verify", { transaction_id, transaction_status })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
};
