var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { style: `width: ${_vm.sizes.container.width}` },
    [
      _vm.fetchingOrganizations
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _vm.myJoinedOrgs.length === 0
        ? _c("v-row", { attrs: { justify: "center" } }, [
            _c("span", [_vm._v(" You have yet to join any organization ")]),
          ])
        : _c("v-simple-table", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th"),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(" Organization Name "),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(" Actions "),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.myJoinedOrgs, function (org) {
                        return _c("tr", { key: org.partner_name }, [
                          _c(
                            "td",
                            [
                              _c("v-list-item-avatar", {
                                attrs: { color: "#cef0ff", size: "46" },
                              }),
                            ],
                            1
                          ),
                          _c("td", [_vm._v(_vm._s(_vm.orgNameContent(org)))]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "red",
                                    rounded: "",
                                    outlined: "",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.prepareLeave(
                                        org.partner_code,
                                        org.partner_name
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("exit_to_app")]),
                                  _vm._v("Leave"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
      _c("alert-dialog", {
        attrs: {
          title: "Leaving",
          "sub-title":
            "Are you sure you want to leave organization " +
            _vm.orgToLeave.name +
            "?",
          "show-cancel": "",
          "ok-text": "Leave",
        },
        on: { action: _vm.alertAction },
        model: {
          value: _vm.confirmDialog,
          callback: function ($$v) {
            _vm.confirmDialog = $$v
          },
          expression: "confirmDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }