var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-hover", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ hover }) {
            return [
              _c(
                "div",
                {
                  staticClass: "px-0 pb-0 mk-base-card",
                  class: { "on-hover": hover },
                  attrs: { elevation: hover ? 12 : 2 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-3 py-2 d-flex justify-space-between align-center",
                    },
                    [
                      _c("h4", [_vm._t("title")], 2),
                      _c(
                        "v-col",
                        { staticClass: "py-0 d-flex justify-end" },
                        [_vm._t("card-actions")],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "my-0 mk-divider" }),
                  _c(
                    "v-row",
                    { staticClass: "ma-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "px-0 mk-card-grey-col hidden-sm-and-down",
                          attrs: { md: "3" },
                        },
                        [_vm._t("col-1")],
                        2
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "8", md: "6" } },
                        [_vm._t("col-2")],
                        2
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 mk-card-grey-col",
                          attrs: { md: "3" },
                        },
                        [_vm._t("col-3")],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }