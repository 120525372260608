var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-navigation-drawer",
        {
          class: ["mk-navigation-drawer", { sidenav: _vm.mini }],
          style: _vm.$vuetify.breakpoint.smAndDown
            ? "padding: 6px; padding-left: 8px;"
            : "padding: 12px;",
          attrs: {
            fixed: "",
            clipped: "",
            app: "",
            floating: "",
            permanent: "",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _vm._t("drawer-items", function () {
            return [
              _c(
                "v-layout",
                {
                  attrs: {
                    "justify-space-between": "",
                    column: "",
                    "fill-height": "",
                  },
                },
                [
                  _c(
                    "v-list",
                    {
                      style: _vm.$vuetify.breakpoint.smAndDown
                        ? "margin-top: 11px"
                        : "margin-top: 38px",
                      attrs: { subheader: "" },
                    },
                    [
                      _vm.$vuetify.breakpoint.smAndDown
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                { staticStyle: { "margin-left": "-6px" } },
                                [
                                  _vm.mini
                                    ? _c("v-app-bar-nav-icon", {
                                        on: {
                                          click: function ($event) {
                                            _vm.mini = !_vm.mini
                                          },
                                        },
                                      })
                                    : _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "orange" },
                                          on: {
                                            click: function ($event) {
                                              _vm.mini = !_vm.mini
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close ")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    staticClass:
                                      "xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        { attrs: { exact: "", to: "/students/home" } },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticStyle: { "margin-right": "16px" } },
                            [
                              _vm.currentRoute !== "/students/home"
                                ? _c("v-img", {
                                    attrs: { src: _vm.icons.Home },
                                  })
                                : _vm._e(),
                              _vm.currentRoute === "/students/home"
                                ? _c("v-img", {
                                    attrs: { src: _vm.icons.HomeColor },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0",
                                },
                                [
                                  _vm._v(
                                    _vm._s(!_vm.$language ? "Home" : "হোম") +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            { attrs: { exact: "", to: "/students/notes" } },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticStyle: { "margin-right": "16px" } },
                                [
                                  _vm.currentRoute !== "/students/notes"
                                    ? _c("v-img", {
                                        attrs: { src: _vm.icons.Lecture },
                                      })
                                    : _vm._e(),
                                  _vm.currentRoute === "/students/notes"
                                    ? _c("v-img", {
                                        attrs: { src: _vm.icons.LectureColor },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0",
                                    },
                                    [_vm._v(_vm._s("Notes") + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { attrs: { exact: "", to: "/students/tests" } },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticStyle: { "margin-right": "16px" } },
                                [
                                  _vm.currentRoute !== "/students/tests"
                                    ? _c("v-img", {
                                        attrs: { src: _vm.icons.Exam },
                                      })
                                    : _vm._e(),
                                  _vm.currentRoute === "/students/tests"
                                    ? _c("v-img", {
                                        attrs: { src: _vm.icons.ExamColor },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "xl:text-sm lg:text-sm md:text-sm sm:text-sm ma-0",
                                    },
                                    [_vm._v(_vm._s("Tests") + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                exact: "",
                                to: "/students/announcements",
                              },
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticStyle: { "margin-right": "16px" } },
                                [
                                  _vm.currentRoute !== "/students/announcements"
                                    ? _c("v-img", {
                                        attrs: { src: _vm.icons.Announcement },
                                      })
                                    : _vm._e(),
                                  _vm.currentRoute === "/students/announcements"
                                    ? _c("v-img", {
                                        attrs: {
                                          src: _vm.icons.AnnouncementColor,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language
                                            ? "Announcements"
                                            : "নোটিশ"
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    { attrs: { subheader: "" } },
                    [
                      _c(
                        "v-list-group",
                        {
                          staticClass: "pl-0 ma-0",
                          attrs: {
                            id: "profile",
                            "no-action": "",
                            "append-icon": "mdi-chevron-up",
                          },
                          on: {
                            click: function ($event) {
                              _vm.courseActive = false
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    { staticClass: "pl-0" },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        {
                                          staticStyle: {
                                            "margin-right": "12px",
                                            padding: "0px",
                                            "margin-left": "-8px",
                                          },
                                          attrs: { height: "32", width: "32" },
                                        },
                                        [
                                          _c("user-avatar", {
                                            staticStyle: {
                                              padding: "0px 0px",
                                              margin: "0",
                                            },
                                            attrs: {
                                              "user-name": _vm.user.name,
                                              size: "32",
                                              color: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        {
                                          staticStyle: {
                                            padding: "0px",
                                            margin: "-4px",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                              style: _vm.profileActive
                                                ? "color: #0080b8"
                                                : "",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.user.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticStyle: {
                                                "font-size": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.user.email) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.profileActive,
                            callback: function ($$v) {
                              _vm.profileActive = $$v
                            },
                            expression: "profileActive",
                          },
                        },
                        [
                          _c(
                            "v-list-item",
                            {
                              staticClass: "pl-2",
                              attrs: { link: "", to: "/accounts" },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticStyle: { "margin-right": "16px" } },
                                [
                                  _vm.currentRoute !== "/accounts"
                                    ? _c("v-img", {
                                        attrs: {
                                          src: _vm.icons.AccountDetails,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.currentRoute === "/accounts"
                                    ? _c("v-img", {
                                        attrs: {
                                          src: _vm.icons.AccountDetailsColor,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                },
                                [_vm._v(_vm._s("My Account") + " ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "pl-2",
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { exact: "", link: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("logout")
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticStyle: { "margin-right": "16px" } },
                                [
                                  _c("v-img", {
                                    attrs: { src: _vm.icons.LogOut },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                    },
                                    [_vm._v(_vm._s("Log out") + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }