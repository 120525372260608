import BatchApi from "@ecf/modules/batch/BatchApi";
import Vue from "vue";
import get from "lodash/get";
import { shouldRefreshCache } from "@ecf/global/helpers";
import sortBy from "lodash/sortBy";

export default {
  namespaced: true,
  state: {
    batchMap: {},
    lastFetched: {},
    batchStudentList: {},
    organizationStudentList: {},
    attendanceMap: {}
  },
  getters: {
    batches: state => {
      const courseIds = Object.keys(state.batchMap);
      const mapToList = batchMap =>
        sortBy(Object.values(batchMap), "batch_title");
      return courseIds.reduce((prev, code) => {
        prev[code] = mapToList(state.batchMap[code]);
        return prev;
      }, {});
    },
    organizationStudents: state => {
      return state.organizationStudentList;
    },
    batchStudents: state => {
      return state.batchStudentList;
    },
    attendaceList: state => {
      return state.attendanceMap;
    }
  },
  mutations: {
    setBatches(state, { courseId, batches }) {
      let makeArrayFromDays = function(days) {
        let daysArr = [];
        if (typeof days === "object" && days != null) {
          for (let key in days) {
            daysArr.push(
              `${key}: ${days[key].start_time}-${days[key].end_time}`
            );
          }
        } else {
          for (let i = 0; i < days.length; i++) {
            let parts = days[i].split("#");
            daysArr.push(`${parts[0]}: ${parts[1]}-${parts[2]}`);
          }
        }
        return daysArr;
      };

      const batchesData = batches.map(b => {
        return b;
      });

      for (let i = 0; i < batchesData.length; i++) {
        let batchElement = batchesData.pop();
        if (batchElement.days != undefined) {
          batchElement.days = makeArrayFromDays(batchElement.days);
        } else {
          batchElement.days = [];
        }
        batchesData.unshift(batchElement);
      }

      const batchMap = batchesData.reduce((prev, batch) => {
        prev[batch.batch_id] = batch;
        return prev;
      }, {});
      Vue.set(state.batchMap, courseId, batchMap);
    },
    setBatchStudents(state, payload) {
      state.batchStudentList = payload;
    },
    setOrganizationStudents(state, payload) {
      state.organizationStudentList = payload;
    },
    setAttendance(state, { studentList, date }) {
      Vue.set(state.attendanceMap, date, studentList);
    },
    removeBatch(state, payload) {
      Vue.delete(state.batches, payload.cid);
    },
    setLastFetched(state, { value, course_id }) {
      state.lastFetched[course_id] = value;
    }
  },
  actions: {
    async list(ctx, { courseId, partner_code, refresh = false }) {
      const now = new Date().getTime();
      const lastFetched = get(ctx.state.lastFetched, courseId, -1);
      if (refresh || shouldRefreshCache(now, lastFetched)) {
        const batches = await BatchApi.find(courseId, partner_code);
        ctx.state.lastFetched[courseId] = now;
        ctx.commit("setBatches", { batches, courseId });
        return batches;
      } else {
        return ctx.getters["batches"][courseId];
      }
    },
    async create(ctx, { courseId, batchData }) {
      let ret_msg = await BatchApi.save(courseId, batchData);
      ctx.dispatch("list", { courseId, refresh: true });
      return ret_msg;
    },
    async updateBatch(ctx, { batchId, courseId, batchData }) {
      await BatchApi.update(courseId, batchId, batchData);
    },
    async removeStudent(ctx, data) {
      await BatchApi.removeStudent(data);
    },
    async retrieveBatchDetails(ctx, { partner_code, courseId, batchId }) {
      const batchObject = await BatchApi.retrieveBatchDetails(
        partner_code,
        courseId,
        batchId
      );
      return batchObject;
    },
    async getStudentInformation(ctx, { partner_code, batch_Id, course_Id }) {
      const studentList = await BatchApi.retrieveStudentInfo(
        partner_code,
        batch_Id,
        course_Id
      );
      ctx.commit("setBatchStudents", studentList);
      return studentList;
    },

    async getOrganizationStudentInformation(ctx, { partner_code, course_Id }) {
      const studentList = await BatchApi.retrieveOrganizationStudentList(
        partner_code,
        course_Id
      );
      ctx.commit("setOrganizationStudents", studentList);
      return studentList;
    },

    async getClassDateList(ctx, { partner_code, course_id, batch_id }) {
      const dateList = await BatchApi.retrieveClassDateList(
        partner_code,
        course_id,
        batch_id
      );
      return dateList["classes_date_list"];
    },

    async updateAttendance(
      ctx,
      { partner_code, course_id, batch_id, student_ids, date }
    ) {
      await BatchApi.updateAttendance(
        partner_code,
        course_id,
        batch_id,
        student_ids,
        date
      );
    },

    async retriveAttendance(ctx, { partner_code, course_id, batch_id, date }) {
      let studentList = await BatchApi.retrieveAttendance(
        partner_code,
        course_id,
        batch_id,
        date
      );
      studentList = studentList["attendance_list"];
      ctx.commit("setAttendance", { studentList, date });
      return studentList;
    },

    async updateStudentList(ctx, { partner_code, course_id, batch_id, data }) {
      await BatchApi.updateBatchStudentList(
        partner_code,
        course_id,
        batch_id,
        data
      );
    }
  }
};
