import topicApi from "../api/topic";
import essayApi from "../api/essay";
import Vue, { set } from "vue";
import { QuestionApi } from "../api/question";
import { cloneDeep } from "lodash";
import { deepEqual } from "vuetify/es5/util/helpers";

const topicModule = {
  namespaced: true,
  state: {
    lastFetched: -1,
    topics: []
  },
  getters: {},
  mutations: {
    setTopics(state, topics) {
      state.topics = topics;
    }
  },
  actions: {
    async list({ commit, state }, payload) {
      let topics = await topicApi.list(payload.owner_id, payload.course_id);
      topics = topics.map(item => {
        if (item.shared) {
          return { id: item.id, text: item.text + " (shared)", shared: true };
        } else {
          return { id: item.id, text: item.text, shared: false };
        }
      });
      commit("setTopics", topics.sort());
      return state.topics;
    },
    async get(context, payload) {},
    async request(context, { topic }) {
      return topicApi.request(topic);
    },
    async update(context, payload) {},
    async delete(context, payload) {}
  }
};

function extractEquations(question) {
  const doc = new DOMParser().parseFromString(
    question.questionDetails.text,
    "text/html"
  );
  let equations = doc.querySelectorAll(".ql-formula");

  const equationTexts = [];
  equations.forEach(item => {
    equationTexts.push(item.outerHTML);
    const placeHolder = document.createElement("span");
    placeHolder.classList.add("eq-placeholder");
    item.parentElement.replaceChild(placeHolder, item);
  });

  question.questionDetails.text = doc.body.innerHTML;

  return equationTexts;
}

export default {
  modules: { topics: topicModule },
  namespaced: true,
  state: {
    questions: {},
    essays: {},
    lastFetched: -1,
    counter: 0,
    lastSearched: {
      key: null,
      topics: [],
      type: null,
      page: 1,
      groupQuestionIndex: -1,
      essayId: ""
    }
  },
  getters: {},
  mutations: {
    setQuestion(state, payload) {
      Vue.set(state.questions, payload.questionId, payload.question);
    },
    setEssay(state, essay) {
      Vue.set(state.essays, essay.essay_id, essay);
    },
    setEssayQuestions(state, { essay_id, questions }) {
      Vue.set(state.essays[essay_id], "questions", questions);
    },
    saveSearchInput(state, payload) {
      state.lastSearched.key = payload.key ?? "";
      state.lastSearched.topics = [...payload.topics];
      state.lastSearched.type = payload.type;
      state.lastSearched.page = payload.page ?? 1;
      state.lastSearched.groupQuestionIndex = payload.index ?? -1;
      state.lastSearched.essayId = payload.essayId ?? "";
    },
    clearSearchInput(state) {
      state.lastSearched.key = null;
      state.lastSearched.topics = [];
      state.lastSearched.type = null;
      state.lastSearched.page = 1;
      state.lastSearched.groupQuestionIndex = -1;
      state.lastSearched.essayId = "";
    }
  },
  actions: {
    async create(context, payload) {
      const equations = extractEquations(payload.question);
      const resp = await QuestionApi.create(
        payload.question,
        payload.courseId,
        payload.ownerId
      );
      if (!(equations === undefined) && equations.length > 0) {
        await QuestionApi.saveEquationsForQuestion({
          question_id: resp.id,
          equations
        });
      }
      return resp;
    },
    async duplicate(context, payload) {
      const equations = extractEquations(payload.question);
      const questionClone = cloneDeep(payload.question);
      // In payload.question the text field in question.questionDetails would not update properly
      // Thus clone is used here which seems to be not edited

      const resp = await QuestionApi.duplicate(
        questionClone,
        payload.question.courseId,
        payload.question.ownerId
      );
      if (!(equations === undefined) && equations.length > 0) {
        await QuestionApi.saveEquationsForQuestion({
          question_id: resp.id,
          equations
        });
      }
      return resp;
    },
    async search(context, payload) {
      return QuestionApi.find(payload);
    },
    async searchLinkable(context, payload) {
      return QuestionApi.findLinkable(payload);
    },
    async getQuestionsForEssay(context, payload) {
      return QuestionApi.getQuestionsForEssay(payload);
    },
    async getQuestionGroupForEssay(context, payload) {
      return essayApi.getQuestionInfo(payload);
    },
    async saveEquationsForQuestion(context, payload) {
      return QuestionApi.saveEquationsForQuestion(payload);
    },
    async getEquationForQuestion(context, payload) {
      return QuestionApi.getEquationForQuestion(payload);
    },
    async get({ commit, dispatch }, payload) {
      const question = await QuestionApi.get(
        payload.questionId,
        payload.owner_id,
        payload.course_id
      );
      if (question.essay_id) {
        await dispatch("getEssay", {
          essay_id: question.essay_id,
          course_id: payload.course_id,
          owner_id: payload.owner_id
        });
      }
      commit("setQuestion", {
        questionId: payload.questionId,
        question: question
      });
      return question;
    },
    async detach({ commit, dispatch }, payload) {
      await QuestionApi.detach(payload.questionId);
    },
    async update(
      _,
      { questionId, questionData, courseId, ownerId, imageUpdated }
    ) {
      const equations = extractEquations(questionData);
      const questionDataClone = cloneDeep(questionData);
      const resp = QuestionApi.update(
        questionId,
        questionDataClone,
        courseId,
        ownerId,
        imageUpdated
      );
      if (!(equations === undefined) && equations.length > 0) {
        await QuestionApi.saveEquationsForQuestion({
          question_id: questionId,
          equations
        });
      }
      return resp;
    },
    delete(_, { questionId, courseId, ownerId }) {
      return QuestionApi.delete(questionId, courseId, ownerId);
    },
    async createEssay(_, { essay, courseId, ownerId }) {
      return essayApi.create(essay, courseId, ownerId);
    },
    async createQuestionInfo(
      _,
      {
        questionGroup,
        questions,
        answers,
        difficulty,
        type,
        subType,
        courseId,
        ownerId,
        essayId
      }
    ) {
      return essayApi.createQuestionInfo(
        questionGroup,
        questions,
        answers,
        difficulty,
        type,
        subType,
        courseId,
        ownerId,
        essayId
      );
    },
    async getEssay({ commit }, { essay_id, course_id, owner_id, include }) {
      const essay = await essayApi.get(essay_id, course_id, owner_id);
      commit("setEssay", essay);
      if (include) {
        if (include.includes("questions")) {
          let questions = await essayApi.getQuestions(
            essay_id,
            course_id,
            owner_id
          );
          if (!Array.isArray(questions)) questions = [questions];
          commit("setEssayQuestions", { essay_id, questions });
        }
      }
      return essay;
    }
  }
};
