import { render, staticRenderFns } from "./FluentFullLogo.vue?vue&type=template&id=4aebe47c&functional=true"
import script from "./FluentFullLogo.vue?vue&type=script&lang=js"
export * from "./FluentFullLogo.vue?vue&type=script&lang=js"
import style0 from "./FluentFullLogo.vue?vue&type=style&index=0&id=4aebe47c&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/fluent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4aebe47c')) {
      api.createRecord('4aebe47c', component.options)
    } else {
      api.rerender('4aebe47c', component.options)
    }
    module.hot.accept("./FluentFullLogo.vue?vue&type=template&id=4aebe47c&functional=true", function () {
      api.rerender('4aebe47c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "portals/teachers-portal/core/components/FluentFullLogo.vue"
export default component.exports