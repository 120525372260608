var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticStyle: { "max-width": "1100px" }, attrs: { justify: "start" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("v-pagination", {
                staticStyle: { "justify-content": "start" },
                attrs: {
                  length: _vm.pageCount,
                  "total-visible": 5,
                  circle: "",
                },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  value: _vm.itemsPerPage,
                  label: "Items per page",
                  hint: "Showing 5 of 100",
                  type: "number",
                  min: "-1",
                  max: "15",
                  "persistent-hint": "",
                },
                on: {
                  input: function ($event) {
                    _vm.itemsPerPage = parseInt($event, 10)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }