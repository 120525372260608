import { _types } from "./service-types";
import userService from "../user/services/UserService";

export default {
  namespaced: true,
  state: {
    services: {}
  },
  getters: {
    [_types.GET_SERVICES]: state => Object.values(state.services)
  },
  mutations: {
    [_types.SET_SERVICES](state, services) {
      state.services = services;
    }
  },
  actions: {
    async [_types.FETCH_SERVICES](ctx, payload) {
      const views = await userService.getAvailableViews();
      const services = await userService.getServices(
        views.map(v => v.service_id)
      );
      services[0].views = views;
      ctx.commit(_types.SET_SERVICES, services);
    }
  }
};
