import axios from "axios";
import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "courseworks/",
  UPLOAD_URL: "uploads/",
  BATCH_SHARE_URL: "batches/courseworks/",
  COURSEWORK_BATCH_URL: "courseworks/batches/",
  COURSEWORK_SUBMISSION_URL: "batches/courseworks/submissions/",

  /**
   * @return Promise<AxiosResponse>
   * @param courseId
   * @param opts
   */
  find(courseId, batch_id = undefined, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    const params = { object_type: "assignment" };
    let channel = "teachers_get_stores";
    if (batch_id) {
      params.batch_id = batch_id;
      channel = "teachers_get_shares";
    } else {
      params.course_id = courseId;
    }

    return $backend
      .get(channel, {
        params,
        ...opts
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },

  get(assignment_id, course_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    const object_type = "assignment";
    const object_id = assignment_id;
    return $backend
      .get("teachers_get_stores", {
        params: { object_id, course_id, object_type }
      })
      .then(checkStatus)
      .then(r => r.data.content.stores[0]);
  },
  /**
   * @return Promise
   * @param courseId
   * @param assignment
   * @param headers
   */
  async save(courseId, assignment, headers = {}) {
    const file = assignment.file;
    assignment.file = assignment.file.name || "file";
    assignment.course_id = courseId;
    assignment.object_type = "assignment";
    assignment.is_downloadable = assignment.isDownloadable;
    return $backend
      .post("teachers_post_stores", assignment)
      .then(checkStatus)
      .then(({ data }) => {
        $backend.upload_to_s3(data.content, file);
      });
  },

  share(coursework_id, data) {
    data["object_id"] = coursework_id;
    data["object_type"] = "assignment";
    return $backend
      .post("teachers_post_shares", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  shareMultiple(data) {
    return $backend
      .post("teachers_post_shares_multiple", { params: data })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  deleteMultiple(data) {
    return $backend
      .post("teachers_delete_shares_multiple", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getSharedBatches(object_id, course_id) {
    return $backend
      .get("teachers_get_shares", {
        params: { object_id, object_type: "assignment", course_id }
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },

  getSubmissions(assignment_id, course_id) {
    return $backend
      .get("teachers_get_submissions", {
        params: {
          object_id: assignment_id,
          object_type: "assignment",
          course_id: course_id
        }
      })
      .then(checkStatus)
      .then(r => r.data.content)
      .then(({ submissions, users }) => {
        for (const submission of submissions) {
          submission.user_name = users[submission.student_id];
        }
        return submissions;
      });
  },
  getSubmission(course_id, object_id, student_id) {
    return $backend
      .get("teachers_get_submissions", {
        params: {
          course_id: course_id,
          object_id: object_id,
          student_id: student_id,
          object_type: "assignment"
        }
      })
      .then(checkStatus)
      .then(r => r.data.content.submissions[0]);
  },
  grade(assignment_id, course_id, batch_id, student_id, grade) {
    return $backend
      .post("teachers_post_grades", {
        object_id: assignment_id,
        object_type: "assignment",
        course_id,
        batch_id,
        student_id,
        grade
      })
      .then(checkStatus);
  },
  get_grades(assignment_id, batch_id) {
    return $backend
      .post("teachers_get_grades", {
        object_id: assignment_id,
        batch_id
      })
      .then(checkStatus)
      .then(r => r.data.content.grades);
  },
  delete(model, courseId, opts = {}) {
    const data = {
      course_id: courseId,
      object_id: model.object_id,
      object_type: model.object_type
    };

    let config = { data, ...opts };
    return $backend.delete("teachers_delete_stores", config).then(checkStatus);
  },
  unshare(object_id, batch_id, course_id) {
    return $backend
      .delete("teachers_delete_shares", {
        data: { object_id, batch_id, course_id, object_type: "assignment" }
      })
      .then(checkStatus);
  }
};
