import axios from "axios";
import { Backend } from "~/utils/backend";
import { checkStatus, expireAuthCookie } from "/global/utils/helpers";

const API_URL = process.env.VUE_APP_BACKEND_SESSIONS_URL;
const $backend = new Backend(process.env.VUE_APP_BACKEND_EVENTS_URL);

export const UserApi = {
  AUTH_URL: "/auth/signin",
  ACCOUNTS_URL: "/auth/signin",
  BROWSE_COURSES: process.env.VUE_APP_STUDENTS_FRONTEND_URL + "/browse-courses",
  AUTH_COOKIE_NAME: "m_URT",
  COOKIE_EXPIRE_DATE: 9,
  DOMAIN: process.env.VUE_APP_DOMAIN,
  SESSION_URL: API_URL.replace("teachers", "sessions"),

  loadAuthToken() {
    let name = this.AUTH_COOKIE_NAME + "=";
    let allCookieArray = document.cookie.split(";");
    for (let i = 0; i < allCookieArray.length; i++) {
      let cookie = allCookieArray[i].trim();
      if (cookie.startsWith(name))
        return cookie.substring(name.length, cookie.length);
    }
    return "";
  },

  async get(token) {
    return await axios
      .get(this.SESSION_URL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(checkStatus)
      .then(({ data }) => data);
  },

  expireAuthCookie() {
    expireAuthCookie();
  },

  setAuthorizationHeader(token) {
    Backend.authToken = token;
  },

  redirectOnPublicEnroll(course_id, teacher_id, partner_code) {
    // should be on CourseSearchApi
    window.location.href = `${this.BROWSE_COURSES}/${course_id}/`;
  },

  saveAuthCookie(token) {
    var cookie = "";
    const date = new Date();
    date.setTime(date.getTime() + 9 * 24 * 60 * 60 * 1000);
    cookie = `m_URT=${token}; expires=${date.toGMTString()};path=/;SameSite=Strict`;
    document.cookie = cookie;
  },

  async refreshSessionData(token) {
    this.setAuthorizationHeader(token);
    return $backend
      .post("refresh_token")
      .then(checkStatus)
      .then(r => {
        this.expireAuthCookie();
        this.saveAuthCookie(r.data.content.new_token);
        this.setAuthorizationHeader(r.data.content.new_token);
        return r.data.content.new_token;
      });
  },
};
