var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ml-10 pl-10" },
    [
      _c("h1", { staticClass: "headline font-weight-medium mt-10" }, [
        _vm._v(" Organizations "),
      ]),
      _c(
        "v-card",
        { staticClass: "mt-10", attrs: { flat: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "mt-2",
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _vm.isTeacher
                    ? _c("v-tab", [_vm._v("My Organizations")])
                    : _vm._e(),
                  _c("v-tab", [_vm._v("Joined Organizations")]),
                  _c("v-tab", [_vm._v("Search Organizations")]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _vm.isTeacher
                    ? _c("v-tab-item", [
                        _c(
                          "div",
                          { staticClass: "m-account-card" },
                          [
                            _c("my-organization-list", {
                              on: {
                                showCreateDialogue: function ($event) {
                                  _vm.isCreateDialogVisible = true
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("v-tab-item", [
                    _c(
                      "div",
                      { staticClass: "m-account-card" },
                      [
                        _c("joined-organization-list", {
                          on: {
                            showJoinDialogue: function ($event) {
                              _vm.isJoinDialogVisible = true
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("v-tab-item", [
                    _c(
                      "div",
                      { staticClass: "m-account-card" },
                      [_c("search-organization")],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.isJoinDialogVisible,
            callback: function ($$v) {
              _vm.isJoinDialogVisible = $$v
            },
            expression: "isJoinDialogVisible",
          },
        },
        [
          _c("join-organization-form", {
            attrs: { joining: _vm.isJoining },
            on: {
              join: _vm.join,
              cancel: function ($event) {
                _vm.isJoinDialogVisible = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.isCreateDialogVisible,
            callback: function ($$v) {
              _vm.isCreateDialogVisible = $$v
            },
            expression: "isCreateDialogVisible",
          },
        },
        [
          _c("new-organization-form", {
            attrs: { creating: _vm.isCreating },
            on: {
              create: _vm.create,
              cancel: function ($event) {
                _vm.isCreateDialogVisible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }