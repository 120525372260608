import { Backend } from "~/utils/backend";
import { checkStatus } from "/global/utils/helpers";

const $backend = new Backend(
  process.env.VUE_APP_BACKEND_ACCT_URL + "/users/language"
);
export default {
  async updateLanguage(language, opts = {}) {
    let outgoing = { lang_preference: language };
    return await $backend
      .post(undefined, outgoing, { shouldSendPartnerCode: false })
      .then(checkStatus)
      .then(response => {
        return response;
      });
  }
};
