<template>
  <v-container class="pa-0">
    <m-batch-card
      v-for="(batch, idx) in batches"
      :key="batch.id"
      :batch="batch"
      @show-details="showDialog(idx)"
    />
    <v-dialog v-model="createDialog" width="650px">
      <m-batch-details-dialog
        :batch="batches[selected_index]"
        :is-subscribed="isSubscribed"
        :course="course"
        @cancel="hideDialog"
      ></m-batch-details-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import MBatchCard from "#ef/batch/components/MBatchCard";
import MBatchDetailsDialog from "@ef/modules/batch/components/MBatchDetailsDialog";
export default {
  name: "MBatchTableP",
  components: { MBatchCard, MBatchDetailsDialog },
  props: {
    batches: {
      type: Array,
      default: () => [],
    },
    course: {
      type: Object,
      required: true,
    },
    isSubscribed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: undefined,
      selected_index: undefined,
      createDialog: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    showDialog(sel_ind) {
      this.selected_index = sel_ind;
      this.createDialog = true;
    },
    hideDialog() {
      this.createDialog = false;
    },
  },
};
</script>

<style></style>
