// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".mk-base-card {\n  border: 1px solid #cfcfcf;\n  border-radius: 5px;\n  transition: all 0.2s;\n  background-color: white;\n}\n.mk-base-card .mk-divider {\n  border-color: #cfcfcf;\n  border-width: 1px;\n}\n.mk-base-card .mk-divider-col {\n  border-color: #cfcfcf;\n  border-width: 1px;\n}\n.mk-base-card .mk-card-grey-col {\n  background: #f5f5f5;\n  border-bottom-left-radius: 5px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n}\n.mk-base-card .on-hover {\n  box-sizing: border-box;\n  border: 1px solid #0099dc;\n  border-left: 8px solid #0099dc;\n  box-shadow: 2px 2px 7px #cfcfcf;\n  transform: scaleX(1.02);\n}\n.mk-base-card .on-hover .mk-divider {\n  border-color: #0099dc;\n  border-width: 0.5px;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
