var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "container-xl": _vm.$vuetify.breakpoint.xlOnly,
        "container-lg": _vm.$vuetify.breakpoint.lgOnly,
        "container-tab":
          _vm.$vuetify.breakpoint.smOnly || _vm.$vuetify.breakpoint.mdOnly,
        "container-mobile": _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c("div", { staticClass: "summary-title" }, [
        _vm._v("Your order summary"),
      ]),
      _c("div", { staticClass: "d-flex summary-sub-title" }, [
        _c("img", {
          attrs: {
            src: require("root-src/assets/mouchak_favicon.svg"),
            alt: "logo",
          },
        }),
        _c("span", [_vm._v("Advanced All Feature")]),
      ]),
      _c("div", { staticClass: "subscription-text" }, [_vm._v("Subscription")]),
      _c("div", { staticClass: "payment-info" }, [
        _c("div", { staticClass: "payment-plan" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.info.selectedPlan.charAt(0).toUpperCase() +
                  _vm.info.selectedPlan.substring(1)
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "payment-amount",
            class: { "m-strikthrough": _vm.info.discount > 0 },
          },
          [
            _vm._v(
              " BDT " + _vm._s(_vm.amount) + " TK /" + _vm._s(_vm.unit) + " "
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "student-count" }, [
        _vm._v(" Students to be enrolled : "),
        _c("span", [_vm._v(_vm._s(_vm.info.numOfStudents) + " Students")]),
      ]),
      _vm.info.discount > 0
        ? _c("div", { staticClass: "offer-info" }, [
            _c("div", { staticClass: "one" }, [_vm._v("SPECIAL OFFER")]),
            _c("div", { staticClass: "two" }, [
              _vm._v(
                " OFF " +
                  _vm._s(_vm.info.discount) +
                  _vm._s(_vm.info.discountType === "percentage" ? "%" : "TK") +
                  " "
              ),
            ]),
            _c("div", { staticClass: "three" }, [
              _vm._v(
                "BDT " +
                  _vm._s(_vm.amount - _vm.discount) +
                  " TK /" +
                  _vm._s(_vm.unit)
              ),
            ]),
          ])
        : _vm._e(),
      _c("hr"),
      _c("div", { staticClass: "subtotal" }, [
        _c("div", [_vm._v("Subtotal")]),
        _c("div", [
          _vm._v(
            "BDT " +
              _vm._s(_vm.amount - _vm.discount) +
              " TK /" +
              _vm._s(_vm.unit)
          ),
        ]),
      ]),
      _c("div", { staticClass: "total" }, [
        _c("div", [_vm._v("TOTAL")]),
        _c("div", [
          _vm._v(
            "BDT " +
              _vm._s(_vm.amount - _vm.discount) +
              " TK /" +
              _vm._s(_vm.unit) +
              " "
          ),
          _c("span", [_vm._v("+ Tax")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }