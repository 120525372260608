<template>
  <v-menu transition="slide-y-transition" bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-icon class="mr-2 ml-2" v-on="on">apps</v-icon>
    </template>
    <v-list class="account-menu__option-list">
      <!--<v-list-item>-->
      <!--<v-list-item-title-->
      <!--&gt;<v-icon>settings</v-icon> &nbsp;Settings</v-list-item-title-->
      <!--&gt;-->
      <!--</v-list-item>-->
      <v-list-item @click="$emit('logout')">
        <v-list-item-icon><v-icon>power_settings_new</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "MService"
};
</script>

<style scoped></style>
