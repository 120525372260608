<template>
  <div class="text-center" :class="screenSize >= 960 ? 'mr-12' : ''">
    <v-menu
      v-model="isActive"
      offset-y
      left
      transition="slide-y-transition"
      :nudge-right="10"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="btn-active"
          width="108"
          height="44"
          text
          color="#0080B8"
          depressed
          :class="isActive ? 'active' : ''"
          v-bind="attrs"
          v-on="on"
          ><img class="pr-2" :src="selectedFlag" />
          {{ selectedBtnLanguage }}
          <v-icon v-if="isActive" color="black">mdi-chevron-up</v-icon>
          <v-icon v-else color="black">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in language"
          :key="i"
          @click="changeLang(item)"
        >
          <img :src="item.img_icon" :alt="item.alt_text" />
          <v-list-item-title class="ml-3 title-color"
            >{{ item.long }} - {{ item.short }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import AppShellService from "@ecf/app-shell/services/AppShellService";
import banFlagIcon from "@ecf/app-shell/assets/img/ban.png";
import engFlagIcon from "@ecf/app-shell/assets/img/eng.png";
export default {
  data: () => ({
    isActive: false,
    selectedBtnLanguage: "",
    selectedFlag: "",
    language: [
      {
        long: "বাংলা",
        short: "বাং",
        img_icon: banFlagIcon,
        key_to_lang: "BN",
        alt_text: "Bangladesh Flag"
      },
      {
        long: "English",
        short: "Eng",
        img_icon: engFlagIcon,
        key_to_lang: "EN",
        alt_text: "USA Flag"
      }
    ]
  }),
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    }
  },
  created() {
    const selectedLangObject = this.$language
      ? this.language[0]
      : this.language[1];
    this.selectedBtnLanguage = selectedLangObject.short;
    this.selectedFlag = selectedLangObject.img_icon;
  },
  methods: {
    changeLang(language_item) {
      this.selectedBtnLanguage = language_item.short;
      this.selectedFlag = language_item.img_icon;
      if (language_item.long === "বাংলা") {
        this.$language = true;
        AppShellService.updateLanguage(language_item.key_to_lang);
      } else {
        this.$language = false;
        AppShellService.updateLanguage(language_item.key_to_lang);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #d6f3ff;
}
.title-color {
  color: #0080b8;
}
#btn-active::before {
  background-color: transparent;
}</style
>>
