import essayMapper from "../mappers/essay-mapper";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";
import { Backend } from "~/utils/backend";
import questionMapper from "#ecf/question-bank/mappers/question-mapper";

const backend = new Backend(process.env.VUE_APP_BACKEND_BOLT_TEACHERS_URL);
const backendEdu = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
export default {
  get(essay_id, course_id, owner_id) {
    return backend
      .get("teachers_get_essays", {
        params: { ids: [essay_id], course_id: course_id, owner_id: owner_id }
      })
      .then(checkStatus)
      .then(({ data }) => essayMapper.remoteToLocal(data.content.essays[0]));
  },

  getQuestions(essay_id, course_id, owner_id) {
    return backend
      .get("teachers_list_essay_questions", {
        params: { essay_id, course_id, owner_id }
      })
      .then(checkStatus)
      .then(({ data }) =>
        data.content.questions.map(questionMapper.remoteToLocal)
      );
  },
  extractBlobsToUpload(htmlContent) {
    let blobArrayToUpload = [];
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlContent, "text/html");
    const htmlElements = htmlDoc.querySelectorAll(
      "img,video,audio,tag-diagram"
    );
    let counter = 0;
    while (counter < htmlElements.length) {
      let htmlElement = htmlElements[counter];
      let srcUUID = htmlElement
        .getAttribute("src")
        .split("/")
        .pop()
        .split(".")[0]; // quite a shaky way to pick the uuid
      blobArrayToUpload.push(srcUUID);
      counter++;
    }
    return blobArrayToUpload;
  },
  async create(essay, courseId, ownerId) {
    const essayData = {
      item_text: essay.text,
      topics: essay.topics,
      files: essay.files
    };
    essayData.course_id = courseId;
    essayData.owner_id = ownerId;

    let blobArrayToUpload = [...this.extractBlobsToUpload(essay.text)];
    let teachers_create_essay_response = await backend
      .post("teachers_create_essay", essayData)
      .then(checkStatus)
      .then(({ data }) => data.content);

    if (teachers_create_essay_response.id) {
      let topicNames = essay.topics.map(t => t.text);
      await this._attachTopics(
        teachers_create_essay_response.id,
        topicNames,
        ownerId,
        courseId
      );
    }
    for (let i = 0; i < blobArrayToUpload.length; i++) {
      await backendEdu.upload_to_s3(
        teachers_create_essay_response.files_info[blobArrayToUpload[i]],
        essay.files[blobArrayToUpload[i]].file
      );
    }
    return teachers_create_essay_response.id;
  },
  _attachTopics(essayId, topics, owner_id, course_id) {
    const data = {
      topics: topics,
      object_id: essayId,
      object_type: "essay",
      owner_id: owner_id,
      course_id: course_id
    };
    return backend.post("teachers_attach_topics", data).then(checkStatus);
  },
  _uploadImage(essayId, image) {
    const data = {
      file: image,
      object_type: "essay",
      object_id: essayId
    };
    return backend.formData(data);
  },

  async update(essayId, essay, courseId, ownerId) {
    const data = essayMapper.localToRemote(essay, essayId);
    data.course_id = courseId;
    data.owner_id = ownerId;
    let resp = await backend.put("teachers_edit_essay", data).then(checkStatus);
    if (resp.data.content.file_info)
      await backend.upload_to_s3(resp.data.content.file_info, essay.image.file);
    return true;
  },

  async delete(essayId, courseId, ownerId) {
    return backend
      .delete("teachers_delete_essay", {
        data: { id: essayId, course_id: courseId, owner_id: ownerId }
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async createQuestionInfo(
    question_group,
    questions,
    answers,
    difficulty,
    type,
    sub_type,
    course_id,
    owner_id,
    essay_id
  ) {
    answers = answers.map((item, index) => ({
      question_id: item.identifier,
      item_text: ["fib", "amcq"].includes(type.toLowerCase())
        ? item.details.join(",")
        : item.details
    }));
    const questionInfoData = {
      question_group,
      questions,
      answers,
      difficulty,
      type,
      sub_type,
      course_id,
      owner_id,
      essay_id,
      is_edit: false
    };
    let blobArrayToUpload = [...this.extractBlobsToUpload(question_group.text)];
    let resp = await backend
      .post("teachers_create_edit_question_matching_info", questionInfoData)
      .then(checkStatus)
      .then(loadDataWithContent);

    for (let item of blobArrayToUpload) {
      await backendEdu.upload_to_s3(
        resp["files_info"][item],
        question_group.files[item].file
      );
    }
    return resp;
  },

  async getQuestionInfo(payload) {
    return backend
      .post("teachers_get_question_group", payload)
      .then(checkStatus)
      .then(loadDataWithContent);
  }
};
