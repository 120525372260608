/**
 *
 * @param {AxiosResponse} res
 * @return {AxiosResponse}
 */

export default function handleResponse(res) {
  res = JSON.parse(JSON.stringify(res));
  if (res.status >= 200 && res.status < 300) {
    if (res.data.status === "error") {
      Logger.log("Response Error", res);
      throw new Error(JSON.stringify(res.data.content));
    } else {
      return res;
    }
  } else {
    Logger.log("Server error", res);
    throw new Error(res.data);
  }
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function expireAuthCookie() {
  const AUTH_COOKIE_NAME = "m_URT";
  let cookie =
    AUTH_COOKIE_NAME +
    `=${null}; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;SameSite=Strict;`;
  document.cookie = cookie; // expiring for {domain_name}

  cookie += `domain=${process.env.VUE_APP_DOMAIN};`;
  document.cookie = cookie; // expiring for .{domain_name}

  cookie += `domain=mouchak.com;`; // expiring for possibly existing .mouchak.com, since the suffix is common
  document.cookie = cookie; // expiring for .{domain_name}
}

export function expireRoleCookie() {
  let cookie =
    "userRole" +
    `=${null}; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;SameSite=Strict;`;
  document.cookie = cookie; // expiring for {domain_name}

  cookie += `domain=${process.env.VUE_APP_DOMAIN};`;
  document.cookie = cookie; // expiring for .{domain_name}

  cookie += `domain=mouchak.com;`; // expiring for possibly existing .mouchak.com, since the suffix is common
  document.cookie = cookie; // expiring for .{domain_name}
}

export function goToAuthPage() {
  window.location.href = `${process.env.VUE_APP_FRONTEND_SIGNIN_URL}?redirectTo=${window.location.href}`;
}

function getResponseMessage(res) {
  const resp = loadDataWithContent(JSON.parse(JSON.stringify(res)));
  return resp.message ?? resp.error;
}

function getResponseCause(res) {
  const resp = loadDataWithContent(JSON.parse(JSON.stringify(res)));
  return resp.cause;
}

export function checkStatus(res) {
  if (res.status >= 200 && res.status < 300) {
    if (res.data.status === "error") {
      Logger.log("Response Error", res);
      throw new Error(getResponseMessage(res), {
        cause: getResponseCause(res)
      });
    } else {
      return res;
    }
  } else {
    throw new Error(res.data);
  }
}

export function loadCookie(name) {
  let allCookieArray = document.cookie.split(";");
  for (let i = 0; i < allCookieArray.length; i++) {
    let cookie = allCookieArray[i].trim();
    if (cookie.startsWith(name))
      return cookie.substring(name.length + 1, cookie.length); // for "="
  }
  return "";
}

export function expireCookie(name, domain = undefined) {
  let cookie =
    name +
    `=${null}; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;SameSite=Strict`;
  if (domain) cookie += `;domain=${domain}`;
  document.cookie = cookie;
}

export const formatAMPM = function(hours, minutes) {
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export async function download(url) {
  let bytes = await fetch(url).then(r => r.blob());
  return bytes;
}

export async function blobToString(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = event => {
      resolve(event.target.result);
    };
    reader.readAsText(blob);
  });
}

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const suffixes = [
  "0th",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
  "29th",
  "30th",
  "31st"
];

export const RandomStringGen = function(length) {
  let charlist =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyx0123456789";
  let len = charlist.length;
  let out = "";
  for (let i = 0; i < length; i++) {
    let num = Math.ceil(Math.random() * 62);
    out += charlist.charAt(num);
  }
  return out;
};

export function delayPromise(t) {
  return new Promise(function(resolve) {
    setTimeout(resolve.bind(null), t);
  });
}

export function getCurrentTimeUTC() {
  const tmLoc = new Date();
  return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
}

export function loadData(res) {
  return res.data;
}

export function loadDataWithContent(res) {
  return res.data.content;
}

export function addNamespace(namespace, types) {
  Object.keys(types).forEach(k => {
    types[k] = `${namespace}/${types[k]}`;
  });
}

export const Logger = {
  debug: process.env.VUE_APP_DEBUG,
  log(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.log(...args);
    }
  },
  dir(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.dir(...args);
    }
  },
  error(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.error(...args);
    }
  },
  info(...args) {
    if (this.debug === "true") {
      // eslint-disable-next-line
      console.info(...args);
    }
  }
};

export function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

export function scrollTo(id, opt = {}) {
  document.getElementById(id)?.scrollIntoView({
    behavior: "smooth",
    ...opt
  });
}

export function removeTagsFromString(stringToConvert) {
  if (stringToConvert === undefined || stringToConvert === "") return "";
  return stringToConvert.replace(/<[^>]*>?/gm, "");
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
