<template>
  <v-app-bar
    color="#ffffff"
    class="m-app-bar align-center"
    clipped-left
    app
    fixed
    flat
  >
    <img
      v-show="!hideMouchakLogo"
      height="49"
      width="79"
      :src="require('@/assets/ielts/ielts-logo.svg')"
      class="clickable ml-sm-8 ml-md-16"
      alt="IELTS Logo"
      @click="$router.push('/')"
    />
    <slot name="options"></slot>
  </v-app-bar>
</template>

<script>
import courseService from "@ef/modules/course/services/CourseService";

export default {
  name: "TheTopBar",
  data() {
    return {
      item: {},
      courseId: undefined,
      fetchingCourses: false,
      partnerCode: undefined,
      batchId: undefined,
      loadSearchResult: false,
      selected: undefined,
      showCourseDetails: undefined,
      courses: [],
      showBar: false,
      hideMouchakLogo: false,
      searchResultItems: [],
      searchKey: "",
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    isSmall() {
      if(this.$vuetify.breakpoint.width < 600){
        return true;
      }else{
        return false;
      }
    },
    showSearchBar(){
      if(this.isSmall){
        return false;
      }else{
        return true;
      }
    },
    userName() {
      return this.$store.state.user.user.name;
    },
    authUrl() {
      return process.env.VUE_APP_ACCOUNTS_FRONTEND_URL || "";
    },
    teacherUrl() {
      return process.env.VUE_APP_TEACHERS_FRONTEND_URL || "";
    },
    isTeacher() {
      return this.$store.state.user.user.authorized_views.includes(
        "TEACHER_VIEW",
      );
    },
    sizes() {
      return {
        searchBar: {
          width: this.breakPointValues(290, 350, 450, 550),
          height: this.breakPointValues(36, 40, 43, 43)
        },
        searchButton:{
          height: this.breakPointValues(36, 40, 43, 43),
          width: this.breakPointValues(20, 68, 78, 78),
          marginLeft: this.breakPointValues(12, -68, -79, -79),
        } 
      }
      
    },
  },
  watch: {
    searchKey: {
      handler(value) {
        if (value.length >= 3) {
          this.fetchSearchResult();
        } else {
          this.courses = [];
          this.searchResultItems = [];
        }
      },
    },
  },
  methods: {
    toogleSearchBar(){
      this.showBar = !this.showBar;
      this.hideMouchakLogo = !this.hideMouchakLogo;
    },
    async fetchSearchResult() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.fetchingCourses = true;
        this.courses = await courseService.searchCourse(this.searchKey);
        this.searchResultItems = this.courses.splice(-3);
        this.loadSearchResult = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingCourses = false;
      }
    },
    search() {
      if (this.searchKey)
        this.$router.push(`/students/browse-courses?q=${this.searchKey}`);
      else{
        this.showBar = true;
      }
    },
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
  },
};
</script>

<style lang="scss" scoped>
$text-field-border-radius: "24px";

.v-menu__content {
  border-radius: 0px 0px 20px 20px;
}
.searchbar {
  .v-label {
    font-size: 1em !important;
  }
  .v-input__control {
    border-radius: 24px !important;
  }

  .v-input__control .v-input__slot {
    border-radius: 24px !important;
    min-height: auto !important;
  }
}

/* language switch button */
.switch {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding-bottom: 10px;
}

.switch > span {
  position: absolute;
  top: 11px;
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  /* color: #f36f25; */
  color: #18a6dd;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  /* color: #f36f25; */
  color: #18a6dd;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  padding-bottom: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 97px;
  height: 35px;
  /* background-color: #f36f25; */
  background-color: #18a6dd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  /* background-color: #f36f25; */
  background-color: #18a6dd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label {
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
}
</style>
