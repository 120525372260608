// import { delayPromise, Logger } from "@ecf/global/helpers";
// import checkStatus from "/global/utils/helpers";
// import { encode } from "@msgpack/msgpack";
// import axios from "axios";
// import _ from "lodash";

// const API_URL = process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL;

// let $axios = axios.create({
//   baseURL: API_URL,
//   timeout: 100000,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// const global = { partner_code: undefined };

// const transformToMsgPack = function transformToMsgPack(data) {
//   return !_.isEmpty(data) ? encode(data) : "";
// };

// function make_request(channel, request_id, data = "") {
//   const requestConfig = {
//     headers: {
//       "Channel-Name": channel,
//       "Request-Id": request_id,
//       "Content-Type": "application/msgpack",
//     },
//     transformRequest: transformToMsgPack,
//   };

//   Logger.log(requestConfig, data);
//   return $axios.post("", data, requestConfig);
// }
// // function make_request(channel, request_id, data = "") {
// //   return new Promise((resolve, reject) => {
// //     let oReq = new XMLHttpRequest();
// //     oReq.open("POST", API_URL);
// //     oReq.setRequestHeader("Channel-Name", channel);
// //     oReq.setRequestHeader("Request-Id", request_id);
// //     oReq.setRequestHeader("Content-Type", "application/msgpack");
// //     oReq.setRequestHeader(
// //       "Authorization",
// //       $axios.defaults.headers["Authorization"]
// //     );
// //     oReq.onload = resolve;
// //     oReq.onerror = reject;
// //     oReq.send(data);
// //   });
// // }

// function get_status(channel, request_id) {
//   return $axios.get("", {
//     headers: {
//       "Channel-Name": channel,
//       "Request-Id": request_id,
//     },
//   });
// }

// async function call_backend(channel, data, options) {
//   if (_.get(options, "shouldSendPartnerCode", true)) {
//     data.partner_code = global.partner_code;
//   }
//   const timeout = _.get(options, "timeout", 1000);
//   Logger.log(`[${channel} Payload: `, data);
//   const rn = window.crypto.getRandomValues(new Uint32Array(1))[0];
//   const requestId = `${channel}-${rn}`;
//   await make_request(channel, requestId, data);

//   await delayPromise(timeout);
//   let lastResponse = undefined;
//   for (let i = 0; lastResponse === undefined && i < 7; i++) {
//     try {
//       lastResponse = await get_status(channel, requestId);
//     } catch (e) {
//       await delayPromise(timeout);
//     }
//   }

//   return lastResponse;
// }

// async function upload_to_s3(channel, data, file) {
//   const response = await call_backend("teachers_post_stores", data)
//     .then(checkStatus)
//     .then(r => r.data.content);
//   const fields = response.fields;
//   const formData = new FormData();
//   formData.append("key", fields.key);
//   Object.entries(fields).forEach(entry => {
//     if (entry[0] !== "key") formData.append(entry[0], entry[1]);
//   });
//   formData.append("file", file);
//   return await axios
//     .post(response.url, formData)
//     .then(checkStatus)
//     .catch(e => {
//       throw JSON.parse(JSON.stringify(e.response));
//     });
// }

// export default {
//   post: (channel, data = {}, _opts = {}) => call_backend(channel, data, _opts),
//   get: (channel, data = {}) =>
//     call_backend(channel, _.get(data, "params", {}), data),
//   put: (channel, data, _opts = {}) => call_backend(channel, data, _opts),
//   delete: (channel, data = {}) => call_backend(channel, data.data, data),
//   apost: (url, data, options = {}) => {
//     if (_.get(options, "shouldSendPartnerCode", true))
//       data.append("partner_code", global.partner_code);
//     return $axios.post(url, data, options.headers);
//   },
//   headers: $axios.defaults.headers,
//   interceptors: $axios.interceptors,
//   upload_to_s3,
//   global,
// };
