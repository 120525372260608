import vuetifyComponents from "~ecf/components/vuetify-wrappers";
import components from "./index";
import Vue from "vue";

Object.keys(vuetifyComponents).forEach(key => {
  Vue.component(key, vuetifyComponents[key]);
});
Object.keys(components).forEach(key => {
  Vue.component(key, components[key]);
});
