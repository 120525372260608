var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "browse-courses pa-0" }, [
    _vm.loadSearchResult === true
      ? _c(
          "div",
          [
            _c(
              "v-container",
              { staticClass: "search-results pa-2" },
              [
                _c("v-row", [
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    !_vm.$language
                      ? _c("p", { staticClass: "pl-3" }, [
                          _vm._v(
                            " Showing " +
                              _vm._s(_vm.courses.length) +
                              " Results For "
                          ),
                          _c("span", { staticStyle: { color: "#0099dc" } }, [
                            _vm._v('"' + _vm._s(_vm.searchQuery) + '"'),
                          ]),
                        ])
                      : _c("p", { staticClass: "pl-3" }, [
                          _c("span", { staticStyle: { color: "#0099dc" } }, [
                            _vm._v(_vm._s(_vm.courses.length)),
                          ]),
                          _vm._v(" টি অনুসন্ধানকৃত ফলাফল "),
                        ]),
                  ]),
                ]),
                _c(
                  "v-row",
                  [
                    _c(
                      "div",
                      { staticClass: "overflow-y" },
                      [
                        _c("course-list", {
                          attrs: {
                            courses: _vm.courses,
                            "search-query": _vm.searchQuery,
                          },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "overflow-x-auto py-0 hidden-sm-and-down",
                        staticStyle: { "max-height": "70vh" },
                      },
                      [
                        _vm.selectedCourse
                          ? _c("m-course-details", {
                              attrs: { course: _vm.selectedCourse },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }