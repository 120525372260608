var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { class: [_vm.classCollapsedSideBar] },
    [
      _c("the-top-bar"),
      _c("new-app-navigation-drawer", {
        on: { collapse: _vm.handleCollapse, logout: _vm.logout },
      }),
      _c(
        "div",
        { staticClass: "pa-3" },
        [_c("router-view", { key: _vm.$route.fullPath })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }