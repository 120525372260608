export const DashboardModule = {
  namespaced: true,
  state: {
    stats: undefined,
    orgStats: undefined,
    totalStats: undefined,
    recentActivities: undefined,
    pendingActivities: undefined
  },
  getters: {},
  mutations: {
    setStats(state, stats) {
      state.stats = stats;
    },
    setOrgStats(state, stats) {
      state.orgStats = stats;
    },
    setTotalStats(state, stats) {
      state.totalStats = stats;
    },
    setRecentActivities(state, activities) {
      state.recentActivities = activities;
    },
    setPendingActivities(state, activities) {
      state.pendingActivities = activities;
    }
  }
};
