import courseworkService from "./services/CourseworkService";
import Vue from "vue";
import { _types } from "@ef/modules/assignment/store-types";
import _ from "lodash";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg"

export default {
  state: {
    courseworks: {},
    lastFetched: -1,
  },
  getters: {
    [_types.GET_COURSEWORKS]: state => Object.values(state.courseworks),
    [_types.GET_COURSEWORK]: state => id => state.courseworks[id],
  },
  mutations: {
    [_types.SET_COURSEWORK](state, payload) {
      Vue.set(state.courseworks, payload.cid, payload.coursework);
    },
    [_types.SET_COURSEWORKS](state, payload) {
      state.courseworks = payload;
    },
    [_types.REMOVE_COURSEWORK](state, payload) {
      Vue.delete(state.courseworks, payload.cid);
    },
    setLastFetched(state, value) {
      state.lastFetched = value;
    },
  },
  actions: {
    async [_types.FETCH_COURSEWORKS](ctx, payload = {}) {
      if (payload.cid && ctx.state.courseworks[payload.cid]) {
        return true;
      }
      if (ctx.getters[_types.GET_COURSEWORKS].length > 0) {
        return true;
      }

      const res = await courseworkService.find(payload);
      if (payload.cid) {
        const coursework = res.coursework;
        ctx.commit(_types.SET_COURSEWORK, { cid: payload.cid, coursework });
        return coursework;
      } else {
        const courseworks = {};
        res.courseworks.forEach(t => (courseworks[t.cid] = t));
        ctx.commit(_types.SET_COURSEWORKS, courseworks);
        return courseworks;
      }
    },
    async [_types.CREATE_COURSEWORK](ctx, payload) {
      const res = await courseworkService.save(payload);
      const cid = res.cid;
      _.delay(_i => {
        try {
          ctx.dispatch(_types.FETCH_COURSEWORKS, { cid });
        } catch (e) {
          Swal.fire({
            title: "Alert",
            text: e,
            imageUrl: alertIcon
          });
        }
      }, 300);

      return cid;
    },
    async [_types.UPDATE_COURSEWORK](ctx, payload) {
      await courseworkService.update(payload);
      const cid = payload.cid;
      _.delay(_i => {
        try {
          ctx.dispatch(_types.FETCH_COURSEWORKS, { cid });
        } catch (e) {
          Swal.fire({
            title: "Alert",
            text: e,
            imageUrl: alertIcon
          });
        }
      }, 300);
      return true;
    },
    async [_types.DELETE_COURSEWORK](ctx, payload) {
      await courseworkService.delete(payload);
      const cid = payload.cid;
      ctx.commit(_types.REMOVE_COURSEWORK, { cid });
      return true;
    },
  },
};
