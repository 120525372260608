import { AuthTypes } from "@af/modules/auth/auth-types";
import { UserTypes } from "@af/modules/user/user-types";
import { ServiceTypes } from "@af/modules/service/service-types";

export default {
  ...AuthTypes,
  ...UserTypes,
  ...ServiceTypes,
  SHOULD_SHOW_MESSAGE: "shouldShowMessage",
  GET_MESSAGE: "getMessage",
  GET_MESSAGE_TYPE: "getMessageType",
  IS_PERSISTENT_MESSAGE: "isPersistentMessage",
  SET_MESSAGE: "setMessage",
  SET_MESSAGE_TYPE: "setMessageType",
  SET_SHOULD_SHOW_MESSAGE: "setShouldShowMessage",
  SET_PERSISTENT_MESSAGE: "setPersistentMessage",
  SHOW_MESSAGE: "showMessage"
};
