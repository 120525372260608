import { Backend } from "~/utils/backend";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";

const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);

export default {
  uploadModule(title, contents, course_id, partner_code) {
    return $backend
      .post(
        "teachers_post_module",
        {
          title,
          contents,
          course_id,
          partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  uploadSection(title, contents, course_id, partner_code) {
    return $backend
      .post(
        "teachers_post_section",
        {
          title,
          contents,
          course_id,
          partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getCourseModules(course_id, partner_code) {
    return $backend
      .post(
        "teachers_get_modules",
        { course_id, partner_code },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getUnassignedModules(course_id, partner_code) {
    return $backend
      .post(
        "teachers_get_unassigned_modules",
        { course_id, partner_code },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  getCourseSections(course_id, partner_code) {
    return $backend
      .post(
        "teachers_get_sections",
        { course_id, partner_code },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  editModule(id, title, contents, course_id, partner_code) {
    return $backend
      .post(
        "teachers_edit_module",
        {
          id,
          title,
          contents,
          course_id,
          partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  editSection(id, title, contents, course_id, partner_code) {
    return $backend
      .post(
        "teachers_edit_section",
        {
          id,
          title,
          contents,
          course_id,
          partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  removeModule(id, course_id, partner_code) {
    return $backend
      .post(
        "teachers_delete_module",
        {
          id,
          course_id,
          partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  },
  removeSection(id, course_id, partner_code) {
    return $backend
      .post(
        "teachers_delete_section",
        {
          id,
          course_id,
          partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(loadDataWithContent);
  }
};
