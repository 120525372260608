import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
const $public_backend = new Backend(
  process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL,
);
import { checkStatus } from "/global/utils/helpers";

function transformCourseSearchResponse(response) {
  for (let course of response.courses) {
    course.teacher = {
      user_id: course.owner_id,
      name: response.teachers[course.owner_id].name,
      avatar: response.teachers[course.owner_id].avatar,
    };
  }
  return response.courses;
}

export default {
  URL: "courses/",

  /**
   * @return Promise
   * @param {object} params
   * @param opts
   */
  find(params = {}, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("students_get_batches", { params, ...opts })
      .then(checkStatus)
      .then(r => r.data.content.batches)
      .then(batches =>
        batches.map(b => {
          b.currency = b.currency.toUpperCase();
          return b;
        }),
      );
  },
  getCourse(courseId) {
    return $backend
      .get("students_get_courses", { params: { course_id: courseId } })
      .then(checkStatus)
      .then(({ data }) => transformCourseSearchResponse(data.content));
  },

  join_with_code(request_code) {
    return $backend
      .get("students_post_joins", { params: { request_code } })
      .then(checkStatus);
  },

  searchCourse(queryString, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    return $backend
      .get("students_search_courses", {
        params: { query: queryString },
        ...opts,
      })
      .then(checkStatus)
      .then(r => transformCourseSearchResponse(r.data.content));
  },

  singleCourseData(course_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    return $backend
      .get("students_get_courses", {
        params: { course_id: course_id },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getAllCourse(queryString, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    return $backend
      .get("teachers_get_courses", { params: { query: queryString }, ...opts })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  searchQueryResult(queryString, opts = {}) {
    return $backend
      .get("public_search_courses", { params: { query: queryString }, ...opts })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getBatchesForThisCourse(course_id, teacher_id, partner_code, opts = {}) {
    return $public_backend
      .get("public_get_batches", {
        params: {
          course_id: course_id,
          teacher_id: teacher_id,
          partner_code: partner_code,
        },
        shouldSendPartnerCode: false,
      })
      .then(checkStatus)
      .then(r => r.data.content.batches);
  },

  studentJoinsBatch(course_id, batch_id, opts = {}) {
    return $backend
      .post("students_join_batch", {
        course_id: course_id,
        batch_id: batch_id,
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
};
