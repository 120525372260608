<template>
  <div class="col-grid" :style="`grid-template-columns: ${columns}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "GridCol",
  props: {
    columns: {
      type: String,
      default: "auto auto"
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>

<style>
.col-grid {
  display: grid;
}
</style>
