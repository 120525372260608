<template>
  <div>
    <v-main :class="[collapseOrNonCollapseSidebar]">
      <the-top-bar></the-top-bar>
      <AppNavigationDrawer :user-name="user.name">
        <template v-slot:drawer-items>
          <CourseNavItemList
            :course-title="course.course_title"
            :partner_code="partner_code"
            @collapseOrNonCollapse="collapseOrNonCollapseHandler"
          ></CourseNavItemList>
        </template>
      </AppNavigationDrawer>
      <!--    <v-card height="500" width="500" color="blue"></v-card>-->
      <div class="pa-3">
        <router-view v-if="renderNow" :key="$route.fullPath"></router-view>
      </div>
    </v-main>
  </div>
</template>

<script>
import AppNavigationDrawer from "@ecf/app-shell/components/AppNavigationDrawer";
import CourseNavItemList from "#ecf/course/components/CourseNavItemList";
import TheTopBar from "../../app-shell/components/TheTopBar.vue";

export default {
  name: "CourseSelectedLayout",
  components: { CourseNavItemList, AppNavigationDrawer, TheTopBar },
  props: {
    courseId: String,
    partner_code: String,
  },
  provide() {
    return {
      $courseId: () => this.courseId,
      $courseOwnerId: () => this.course.owner_id,
    };
  },
  data() {
    return {
      renderNow: false,
      isCollapse: true,
    };
  },
  computed: {
    user() {
      return this.$store.state.userDetails;
    },
    course() {
      const partnerCourses =
        this.$store.state.course.courseMap[this.partner_code];
      if (partnerCourses) {
        return partnerCourses[this.courseId];
      } else {
        return "";
      }
    },
    smallScreen() {
      return this.$vuetify.breakpoint.width < 801;
    },
    collapseOrNonCollapseSidebar() {
      return this.smallScreen
        ? "mk-small-screen-active-and-noncollapse-active"
        : "";
    },
    shouldActiveDarkShadow() {
      return this.smallScreen && !this.isCollapse ? true : false;
    },
  },
  async created() {
    await this.$store.commit(
      "organization/setCurrentOrganization",
      this.partner_code
    );
    await this.$store.dispatch("course/list", {
      partner_code: this.partner_code,
      selectedCourseId: this.courseId,
    });

    let currentOwnerId =
      this.$store.state.course.courseMap[this.partner_code][this.courseId]
        .owner_id;
    await this.$store.dispatch("course/setOwner", {
      currentOwnerId,
    });
    this.renderNow = true;
  },
  methods: {
    collapseOrNonCollapseHandler(isCollapse) {
      this.isCollapse = isCollapse;
      let elm = document.getElementsByClassName(
        "mk-small-screen-active-and-noncollapse-active"
      )[0];
      if (this.shouldActiveDarkShadow) {
        elm.style.setProperty("--psudo-width", "100%");
      } else {
        elm.style.setProperty("--psudo-width", "0%");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mk-small-screen-active-and-noncollapse-active {
  padding-left: 56px !important;
  --psudo-height: 100%;
  --psudo-width: 0px;
  height: 100vh;
}

.mk-small-screen-active-and-noncollapse-active::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--psudo-width);
  height: var(--psudo-height);
  left: 0px;
  top: 0px;
  z-index: 1;
  background: #0000008c 0% 0% no-repeat padding-box;
  opacity: 0.5;
  transition: width 0.5s !important;
  transition-timing-function: ease-in-out !important;
}
</style>
