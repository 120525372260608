import { _types } from "./store-types";

export default {
  state: {
    showMessage: false,
    messageText: "",
    messageType: "success",
    persistentMessage: false
  },
  getters: {
    [_types.GET_MESSAGE]: state => state.messageText,
    [_types.GET_MESSAGE_TYPE]: state => state.messageType,
    [_types.SHOULD_SHOW_MESSAGE]: state => state.showMessage,
    [_types.IS_PERSISTENT_MESSAGE]: state => state.persistentMessage
  },
  mutations: {
    [_types.SET_MESSAGE](state, messageText) {
      state.messageText = messageText;
    },
    [_types.SET_MESSAGE_TYPE](state, messageType) {
      state.messageType = messageType;
    },
    [_types.SET_SHOULD_SHOW_MESSAGE](state, value) {
      state.showMessage = value;
    },
    [_types.SET_PERSISTENT_MESSAGE](state, value) {
      state.persistentMessage = value;
    }
  },
  actions: {
    [_types.SHOW_MESSAGE](ctx, payload) {
      ctx.commit(_types.SET_MESSAGE, payload.message);
      ctx.commit(_types.SET_MESSAGE_TYPE, payload.messageType || "success");
      if (payload.persistent) {
        ctx.commit(_types.SET_PERSISTENT_MESSAGE, true);
      }
      ctx.commit(_types.SET_SHOULD_SHOW_MESSAGE, true);
    }
  }
};
