<template>
  <v-app-bar
    color="#ffffff"
    class="m-app-bar"
    clipped-left
    clipped-right
    app
    fixed
    flat
  >
    <!--<v-toolbar-title>{{ currentRouteTitle }}</v-toolbar-title>-->
    <img
      :src="require('@/assets/ielts/ielts-logo.svg')"
      class="m-dashboard__logo clickable"
      alt=""
      @click="goToHome"
    />
    <v-spacer></v-spacer>
    <language-button></language-button>
  </v-app-bar>
</template>

<script>
import LanguageButton from "@ecf/app-shell/components/LanguageButton.vue";

export default {
  name: "TheTopBar",
  components: { LanguageButton },
  props: {
    userName: String,
    joinRequestCount: Number
  },
  computed: {
    pendingRequestIconColor() {
      return this.joinRequestCount ? "primary" : "";
    },
    authUrl() {
      return process.env.VUE_APP_ACCOUNTS_FRONTEND_URL || "";
    },
    studentUrl() {
      return process.env.VUE_APP_STUDENTS_FRONTEND_URL || "";
    },
    isStudent() {
      return this.$store.state.user.user.authorized_views.includes(
        "STUDENT_VIEW"
      );
    }
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";
@import "/global/styles/scss/app";
/* language switch button */
.switch {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding-bottom: 10px;
}

.switch > span {
  position: absolute;
  top: 11px;
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  /* color: #f36f25; */
  color: #18a6dd;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  /* color: #f36f25; */
  color: #18a6dd;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  padding-bottom: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 97px;
  height: 35px;
  /* background-color: #f36f25; */
  background-color: #18a6dd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  /* background-color: #f36f25; */
  background-color: #18a6dd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
}

input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label {
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
}
</style>
