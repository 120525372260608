<template>
  <div :style="`width: ${style.wholeWidth}px;`">
    <p :style="`color: #0099dc; font-size: ${style.stepText}px;`">
      Step 1 Of 3
    </p>
    <p :style="`font-size: ${style.pickText}px; font-weight: bold;`">
      Pick a subscription
    </p>
    <div
      style="
        background: white;
        border: 1px solid #f1f1f1;
        border-radius: 5px;
        padding: 15px 0 15px 25px;
      "
    >
      <div class="d-flex">
        <img
          :src="require('root-src/assets/mouchak_favicon.svg')"
          alt="logo"
          width="21.5"
        />
        <span
          class="ml-2"
          :style="`font-size: ${style.advcText}px; font-weight: bold;`"
          >Advanced All Feature</span
        >
      </div>
      <div :style="`font-size: ${style.smText}px;`" class="mt-2">
        In this package, you will be getting all the features unlocked. You will
        be charged <span style="color: #0099dc">BDT 20/per student</span> in
        this package.
      </div>
    </div>
    <submission-alert-message
      should-scroll-to-top
      class="pt-3"
      ref="banner"
      v-model="showBanner"
      :message="bannerMessage"
    ></submission-alert-message>
    <span
      :style="`font-weight: 600; font-size: ${style.smText}px; position: relative; top: ${style.instructionPosition}%`"
      >Please Enter How many students you wish to enroll</span
    >
    <div style="display: flex; align-items: baseline">
      <div
        :style="`width: ${style.textFieldWidth}px; margin-top: ${style.textmt}px;`"
      >
        <v-form ref="form">
          <v-text-field
            outlined
            type="number"
            v-model="formData.numOfStudents"
            label="Students to be enrolled"
            placeholder="Ex: 100"
            :rules="[required, (v) => minValue(v, 10)]"
          ></v-text-field>
        </v-form>
      </div>
      <p
        :style="`font-size: ${
          $vuetify.breakpoint.xs ? 16 : style.pickText
        }px; color: #0099DC; font-weight: bold; margin-left: ${
          style.tkleft
        }px;`"
      >
        X 20.00 TK
      </p>
    </div>
    <div style="display: flex">
      <div :style="`width: ${style.textFieldWidth}px;`">
        <v-text-field
          outlined
          label="Coupon"
          v-model="formData.coupon"
          placeholder="Ex: june20"
          @change="getCoupon"
        ></v-text-field>
      </div>
      <div
        v-if="formData.discount > 0"
        :class="{
          'm-discount-card-xl': $vuetify.breakpoint.xlOnly,
          'm-discount-card-lg': $vuetify.breakpoint.lgOnly,
          'm-discount-card-tab':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
          'm-discount-card-mobile': $vuetify.breakpoint.xsOnly,
        }"
      >
        <div>SPECIAL OFFER</div>
        <div>
          OFF {{ formData.discount
          }}{{ formData.discountType == "percentage" ? "%" : "TK" }}
        </div>
      </div>
    </div>
    <span
      :style="`font-weight: 600; font-size: ${style.smText}px; position: relative; bottom: 1%`"
      >Please select your subscription</span
    >
    <div class="d-flex flex-wrap">
      <div
        :class="{
          'm-selected': formData.selectedPlan === 'monthly',
          'm-xlonly-plan-card': $vuetify.breakpoint.xlOnly,
          'm-lgonly-plan-card': $vuetify.breakpoint.lgOnly,
          'm-tabonly-plan-card':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
          'm-mobile-plan-card': $vuetify.breakpoint.xsOnly,
          'm-stretch-card-xl':
            $vuetify.breakpoint.xlOnly && formData.discount > 0,
          'm-stretch-card-lg':
            $vuetify.breakpoint.lgOnly && formData.discount > 0,
          'm-stretch-card-tab':
            ($vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly) &&
            formData.discount > 0,
          'm-stretch-card-mobile':
            $vuetify.breakpoint.xsOnly && formData.discount > 0,
          'border-decline': formData.selectedPlan === undefined && validating,
        }"
        @click="formData.selectedPlan = 'monthly'"
      >
        <div class="monthly-plan">Monthly Plan</div>
        <div class="billed">Billed monthly</div>
        <div
          v-if="formData.discount > 0"
          class="actual-amount"
          :class="{
            'mt-6': $vuetify.breakpoint.xlOnly,
            'mt-5':
              $vuetify.breakpoint.lgOnly ||
              $vuetify.breakpoint.smOnly ||
              $vuetify.breakpoint.mdOnly,
          }"
        >
          BDT {{ formData.numOfStudents * 20 }} TK<span class="small">/mo</span>
        </div>
        <div
          :class="{
            'mt-1': formData.discount > 0,
            'amount-shrinked':
              formData.discount > 0 && $vuetify.breakpoint.xsOnly,
            amount: !(formData.discount > 0 && $vuetify.breakpoint.xsOnly),
          }"
        >
          BDT {{ getDiscountedPrice(formData.numOfStudents * 20) }} TK<span
            class="small"
            >/mo</span
          >
        </div>
      </div>
      <div
        :class="{
          'm-selected': formData.selectedPlan === 'annually',
          'm-xlonly-plan-card mx-5': $vuetify.breakpoint.xlOnly,
          'm-lgonly-plan-card mx-4': $vuetify.breakpoint.lgOnly,
          'm-tabonly-plan-card ml-2':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
          'm-mobile-plan-card my-2': $vuetify.breakpoint.xsOnly,
          'm-stretch-card-xl':
            $vuetify.breakpoint.xlOnly && formData.discount > 0,
          'm-stretch-card-lg':
            $vuetify.breakpoint.lgOnly && formData.discount > 0,
          'm-stretch-card-tab':
            ($vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly) &&
            formData.discount > 0,
          'm-stretch-card-mobile':
            $vuetify.breakpoint.xsOnly && formData.discount > 0,
          'border-decline': formData.selectedPlan === undefined && validating,
        }"
        @click="formData.selectedPlan = 'annually'"
      >
        <div class="monthly-plan">
          Annual Plan <span class="m-duration">(12 month)</span>
        </div>
        <div class="billed">Billed annually</div>
        <div
          v-if="formData.discount > 0"
          class="actual-amount"
          :class="{
            'mt-6': $vuetify.breakpoint.xl,
            'mt-5':
              $vuetify.breakpoint.lgOnly ||
              $vuetify.breakpoint.smOnly ||
              $vuetify.breakpoint.mdOnly,
          }"
        >
          BDT {{ formData.numOfStudents * 240 }} TK<span class="small"
            >/mo</span
          >
        </div>
        <div
          :class="{
            'mt-1': formData.discount > 0,
            'amount-shrinked':
              formData.discount > 0 && $vuetify.breakpoint.xsOnly,
            amount: !(formData.discount > 0 && $vuetify.breakpoint.xsOnly),
          }"
        >
          BDT {{ getDiscountedPrice(formData.numOfStudents * 240) }} TK<span
            class="small"
            >/yr</span
          >
        </div>
        <div class="m-info">(BDT {{ formData.numOfStudents * 20 }} TK/mo)</div>
      </div>
      <div
        :class="{
          'm-selected': formData.selectedPlan === 'quarterly',
          'm-xlonly-plan-card': $vuetify.breakpoint.xlOnly,
          'm-lgonly-plan-card': $vuetify.breakpoint.lgOnly,
          'm-tabonly-plan-card mt-2':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
          'm-mobile-plan-card': $vuetify.breakpoint.xsOnly,
          'm-stretch-card-xl':
            $vuetify.breakpoint.xlOnly && formData.discount > 0,
          'm-stretch-card-lg':
            $vuetify.breakpoint.lgOnly && formData.discount > 0,
          'm-stretch-card-tab':
            ($vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly) &&
            formData.discount > 0,
          'm-stretch-card-mobile':
            $vuetify.breakpoint.xsOnly && formData.discount > 0,
          'border-decline': formData.selectedPlan === undefined && validating,
        }"
        @click="formData.selectedPlan = 'quarterly'"
      >
        <div class="monthly-plan">
          Quarterly Plan <span class="m-duration">(3 month)</span>
        </div>
        <div class="billed">Billed quarterly</div>
        <div
          v-if="formData.discount > 0"
          class="actual-amount"
          :class="{
            'mt-6': $vuetify.breakpoint.xl,
            'mt-5':
              $vuetify.breakpoint.lgOnly ||
              $vuetify.breakpoint.smOnly ||
              $vuetify.breakpoint.mdOnly,
          }"
        >
          BDT {{ formData.numOfStudents * 60 }} TK<span class="small">/mo</span>
        </div>
        <div
          :class="{
            'mt-1': formData.discount > 0,
            'amount-shrinked':
              formData.discount > 0 && $vuetify.breakpoint.xsOnly,
            amount: !(formData.discount > 0 && $vuetify.breakpoint.xsOnly),
          }"
        >
          BDT {{ getDiscountedPrice(formData.numOfStudents * 60) }} TK<span
            class="small"
            >/qt</span
          >
        </div>
        <div class="m-info">(BDT {{ formData.numOfStudents * 20 }} TK/mo)</div>
      </div>
    </div>
    <div class="pt-2 px-3" style="height: 24px">
      <v-slide-y-transition>
        <div
          v-show="formData.selectedPlan === undefined && validating"
          class="decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs text-compress"
        >
          Subscription plan is required
        </div>
      </v-slide-y-transition>
    </div>
    <span
      v-if="!$vuetify.breakpoint.xsOnly"
      :style="`font-weight: 600; font-size: ${style.smText}px; position: relative; top: ${style.instructionPosition}%`"
      >Press "CONTINUE" to proceed to the payment method selection</span
    >
    <div v-if="$vuetify.breakpoint.smAndUp">
      <button
        :class="{
          'payment-back-xl': $vuetify.breakpoint.xlOnly,
          'payment-back-lg': $vuetify.breakpoint.lgOnly,
          'payment-back-tab':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
        }"
        @click="$router.go(-1)"
      >
        BACK
      </button>
      <button
        :class="{
          'payment-continue-xl': $vuetify.breakpoint.xlOnly,
          'payment-continue-lg': $vuetify.breakpoint.lgOnly,
          'payment-continue-tab':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
        }"
        @click="continueHandler"
      >
        CONTINUE
      </button>
    </div>
  </div>
</template>

<script>
import { PaymentApi } from "@af/modules/payment/api";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "PaymentForm",
  props: ["value"],
  components: { SubmissionAlertMessage },
  mixins: [FieldValidations],
  computed: {
    ok(){
      return this.formData.numOfStudents >= 10 && this.formData.selectedPlan;
    },
    style() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return {
            wholeWidth: 330,
            stepText: 12,
            pickText: 18,
            advcText: 14,
            textFieldWidth: 220,
            textmt: 15,
            smText: 11,
            tkleft: 10,
            instructionPosition: 1,
          };
        case "sm":
          return {
            wholeWidth: 420,
            stepText: 14,
            pickText: 20,
            advcText: 14,
            textFieldWidth: 220,
            textmt: 34,
            smText: 11,
            tkleft: 20,
            instructionPosition: 1,
          };
        case "md":
          return {
            wholeWidth: 420,
            stepText: 14,
            pickText: 20,
            advcText: 14,
            textFieldWidth: 220,
            textmt: 34,
            smText: 11,
            tkleft: 20,
            instructionPosition: 1,
          };
        case "lg":
          return {
            wholeWidth: 690,
            stepText: 14,
            pickText: 20,
            advcText: 16,
            smText: 12,
            textFieldWidth: 292,
            textmt: 26,
            tkleft: 20,
            instructionPosition: 1,
          };
        case "xl":
          return {
            wholeWidth: 820,
            stepText: 16,
            pickText: 25,
            advcText: 18,
            smText: 14,
            textFieldWidth: 350,
            textmt: 52,
            tkleft: 35,
            instructionPosition: 3,
          };
        default:
          return {};
      }
    },
    bannerMessage() {
      return "Must do the necessary steps";
    },
  },
  watch: {
    formData: {
      handler(value) {
        this.$emit("input", value);
      },
      deep: true,
    },
    ok: {
      handler(value){
        if(value){
          this.showBanner = false;
          this.validating = false;
        }
      }
    },
    "formData.numOfStudents": {
      handler(value) {
        if (value >= 10 && this.studentAlert) this.studentAlert = false;
      },
    },
    "formData.selectedPlan": {
      handler(value) {
        if (!(value === undefined) && this.planAlert) this.planAlert = false;
      },
    },
  },
  data() {
    return {
      formData: {
        coupon: "",
        selectedPlan: undefined,
        numOfStudents: 0,
        discountType: "percentage",
        discount: 0,
      },
      showBanner: false,
      validating: false,
    };
  },
  mounted() {
    this.formData.coupon = this.value.coupon;
    this.formData.selectedPlan = this.value.selectedPlan;
    this.formData.numOfStudents = this.value.numOfStudents;
    this.formData.discountType = this.value.discountType;
    this.formData.discount = this.value.discount;
  },
  methods: {
    async getCoupon() {
      try {
        let details = await PaymentApi.queryCoupon(
          this.formData.coupon,
          "offer_page"
        );
        if (
          Object.keys(details).length &&
          details.applicable_for === "teacher"
        ) {
          this.formData.discountType = details.coupon_type;
          this.formData.discount = details.amount;
        } else {
          this.formData.discount = 0;
        }
      } catch (e) {
        this.formData.discount = 0;
        this.$root.$emit("alert", [undefined, e.response.data.content.message]);
      }
    },
    continueHandler() {
      if (this.ok) {
        this.$emit("continue");
      }
      this.validating = true;
      this.$refs.form.validate();
      if (!this.showBanner) this.showBanner = true;
      else {
        this.$refs.banner.flash();
      }
    },
    getDiscountedPrice(amount) {
      let discount =
        this.formData.discountType === "percentage"
          ? (amount * this.formData.discount) / 100.0
          : this.formData.discount;
      return amount - discount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@af/modules/payment/styles/payment-form.scss";
</style>
