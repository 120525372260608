var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.batch.batch_title) + " ")]
        },
        proxy: true,
      },
      {
        key: "col-1",
        fn: function () {
          return [
            _c("div", [
              _c("p", { staticClass: "text-center my-3" }, [
                _vm._v(
                  " " +
                    _vm._s(!_vm.$language ? "Type" : "ব্যাচ টাইপ") +
                    ":   " +
                    _vm._s(_vm.batchType) +
                    " "
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "col-2",
        fn: function () {
          return [
            _c(
              "v-row",
              { class: { "flex-column": _vm.$vuetify.breakpoint.smAndDown } },
              [
                _c(
                  "v-col",
                  [
                    _c("m-base-card-info", {
                      attrs: {
                        label: !_vm.$language ? "Price" : "মূল্য",
                        value: !_vm.$language
                          ? `${
                              _vm.batch.price || _vm.batch.amount
                            } ${_vm.batch.currency.toUpperCase()}`
                          : `${_vm.batch.price || _vm.batch.amount} ${
                              _vm.currencyBN
                            }`,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "m-base-card-info",
                      { attrs: { label: !_vm.$language ? "Session" : "সেশন" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$language
                                ? _vm.batch.begin_date
                                : new Date(_vm.batch.begin_date).toLocaleString(
                                    "bn-BD",
                                    { dateStyle: "short" }
                                  )
                            ) +
                            " " +
                            _vm._s(!_vm.$language ? "to" : "থেকে") +
                            " " +
                            _vm._s(
                              !_vm.$language
                                ? _vm.batch.end_date
                                : new Date(_vm.batch.end_date).toLocaleString(
                                    "bn-BD",
                                    { dateStyle: "short" }
                                  )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "col-3",
        fn: function () {
          return [
            _c(
              "m-base-card-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("show-details")
                  },
                },
              },
              [_vm._v(_vm._s(!_vm.$language ? "DETAILS" : "বিস্তারিত"))]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }