import CourseSelectedLayout from "@ecf/routes/layouts/CourseSelectedLayout";
import Vue from "vue";
import Router from "vue-router";
import CourseUnselectedLayout from "./layouts/CourseUnselectedLayout";

// Vue.use(Router);

const ThankYou = () => import("./thankyou/ThankYouPage");
const Batch = () => import("./batch/BatchListPage");
const BatchAdd = () => import("./batch/BatchCreatePage");
const BatchHome = () => import("./batch/BatchDetailsPage");
// const Courses = () => import("./course/CourseListPage");
const BulkEnroll = () => import("./course/BulkEnrollPage");
const CourseCreate = () => import("./course/CourseCreatePage");
const CourseDetails = () => import("./course/CourseDetailsPage");
const CourseEdit = () => import("./course/CourseEditPage");
const Assignment = () => import("./assignment/AssignmentListPage");
const AssignmentDetails = () => import("./assignment/AssignmentDetailsPage");
const AssignmentAssesment = () =>
  import("./assignment/AssignmentAssesmentPage");
const Project = () => import("./project/ProjectListPage");
const AddProject = () => import("./project/ProjectUpload");
const ProjectDetails = () => import("./project/ProjectDetailsPage");
const ProjectGrades = () => import("./project/ProjectGradesPage");
const ProjectAssesment = () => import("./project/ProjectAssessmentPage");
const Exam = () => import("./exam/ExamListPage");
const ExamBatchList = () => import("./exam/ExamBatchListPage");
const ExamStudentList = () => import("./exam/ExamStudentListPage");
const ExamStudentDetails = () => import("./exam/ExamStudentDetailsPage");
const ExamCreate = () => import("./exam/ExamCreate");
const ExamDetails = () => import("./exam/ExamDetails");
const FluentExamDetails = () => import("./exam/FluentStudentExamDetails");
const ExamEdit = () => import("./exam/ExamEdit");
const ExamAssesment = () => import("./exam/ExamAssessmentPage");
const Lectures = () => import("./lecture/LectureListPage");
const LecturesAdd = () => import("#ecf/lecture/components/LectureUploadDialog");
const QuestionCreate = () =>
  import("@ecf/routes/question-bank/SingleQuestion/Create");
const QuestionEdit = () =>
  import("@ecf/routes/question-bank/SingleQuestion/Edit");
const QuestionGroupCreate = () =>
  import("@ecf/routes/question-bank/GroupQuestion/Create");
const QuestionGroupEdit = () =>
  import("@ecf/routes/question-bank/GroupQuestion/Edit");
const QuestionGroupShow = () =>
  import("@ecf/routes/question-bank/GroupQuestion/Details");
const QuestionList = () => import("@ecf/routes/question-bank/QuestionList");
const QuestionShow = () =>
  import("@ecf/routes/question-bank/SingleQuestion/Details");
const StudentList = () => import("./student/StudentListPage");
const QuestionPicker = () =>
  import("@ecf/modules/exam/components/QuestionPicker");
const GradingFormat = () => import("./grading/GradingPage");
const BatchGrading = () => import("./grading/BatchGradeList");
const ViewGrades = () => import("./grading/ViewGrades");
const CertificateTemplate = () => import("./grading/CertificateTemplate");
const SmsAndEmail = () => import("./sms-and-email/SmsAndEmail");
const CreateSmsAndEmail = () => import("./sms-and-email/CreateSmsAndEmail");
const SmsAndEmailDetails = () => import("./sms-and-email/SmsAndEmailDetails");
const Announcements = () => import("./announcement/Announcements");
const CreateAnnouncements = () => import("./announcement/CreateAnnouncement");
const AnnouncementDetails = () => import("./announcement/AnnouncementDetails");
const PaymentTransations = () =>
  import("./payment-transation/PaymentTransactionPage.vue");
const SubscriptionPurchase = () =>
  import("./payment-transation/SubscriptionPurchasePage.vue");

const Dashboard = () => import("./dashboard/Dashboard");
const CoursesNew = () => import("./courses/Courses");
const PackageCreate = () => import("./package/PackageCreatePage");
const PackageEdit = () => import("./package/PackageEditPage");
const PackageDetails = () => import("./package/PackageDetailsPage");

const prefix_selected_routes = "/teachers/:partner_code/courses/:courseId";

const CourseDesigner = () => import("./course-design/CourseDesignPage");

export const routesECFUnselected = [
  {
    path: "/teachers",
    component: Dashboard,
    meta: { title: "Dashboard" }
  },
  {
    path: "/teachers/courses",
    name: "courses",
    component: CoursesNew,
    meta: { title: "Courses" },
    props: true
  },
  {
    path: "/teachers/sms-email",
    name: "sms-email",
    component: SmsAndEmail
  },
  {
    path: "/teachers/create-email",
    name: "create-email",
    component: CreateSmsAndEmail,
    props: {
      elementType: "email"
    }
  },
  {
    path: "/teachers/create-sms",
    name: "create-sms",
    component: CreateSmsAndEmail,
    props: {
      elementType: "sms"
    }
  },
  {
    path: "/teachers/sms-details/:message_id",
    name: "sms-details",
    component: SmsAndEmailDetails,
    // props: true
    props: {
      elementType: "sms"
    }
  },
  {
    path: "/teachers/email-details/:message_id",
    name: "email-details",
    component: SmsAndEmailDetails,
    props: {
      elementType: "email"
    }
  },
  {
    path: "/teachers/announcements",
    name: "announcements",
    component: Announcements,
    meta: { title: "Announcements" },
    props: true
  },
  {
    path: "/teachers/create-announcement",
    name: "create-announcement",
    component: CreateAnnouncements,
    meta: { title: "CreateAnnouncements" },
    props: true
  },
  {
    path:
      "/teachers/announcement-details/:announcement_id/:course_id/:partner_code",
    name: "announcement-details",
    component: AnnouncementDetails,
    meta: { title: "AnnouncementDetails" },
    props: true
  },
  {
    path: "/teachers/:partner_code/bulk-enrollment",
    name: "bulk-enrollment",
    component: BulkEnroll,
    meta: { title: "Bulk Enrollment" },
    props: true
  },
  {
    path: "/teachers/:partner_code/courses/create",
    name: "course-create",
    component: CourseCreate,
    meta: { title: "Create Course" },
    props: true
  },
  {
    path: "/teachers/:partner_code/packages/create",
    name: "package-create",
    component: PackageCreate,
    meta: { title: "Package Create" }
  },
  {
    path: "/teachers/:partner_code/packages/:package_id/edit",
    name: "package-edit",
    component: PackageEdit,
    meta: { title: "Package Edit" },
    props: true
  },
  {
    path: "/teachers/:partner_code/packages/:package_id/details",
    name: "package-details",
    component: PackageDetails,
    meta: { title: "Package Details" },
    props: true
  },
  {
    path: "/teachers/thankyou",
    name: "thank-you-page",
    component: ThankYou,
    meta: { title: "Purchase-Successful-Acknowledgement" }
  },
  {
    path: "/teachers/payment-transaction",
    name: "teacher-payment-transaction-page",
    component: PaymentTransations,
    meta: { title: "Payments & Transactions" },
    props: true
  },
  {
    path: "/teachers/subscription-purchase",
    name: "subscription-purchase-page",
    component: SubscriptionPurchase,
    meta: { title: "Purchase Subscription" },
    props: true
  }
];
export const routesECFSelected = [
  {
    path: prefix_selected_routes,
    redirect: "batches"
  },
  {
    path: prefix_selected_routes.concat("/batches"),
    name: "batches",
    component: Batch,
    props: true,
    meta: { title: "Batch" }
  },
  {
    path: prefix_selected_routes.concat("/batches/create"),
    component: BatchAdd,
    props: true,
    meta: { title: "Create Batch" }
  },
  {
    path: prefix_selected_routes.concat("/batches/:batchId/management"),
    name: "batch-management",
    component: BatchHome,
    props: true,
    meta: { title: "Edit Batch" }
  },
  {
    path: prefix_selected_routes.concat("/assignments"),
    name: "assignments",
    component: Assignment,
    props: true,
    meta: { title: "Assignment" }
  },
  {
    path: prefix_selected_routes.concat("/assignments/:assignment_id/"),
    name: "assignment-details",
    component: AssignmentDetails,
    props: true,
    meta: { title: "Assignment Details" }
  },
  {
    path: prefix_selected_routes.concat(
      "/assignments/:assignmentId/:studentId/:index"
    ),
    name: "assignment-assessment",
    component: AssignmentAssesment,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/projects"),
    name: "projects",
    component: Project,
    props: true,
    meta: { title: "Project" }
  },
  {
    path: prefix_selected_routes.concat("/projects/add/"),
    name: "add-project",
    component: AddProject,
    props: true,
    meta: { title: "AddProject" }
  },
  {
    path: prefix_selected_routes.concat("/projects/:project_id/"),
    component: ProjectDetails,
    props: true,
    meta: { title: "Project Details" }
  },
  {
    path: prefix_selected_routes.concat("/projectGrades/:project_id/"),
    component: ProjectGrades,
    props: true,
    meta: { title: "Project Grades" }
  },
  {
    path: prefix_selected_routes.concat(
      "/projects/:projectId/:studentId/:index"
    ),
    name: "project-assessment",
    component: ProjectAssesment,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/exams"),
    name: "exams",
    component: Exam,
    props: true,
    meta: { title: "Exam" }
  },
  {
    path: prefix_selected_routes.concat("/exams/create"),
    component: ExamCreate,
    name: "exam-create",
    props: true,
    meta: { title: "Exam Create" }
  },
  {
    path: prefix_selected_routes.concat("/exams/:examId"),
    name: "exam-details",
    component: ExamDetails,
    props: true,
    meta: { title: "Exam Details" }
  },
  {
    path: prefix_selected_routes.concat("/exams/:examId/edit"),
    name: "exam-edit",
    component: ExamEdit,
    props: true,
    meta: { title: "Exam Edit" }
  },
  {
    path: prefix_selected_routes.concat("/exams/:examId/batches"),
    name: "exam-batches",
    component: ExamBatchList,
    props: true,
    meta: { title: "Batches Shared" }
  },
  {
    path: prefix_selected_routes.concat("/exams/:examId/batches/:batchId"),
    name: "exam-students",
    component: ExamStudentList,
    props: true,
    meta: { title: "Students Participated" }
  },
  {
    path: prefix_selected_routes.concat(
      "/exams/:examId/student-exams/:studentExamId"
    ),
    name: "fluent-student-exam-details",
    component: FluentExamDetails,
    meta: { title: "Exam Details" },
    props: true
  },
  {
    path: prefix_selected_routes.concat(
      "/exams/:studentExamId/:studentId/:questionId"
    ),
    name: "exam-assesment",
    component: ExamAssesment,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/lectures"),
    name: "lectures",
    component: Lectures,
    props: true,
    meta: { title: "Lectures" }
  },
  {
    path: prefix_selected_routes.concat("/lectures/upload"),
    component: LecturesAdd,
    props: true,
    meta: { title: "Upload Lectures" }
  },
  {
    path: prefix_selected_routes.concat("/questions"),
    name: "question-list",
    component: QuestionList
  },
  {
    path: prefix_selected_routes.concat("/questions/create"),
    name: "question-create",
    props: true,
    component: QuestionCreate
  },
  {
    path: prefix_selected_routes.concat("/questions/group/create"),
    name: "question-group-create",
    component: QuestionGroupCreate
  },
  {
    path: prefix_selected_routes.concat("/questions/groups/:essayId"),
    name: "question-group-show",
    component: QuestionGroupShow,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/questions/groups/:essayId/edit"),
    name: "question-group-edit",
    component: QuestionGroupEdit,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/questions/:questionId/edit"),
    name: "question-edit",
    component: QuestionEdit,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/questions/:questionId"),
    name: "question-show",
    component: QuestionShow,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/course_details"),
    name: "course-details",
    component: CourseDetails,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/course_edit"),
    name: "course-edit",
    component: CourseEdit,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/students"),
    name: "students",
    component: StudentList,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/grading"),
    name: "grading",
    component: BatchGrading,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/gradingFormat"),
    name: "gradingFormat",
    component: GradingFormat,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/viewGrades/:batchId"),
    name: "viewGrades",
    component: ViewGrades,
    props: true
  },
  {
    path: prefix_selected_routes.concat("/certificateTemplate"),
    name: "certificate-template",
    component: CertificateTemplate
  },
  {
    path: prefix_selected_routes.concat("/design-course"),
    name: "fluent-design-course",
    component: CourseDesigner,
    meta: { title: "Design Course" },
    props: true
  }
];
