var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pt-10" },
    [
      _c("v-card-title", [_vm._v(" Create an Organization ")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            { ref: "createForm" },
            [
              _c("v-text-field", {
                staticClass: "mt-8",
                attrs: {
                  label: "Name",
                  placeholder: "Enter the organization name...",
                  outlined: "",
                  rules: _vm.rules,
                },
                model: {
                  value: _vm.organizationName,
                  callback: function ($$v) {
                    _vm.organizationName = $$v
                  },
                  expression: "organizationName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", rounded: "", color: "red" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3",
                      attrs: {
                        rounded: "",
                        color: "primary",
                        loading: _vm.creating,
                      },
                      on: { click: _vm.create },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }