import { CertificateApi } from "#ef/certification/api";
import { keyBy } from "lodash";
import Vue from "vue";

export const CertificateModule = {
  namespaced: true,
  state: {
    certificates: {},
    lastFetched: -1,
  },
  getters: {
    certificates: state => Object.values(state.certificates),
    certificate: state => id => state.certificates[id],
  },
  mutations: {
    setCertificate(state, certificate) {
      Vue.set(
        state.certificates,
        certificate.course_id,
        Object.assign(
          state.certificates[certificate.course_id] || {},
          certificate,
        ),
      );
    },
    setCertificates(state, certificates) {
      state.certificates = keyBy(certificates, "course_id");
    },
    setLastFetched(state, value) {
      state.lastFetched = value;
    },
  },
  actions: {
    async list(ctx, payload = {}) {
      const certificates = await CertificateApi.list();
      ctx.commit("setCertificates", certificates);
    },
    async get(ctx, { courseId, batchId }) {
      const certificate = await CertificateApi.get(courseId, batchId);
      ctx.commit("setCertificate", certificate);
      return certificate;
    },
  },
};
