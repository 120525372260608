import Vue from "vue";
import Vuetify from "vuetify/lib";
import AlertIcon from "~ecf/components/AlertIcon.vue";

import colors from "/global/styles/scss/variables.scss";

Vue.use(Vuetify, { components: {} });

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      alert_icon: {
        component: AlertIcon
      }
    }
  },
  theme: {
    themes: {
      light: {
        ...colors
      }
    }
  }
});
