import { checkStatus } from "/global/utils/helpers";
import { Backend } from "~/utils/backend";
import questionMapper from "#ecf/question-bank/mappers/question-mapper";
import ExamCardImg from "#ecf/exam/assets/img_exam.svg";
import ExamPackageCardImg from "#ecf/exam/assets/img_exam_package.jpg";
import moment from "moment";

const backend = new Backend(process.env.VUE_APP_BACKEND_BOLT_TEACHERS_URL);
const eduBackend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);

const ExamMapper = {
  fromRemote(data) {
    data.image_url = data.image_url ?? ExamCardImg;
    data.duration = data.total_duration;
    data.points = data.total_points;
    return data;
  },
  toRemote(exam) {
    const details = exam.examDetails;
    if (exam.type !== undefined && exam.type === "section") {
      let ques = [];
      for (let i = 0; i < exam.questions.length; i++) {
        ques = exam.questions[i].questions.map((q, idx) => ({
          q_id: q.id,
          point: q.points,
          order: idx
        }));
        exam.questions[i].questions = ques;
        exam.questions[i].order = i;
      }
      return {
        ...(details.id ? { id: details.id } : {}),
        title: details.title,
        view: details.view,
        for_practice: details.for_practice,
        instruction: details.instruction,
        total_points: details.points,
        total_duration: details.duration,
        sections: exam.questions
      };
    } else {
      return {
        ...(details.id ? { id: details.id } : {}),
        title: details.title,
        instruction: details.instruction,
        view: details.view,
        for_practice: details.for_practice,
        total_points: details.points,
        total_duration: details.duration,
        questions: exam.questions.map((q, idx) => ({
          q_id: q.id,
          point: q.points,
          order: idx
        }))
      };
    }
  }
};

export const ExamApi = {
  list(course_id, owner_id) {
    return eduBackend
      .get("teachers_list_exams", { params: { course_id, owner_id } })
      .then(checkStatus)
      .then(({ data }) => data.content.exams.map(ExamMapper.fromRemote));
  },
  async getById(examId, course_id, owner_id) {
    const exam = await backend
      .get("teachers_get_exam", {
        params: { id: examId, course_id: course_id, owner_id: owner_id }
      })
      .then(checkStatus)
      .then(({ data }) => ExamMapper.fromRemote(data.content.exam));
    const examContent = await backend
      .get("teachers_list_exam_questions", {
        params: { exam_id: examId, course_id: course_id, owner_id: owner_id }
      })
      .then(checkStatus)
      .then(({ data }) => data.content);

    exam.questions = examContent.questions.map(questionMapper.remoteToLocal);
    exam.sections = examContent.sections;
    exam.options = examContent.options;
    exam.essays = examContent.essays;
    return exam;
  },

  attachQuestionsToExam(partner_code, exam_id, question_ids) {
    return backend
      .post("teachers_attach_questions", {
        partner_code: partner_code,
        exam_id: exam_id,
        question_ids: question_ids
      })
      .then(checkStatus);
  },

  addQuestionsToCategory(examId, categoryId, questions) {
    return backend
      .post("teachers_attach_questions", {
        exam_id: examId,
        category_id: categoryId,
        question_ids: questions
      })
      .then(checkStatus);
  },

  getQuestionsForCategory(examId, categoryId) {
    return backend
      .get("teachers_list_category_questions", {
        params: { exam_id: examId, category_id: categoryId }
      })
      .then(checkStatus)
      .then(({ data }) =>
        data.content.questions.map(questionMapper.remoteToLocal)
      );
  },
  async create(exam, courseId, ownerId) {
    const transformedExam = ExamMapper.toRemote(exam);
    transformedExam.course_id = courseId;
    transformedExam.owner_id = ownerId;
    const questions = transformedExam.questions;
    const sections = transformedExam.sections;
    delete transformedExam.questions;
    delete transformedExam.sections;
    const examResponse = await backend
      .post("teachers_create_exam", transformedExam)
      .then(checkStatus)
      .then(({ data }) => data.content.exam);
    if (exam.type !== undefined && exam.type === "section") {
      await backend
        .post("teachers_attach_questions", {
          exam_id: examResponse.id,
          section_ids: sections,
          owner_id: ownerId,
          course_id: courseId
        })
        .then(checkStatus);
    } else {
      await backend
        .post("teachers_attach_questions", {
          exam_id: examResponse.id,
          question_ids: questions,
          owner_id: ownerId,
          course_id: courseId
        })
        .then(checkStatus);
    }
    await eduBackend
      .post("teachers_add_exam", {
        exam_id: examResponse.id,
        course_id: courseId,
        owner_id: ownerId
      })
      .then(checkStatus);

    return examResponse.id;
  },

  async update(exam, courseId, ownerId) {
    const data = ExamMapper.toRemote(exam);
    data.course_id = courseId;
    const questions = data.questions;
    delete data.questions;
    await backend.put("teachers_edit_exam", data).then(checkStatus);
    return backend.post("teachers_attach_questions", {
      exam_id: data.id,
      question_ids: questions,
      course_id: courseId,
      owner_id: ownerId
    });
  },

  removeExam(examId, courseId) {
    return eduBackend
      .post("teachers_delete_exam", {
        exam_id: examId,
        course_id: courseId
      })
      .then(checkStatus);
  },

  async delete(examId) {
    return backend
      .delete("teachers_delete_exam", { data: { _id: examId } })
      .then(checkStatus);
  },

  shareExam(examId, batchId, courseId, startDate, endDate) {
    return eduBackend
      .post("teachers_share_exam", {
        exam_id: examId,
        batch_id: batchId,
        course_id: courseId,
        start_date: startDate,
        end_date: endDate
      })
      .then(checkStatus);
  },
  listSharedExams(courseId, batchId) {},
  listSharedBatches(examId, courseId) {
    return eduBackend
      .get("teachers_list_shared_exams", {
        params: {
          exam_id: examId,
          course_id: courseId
        }
      })
      .then(checkStatus)
      .then(({ data }) => data.content.shared_exams);
  },
  unshareExam(examId, batchId, courseId) {
    return eduBackend
      .post("teachers_unshare_exam", {
        exam_id: examId,
        batch_id: batchId,
        course_id: courseId
      })
      .then(checkStatus);
  },
  getStudentExams(exam_id, batch_id) {
    return backend
      .post("teachers_get_student_exams", {
        exam_id,
        batch_id
      })
      .then(checkStatus)
      .then(({ data }) => data.content.exams);
  },
  getStudentExamDetails(student_exam_id) {
    return backend
      .post("teachers_get_student_exam_details", {
        student_exam_id
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  setScore(question_id, score, student_exam_id) {
    return backend
      .post("teachers_set_score", {
        student_exam_id,
        score,
        ...(question_id !== student_exam_id ? { question_id } : {})
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  setEvaluation(student_exam_id) {
    return backend
      .post("teachers_evaluate_student_exam", {
        student_exam_id
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  publishScore(batch_id) {
    return backend
      .post("teachers_publish_exam_score", {
        batch_id
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  }
};

const ExamPackageMapper = {
  fromRemote(data) {
    data.startDate = data.start_date
      ? moment(data.start_date, "YYYY-MM-DD")
      : undefined;
    data.endDate = data.end_date
      ? moment(data.end_date, "YYYY-MM-DD")
      : undefined;
    data.image_url = ExamPackageCardImg;
    data.exams = data.exams ? data.exams.map(ExamMapper.fromRemote) : [];
    return data;
  },
  toRemote(examPackage) {
    const details = examPackage.packageDetails;
    return {
      ...(details.id ? { id: details.id } : {}),
      title: details.title,
      price: details.price,
      start_date: details.startDate,
      end_date: details.endDate,
      exams: examPackage.exams ? examPackage.exams.map(e => ({ id: e.id })) : []
    };
  }
};

export const ExamPackageApi = {
  list() {
    return backend
      .get("teachers_list_exam_packages")
      .then(checkStatus)
      .then(({ data }) =>
        data.content.exam_packages.map(ExamPackageMapper.fromRemote)
      );
  },
  getById(examPackageId) {
    return backend
      .get("teachers_get_exam_package", { params: { _id: examPackageId } })
      .then(checkStatus)
      .then(({ data }) =>
        ExamPackageMapper.fromRemote(data.content.exam_package)
      );
  },

  async create(exam_package) {
    const { _id } = await backend
      .post(
        "teachers_create_exam_package",
        ExamPackageMapper.toRemote(exam_package)
      )
      .then(checkStatus)
      .then(({ data }) => data.content);
    return _id;
  },

  async update(exam_package) {
    const data = ExamPackageMapper.toRemote(exam_package);
    await backend.put("teachers_edit_exam_package", data).then(checkStatus);
    return true;
  },

  async delete(examPackageId) {
    return backend
      .delete("teachers_delete_exam_package", { data: { _id: examPackageId } })
      .then(checkStatus);
  },
  async getPurchases(examPackageId) {
    return backend
      .get("teachers_get_purchases", {
        params: { exam_package_id: examPackageId }
      })
      .then(checkStatus)
      .then(({ data }) => data.content.purchases);
  }
};
