<template>
  <v-main :class="[classCollapsedSideBar]">
    <the-top-bar></the-top-bar>
    <new-app-navigation-drawer @collapse="handleCollapse" @logout="logout">
    </new-app-navigation-drawer>
    <!-- <AppNavigationDrawer v-slot:drawer-items :user-name="user.name"> -->
    <!-- <template v-slot:drawer-items>
        <no-course-nav-item-list @collapseOrNonCollapse="collapseOrNonCollapseHandler"></no-course-nav-item-list>
      </template> -->
    <!-- <no-course-nav-item-list
        @collapseOrNonCollapse="collapseOrNonCollapseHandler"
      ></no-course-nav-item-list>
    </AppNavigationDrawer> -->
    <div class="pa-3">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </v-main>
</template>

<script>
// import AppNavigationDrawer from "@ecf/app-shell/components/AppNavigationDrawer";
// import NoCourseNavItemList from "#ecf/course/components/NoCourseNavItemList.vue";
import NewAppNavigationDrawer from "../../app-shell/components/NewAppNavigationDrawer.vue";
import TheTopBar from "@ecf/app-shell/components/TheTopBar.vue";

// import CourseSvg from "@ecf/global/assets/img/icons/Courses.svg"
// import CourseSvgColor from "@ecf/global/assets/img/icons/Courses_color.svg"
const ROUTE_WITHOUT_REDIRECT = [
  "course-list",
  "course-create",
  "bulk-enrollment",
  "sms-and-email",
  "announcements",
  "create-announcement",
  "announcement-details"
];
export default {
  name: "CourseUnselectedLayout",
  components: { NewAppNavigationDrawer, TheTopBar },
  data() {
    return {
      isCollapse: true,
      classCollapsedSideBar: ""
    };
  },
  computed: {
    // icons() {
    //   return {
    //     course: CourseSvg,
    //     courseColor: CourseSvgColor,
    //     dashboard: DashboardSvg,
    //     dashboardColor: DashboardSvgColor,
    //   };
    // },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    user() {
      return this.$store.state.userDetails;
    },
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    currentRoute() {
      return this.$route.fullPath;
    },
    smallScreen() {
      return this.$vuetify.breakpoint.width < 801;
    },
    collapseOrNonCollapseSidebar() {
      return this.smallScreen
        ? "mk-small-screen-active-and-noncollapse-active"
        : "";
    },
    shouldActiveDarkShadow() {
      return this.smallScreen && !this.isCollapse ? true : false;
    }
  },
  // watch: {
  //   async currentOrganization() {
  //     await this.$router.push({
  //       name: "course-list",
  //       params: { partner_code: this.currentOrganization.partner_code },
  //     });
  //   },
  // },
  created() {
    if (ROUTE_WITHOUT_REDIRECT.includes(this.$route.name)) return; // Avoid repeated redirect
    // const partner_code = this.$store.state.organization.currentOrganization
    //   .partner_code;
    // this.$router.replace({
    //   name: "course-list",
    //   params: { partner_code: partner_code },
    // });
  },
  // watch:{
  //   screenSize: {
  //     handler(value){
  //       if(value>=960){
  //         this.classCollapsedSideBar = "";
  //       }
  //     }
  //   }
  // },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
    },
    collapseOrNonCollapseHandler(isCollapse) {
      this.isCollapse = isCollapse;
      let elm = document.getElementsByClassName(
        "mk-small-screen-active-and-noncollapse-active"
      )[0];
      if (this.shouldActiveDarkShadow) {
        elm.style.setProperty("--psudo-width", "100%");
      } else {
        elm.style.setProperty("--psudo-width", "0%");
      }
    },
    handleCollapse(payload) {
      let s = this.screenSize;
      // if(s<600){
      //   this.classCollapsedSideBar = "mk-small-screen-active-and-noncollapse-active";
      // }else if(s<801 && payload){
      //   this.classCollapsedSideBar = "mk-small-screen-active-and-noncollapse-active";
      // }else if(!payload){
      //   this.classCollapsedSideBar = "";
      // }
      if (s < 961) {
        this.classCollapsedSideBar =
          "mk-small-screen-active-and-noncollapse-active";
      } else if (!payload) {
        this.classCollapsedSideBar = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mk-small-screen-active-and-noncollapse-active {
  padding-left: 56px !important;
  --psudo-height: 100%;
  --psudo-width: 0px;
  height: 100vh;
}

.mk-small-screen-active-and-noncollapse-active::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--psudo-width);
  height: var(--psudo-height);
  left: 0px;
  top: 0px;
  z-index: 1;
  background: #0000008c 0% 0% no-repeat padding-box;
  opacity: 0.5;
  transition: width 0.5s !important;
  transition-timing-function: ease-in-out !important;
}
</style>
