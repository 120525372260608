// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".mk-batch-card {\n  border: 1px solid #cfcfcf;\n  max-width: 80%;\n  border-radius: 5px;\n  transition: all 0.2s;\n  background-color: white;\n}\n@media screen and (max-width: 1920px) {\n.mk-batch-card {\n    max-width: 100%;\n}\n}\n.mk-batch-card .mk-divider {\n  border-color: #cfcfcf;\n  border-width: 1px;\n}\n.mk-batch-card .mk-divider-col {\n  border-color: #cfcfcf;\n  border-width: 1px;\n}\n.mk-batch-card .mk-card-col1 {\n  background: #f5f5f5;\n  border-bottom-left-radius: 5px;\n  padding: 16px 32px;\n  width: unset;\n}\n.mk-batch-card .mk-card-col2 {\n  background: #f5f5f5;\n  padding: 16px 48px;\n  border-bottom-right-radius: 5px;\n  width: unset;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
