import sortBy from "lodash/sortBy";
import OrganizationApi from "./OrganizationApi";
import { Backend } from "~/utils/backend";

export default {
  namespaced: true,
  state: {
    organizationMap: {},
    currentOrganization: undefined,
    lastFetched: -1
  },
  getters: {
    organizations: state =>
      sortBy(Object.values(state.organizationMap), "partner_name")
  },
  mutations: {
    setOrganizations(state, organizations) {
      state.organizationMap = organizations.reduce((prev, org) => {
        prev[org.partner_code] = org;
        return prev;
      }, {});
    },
    setCurrentOrganization(state, partner_code) {
      if (!state.organizationMap[partner_code]) {
        state.currentOrganization = Object.values(state.organizationMap)[1];
      } else {
        state.currentOrganization = state.organizationMap[partner_code];
      }
      // hack for sending partner_code with all requests
      Backend.partner_code = state.currentOrganization.partner_code;
    }
  },
  actions: {
    async list(ctx) {
      const now = new Date().getTime() / 1000;
      const lastFetched = ctx.state.lastFetched;
      if (now - lastFetched > 1800) {
        const orgs = await OrganizationApi.list();
        ctx.state.lastFetched = now;
        ctx.commit("setOrganizations", orgs);
        return orgs;
      } else {
        return ctx.getters["organizations"];
      }
    },
    async teachers(ctx, payload) {
      return await OrganizationApi.teachers(payload.partner_code);
    }
  }
};
