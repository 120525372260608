import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  /**
   * @return Promise<AxiosResponse>
   * @param courseId
   * @param opts
   */
  find(courseId, batch_id = undefined, opts = {}) {
    const params = { object_type: "project" };
    let channel = "teachers_get_stores";
    if (batch_id) {
      params.batch_id = batch_id;
      channel = "teachers_get_shares";
    } else {
      params.course_id = courseId;
    }

    return $backend
      .get(channel, {
        params,
        ...opts
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },

  get(project_id, course_id) {
    const object_type = "project";
    const object_id = project_id;
    return $backend
      .get("teachers_get_stores", {
        params: { object_id, course_id, object_type }
      })
      .then(checkStatus)
      .then(r => r.data.content.stores[0]);
  },
  /**
   * @return Promise
   * @param courseId
   * @param project
   * @param headers
   */
  async save(courseId, project) {
    const files = [];
    project.course_id = courseId;
    project.object_type = "project";
    for (let i = 0; i < project.project_steps.length; i++) {
      if (project.project_steps[i].files.length > 0) {
        files.push(project.project_steps[i].files);
        project.project_steps[i].files = project.project_steps[i].files.map(
          f => f.name
        );
      }
    }
    return $backend
      .post("teachers_post_projects", project)
      .then(checkStatus)
      .then(({ data }) => {
        let j = 0;
        for (let i = 0; i < project.project_steps.length; i++) {
          if (project.project_steps[i].files.length > 0) {
            let t = files.shift();
            for (let k = 0; k < t.length; k++) {
              $backend.upload_to_s3(data.content.info[j][k], t[k]);
            }
            j++;
          }
        }
      });
  },

  share(coursework_id, data) {
    data["object_id"] = coursework_id;
    data["object_type"] = "project";
    return $backend
      .post("teachers_post_shares", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  shareMultiple(data) {
    return $backend
      .post("teachers_post_shares_multiple", { params: data })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  deleteMultiple(data) {
    return $backend
      .post("teachers_delete_shares_multiple", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getSharedBatches(object_id, course_id) {
    return $backend
      .get("teachers_get_shares", {
        params: { object_id, object_type: "project", course_id }
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },

  getSubmissions(project_id, course_id, batch_id) {
    return $backend
      .get("teachers_get_submissions", {
        params: {
          object_id: project_id,
          object_type: "project",
          course_id: course_id,
          batch_id: batch_id
        }
      })
      .then(checkStatus)
      .then(r => r.data.content)
      .then(({ submissions, users }) => {
        for (const submission of submissions) {
          submission.user_name = users[submission.student_id];
        }
        return submissions;
      });
  },
  getSubmission(course_id, object_id, student_id) {
    return $backend
      .get("teachers_get_submissions", {
        params: {
          course_id: course_id,
          object_id: object_id,
          student_id: student_id,
          object_type: "project"
        }
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  grade(project_id, course_id, batch_id, student_id, grade) {
    return $backend
      .post("teachers_post_grades", {
        object_id: project_id,
        object_type: "project",
        course_id,
        batch_id,
        student_id,
        grade
      })
      .then(checkStatus);
  },
  get_grades(project_id, batch_id) {
    return $backend
      .post("teachers_get_grades", {
        object_id: project_id,
        batch_id
      })
      .then(checkStatus)
      .then(r => r.data.content.grades);
  },
  delete(model, courseId, opts = {}) {
    const data = {
      course_id: courseId,
      object_id: model.object_id,
      object_type: model.object_type
    };

    let config = { data, ...opts };
    return $backend.delete("teachers_delete_stores", config).then(checkStatus);
  },
  unshare(object_id, batch_id, course_id) {
    return $backend
      .delete("teachers_delete_shares", {
        data: { object_id, batch_id, course_id, object_type: "project" }
      })
      .then(checkStatus);
  }
};
