var render = function render(_c, _vm) {
  return _c("img", {
    staticClass: "mouchak-logo",
    attrs: {
      src: require("@/assets/ielts/ielts-logo.svg"),
      alt: "Fluent Logo",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }