<template>
  <v-dialog v-model="visible" max-width="500">
    <v-card class="pa-6 rounded">
      <v-card-title
        class="align-center justify-center flex-column"
        style="background: #FAFBFD"
      >
        <img :src="icon" alt="icon" />
        <div
          class="pt-4 ft-medium xl:text-base lg:text-base md:text-base sm:text-base"
        >
          {{ header }}
        </div>
      </v-card-title>
      <v-row justify="center" align="center" no-gutters class="px-4">
        <p class="ma-0 xl:text-sm lg:text-sm md:text-sm sm:text-sm">
          {{ message }}
        </p>
      </v-row>
      <v-card-actions class="px-12 py-4">
        <v-row no-gutters class="d-flex justify-center align-center">
          <v-btn
            text
            height="48"
            class="rounded primary white--text"
            @click="buttonAction"
            >{{ buttonText }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AccessAlertDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    cause: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: "You do not have access"
    },
    url: {
      type: String,
      default: ""
    },
    dialogIcon: {
      type: String,
      default: require("../assets/alertDialogIcons/alert.svg")
    }
  },
  data() {
    return {
      visible: undefined
    };
  },
  computed: {
    icon() {
      return this.dialogIcon;
    },
    actions() {
      return this.cause.split(":").slice(1);
    },
    header() {
      if (this.actions.includes("auth")) return "Not logged in?";
      else if (this.actions.includes("upgrade")) return "You are on basic plan";
      else return "Attention";
    },
    buttonText() {
      if (this.actions.includes("auth")) return "Login";
      else if (this.actions.includes("upgrade")) return "Upgrade";
      else return "Proceed";
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.visible = value;
      }
    },
    visible: {
      handler(value) {
        this.$emit("exit", value);
      }
    }
  },
  methods: {
    async buttonAction() {
      this.visible = !this.visible;
      if (this.actions.includes("auth")) {
        await this.$router.push({
          name: "login",
          query: {
            redirectTo: this.url
          }
        });
      } else if (this.actions.includes("upgrade"))
        await this.$router.push({
          name: "student-payment-transaction-page",
          query: {
            redirectTo: this.url
          }
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
