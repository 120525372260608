<template>
  <v-container>
    <v-row style="min-height: 280px;">
      <div class="col-sm-12 col-md5">
        <h2>
          {{ `${course.course_key}: ${course.course_title}` }}
        </h2>
        <p>by {{ course.teacher.name }}</p>
        <h4 class="mb-2">{{ !$language ? "Description" : "বিবরণ" }}</h4>
        <!-- <p>{{ course.course_description }}</p> -->
        <div v-html="course.course_description"></div>
      </div>
      <v-col
        class="d-flex align-center justify-center"
        :class="{ 'image-with-button': isLargeScreen }"
      >
        <v-btn
          v-if="batches.length > 0"
          rounded
          large
          color="primary"
          class="enroll-btn"
          :disabled="course.is_subscribed"
          @click="scrollToBatchList"
          >{{ buttonText }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="mt-3 flex-wrap">
      <div class="col-sm-12 col-md-6">
        <h4 class="mb-2">
          {{ !$language ? "How it works" : "এটি কীভাবে কাজ করে" }}
        </h4>
        <!--        <p>{{ course.how_it_works }}</p>-->
        <div v-html="course.how_it_works"></div>
      </div>
      <div class="col-sm-12 col-md-6">
        <h4 class="mb-2">{{ !$language ? "Syllabus" : "সিলেবাস" }}</h4>
        <!--        <p>{{ course.syllabus }}</p>-->
        <div v-html="course.syllabus"></div>
      </div>
    </v-row>
    <v-row id="batchlist">
      <v-col>
        <h4 class="mb-2">
          {{ !$language ? "List of Batches" : "ব্যাচের তালিকা" }}
        </h4>
        <p v-if="!$language">
          All the batches that are available in this <strong>Course</strong> are
          here.
        </p>
        <p v-else>
          এই কোর্সের আওতাভুক্ত সকল ব্যচের তালিকা এখানে আছে
        </p>
        <m-batch-table-p
          :batches="batches"
          :course="course"
          :is-subscribed="course.is_subscribed"
        ></m-batch-table-p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CourseService from "../services/CourseService";
import MBatchTableP from "@ef/modules/batch/components/MBatchTableP";

export default {
  name: "MCourseDetails",
  components: { MBatchTableP },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      batches: [],
    };
  },
  computed: {
    isLargeScreen() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    buttonText() {
      if (!this.$language) {
        return this.course.is_subscribed ? "Enrolled" : "Enroll Now!";
      } else return this.course.is_subscribed ? "এনরোলড" : "এখনই এনরোল করো!";
    },
  },
  watch: {
    course: {
      async handler() {
        this.batches = await CourseService.getBatchesForThisCourse(
          this.course.course_id,
          this.course.teacher.user_id,
          this.course.partner_code,
        );
      },
      immediate: true,
    },
  },
  methods: {
    scrollToBatchList() {
      let element = document.getElementById("batchlist");
      element.scrollIntoView(true);
    },
  },
};
</script>
<style lang="scss">
.img-with-btn {
  border-radius: 10px;
  background: url("~@ef/modules/course/assets/courseImage.png");
}
.enroll-btn {
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.25), 3px 3px 6px rgba(0, 0, 0, 0.25) !important;
  min-width: 200px !important;
}
</style>
