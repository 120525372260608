import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "lectures/",
  BATCH_SHARE_URL: "batches/lectures/",
  LECTURE_BATCH_URL: "lectures/batches/",
  UPLOAD_URL: "uploads/",

  /**
   * @return Promise<AxiosResponse>
   * @param batch_id
   * @param opts
   */
  find(batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("students_get_shares", {
        params: { batch_id, object_type: "lecture" },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },
  get(object_id, batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    const object_type = "lecture";
    return $backend
      .get("students_get_shares", {
        params: { object_id, batch_id, object_type },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.stores[0]);
  },
  /**
   * @return Promise
   * @param courseId
   * @param lecture
   * @param headers
   */
  async save(courseId, lecture, headers = {}) {
    lecture.course_id = courseId;
    lecture.object_type = "lecture";
    const data = new FormData();
    Object.entries(lecture).forEach(entry => {
      data.append(entry[0], entry[1]);
    });
    return await $backend
      .apost(this.UPLOAD_URL, data, headers)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async save_annotation(params) {
    return await $backend
      .post("students_add_annotation", params)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async get_annotations(params) {
    return await $backend
      .post("students_get_annotations", params)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  share(lecture_id, data) {
    data["object_id"] = lecture_id;
    data["object_type"] = "lecture";
    return $backend
      .post("teachers_post_shares", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getSharedBatches(lecture_id) {
    return $backend
      .get(this.LECTURE_BATCH_URL, { params: { lecture_id } })
      .then(checkStatus)
      .then(r => r.data.data);
  },
};
