<template>
  <v-card class="mk-card-radius pa-4">
    <v-container class="pt-0">
      <v-row>
        <v-col class="d-flex justify-end pa-0">
          <v-btn icon color="grey" @click="$emit('cancel')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title class="title mk-highlight-text px-0 pt-0 pb-3">{{
        batch.batch_title
      }}</v-card-title>

      <p>{{ batch.summary }}</p>

      <h4>{{ !$language ? "Description" : "বিবরণ" }}</h4>
      <p>{{ batch.description }}</p>

      <v-row>
        <v-col>
          <h4>
            {{ !$language ? "Batch Type" : "ব্যাচ টাইপ" }}:
            {{ batchType }}
          </h4>
        </v-col>
        <v-col>
          <h4 style="color: #0099dc">
            {{ !$language ? "Price" : "মূল্য" }}:
            {{ batch.price || batch.amount }}
            {{ !$language ? batch.currency.toUpperCase() : currencyBN }}
          </h4>
        </v-col>
      </v-row>

      <h4>{{ !$language ? "Schedule" : "সময় সূচি" }}</h4>
      <p v-if="batch.batch_type === 'Online'">
        {{ !$language ? "Self-Based" : "ব্যক্তিগত ইচ্ছা ভিত্তিক" }}
      </p>
      <p v-else-if="noScheduleCondition">
        {{ !$language ? "Unavailable" : "অপ্রদত্ত" }}
      </p>
      <p
        v-else
        style="font-size: 14px"
        v-html="formatDay(batch.days).join('<br>')"
      ></p>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          depressed
          large
          rounded
          min-width="200"
          color="primary"
          class="ml-2 btn-outlined"
          :disabled="isSubscribed || isInCart"
          @click="addToCart"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: "MBatchDetailsDialog",
  components: {},
  props: {
    batch: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    isSubscribed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    batchType() {
      if (this.$language) {
        if (this.batch.batch_type == "Online") {
          return "অনলাইন";
        } else if (this.batch.batch_type == "In Person") {
          return "ব্যক্তিগত ভাবে";
        }
      }
      return this.batch.batch_type;
    },
    currencyBN() {
      if (this.batch.currency.toLowerCase() === "bdt") return "টাকা";
      else return "";
    },

    buttonText() {
      if (!this.$language) {
        return this.isSubscribed
          ? "Enrolled"
          : this.isInCart
          ? "Added to Cart"
          : "Add to Cart";
      } else {
        return this.isSubscribed
          ? "এনরোলড"
          : this.isInCart
          ? "কার্টে যোগ করা হয়েছে"
          : "কার্টে যোগ করো";
      }
    },
    isInCart() {
      return this.$store.getters["cart/coursesInCart"].has(
        this.course.course_id,
      );
    },
    noScheduleCondition() {
      if ("days" in this.batch) {
        if (this.batch.length > 0) return false;
      }
      return true;
    },
  },
  created() {},
  methods: {
    async addToCart() {
      if (moment(this.batch.end_date).isBefore()) {
        this.$root.$emit("alert", [
          undefined,
          !this.$language ? "This batch is expired" : "এই ব্যাচের মেয়াদ শেষ",
        ]);
        return;
      }
      try {
        const item = {
          course_id: this.batch.course_id,
          course_type: this.course.course_type,
          partner_code: this.batch.partner_code,
          item_id: this.batch.batch_id,
          item_title: `${this.course.course_title} | ${this.batch.batch_title}`,
          item_body: {
            teacher: this.course.teacher.name,
            begin_date: this.batch.begin_date,
            end_date: this.batch.end_date,
            num_students: this.batch.num_students,
          },
        };
        await this.$store.dispatch("cart/addItem", {
          item,
        });
        this.$emit("cancel");
      } catch (e) {
        this.$root.$emit('alert', ['Success', e.message]);
      }
    },
    formatDay(days) {
      let formattedArray = [];
      let i = 0;
      for (i = 0; i < days.length; i++) {
        let splitDay = days[i].split("#");
        let startTimeExtraction = parseInt(splitDay[1]);
        let endTimeExtraction = parseInt(splitDay[2]);
        let startTime = startTimeExtraction % 12;
        if (startTimeExtraction >= 12) {
          startTime += " PM";
        } else {
          startTime += " AM";
        }
        let endTime = endTimeExtraction % 12;
        if (endTime < 10)
          if (endTimeExtraction < 12) {
            endTime += " AM";
          } else {
            endTime += " PM";
          }
        formattedArray.push(
          splitDay[0] +
            ": " +
            this.returnTimeAMPM(splitDay[1]) +
            " - " +
            this.returnTimeAMPM(splitDay[2]),
        );
      }
      return formattedArray;
    },
    returnTimeAMPM(time) {
      // returns AM/PM format time from 24 hour version
      let splitTime = time.split(":");
      let hours = splitTime[0];
      let AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      let minutes = splitTime[1];
      let finalTime = hours + ":" + minutes + " " + AmOrPm;
      return finalTime;
    },
  },
};
</script>

<style></style>
