<template functional>
  <span class="app-name caption text-uppercase font-weight-bold ml-1">{{
    props.name
  }}</span>
</template>

<script>
export default {
  name: "AppName",
  props: {
    name: {
      type: String,
      required: true
    }
  }
};
</script>
