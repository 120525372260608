var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    {
      attrs: { color: _vm.avatarColor, size: _vm.size },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("span", { staticClass: "white--text", style: _vm.userNameStyle }, [
        _vm._v(_vm._s(_vm.initial)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }