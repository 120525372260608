<template>
  <v-app class="m-site">
    <v-app-bar
      fixed
      elevate-on-scroll
      color="#fff"
      scroll-target="#ielts-content"
      style="background-color: white;"
      class="pa-0 ma-0 navbar"
    >
    <v-row class="pa-0 ma-0">
      <v-col cols="6" :style="`background-color: ${$vuetify.breakpoint.smAndDown ?  '' : '#FAFAFA'}`">
        <img
          height="38"
          width="78"
          class="clickable"
          :src="require('@/assets/ielts/ielts-logo.svg')"
          alt="IELTS Logo"
          @click="$router.push('/')"
          @click.self="scrollToTop"
        />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" class="d-flex justify-space-between"  style="background-color:#FFFF">
        <div class="d-flex align-center">
            <div
              class="mr-8 clickable"
              @click="scrollTo('course')"
            >
              Course
            </div>
            <div
              class="clickable"
              @click=" scrollTo('features')"
            >
              Features
            </div>
          </div>
          <div class="d-flex justify-end pr-3">
            <div v-if="!doesSessionExist">
              <v-btn
                height="48px"
                :width="$vuetify.breakpoint.lgAndUp ? '103px': ''"
                outlined
                @click="$router.push({ path: '/auth/signin' })"
              >
                Log In
              </v-btn>
              <v-btn
                class="ml-4 white--text"
                color="primary"
                depressed
                height="48px"
                :width="$vuetify.breakpoint.lgAndUp ? '169px': ''"
                @click="$router.push({ path: '/auth/register' })"
              >
                Start Free Trial
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                height="45"
                :width="$vuetify.breakpoint.lgAndUp ?'120px' : ''"
                outlined
                @click="$router.push({ path: userPath })"
              >
                Enter
              </v-btn>
              <v-menu
                open-on-hover
                bottom
                offset-y
                transition="slide-y-transition"
              >
                <template #activator="{on}">
                  <v-btn
                    class="ml-4 white--text"
                    height="45"
                    color="primary"
                    depressed
                    :width="$vuetify.breakpoint.lgAndUp ?'180px' : ''"
                    v-on="on"
                  >
                    My Account
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="">
                  <v-list-item
                    class="d-flex justify-center align-center"
                    to="/accounts"
                  >
                    <v-icon class="mr-2">mdi-account-circle</v-icon>
                    <span>View profile</span>
                  </v-list-item>
                  <v-list-item
                    class="d-flex justify-center align-center"
                    @click="logout()"
                  >
                    <v-icon class="mr-2">mdi-location-exit</v-icon>
                    <span>Logout</span></v-list-item
                  >
                </v-list>
              </v-menu>
            </div>
          </div>
      </v-col>
      <v-col v-else class="d-flex justify-end">
      <v-btn @click="isMenu = true" icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      </v-col>
    </v-row>
    </v-app-bar>
    <v-dialog v-model="isMenu" class="ma-5" fullscreen>
      <v-card  
       class="d-flex justify-center align-center"
      style="background: #FFFF; position: relative;"
      >
      <div style="position: absolute; right: 16px; top: 16px;">
        <v-btn id="close-button" icon @click="isMenu = false">
        <v-icon >mdi-close-thick</v-icon>
      </v-btn>
      </div>
      <div >
        <div class="d-flex flex-column justify-center mb-4 align-center" style="gap:16px">
          <v-btn
              height="48px"
              width="175px"
              outlined
               @click="scrollTo('course'), isMenu = false"
            >
            Course
            </v-btn>
            <v-btn
              height="48px"
               width="175px"
              outlined
               @click="scrollTo('features'), isMenu = false"
            >
            Features
            </v-btn>
        </div>
        <div v-if="!doesSessionExist" class="d-flex flex-column justify-center align-center" style="gap:16px">
            <v-btn
              height="48px"
              width="169px"
              outlined
              @click="$router.push({ path: '/auth/signin' })"
            >
              Log In
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              depressed
              height="48px"
              width="169px"
              @click="$router.push({ path: '/auth/register' })"
            >
              Start Free Trial
            </v-btn>
        </div>
        <div v-else class="d-flex flex-column justify-center align-center" style="gap:16px">
                <v-btn
                  height="45"
                   width="180"
                  outlined
                  @click="$router.push({ path: userPath })"
                >
                  Enter
                </v-btn>
                <v-btn  
                  height="45"
                  width="180" 
                  to="/accounts" 
                   outlined>
                  <v-icon class="mr-2">mdi-account-circle</v-icon>
                      <span>View profile</span>
                </v-btn>
                <v-btn  
                  height="45"
                  width="180"  
                  @click="logout()" 
                   outlined>
                  <v-icon class="mr-2">mdi-location-exit</v-icon>
                  <span>Logout</span>
                </v-btn>
                <!-- <v-menu
                  open-on-hover
                  bottom
                  offset-y
                  transition="slide-y-transition"
                >
                  <template #activator="{on}">
                    <v-btn
                      class="white--text"
                      height="45"
                      color="primary"
                      depressed
                      width="180"
                      v-on="on"
                    >
                      My Account
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="">
                    <v-list-item
                      class="d-flex justify-center align-center"
                      to="/accounts"
                    >
                      <v-icon class="mr-2">mdi-account-circle</v-icon>
                      <span>View profile</span>
                    </v-list-item>
                    <v-list-item
                      class="d-flex justify-center align-center"
                      @click="logout()"
                    >
                      <v-icon class="mr-2">mdi-location-exit</v-icon>
                      <span>Logout</span></v-list-item
                    >
                  </v-list>
                </v-menu> -->
        </div>
      </div>
      </v-card>
    </v-dialog>
    <v-main id="ielts-content">
      <router-view></router-view>
    </v-main>
    <!-- Footer section -->
    <v-row no-gutters justify="center" align="center" class="mt-12">
      <v-img :src="require('@/assets/ielts/section2/background-sec2.png')">
        <v-row class="pt-12" no-gutters justify="center" align="center">
          <img
            height="76"
            width="156"
            :src="require('@/assets/ielts/ielts-logo-white.svg')"
          />
        </v-row>
        <v-row
        class="mt-8"
          no-gutters
          justify="center"
          align="center"
        >
          <v-icon color="white" class="mr-9">mdi-facebook</v-icon>
          <v-icon color="white" class="mr-9">mdi-instagram</v-icon>
          <v-icon color="white">mdi-linkedin</v-icon>
        </v-row>
        <hr class="mt-16 mx-16 mb-6">
        <div class="d-flex justify-center white--text mb-6" style="font-size: 14px !important;">
          Non Copyrighted © 2023 Fluency First
        </div>
      </v-img>
    </v-row>
  </v-app>
</template>

<script>
import { expireAuthCookie, expireRoleCookie } from "/global/utils/helpers";

export default {
  name: "IeltsFrontend",
  data() {
    return {
      scrolling: false,
      isMenu: false,
    };
  },
  computed: {
    doesSessionExist() {
      return this.$store.state.user.authToken !== undefined;
    },
    isTeacher() {
      return this.$store.state.role.includes("TEACHER_VIEW");
    },
    isStudent() {
      return this.$store.state.role.includes("STUDENT_VIEW");
    },
    userPath() {
      return this.isTeacher
        ? "/teachers/"
        : this.isStudent
        ? "/students/"
        : "/";
    }
  },
  methods: {
    makeScroll() {
      this.scrolling = true;
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        top: 0,
        behavior: "smooth"
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    logout() {
      expireAuthCookie();
      expireRoleCookie();
      window.location.href = process.env.VUE_APP_FRONTEND_URL;
    }
  }
};
</script>

<style scoped>
.m-site {
  background-color: #fff !important;
}

</style>
