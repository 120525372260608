<template>
  <v-avatar :color="avatarColor" :size="size" @click="$emit('click', $event)">
    <span class="white--text" :style="userNameStyle">{{ initial }}</span>
  </v-avatar>
</template>

<script>
import colors from "vuetify/es5/util/colors";
import _ from "lodash";

const color_copy = { ...colors };
color_copy.shades = undefined;
const color_list = Object.keys(color_copy);

export default {
  name: "UserAvatar",
  props: {
    userName: {
      type: String,
      required: true,
      default: "",
    },
    color: {
      type: String,
      default: "random",
    },
    size: {
      type: String,
      default: "52",
    },
  },
  computed: {
    avatarColor() {
      if (this.color === "random") {
        return _.sample(color_list);
      } else {
        return this.color;
      }
    },
    initial() {
      const initials = this.userName.split(" ")[0];
      return `${initials[0]}`.toUpperCase();
    },
    userNameStyle() {
      return {
        fontSize: `${this.size / 2}px`,
      };
    },
  },
};
</script>
