<template>
  <v-container :style="`width: ${sizes.container.width}`">
    <v-row  v-if="fetchingOrganizations" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              Organization Name
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in organizations" :key="org.partner_code">
            <td>
              <v-list-item-avatar
                color="#cef0ff"
                size="46"
              ></v-list-item-avatar>
            </td>
            <td>
              {{ orgNameContent(org) }}
              <br />
              <!--              <v-tooltip top>-->
              <!--                <template v-slot:activator="{ on }">-->
              <!--                  <v-icon class="clickable" v-on="on">info</v-icon>-->
              <!--                </template>-->
              <!--                <span-->
              <!--                  >Join Code: Code that will be used by other teachers to-->
              <!--                  request to join your organization. Click on the code to-->
              <!--                  copy.</span-->
              <!--                >-->
              <!--              </v-tooltip>-->
              <!--              <v-tooltip-->
              <!--                :value="isCopiedMessageVisibleForCode === org.join_code"-->
              <!--                top-->
              <!--              >-->
              <!--                <template v-slot:activator="{ on }">-->
              <!--                  <span-->
              <!--                    style="text-decoration: underline"-->
              <!--                    class="clickable mx-3"-->
              <!--                    @click="copy(org.join_code)"-->
              <!--                    >{{ org.join_code }}</span-->
              <!--                  >-->
              <!--                </template>-->
              <!--                <span>Copied!</span>-->
              <!--              </v-tooltip>-->
              <!--              <v-icon @click="refreshCode(org.partner_code)">refresh</v-icon>-->
            </td>
            <td>
              <v-btn
                color="primary"
                rounded
                outlined
                small
                :to="organizationSettingsRoute(org.partner_code)"
                ><v-icon>settings</v-icon>Settings</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import { OrganizationService } from "@af/modules/organization/OrganizationService";

export default {
  name: "MyOrganizationList",
  props: {},
  beforeCreate() {
    this.organizationService = new OrganizationService({ store: this.$store });
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    try {
      await this.getOrganizations();
    } catch(e) {
      this.$root.$emit("alert", ["Alert", e.message])
    }
  },
  data() {
    return {
      organizations: [],
      generatingCode: {},
      fetchingOrganizations: false,
      isCopiedMessageVisibleForCode: ""
    };
  },
  computed: {
    sizes(){
      return {
        container: {
          width: '840px'
        }
      }
    }
  },
  methods: {
    async getOrganizations() {
      this.fetchingOrganizations = true;
      let allOrganizations = await this.organizationService.list();
      this.organizations = allOrganizations.filter(org =>
        org.access.includes("owner")
      );
      this.fetchingOrganizations = false;
    },
    async refreshCode(partnerCode) {
      try {
        this.$set(this.generatingCode, partnerCode, true);
        await this.$store.dispatch("organization/generateCode", {
          partner_code: partnerCode
        });
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Failed to generate code. Please try again later"
        ]);
      } finally {
        this.generatingCode[partnerCode] = false;
      }
    },
    async copy(code) {
      await navigator.clipboard.writeText(code);
      this.showCopiedMessageForCode(code);
    },
    showCopiedMessageForCode(code) {
      this.isCopiedMessageVisibleForCode = code;
      setTimeout(() => (this.isCopiedMessageVisibleForCode = ""), 1000);
    },
    orgNameContent(org) {
      return org.partner_name
        ? org.partner_name
        : "Go to settings to give your organization a name";
    },
    organizationSettingsRoute(orgId) {
      return {
        name: "organization-settings",
        params: { organizationId: orgId }
      };
    }
  }
};
</script>

<style scoped></style>
