import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "lectures/",
  BATCH_SHARE_URL: "batches/lectures/",
  LECTURE_BATCH_URL: "lectures/batches/",
  UPLOAD_URL: "uploads/",

  /**
   * @return Promise<AxiosResponse>
   * @param courseId
   * @param opts
   */
  find(courseId, batch_id = undefined, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    const params = { object_type: "lecture" };
    let channel = "teachers_get_stores";
    if (batch_id) {
      params.batch_id = batch_id;
      channel = "teachers_get_shares";
    } else {
      params.course_id = courseId;
    }

    return $backend
      .get(channel, {
        params,
        ...opts
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },

  get(lecture_id, course_id) {
    const object_type = "lecture";
    const object_id = lecture_id;
    return $backend
      .get("teachers_get_stores", {
        params: { object_id, course_id, object_type }
      })
      .then(checkStatus)
      .then(r => r.data.content.stores[0]);
  },
  /**
   * @return Promise
   * @param courseId
   * @param lecture
   * @param headers
   */
  async save(courseId, lecture, headers = {}) {
    lecture.course_id = courseId;
    lecture.object_type = "lecture";
    const data = new FormData();
    Object.entries(lecture).forEach(entry => {
      data.append(entry[0], entry[1]);
    });
    return await $backend
      .apost(this.UPLOAD_URL, data, headers)
      .then(checkStatus)
      .then(r => r.data.content)
      .catch(e => {
        throw JSON.parse(JSON.stringify(e.response));
      });
  },

  share(lecture_id, data) {
    data["object_id"] = lecture_id;
    data["object_type"] = "lecture";
    return $backend
      .post("teachers_post_shares", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  shareToMultiple(data) {
    return $backend
      .post("teachers_post_shares_multiple", { params: data })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  deleteMultipleShare(data) {
    return $backend
      .post("teachers_delete_shares_multiple", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getSharedBatches(object_id) {
    return $backend
      .get("teachers_get_shares", {
        params: { object_id, object_type: "lecture" }
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },
  delete(model, courseId, opts = {}) {
    const data = {
      course_id: courseId,
      object_id: model.object_id,
      object_type: model.object_type
    };

    let config = { data, ...opts };
    return $backend.delete("teachers_delete_stores", config).then(checkStatus);
  },
  unshare(object_id, batch_id, course_id) {
    return $backend
      .delete("teachers_delete_shares", {
        data: { object_id, batch_id, course_id, object_type: "lecture" }
      })
      .then(checkStatus);
  }
};
