export const _types = {
  GET_COURSEWORKS: "getStudentAssignments",
  GET_COURSEWORK: "getStudentAssignment",
  SET_COURSEWORKS: "setCourseworks",
  SET_COURSEWORK: "setCoursework",
  REMOVE_COURSEWORK: "removeCoursework",
  FETCH_COURSEWORKS: "fetchCourseworks",
  CREATE_COURSEWORK: "createCoursework",
  UPDATE_COURSEWORK: "updateCoursework",
  DELETE_COURSEWORK: "deleteCoursework",
};

const __types = { ..._types };

export const CourseworkTypes = __types;
