import Vue from "vue";
import Vuex from "vuex";
import { UserModule } from "~/modules/user";
import {
  loadCookie,
  Logger,
  checkStatus,
  loadDataWithContent
} from "/global/utils/helpers";
import axios from "axios";
import { router } from "./main";

// ecf
import { ExamModule } from "@ecf/modules/exam/store";
// import types from "@ecf/store/store-types";
import courseModule from "@ecf/modules/course/store";
import { CourseModule } from "#ecf/courses/store";
import batchModule from "@ecf/modules/batch/store";
import courseworkModule from "@ecf/modules/assignment/store";
import lectureModule from "@ecf/modules/lecture/store";
import messageModule from "@ecf/app-shell/message/store";
import organizationModule from "~ecf/modules/organization/store";
// import { UserModuleECF } from "~ecf/modules/user";
import questionModule from "@ecf/modules/question-bank/store";
import studentModule from "@ecf/modules/student/store";
import { DashboardModule } from "@ecf/modules/dashboard/store";
import announcementModule from "@ecf/modules/announcement/store";
import TransactionModule from "@ecf/modules/payment-transation/store";

// import from education-frontend
import studentAssignmentModule from "@ef/modules/assignment/store";
import studentLectureModule from "@ef/modules/lecture/store";
import { CartModule } from "#ef/cart/store";
import studentAnnouncementModule from "@ef/modules/announcement/store";
import { StudentExamModule } from "@ef/modules/exam/store";
import { CertificateModule } from "#ef/certification/store";
import { StudentDashboardModule } from "#ef/dashboard/store";
import TransactionModuleEF from "@ef/modules/payment-transaction/store.js";

// import accounts frontend
import authModule from "@af/modules/auth/store";
import userModuleAF from "@af/modules/user/store";
import serviceModule from "@af/modules/service/store";
import organizationModuleAF from "@af/modules/organization/store";
import types from "@af/app-shell/store-types";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg";

// moxylab stores
import CourseDesign from "@ecf/modules/course-design/store";
import StudentCourseDesign from "@ef/modules/dashboard/dashboardStore";

Vue.use(Vuex);
const APP_URL = process.env.VUE_APP_ACCOUNTS_FRONTEND_URL;

const alertModule = {
  namespaced: true,
  actions: {
    reportError(ctx, e) {
      Swal.fire({
        title: "Alert",
        text: JSON.stringify(e.response ? e.response.data : e),
        imageUrl: alertIcon
      });
    }
  }
};
const store = new Vuex.Store({
  modules: {
    user: UserModule,
    courses: CourseModule,
    course: courseModule,
    batch: batchModule,
    coursework: courseworkModule,
    lecture: lectureModule,
    message: messageModule,
    organization: organizationModule,
    // userECF: UserModuleECF,
    exam: ExamModule,
    questionBank: questionModule,
    student: studentModule,
    dashboard: DashboardModule,
    announcement: announcementModule,
    transaction: TransactionModule,
    // student module
    studentAssignment: studentAssignmentModule,
    studentLecture: studentLectureModule,
    studentExam: StudentExamModule,
    cart: CartModule,
    certificate: CertificateModule,
    studentDashboard: StudentDashboardModule,
    studentAnnouncement: studentAnnouncementModule,
    transactionEF: TransactionModuleEF,

    //  accounts Frontend
    auth: authModule,
    userAF: userModuleAF,
    service: serviceModule,
    organizationAF: organizationModuleAF,
    alert: alertModule,

    // moxylab
    design: CourseDesign,
    studentCourseDesignDashboard: StudentCourseDesign
  },
  state: {
    role: [],
    allCourses: [],
    showMessage: false,
    messageText: "",
    messageType: "success",
    persistentMessage: false,
    userDetails: {},
    publicCourseDetails: {}
  },
  mutations: {
    [types.SET_MESSAGE](state, messageText) {
      state.messageText = messageText;
    },
    [types.SET_MESSAGE_TYPE](state, messageType) {
      state.messageType = messageType;
    },
    [types.SET_SHOULD_SHOW_MESSAGE](state, value) {
      state.showMessage = value;
    },
    [types.SET_PERSISTENT_MESSAGE](state, value) {
      state.persistentMessage = value;
    },
    setRole(state, role) {
      state.role = role;
    },
    setCourses(state, courses) {
      state.allCourses = courses;
    },
    setPublicCourseDetails(state, publicCourseDetails) {
      state.publicCourseDetails = publicCourseDetails;
    }
  },

  actions: {
    [types.SHOW_MESSAGE](ctx, payload) {
      ctx.commit(types.SET_MESSAGE, payload.message);
      ctx.commit(types.SET_MESSAGE_TYPE, payload.messageType || "success");
      if (payload.persistent) {
        ctx.commit(types.SET_PERSISTENT_MESSAGE, true);
      }
      ctx.commit(types.SET_SHOULD_SHOW_MESSAGE, true);
    },
    checkRole(ctx) {
      let role = ["STUDENT_VIEW", "TEACHER_VIEW"];
      try {
        role = JSON.parse(loadCookie("userRole"));
      } catch (e) {
        Logger.log(e);
      } finally {
        ctx.commit("setRole", role);
      }
    },
    async fetchAllPublicCourse(ctx) {
      const API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let requestId = "public_get_all_courses-" + Math.random(0, 99999999);
      await axios(API_URL, {
        method: "POST",
        data: {},
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "public_get_all_courses",
          "Request-Id": requestId
        }
      })
        .then(response => {
          ctx.commit("setPublicCourseDetails", response.data.content.courses);
          this.renderNow = true;
        })
        .catch(error => {
          Logger.error(error);
        });
    },

    async fetchPublicCourseDetails(ctx) {
      const API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let requestId = "public_get_course_design-" + Math.random(0, 99999999);
      await axios(API_URL, {
        method: "POST",
        data: {
          course_id: "0da52a0a-500b-40e5-8797-3c977fd7bc6c",
          partner_code: "A3FF1784"
        },
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "public_get_course_design",
          "Request-Id": requestId
        }
      })
        .then(response => {
          ctx.commit("setPublicCourseDetails", response.data.content);
          this.renderNow = true;
        })
        .catch(error => {
          Logger.error(error);
        });
    },

    async courseSearchCardClicked({ commit }, item) {
      const API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let requestId = "public_update_course_rank-" + Math.random(0, 999999999);
      await axios(API_URL, {
        method: "POST",
        data: {
          course_id: item.course_id,
          partner_code: item.partner_code,
          event_type: "view_course_details"
        },
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "public_update_course_rank",
          "Request-Id": requestId
        }
      })
        .then(response => {
          this.searchedCourses = response.data.content.courses;
          this.renderNow = true;
        })
        .catch(error => {
          Logger.error(error);
        });
      await router.push({
        path: `/course-details/${item.partner_code}/${item.course_id}`
      });
      // this.$emit("clicked");
    },

    async getDefaultOrganizationValues(ctx) {
      const API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let channelName = "public_get_default_organization_values";
      let requestId = `${channelName}-${Math.random(0, 999999999)}`;
      return await axios(API_URL, {
        method: "POST",
        data: {},
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": channelName,
          "Request-Id": requestId
        }
      })
        .then(checkStatus)
        .then(loadDataWithContent);
    }
  }
});

store.$types = types;

export default store;
