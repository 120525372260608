<template>
  <v-dialog v-model="visible" :width="maxWidth" :persistent="persist">
    <template v-if="$scopedSlots.activator" v-slot:activator="scope">
      <slot name="activator" v-bind="scope"> </slot>
    </template>
    <v-card class="mk-card-radius pa-4">
      <v-card-title class="justify-center">
        <slot name="title">
          <div class="d-flex flex-column align-center">
            <v-img :src="icon.name"></v-img>
            <div style="height: 10px;"></div>
            <p class="alert-title">{{ title }}</p>
          </div>
        </slot>
      </v-card-title>
      <v-card-text class="alert-text">
        <slot>{{ subTitle }}</slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="showCancel"
          text
          color="primary"
          @click="$emit('action', 0)"
          >{{ cancelText }}</v-btn
        >
        <v-btn rounded color="primary" @click="$emit('action', 1)">{{
          okText
        }}</v-btn>
        <v-btn
          v-if="toSignup"
          rounded
          color="primary"
          class="px-5"
          @click="redirectToSignup()"
          >Signup <v-icon class="ml-1">mdi-arrow-right</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import successIcon from "/global/assets/alertDialogIcons/Icon_Success.svg"
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg"
import infoIcon from "/global/assets/alertDialogIcons/Icon_Info.svg"
import leavingIcon from "/global/assets/alertDialogIcons/Icon_Leaving.svg"
export default {
  name: "AlertDialog",
  props: {
    value: { type: Boolean, required: true },
    title: { type: String, default: "Alert" },
    subTitle: { type: String, required: true },
    cancelText: { type: String, default: "Cancel" },
    okText: { type: String, default: "OK" },
    showCancel: { type: Boolean, default: false },
    maxWidth: { type: String, default: "500px" },
    persist: { type: Boolean, default: true },
    toSignup: { type: Boolean, default: false }
  },
  computed: {
    icon() {
      switch (this.title) {
        case "Info":
          return{ name: infoIcon}
        case "Success":
          return{ name: successIcon}
        case "Leaving":
          return{ name: leavingIcon}
        default:
          return{ name: alertIcon}
      }
    }
  },
  data() {
    return {
      visible: this.value
    };
  },
  watch: {
    value() {
      this.visible = this.value;
    },
    visible() {
      this.$emit("input", this.visible);
    }
  },
  methods: {
    redirectToSignup() {
      this.$emit("action", 0);
      this.$router.push("register");
    }
  }
};
</script>

<style>
.alert-title {
  font-size: 26px;
  font-weight: 500;
}
.alert-text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  color: #3d3d3d;
}
</style>
