import Vue from "vue";
import Vuex from "vuex";
import authModule from "@af/modules/auth/store";
import userModule from "@af/modules/user/store";
import serviceModule from "@af/modules/service/store";
import organizationModule from "@af/modules/organization/store";
import types from "./store-types";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg"

Vue.use(Vuex);

const alertModule = {
  namespaced: true,
  actions: {
    reportError(ctx, e) {
      Swal.fire({
        title: "Alert",
        text: JSON.stringify(e.response ? e.response.data : e),
        imageUrl: alertIcon
      });
    }
  }
};

const store = new Vuex.Store({
  modules: {
    auth: authModule,
    user: userModule,
    service: serviceModule,
    organization: organizationModule,
    alert: alertModule
  },
  state: {
    showMessage: false,
    messageText: "",
    messageType: "success",
    persistentMessage: false
  },
  getters: {
    [types.GET_MESSAGE]: state => state.messageText,
    [types.GET_MESSAGE_TYPE]: state => state.messageType,
    [types.SHOULD_SHOW_MESSAGE]: state => state.showMessage,
    [types.IS_PERSISTENT_MESSAGE]: state => state.persistentMessage
  },
  mutations: {
    [types.SET_MESSAGE](state, messageText) {
      state.messageText = messageText;
    },
    [types.SET_MESSAGE_TYPE](state, messageType) {
      state.messageType = messageType;
    },
    [types.SET_SHOULD_SHOW_MESSAGE](state, value) {
      state.showMessage = value;
    },
    [types.SET_PERSISTENT_MESSAGE](state, value) {
      state.persistentMessage = value;
    }
  },
  actions: {
    [types.SHOW_MESSAGE](ctx, payload) {
      ctx.commit(types.SET_MESSAGE, payload.message);
      ctx.commit(types.SET_MESSAGE_TYPE, payload.messageType || "success");
      if (payload.persistent) {
        ctx.commit(types.SET_PERSISTENT_MESSAGE, true);
      }
      ctx.commit(types.SET_SHOULD_SHOW_MESSAGE, true);
    }
  }
});

store.$types = types;
export default store;
