import { VBtn } from "vuetify/es5/components/VBtn";
import { VTextField } from "vuetify/es5/components/VTextField";
import { VIcon } from "vuetify/es5/components/VIcon";
import { VChip } from "vuetify/es5/components/VChip";
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import { VTextarea } from "vuetify/es5/components/VTextarea";
import VCard from "vuetify/es5/components/VCard/VCard";
import VAutocomplete from "vuetify/es5/components/VAutocomplete/VAutocomplete";
import VRow from "vuetify/es5/components/VGrid/VRow";
import VCol from "vuetify/es5/components/VGrid/VCol";
import VContainer from "vuetify/es5/components/VGrid/VContainer";
import VApp from "vuetify/es5/components/VApp/VApp";
import VContent from "vuetify/es5/components/VContent/VContent";
import VSelect from "vuetify/es5/components/VSelect/VSelect";
import VDataTable from "vuetify/es5/components/VDataTable/VDataTable";
import {
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep
} from "vuetify/es5/components/VStepper";
import VDivider from "vuetify/es5/components/VDivider/VDivider";
import VImg from "vuetify/es5/components/VImg/VImg";
import VSimpleTable from "vuetify/es5/components/VDataTable/VSimpleTable";
import { VCardText } from "vuetify/es5/components/VCard";
import VDialog from "vuetify/es5/components/VDialog/VDialog";

function wrapComponent(component, componentName, props = {}) {
  return {
    name: componentName,
    functional: true,
    render(h, context) {
      const { data, children } = context;
      const clazz = get(data, "class", {});
      clazz[kebabCase(componentName)] = true;
      data.class = clazz;
      data.props = props ? { ...props, ...data.attrs } : data.attrs;
      return h(component, data, children);
    }
  };
}

const w = wrapComponent;

// To allow autocomplete in webstorm. Otherwise, looping over the required components would suffice
export default {
  MButton: wrapComponent(VBtn, "MButton", { color: "primary" }),
  MRoundedButton: wrapComponent(VBtn, "MRoundedButton", {
    rounded: true,
    color: "primary"
  }),
  MChip: w(VChip, "MChip"),
  MCard: w(VCard, "MCard"),
  MCardText: w(VCardText, "MCardText"),
  MAutocomplete: w(VAutocomplete, "MAutocomplete"),
  MSelect: w(VSelect, "MSelect"),
  MTextField: w(VTextField, "MTextField"),
  MIcon: w(VIcon, "MIcon"),
  MTextarea: w(VTextarea, "MTextarea"),
  MRow: w(VRow, "MRow"),
  MCol: w(VCol, "MCol"),
  MContainer: w(VContainer, "MContainer"),
  MApp: w(VApp, "MApp"),
  MContent: w(VContent, "MContent"),
  MDataTable: w(VDataTable, "MDataTable"),
  MSimpleTable: w(VSimpleTable, "MSimpleTable"),
  MStepper: w(VStepper, "MStepper"),
  MStepperContent: w(VStepperContent, "MStepperContent"),
  MStepperHeader: w(VStepperHeader, "MStepperHeader"),
  MStepperItems: w(VStepperItems, "MStepperItems"),
  MStepperStep: w(VStepperStep, "MStepperStep"),
  MDivider: w(VDivider, "MDivider"),
  MImg: w(VImg, "MImg"),
  MDialog: w(VDialog, "MDialog")
};
