import { Backend } from "~/utils/backend";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";
import backend from "../../global/backend";

const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);

export default {
  /**
   * @return Promise
   * @param {object} params
   * @param opts
   */
  async find(params = {}, opts = {}) {
    return await $backend
      .get("teachers_get_courses", { params, ...opts })
      .then(checkStatus)
      .then(r => r.data.content.courses);
  },
  /**
   * @return Promise
   * @param course
   * @param headers
   */
  save(partner_code, course, headers = {}) {
    course.partner_code = partner_code;
    let file = undefined;
    if ("image" in course) {
      course.filename = course.image.imageURL;
      file = course.image.file;
    }
    return $backend
      .post("teachers_post_courses", course, { shouldSendPartnerCode: false })
      .then(checkStatus)
      .then(({ data }) => {
        if ("image" in course)
          $backend.upload_to_s3(data.content.upload_info, file);
      });
  },
  update(partner_code, course_id, course_data) {
    course_data.partner_code = partner_code;
    course_data.course_id = course_id;
    let file = undefined;
    if ("image" in course_data) {
      course_data.filename = course_data.image.imageURL;
      file = course_data.image.file;
    }
    let data = { partner_code, course_id, ...course_data };
    return $backend
      .post("teachers_update_courses", data)
      .then(checkStatus)
      .then(r => {
        if ("image" in course_data) {
          $backend.upload_to_s3(r.data.content.upload_info, file);
        }
      });
  },
  bulkEnroll(student_ids, batches, course_id) {
    return $backend
      .post("teachers_bulk_enroll", { student_ids, batches, course_id })
      .then(checkStatus);
  },
  bulkEnrollv2(
    file_name,
    batches,
    course_id,
    partner_code,
    s3_path = undefined
  ) {
    let data = undefined;
    if (s3_path)
      data = { file_name, batches, course_id, partner_code, s3_path };
    else data = { file_name, batches, course_id, partner_code };

    return $backend
      .post("teachers_bulk_enroll", data, { shouldSendPartnerCode: false })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  uploadEnrollFile(policy, file) {
    return $backend.upload_to_s3(policy, file).then(checkStatus);
  },
  bulkEnrollForwardStepOne(url, data) {
    return $backend
      .customRequest("post", url, data)
      .then(checkStatus)
      .then(loadDataWithContent)
  },
  bulkEnrollForwardStepTwo(data){
    return $backend.post("forward_bulk_enroll", data, {shouldSendPartnerCode: false}).then(checkStatus).then(loadDataWithContent);
  },
  retrieveCategorySubCategory(partner_code) {
    return $backend
      .post("teachers_courses_categories", { partner_code })
      .then(checkStatus)
      .then(r => r.data.content);
  }
};
