<template>
  <v-container class="ml-10 pl-10">
    <h1 class="headline font-weight-medium mt-10">
      Organizations
    </h1>

    <v-card flat class="mt-10">
      <v-row no-gutters>
        <v-tabs v-model="tab" class="mt-2">
          <v-tab v-if="isTeacher">My Organizations</v-tab>
          <v-tab>Joined Organizations</v-tab>
          <v-tab>Search Organizations</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="isTeacher">
            <div class="m-account-card">
              <my-organization-list
                @showCreateDialogue="isCreateDialogVisible = true"
              ></my-organization-list>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="m-account-card">
              <joined-organization-list
                @showJoinDialogue="isJoinDialogVisible = true"
              ></joined-organization-list>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="m-account-card">
              <search-organization></search-organization>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-row>
    </v-card>

    <v-dialog v-model="isJoinDialogVisible" width="500px">
      <join-organization-form
        @join="join"
        @cancel="isJoinDialogVisible = false"
        :joining="isJoining"
      ></join-organization-form>
    </v-dialog>
    <v-dialog v-model="isCreateDialogVisible" width="500px">
      <new-organization-form
        @create="create"
        @cancel="isCreateDialogVisible = false"
        :creating="isCreating"
      ></new-organization-form>
    </v-dialog>
  </v-container>
</template>

<script>
import SearchOrganization from "@af/modules/organization/organization-list-page/SearchOrganization";
import MyOrganizationList from "./MyOrganizationList";
import JoinedOrganizationList from "./JoinedOrganizationList";
import JoinOrganizationForm from "@af/modules/organization/organization-list-page/JoinOrganizationForm";
import NewOrganizationForm from "@af/modules/organization/organization-list-page/NewOrganizationForm";
import { OrganizationService } from "@af/modules/organization/OrganizationService";
export default {
  name: "OrganizationListPage",
  components: {
    SearchOrganization,
    NewOrganizationForm,
    JoinedOrganizationList,
    MyOrganizationList,
    JoinOrganizationForm
  },
  beforeCreate() {
    this.organizationService = new OrganizationService({ store: this.$store });
  },
  data() {
    return {
      tab: 0,
      isJoinDialogVisible: false,
      isCreateDialogVisible: false,
      isJoining: false,
      isCreating: false
    };
  },
  methods: {
    async join(code) {
      try {
        this.isJoining = true;
        await this.organizationService.join(code);
        this.isJoinDialogVisible = false;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Failed to join organization. Please try again later"
        ]);
      } finally {
        this.isJoining = false;
      }
    },
    async create(name) {
      try {
        this.isCreating = true;
        await this.organizationService.create(name);
        this.isCreateDialogVisible = false;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Failed to create organization. Please try again later"
        ]);
      } finally {
        this.isCreating = false;
      }
    }
  },
  computed: {
    isTeacher() {
      return this.$store.getters["userAF/isTeacher"];
    }
  }
};
</script>

<style>
.m-account-card-title {
  font-family: "Livvic", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
}
</style>
