import { Backend } from "~/utils/backend";
import { checkStatus } from "/global/utils/helpers";

const backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
const acct_backend = new Backend(process.env.VUE_APP_BACKEND_ACCT_URL);

export const OrganizationApi = {
  list() {
    return backend
      .get("teachers_get_organizations", { shouldSendPartnerCode: false })
      .then(checkStatus)
      .then(({ data }) => data.content.organizations);
  },
  teachers(partner_code) {
    return acct_backend.$axios
      .get("/partners/users", {
        params: { partner_code },
        headers: {
          Authorization: `Bearer ${Backend.authToken}`,
          "Channel-Name": "get-teachers"
        }
      })
      .then(checkStatus)
      .then(r => r.data);
  }
};

export default OrganizationApi;
