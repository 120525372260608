import { Backend } from "~/utils/backend";
import { checkStatus } from "/global/utils/helpers";

const backend = new Backend(process.env.VUE_APP_BACKEND_BOLT_TEACHERS_URL);
export default {
  list(owner_id, course_id) {
    // return delayPromise(500).then(() => db.get("topics").value());
    return backend
      .post("teachers_list_topics", {
        owner_id: owner_id,
        course_id: course_id
      })
      .then(checkStatus)
      .then(({ data }) => data.content.topics);
  },
  request(topic) {
    return backend
      .post("teachers_request_topics", { topics: [topic] })
      .then(checkStatus);
  }
};
