<template>
  <v-card class="pt-10">
    <v-card-title>
      Create an Organization
    </v-card-title>
    <v-card-text>
      <v-form ref="createForm">
        <v-text-field
          class="mt-8"
          label="Name"
          placeholder="Enter the organization name..."
          outlined
          :rules="rules"
          v-model="organizationName"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row justify="end">
          <v-btn outlined rounded color="red" @click="cancel">Cancel</v-btn>
          <v-btn
            rounded
            color="primary"
            class="ml-3"
            :loading="creating"
            @click="create"
            >Create</v-btn
          >
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "NewOrganizationForm",
  props: {
    creating: Boolean
  },
  created() {},
  mixins: [FieldValidations],
  data() {
    return {
      organizationName: undefined,
      rules: [v=>this.required(v, "Name")]
    };
  },
  methods: {
    create() {
      if (this.$refs.createForm.validate())
        this.$emit("create", this.organizationName);
    },
    cancel() {
      this.organizationName = undefined;
      this.$emit("cancel");
    }
  },
  computed: {}
};
</script>

<style></style>
