import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
export default {
  localToRemote(answer) {
    return omitBy(
      {
        id: answer.answer_id,
        item_text: answer.text,
        is_correct: answer.is_correct ? 1 : 0,
        explanation: answer.explanation,
        file: answer.image.remote ? undefined : answer.image?.file?.name,
        image_unchanged: answer.image.remote ?? false
      },
      isNil
    );
  },

  duplicateForRemote(answer) {
    return omitBy(
      {
        id: answer.answer_id,
        item_text: answer.text,
        is_correct: answer.is_correct ? 1 : 0,
        explanation: answer.explanation,
        file: answer.image?.file?.name,
        image_unchanged: answer.image.remote ?? false
      },
      isNil
    );
  },

  remoteToLocal(answerData) {
    return {
      answer_id: answerData.id,
      text: answerData.item_text,
      is_correct: answerData.is_correct ?? false,
      explanation: answerData.explanation,
      image: {
        imageURL: answerData.file_url ?? null,
        remote: true,
        file: answerData.file_url
          ? {
              name: answerData.file_url
                .split("?")[0]
                .split("/")
                .pop()
            }
          : null
      }
    };
  }
};
