var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "m-site" },
    [
      _c(
        "v-app-bar",
        {
          staticClass: "pa-0 ma-0 navbar",
          staticStyle: { "background-color": "white" },
          attrs: {
            fixed: "",
            "elevate-on-scroll": "",
            color: "#fff",
            "scroll-target": "#ielts-content",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "pa-0 ma-0" },
            [
              _c(
                "v-col",
                {
                  style: `background-color: ${
                    _vm.$vuetify.breakpoint.smAndDown ? "" : "#FAFAFA"
                  }`,
                  attrs: { cols: "6" },
                },
                [
                  _c("img", {
                    staticClass: "clickable",
                    attrs: {
                      height: "38",
                      width: "78",
                      src: require("@/assets/ielts/ielts-logo.svg"),
                      alt: "IELTS Logo",
                    },
                    on: {
                      click: [
                        function ($event) {
                          return _vm.$router.push("/")
                        },
                        function ($event) {
                          if ($event.target !== $event.currentTarget)
                            return null
                          return _vm.scrollToTop.apply(null, arguments)
                        },
                      ],
                    },
                  }),
                ]
              ),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-space-between",
                      staticStyle: { "background-color": "#FFFF" },
                      attrs: { cols: "6" },
                    },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mr-8 clickable",
                            on: {
                              click: function ($event) {
                                return _vm.scrollTo("course")
                              },
                            },
                          },
                          [_vm._v(" Course ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "clickable",
                            on: {
                              click: function ($event) {
                                return _vm.scrollTo("features")
                              },
                            },
                          },
                          [_vm._v(" Features ")]
                        ),
                      ]),
                      _c("div", { staticClass: "d-flex justify-end pr-3" }, [
                        !_vm.doesSessionExist
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      height: "48px",
                                      width: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "103px"
                                        : "",
                                      outlined: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          path: "/auth/signin",
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v(" Log In ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-4 white--text",
                                    attrs: {
                                      color: "primary",
                                      depressed: "",
                                      height: "48px",
                                      width: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "169px"
                                        : "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          path: "/auth/register",
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v(" Start Free Trial ")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      height: "45",
                                      width: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "120px"
                                        : "",
                                      outlined: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          path: _vm.userPath,
                                        })
                                      },
                                    },
                                  },
                                  [_vm._v(" Enter ")]
                                ),
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "open-on-hover": "",
                                      bottom: "",
                                      "offset-y": "",
                                      transition: "slide-y-transition",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "ml-4 white--text",
                                                    attrs: {
                                                      height: "45",
                                                      color: "primary",
                                                      depressed: "",
                                                      width: _vm.$vuetify
                                                        .breakpoint.lgAndUp
                                                        ? "180px"
                                                        : "",
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(" My Account "),
                                                  _c("v-icon", [
                                                    _vm._v("mdi-chevron-down"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3483607857
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      {},
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "d-flex justify-center align-center",
                                            attrs: { to: "/accounts" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mr-2" },
                                              [_vm._v("mdi-account-circle")]
                                            ),
                                            _c("span", [
                                              _vm._v("View profile"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "d-flex justify-center align-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.logout()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mr-2" },
                                              [_vm._v("mdi-location-exit")]
                                            ),
                                            _c("span", [_vm._v("Logout")]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ]),
                    ]
                  )
                : _c(
                    "v-col",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.isMenu = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-menu")])],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "ma-5",
          attrs: { fullscreen: "" },
          model: {
            value: _vm.isMenu,
            callback: function ($$v) {
              _vm.isMenu = $$v
            },
            expression: "isMenu",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { background: "#FFFF", position: "relative" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "close-button", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.isMenu = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-thick")])],
                    1
                  ),
                ],
                1
              ),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column justify-center mb-4 align-center",
                    staticStyle: { gap: "16px" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { height: "48px", width: "175px", outlined: "" },
                        on: {
                          click: function ($event) {
                            _vm.scrollTo("course"), (_vm.isMenu = false)
                          },
                        },
                      },
                      [_vm._v(" Course ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { height: "48px", width: "175px", outlined: "" },
                        on: {
                          click: function ($event) {
                            _vm.scrollTo("features"), (_vm.isMenu = false)
                          },
                        },
                      },
                      [_vm._v(" Features ")]
                    ),
                  ],
                  1
                ),
                !_vm.doesSessionExist
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column justify-center align-center",
                        staticStyle: { gap: "16px" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              height: "48px",
                              width: "169px",
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  path: "/auth/signin",
                                })
                              },
                            },
                          },
                          [_vm._v(" Log In ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: {
                              color: "primary",
                              depressed: "",
                              height: "48px",
                              width: "169px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  path: "/auth/register",
                                })
                              },
                            },
                          },
                          [_vm._v(" Start Free Trial ")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column justify-center align-center",
                        staticStyle: { gap: "16px" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { height: "45", width: "180", outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({ path: _vm.userPath })
                              },
                            },
                          },
                          [_vm._v(" Enter ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              height: "45",
                              width: "180",
                              to: "/accounts",
                              outlined: "",
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-account-circle"),
                            ]),
                            _c("span", [_vm._v("View profile")]),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { height: "45", width: "180", outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.logout()
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-location-exit"),
                            ]),
                            _c("span", [_vm._v("Logout")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("v-main", { attrs: { id: "ielts-content" } }, [_c("router-view")], 1),
      _c(
        "v-row",
        {
          staticClass: "mt-12",
          attrs: { "no-gutters": "", justify: "center", align: "center" },
        },
        [
          _c(
            "v-img",
            {
              attrs: {
                src: require("@/assets/ielts/section2/background-sec2.png"),
              },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "pt-12",
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      height: "76",
                      width: "156",
                      src: require("@/assets/ielts/ielts-logo-white.svg"),
                    },
                  }),
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "mt-8",
                  attrs: {
                    "no-gutters": "",
                    justify: "center",
                    align: "center",
                  },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-9", attrs: { color: "white" } },
                    [_vm._v("mdi-facebook")]
                  ),
                  _c(
                    "v-icon",
                    { staticClass: "mr-9", attrs: { color: "white" } },
                    [_vm._v("mdi-instagram")]
                  ),
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-linkedin"),
                  ]),
                ],
                1
              ),
              _c("hr", { staticClass: "mt-16 mx-16 mb-6" }),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-center white--text mb-6",
                  staticStyle: { "font-size": "14px !important" },
                },
                [_vm._v(" Non Copyrighted © 2023 Fluency First ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }