<template>
  <div
    v-if="step === 1"
    class="d-flex flex-wrap"
    :class="{
      'payment-padding-xl': $vuetify.breakpoint.xlOnly,
      'pt-6': $vuetify.breakpoint.lgOnly,
      'pt-8': $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
      'pt-5': $vuetify.breakpoint.xsOnly,
    }"
    style="
      background: #fcfcfc;
      justify-content: center;
      height: max(100vh, 100%);
    "
  >
    <payment-form v-model="formData" @continue="goToStep(2)"></payment-form>
    <div :style="`width: ${gap}px;`"></div>
    <plans></plans>
    <p
      v-if="$vuetify.breakpoint.xsOnly"
      :style="`font-weight: 600; font-size: ${instructionFont}px; position: relative; top: 2%`"
    >
      Press "CONTINUE" to proceed to payment selection page
    </p>
  </div>
  <div
    v-else
    class="d-flex flex-wrap"
    :class="{
      'payment-padding-xl': $vuetify.breakpoint.xlOnly,
      'pt-6': $vuetify.breakpoint.lgOnly,
      'pt-8': $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
      'pt-5': $vuetify.breakpoint.xsOnly,
    }"
    style="
      background: #fcfcfc;
      justify-content: center;
      height: max(100vh, 100%);
    "
  >
    <payment-method-chooser
      :amount="overAllAmount"
      :plan="formData.selectedPlan"
      v-model="paymentData"
      @back="goToStep(1)"
      @continue="executePayment"
    ></payment-method-chooser>
    <div :style="`width: ${gap}px;`"></div>
    <order-summary :info="formData"></order-summary>
    <p
      v-if="$vuetify.breakpoint.xsOnly"
      :style="`font-weight: 600; font-size: ${instructionFont}px; position: relative; top: 10%`"
    >
      Press "CONTINUE" to start the payment process
    </p>
    <v-dialog v-model="waiting" persistent width="500">
      <v-card class="pa-4">
        <v-card-title class="justify-center">Please Wait</v-card-title>
        <div style="height: 20px"></div>
        <v-card-text style="text-align: center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentForm from "@af/modules/payment/components/PaymentForm.vue";
import Plans from "@af/modules/payment/components/Plans.vue";
import PaymentMethodChooser from "@af/modules/payment/components/PaymentMethodChooser.vue";
import OrderSummary from "@af/modules/payment/components/OrderSummary.vue";
import { PaymentApi } from "@af/modules/payment/api";

export default {
  components: {
    PaymentForm,
    Plans,
    PaymentMethodChooser,
    OrderSummary,
  },
  name: "PaymentPage",
  beforeCreate() {},
  created() {},
  data() {
    return {
      step: 1,
      waiting: false,
      showInsufficentEnrollmentAlert: false,
      showNoPlanAlert: false,
      formData: {
        coupon: "",
        selectedPlan: undefined,
        numOfStudents: 0,
        discountType: "percentage",
        discount: 0,
      },
      paymentData: {
        method: undefined,
        agreeToPay: false,
        agreeWithTerms: false,
      },
    };
  },
  watch: {
    "formData.numOfStudents": {
      handler(value) {
        if (value >= 10 && this.showInsufficentEnrollmentAlert)
          this.showInsufficentEnrollmentAlert = false;
      },
    },
    "formData.selectedPlan": {
      handler(value) {
        if(value && this.showNoPlanAlert) this.showNoPlanAlert = false;
      }
    }
  },
  methods: {
    goToStep(step) {
      if (!this.formData.selectedPlan && step === 2) {
        this.$root.$emit("alert", [undefined, "Select a plan"]);
      } else {
        this.step = step;
      }
    },
    isValid() {
      if (
        this.formData.numOfStudents < 1 ||
        this.formData.numOfStudents > 500
      ) {
        this.$root.$emit("alert", [
          undefined,
          "Number of students must be between 1 and 500",
        ]);
        return false;
      }
      if (this.paymentData.method.length == 0) {
        this.$root.$emit("alert", [undefined, "Select a payment method"]);
        return false;
      }
      if (!this.paymentData.agreeToPay) {
        this.$root.$emit("alert", [undefined, "You must agree to pay"]);
        return false;
      }
      if (!this.paymentData.agreeWithTerms) {
        this.$root.$emit("alert", [
          undefined,
          "You must agree with terms and condition",
        ]);
        return false;
      }
      return true;
    },
    async executePayment() {
      if (!this.isValid()) {
        return;
      }
      try {
        this.waiting = true;
        let resp = await PaymentApi.initTransaction({
          method: ["debit/credit", "internet", "mobile"].includes(
            this.paymentData.method
          )
            ? "sslcommerz"
            : this.paymentData.method,
          type: this.formData.selectedPlan,
          num_student: this.formData.numOfStudents,
          coupon_code:
            this.formData.discount > 0 ? this.formData.coupon : undefined,
          agent: "teacher",
        });
        window.location.href = resp.gatewayURL;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.waiting = false;
      }
    },
  },
  computed: {
    instructionFont() {
      let bp = this.$vuetify.breakpoint.name;
      switch (bp) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 11;
        case "lg":
          return 12;
        case "xl":
          return 14;
        default:
          return 10;
      }
    },
    gap() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 120;
        case "lg":
          return 42;
        case "md":
          return 40;
        case "sm":
          return 40;
        default:
          return 0;
      }
    },
    overAllAmount() {
      let actualAmount = this.formData.numOfStudents * 20;
      if (!this.formData.selectedPlan) {
        return 0;
      } else if (this.formData.selectedPlan === "quarterly") {
        actualAmount = actualAmount * 3;
      } else if (this.formData.selectedPlan === "annually") {
        actualAmount = actualAmount * 12;
      }
      let discount =
        this.formData.discountType === "percentage"
          ? (actualAmount * this.formData.discount) / 100
          : this.formData.discount;
      return actualAmount - discount;
    },
  },
};
</script>

<style scoped lang="scss">
.payment-padding-xl {
  padding-top: 69px;
}
</style>
