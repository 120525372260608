<template>
  <v-container :style="`width: ${sizes.container.width}`">
    <v-text-field
      v-model="query"
      outlined
      color="primary"
      append-icon="search"
      placeholder="Search for organization here"
      style="max-width: 600px"
      @keyup.enter="search"
    ></v-text-field>
    <v-row v-if="searching" justify="center"> <v-progress-circular indeterminate color="primary"></v-progress-circular> </v-row>
    <div v-else>
      <v-simple-table v-if="organizations.length>0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Organization Name</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="org in organizations" :key="org.partner_name">
              <td>{{ org.partner_name }}</td>
              <td
                v-if="
                  org.partner_code in requestStates &&
                  requestStates[org.partner_code] !== 'Reject'
                "
              >
                <v-chip
                  v-if="requestStates[org.partner_code] === 'Accept'"
                  color="primary"
                  >Joined</v-chip
                >
                <v-chip
                  v-else-if="requestStates[org.partner_code] === 'Pending'"
                  color="warning"
                  >Pending</v-chip
                >
              </td>
              <td v-else>
                <div v-if="user.access[org.partner_code] != 'owner'">
                  <v-btn
                    color="primary"
                    rounded
                    outlined
                    small
                    v-if="isTeacher"
                    :loading="joining[org.partner_code + 'teacher']"
                    @click="joinOrganization(org.partner_code, 'teacher')"
                    >Join As Teacher</v-btn
                  >
                  <v-btn
                    color="primary"
                    rounded
                    outlined
                    small
                    :loading="joining[org.partner_code + 'student']"
                    @click="joinOrganization(org.partner_code, 'student')"
                    >Join As Student</v-btn
                  >
                </div>
                <v-chip v-else color="primary"> Owner </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        No organization found
      </div>
    </div>
    
  </v-container>
</template>

<script>
import OrganizationApi from "@af/modules/organization/OrganizationApi";
import authService from "@af/modules/auth/services/AuthService";
import store from "@af/app-shell/store";

export default {
  name: "SearchOrganization",
  props: {},
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    this.getAlreadySentRequest();
    await this.getUser();
  },
  mounted() {
    this.$root.$on("leave", (data) => {
      this.getAlreadySentRequest();
    });
  },
  data() {
    return {
      user: undefined,
      requestSent: [],
      requestStates: {},
      query: undefined,
      organizations: [],
      joining: {},
      searching: false,
    };
  },
  methods: {
    async getUser() {
      this.user = await authService.getUser(store.state.auth.token);
    },
    async search() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.searching = true;
        const result = await OrganizationApi.search(this.query);
        this.organizations = result.partners;
        this.searching = false;
      } catch (err) {
        this.$notify(
          JSON.stringify(err.response ? err.response.data.content : err)
        );
      }
    },
    async getAlreadySentRequest() {
      try {
        this.requestSent = (
          await this.$store.dispatch("organizationAF/requestSent")
        ).data;
        this.requestStates = this.arrangeRequestStates(this.requestSent);
      } catch (err) {
        this.$notify(
          JSON.stringify(err.response ? err.response.data.content : err)
        );
      }
    },
    arrangeRequestStates(requestSentData) {
      let reqState = {};
      for (let i = 0; i < requestSentData.length; i++) {
        reqState[requestSentData[i]["partner_code"]] =
          requestSentData[i]["status"];
      }
      return reqState;
    },
    async joinOrganization(partner_code, role) {
      try {
        this.$set(this.joining, partner_code + role, true);
        await this.$store.dispatch("organizationAF/join", {
          partner_code,
          role,
        });
        await this.getAlreadySentRequest();
      } catch (err) {
        this.$notify(
          JSON.stringify(err.response ? err.response.data.content : err)
        );
      } finally {
        this.joining[partner_code + role] = false;
      }
    },
    orgNameContent(org) {
      return org.partner_name
        ? org.partner_name
        : "Go to settings to give your organization a name";
    },
    organizationSettingsRoute(orgId) {
      return {
        name: "organization-settings",
        params: { organizationId: orgId },
      };
    },
  },
  computed: {
    joinedOrganizations() {
      return this.$store.getters["organizationAF/joinedOrganizations"];
    },
    isTeacher() {
      return this.$store.getters["userAF/isTeacher"];
    },
    sizes() {
      return {
        container: {
          width: "840px",
        },
      };
    },
  },
};
</script>

<style></style>
