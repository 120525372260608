import $backend from "@af/utils/backend";
import { Backend } from "@af/utils/backend";
import { backendAsync } from "@af/utils/backend";
import { checkStatus, expireAuthCookie } from "/global/utils/helpers";

const authService = {
  SESSION_URL: "sessions",
  REFRESH_TOKEN_URL: "events",
  USER_URL: "users",
  USER_ACTIVATE_URL: "users/activate",
  PASSWORD_RESET_URL: "users/forgotpassword",
  AUTH_COOKIE_NAME: "m_URT",
  COOKIE_EXPIRE_DATE: 9,
  DOMAIN: process.env.VUE_APP_DOMAIN,

  /**
   * Logs in user in the server
   * If successful set authenticated to true in the store and save email and token.
   * @throws Error
   * @param data
   * @param type
   * @param provider
   * @param opts
   * @return {Q.Promise<any>} data Data returned from the server
   */
  async login(data, type, provider, opts = {}) {
    const rn = window.crypto.getRandomValues(new Uint32Array(1))[0];
    const requestId = `request-${rn}`;
    const headers = {
      // "Channel-Name": "user-sessions-signin",
      // "Request-Id": requestId,
      // "Allow-Block": 1
    };
    return $backend
      .post(this.SESSION_URL, { ...data, type, provider }, { headers, ...opts })
      .then(checkStatus)
      .then(res => res.data.content);
  },

  signup(data, type, provider) {
    return $backend
      .post(this.USER_URL, { ...data, type, provider })
      .then(checkStatus)
      .then(res => res.data);
  },

  saveAuthCookie(token, permanent = true) {
    var cookie = "";
    const date = new Date();
    date.setTime(
      date.getTime() + this.COOKIE_EXPIRE_DATE * 24 * 60 * 60 * 1000
    );
    cookie = `${
      this.AUTH_COOKIE_NAME
    }=${token}; expires=${date.toGMTString()};path=/;SameSite=Strict`;
    document.cookie = cookie;
  },

  saveRole(role, permanent = true) {
    let cookie = "";
    const date = new Date();
    date.setTime(
      date.getTime() + this.COOKIE_EXPIRE_DATE * 24 * 60 * 60 * 1000
    );
    cookie = `userRole=${role}; expires=${date.toGMTString()};path=/;SameSite=Strict`;
    document.cookie = cookie;
  },
  loadAuthToken() {
    let name = this.AUTH_COOKIE_NAME + "=";
    let allCookieArray = document.cookie.split(";");
    for (let i = 0; i < allCookieArray.length; i++) {
      let cookie = allCookieArray[i].trim();
      if (cookie.startsWith(name))
        return cookie.substring(name.length, cookie.length);
    }
    return "";
  },

  expireAuthCookie() {
    expireAuthCookie();
  },
  setAuthorizationHeader(token) {
    $backend.headers.common["Authorization"] = `Bearer ${token}`;
    $backend.headers["Authorization"] = `Bearer ${token}`;
  },

  gotoSignUpPage(redirectTo) {
    window.location.href = "/auth/register" + "?redirectTo=" + redirectTo;
  },

  sendPasswordResetLink(email, opts = {}) {
    return $backend.post(this.PASSWORD_RESET_URL, { email }, opts);
  },

  resetPassword(token, password, opts = {}) {
    return $backend.put(this.PASSWORD_RESET_URL, { token, password }, opts);
  },
  getUser(token) {
    return $backend
      .get(this.USER_URL, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(({ data }) => data);
  },
  async refreshSessionData(token) {
    const rn = window.crypto.getRandomValues(new Uint32Array(1))[0];
    const requestId = `request-${rn}`;
    const headers = {
      "Channel-Name": "refresh_token",
      "Request-Id": requestId
    };
    this.setAuthorizationHeader(token);
    return $backend
      .post(this.REFRESH_TOKEN_URL, {}, { headers })
      .then(checkStatus)
      .then(r => {
        this.expireAuthCookie();
        this.saveAuthCookie(r.data.content.new_token);
        this.setAuthorizationHeader(r.data.content.new_token);
        return r.data.content.new_token;
      });
  }
};

export default authService;
