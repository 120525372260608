import StudentListApi from "@ecf/modules/student/StudentListApi";
import Vue from "vue";
import get from "lodash/get";
import { shouldRefreshCache } from "@ecf/global/helpers";
import sortBy from "lodash/sortBy";
import { Logger } from "/global/utils/helpers";

export const CourseModule = {
  namespaced: true,
  state: {
    myStats: undefined,
    orgStats: undefined,
    myCourses: undefined,
    orgCourses: undefined,
    itemsCount: {}
  },
  mutations: {
    setMyStats(state, stats) {
      state.myStats = stats;
    },
    setOrgStats(state, stats) {
      state.orgStats = stats;
    },
    setMyCourses(state, courses) {
      state.myCourses = courses;
    },
    setOrgCourses(state, courses) {
      state.orgCourses = courses;
    },
    setItemsCount(state, { courseId, itemsCount }) {
      Vue.set(state.itemsCount, courseId, itemsCount);
    },
    removeMyCourse(state, { courseId }) {
      state.myCourses = state.myCourses.filter(c => c.course_id !== courseId);
    },
    removeOrgCourse(state, { courseId }) {
      state.orgCourses = state.orgCourses.filter(c => c.course_id !== courseId);
    }
  }
};
