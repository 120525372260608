import Vue from "vue";
import { CartApi, PaymentApi } from "./api";
import { get, keyBy, orderBy, sortBy } from "lodash";

// const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);

export const CartModule = {
  namespaced: true,
  state: { cart: { items: [] }, lastFetched: {}, orders: {} },
  getters: {
    coursesInCart: state =>
      new Set(state.cart.items.map(item => item.course_id)),
  },
  mutations: {
    setCart(state, cart) {
      state.cart = cart;
    },
    setOrder(state, order) {
      Vue.set(state.orders, order.id, order);
    },
    setOrders(state, orders) {
      state.orders = keyBy(orders, "id");
    },
  },
  actions: {
    async get({ commit }) {
      const cart = {};
      const items = await CartApi.get() || [];
      cart.price = items.reduce(
        (a, c) => a + parseInt(get(c, "price", "0")),
        0,
      );
      for (let item of items) {
        item.currency = (item.currency || "bdt").toUpperCase();
      }
      if (items.length > 0) {
        cart.currency = items[0].currency;
      }
      cart.items = items;
      commit("setCart", cart);
      return cart;
    },
    async addItem({ dispatch }, { item }) {
      await CartApi.addItem(item);
      dispatch("get");
    },
    async removeItem({ dispatch }, { id }) {
      await CartApi.removeItem(id);
      dispatch("get");
    },
    async queryCoupon(ctx, { code, coupon_inserted_from }) {
      return await PaymentApi.queryCoupon(code, coupon_inserted_from);
    },
    async checkout({ commit }, { code }) {
      const orderId = await CartApi.checkout(code);
      return orderId;
    },
    async getOrder({ commit }, { orderId }) {
      const order = await PaymentApi.getOrder(orderId);
      commit("setOrder", order);
      return order;
    },
    async getAllOrders({ commit }) {
      const orders = await PaymentApi.getAllOrders();
      commit("setOrders", orders);
      return orderBy(orders, v => (v.created_at ? v.created_at : 0)).reverse();
    },
    async pay(ctx, { amount, method, orderId, agent }) {
      const session = await PaymentApi.createSession(method, orderId, agent);
      if (amount === 0) {
        return session;
      }
      // if (session.method === "stripe") {
      //   return stripe.redirectToCheckout({ sessionId: session.id });
      // } else {
      //   window.location.href = session.gatewayURL;
      // }
    },
    async verify(ctx, { transactionId, transactionTime }) {
      return await PaymentApi.verify(transactionId, transactionTime);
    },
  },
};
