import Vue from "vue";
import CourseDesignService from "#ecf/course-design/services/CourseDesignService";

// other services
import courseworkService from "#ecf/assignment/services/CourseworkService";
import lectureService from "#ecf/lecture/services/LectureService";
import projectService from "#ecf/project/services/ProjectWorkService";
import { ExamApi } from "#ecf/exam/api";

const CONTENTS = ["exams", "projects", "assignments", "lectures"];

export default {
  namespaced: true,
  state: {
    exams: {},
    projects: {},
    lectures: {},
    assignments: {},
    contents: {},
    modules: {},
    sections: {}
  },
  getters: {
    exams: state => {
      return state.exams;
    },
    projects: state => {
      return state.projects;
    },
    lectures: state => {
      return state.lectures;
    },
    assignments: state => {
      return state.assignments;
    },
    find: state => {
      return state.contents;
    },
    modules: state => {
      return state.modules;
    },
    sections: state => {
      return state.sections;
    }
  },
  mutations: {
    set(state, { data, courseId }) {
      // data = {lectures, assignments, projects, exams, courseId}
      CONTENTS.forEach(key => Vue.set(state[key], courseId, data[key]));

      // populate contents
      if (!(courseId in state.contents)) Vue.set(state.contents, courseId, {});

      // for stores items
      CONTENTS.filter(label => {
        return label !== "exams";
      }).forEach(label => {
        state[label][courseId].forEach(item => {
          Vue.set(state.contents[courseId], item.object_id, item);
        });
      });

      // for exams
      state.exams[courseId].forEach(item => {
        Vue.set(state.contents[courseId], item.id, item);
      });
    },
    setModules(state, { data, courseId }) {
      let moduleMap = {};
      data.modules.forEach(module => {
        Vue.set(moduleMap, module.module_id, module);
      });
      Vue.set(state.modules, courseId, moduleMap);
    },
    setUnassigned(state, { data, courseId }) {
      if (data.modules.length > 0)
        state.sections[courseId].push({
          title: "Unassigned",
          contents: data.modules
        });
    },
    setSections(state, { data, courseId }) {
      Vue.set(state.sections, courseId, data.sections || []);
    }
  },
  actions: {
    async retrieveStoresAndExams(ctx, { courseId, ownerId }) {
      let data = {
        assignments: await courseworkService.find(courseId),
        lectures: await lectureService.find(courseId),
        projects: await projectService.find(courseId),
        exams: await ExamApi.list(courseId, ownerId)
        // exams: []
      };
      ctx.commit("set", { data, courseId });
    },
    async saveModule(ctx, { title, contents, courseId, ownerId }) {
      return await CourseDesignService.uploadModule(
        title,
        contents,
        courseId,
        ownerId
      );
    },
    async saveSection(ctx, { title, contents, courseId, ownerId }) {
      return await CourseDesignService.uploadSection(
        title,
        contents,
        courseId,
        ownerId
      );
    },
    async getCourseModules(ctx, { courseId, ownerId }) {
      let data = await CourseDesignService.getCourseModules(courseId, ownerId);
      ctx.commit("setModules", { data, courseId });
    },
    async getUnassignedModules(ctx, { courseId, ownerId }) {
      let data = await CourseDesignService.getUnassignedModules(
        courseId,
        ownerId
      );
      ctx.commit("setUnassigned", { data, courseId });
    },
    async getCourseSections(ctx, { courseId, ownerId }) {
      let data = await CourseDesignService.getCourseSections(courseId, ownerId);
      ctx.commit("setSections", { data, courseId });
    },
    async editModule(ctx, { id, title, contents, courseId, ownerId }) {
      return await CourseDesignService.editModule(
        id,
        title,
        contents,
        courseId,
        ownerId
      );
    },
    async editSection(ctx, { id, title, contents, courseId, ownerId }) {
      return await CourseDesignService.editSection(
        id,
        title,
        contents,
        courseId,
        ownerId
      );
    },
    async removeModule(ctx, { moduleId, courseId, ownerId }) {
      return await CourseDesignService.removeModule(
        moduleId,
        courseId,
        ownerId
      );
    },
    async removeSection(ctx, { sectionId, courseId, ownerId }) {
      return await CourseDesignService.removeSection(
        sectionId,
        courseId,
        ownerId
      );
    }
  }
};
