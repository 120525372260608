var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "mk-education-console" },
    [
      _c("router-view"),
      _c("alert-dialog", {
        attrs: {
          title: _vm.error.title,
          "sub-title": _vm.error.subTitle,
          "to-signup": _vm.error.toSignup,
        },
        on: {
          action: function ($event) {
            _vm.error.show = false
          },
        },
        model: {
          value: _vm.error.show,
          callback: function ($$v) {
            _vm.$set(_vm.error, "show", $$v)
          },
          expression: "error.show",
        },
      }),
      _c(
        "access-alter-dialog",
        _vm._b(
          { on: { exit: (v) => (_vm.accessAlertBinds.show = v) } },
          "access-alter-dialog",
          _vm.accessAlertBinds,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }