export const _types = {
  SHOULD_SHOW_MESSAGE: "shouldShowMessage",
  GET_MESSAGE: "getMessage",
  GET_MESSAGE_TYPE: "getMessageType",
  IS_PERSISTENT_MESSAGE: "isPersistentMessage",
  SET_MESSAGE: "setMessage",
  SET_MESSAGE_TYPE: "setMessageType",
  SET_SHOULD_SHOW_MESSAGE: "setShouldShowMessage",
  SET_PERSISTENT_MESSAGE: "setPersistentMessage",
  SHOW_MESSAGE: "showMessage"
};

const __types = { ..._types };

export const MessageTypes = __types;
