export const _types = {
  GET_LECTURES: "getLectures",
  GET_LECTURE: "getLecture",
  SET_LECTURES: "setLectures",
  SET_LECTURE: "setLecture",
  REMOVE_LECTURE: "removeLecture",
  FETCH_LECTURES: "fetchLectures",
  CREATE_LECTURE: "createLecture",
  UPDATE_LECTURE: "updateLecture",
  DELETE_LECTURE: "deleteLecture",
};

const __types = { ..._types };

export const LectureTypes = __types;
