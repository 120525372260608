import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
import { checkStatus } from "/global/utils/helpers";

export default {
  URL: "courseworks/",
  UPLOAD_URL: "uploads/",
  BATCH_SHARE_URL: "batches/courseworks/",
  COURSEWORK_BATCH_URL: "courseworks/batches/",
  COURSEWORK_SUBMISSION_URL: "batches/courseworks/submissions/",

  /**
   * @return Promise<AxiosResponse>
   * @param batch_id
   * @param opts
   */
  find(batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("students_get_shares", {
        params: { batch_id, object_type: "assignment" },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.stores);
  },
  get(object_id, batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    const object_type = "assignment";
    return $backend
      .get("students_get_shares", {
        params: { object_id, batch_id, object_type },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.stores[0]);
  },
  /**
   * @return Promise
   * @param courseId
   * @param assignment
   * @param headers
   */
  async save(courseId, assignment, headers = {}) {
    const files = [...assignment.files];
    assignment.files = files.map(item => item.name);
    assignment.batch_id = courseId;
    assignment.object_type = "assignment";
    return $backend
      .post("students_post_submissions", assignment)
      .then(checkStatus)
      .then(({ data }) =>
        data.content.info.forEach((element, index) =>
          $backend.upload_to_s3(element, files[index]),
        ),
      );
  },

  share(coursework_id, data) {
    data["object_id"] = coursework_id;
    data["object_type"] = "assignment";
    return $backend
      .post("teachers_post_shares", data)
      .then(checkStatus)
      .then(r => r.data.content);
  },

  getSharedBatches(coursework_id) {
    return $backend
      .get(this.COURSEWORK_BATCH_URL, { params: { coursework_id } })
      .then(checkStatus)
      .then(r => r.data.data.shared_batches);
  },

  getSubmissions(object_id) {
    return $backend
      .get("students_get_submissions", {
        params: { object_id, object_type: "assignment" },
      })
      .then(checkStatus)
      .then(r => r.data.content.submissions);
  },
};
