var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mk-card-radius pa-4" },
    [
      _c(
        "v-container",
        { staticClass: "pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-end pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "grey" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-title",
            { staticClass: "title mk-highlight-text px-0 pt-0 pb-3" },
            [_vm._v(_vm._s(_vm.batch.batch_title))]
          ),
          _c("p", [_vm._v(_vm._s(_vm.batch.summary))]),
          _c("h4", [_vm._v(_vm._s(!_vm.$language ? "Description" : "বিবরণ"))]),
          _c("p", [_vm._v(_vm._s(_vm.batch.description))]),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h4", [
                  _vm._v(
                    " " +
                      _vm._s(!_vm.$language ? "Batch Type" : "ব্যাচ টাইপ") +
                      ": " +
                      _vm._s(_vm.batchType) +
                      " "
                  ),
                ]),
              ]),
              _c("v-col", [
                _c("h4", { staticStyle: { color: "#0099dc" } }, [
                  _vm._v(
                    " " +
                      _vm._s(!_vm.$language ? "Price" : "মূল্য") +
                      ": " +
                      _vm._s(_vm.batch.price || _vm.batch.amount) +
                      " " +
                      _vm._s(
                        !_vm.$language
                          ? _vm.batch.currency.toUpperCase()
                          : _vm.currencyBN
                      ) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("h4", [_vm._v(_vm._s(!_vm.$language ? "Schedule" : "সময় সূচি"))]),
          _vm.batch.batch_type === "Online"
            ? _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.$language ? "Self-Based" : "ব্যক্তিগত ইচ্ছা ভিত্তিক"
                    ) +
                    " "
                ),
              ])
            : _vm.noScheduleCondition
            ? _c("p", [
                _vm._v(
                  " " +
                    _vm._s(!_vm.$language ? "Unavailable" : "অপ্রদত্ত") +
                    " "
                ),
              ])
            : _c("p", {
                staticStyle: { "font-size": "14px" },
                domProps: {
                  innerHTML: _vm._s(_vm.formatDay(_vm.batch.days).join("<br>")),
                },
              }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2 btn-outlined",
                  attrs: {
                    depressed: "",
                    large: "",
                    rounded: "",
                    "min-width": "200",
                    color: "primary",
                    disabled: _vm.isSubscribed || _vm.isInCart,
                  },
                  on: { click: _vm.addToCart },
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }