import { addNamespace } from "/global/utils/helpers";
const MODULE_NAME = "auth";

export const _types = {
  IS_AUTHENTICATED: `IS_AUTHENTICATED`,
  AUTHENTICATE: "AUTHENTICATE",
  SIGNUP: "signup",
  FETCH_USER: "fetchUser",
  LOGIN_PAGE_URL: "loginPageUrl",
  SIGNUP_PAGE_URL: "signupPageUrl",
  GO_TO_AUTH_PAGE: "goToAuthPage",
  SET_AUTHENTICATED: "setAuthenticated",
  SET_TOKEN: "setToken"
};

// export const _types = addNamespace("/auth", AuthTypes);
const __types = { ..._types };
addNamespace(MODULE_NAME, __types);
export const AuthTypes = __types;
