var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mk-large-small-screen-side-bar" }, [
    !_vm.smallScreen
      ? _c(
          "div",
          { staticClass: "mk-large-screen-side-bar" },
          [
            _c(
              "v-list",
              { attrs: { shaped: "" } },
              [
                _c(
                  "v-list-item-group",
                  [
                    _c(
                      "v-list-item",
                      {
                        staticClass: "mt-5 mb-10",
                        attrs: { exact: "", to: "/teachers/courses" },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              { staticStyle: { "white-space": "normal" } },
                              [_c("div", [_vm._v(_vm._s(_vm.courseTitle))])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "batches" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("group_work")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [_vm._v("Batches")])
                              : _c("v-list-item-title", [_vm._v("ব্যাচ")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "lectures" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("collections_bookmark")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [_vm._v("Lectures")])
                              : _c("v-list-item-title", [_vm._v("লেকচার")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "assignments" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("library_books")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [_vm._v("Assignments")])
                              : _c("v-list-item-title", [
                                  _vm._v("অ্যাসাইনমেন্ট"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "projects" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [
                            _c("v-icon", [
                              _vm._v("mdi-projector-screen-outline"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [_vm._v("Projects")])
                              : _c("v-list-item-title", [_vm._v("প্রজেক্ট")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "exams" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("assignment_turned_in")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [_vm._v("Exams")])
                              : _c("v-list-item-title", [_vm._v("পরীক্ষা")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "question-list" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("assignment")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [
                                  _vm._v("Question Bank"),
                                ])
                              : _c("v-list-item-title", [
                                  _vm._v("প্রশ্ন ব্যাংক"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "grading" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("school")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [_vm._v("Grading")])
                              : _c("v-list-item-title", [_vm._v("গ্রেডিং")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "fluent-design-course" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("school")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [
                                  _vm._v("Course design"),
                                ])
                              : _c("v-list-item-title", [
                                  _vm._v("কোর্স রূপরেখা"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "course-details" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("info")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [
                                  _vm._v("About Course"),
                                ])
                              : _c("v-list-item-title", [
                                  _vm._v("কোর্স সম্পর্কে বিস্তারিত"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            class: [
              "mk-small-screen-side-bar",
              _vm.collapseOrNonCollapseSidebar,
            ],
          },
          [
            _c(
              "v-list",
              { attrs: { shaped: "" } },
              [
                _c(
                  "v-list-item-group",
                  [
                    _c(
                      "v-list-item",
                      {
                        staticClass: "mt-3 mb-8",
                        attrs: { ripple: !_vm.shouldCollapse },
                        on: { click: _vm.collapseOrNonCollapseHandler },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("mdi-menu")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        staticClass: "mb-5",
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          exact: "",
                          to: "/courses",
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              { staticStyle: { "white-space": "normal" } },
                              [_c("div", [_vm._v(_vm._s(_vm.courseTitle))])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "batches" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("group_work")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Batches")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "lectures" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("collections_bookmark")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Lectures")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "assignments" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("library_books")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Assignments")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "projects" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [
                            _c("v-icon", [
                              _vm._v("mdi-projector-screen-outline"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Projects")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "exams" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("assignment_turned_in")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Exams")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "question-list" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("assignment")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Question Bank")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "grading" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("school")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("Grading")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { to: { name: "fluent-design-course" } } },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("school")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.$language
                              ? _c("v-list-item-title", [
                                  _vm._v("Course design"),
                                ])
                              : _c("v-list-item-title", [
                                  _vm._v("কোর্স রূপরেখা"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: {
                          ripple: !_vm.shouldCollapse,
                          to: { name: "course-details" },
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v("info")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [_c("v-list-item-title", [_vm._v("About Course")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }