<template>
  <m-container>
    <slot v-if="item"></slot>
    <slot v-if="!item" name="placeholder">
      <m-row class="mt-5" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </m-row>
    </slot>
  </m-container>
</template>

<script>
export default {
  name: "LazyLoad",
  props: ["item"]
};
</script>

<style></style>
