import { UserApi } from "./UserApi";
import { expireAuthCookie, goToAuthPage } from "/global/utils/helpers";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg"

export default {
  namespaced: true,
  state: { user: {}, authenticated: false, authToken: undefined },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    // setLanguage(state, value) {
    //   console.log(value)
    //   if (state.language) {
    //     state.language = false
    //   } else {
    //     state.language = true
    //   }
    // },
  },
  actions: {
    async doesSessionExist(ctx) {
      const token = process.env.VUE_APP_AUTH_TOKEN || UserApi.loadAuthToken();
      if (!token) {
        return false;
      } else {
        ctx.state.authToken = token;
        return true;
      }
    },
    async getLoggedInUser(ctx) {
      let token = ctx.state.authToken;
      const user = await UserApi.get(token);
      ctx.commit("setUser", user);
      ctx.commit("setAuthenticated", true);
      UserApi.setAuthorizationHeader(token);
      return user;
    },

    async refreshSessionData(ctx) {
      let token = ctx.state.authToken;
      try {
        token = await UserApi.refreshSessionData(token);
        ctx.state.authToken = token;
        return true;
      } catch (e) {
        Swal.fire({
          title: "Alert",
          text: e.message,
          imageUrl: alertIcon
        });
        return false;
      }
    },

    logout() {
      expireAuthCookie();
      window.location.href = process.env.VUE_APP_FRONTEND_SIGNIN_URL;
    },
    expireCookie() {
      expireAuthCookie();
    },
    login() {
      goToAuthPage();
    },
  },
};
