//@flow
import { _types } from "@ecf/modules/assignment/store-types";
import keyBy from "lodash/keyBy";
import { ExamApi } from "./api";
import Vue from "vue";
import _, { sortBy } from "lodash";

export const ExamModule = {
  namespaced: true,
  state: { exams: {}, examQuestions: {}, examShares: {}, lastFetched: {} },
  getters: {
    exams: state => Object.values(state.exams)
  },
  mutations: {
    setExams(state, exams) {
      state.exams = keyBy(exams, "id");
    },

    setExam(state, exam) {
      Vue.set(state.exams, exam.id, exam);
      Vue.set(state.examQuestions, exam.id, exam.questions ?? []);
    },
    setShares(state, { examId, shares }) {
      const examMap = keyBy(shares, "batch_id");
      Vue.set(state.examShares, examId, examMap);
    },
    removeExam(state, id) {
      Vue.delete(state.exams, id);
    }
  },
  actions: {
    create(_, { exam, courseId, owner_id }) {
      if (exam.examDetails.id) {
        return ExamApi.update(exam, courseId, owner_id);
      }
      return ExamApi.create(exam, courseId, owner_id);
    },
    async list({ commit, dispatch }, { courseId, owner_id }) {
      const exams = await ExamApi.list(courseId, owner_id);
      commit("setExams", exams);
      await Promise.all(
        exams.map(exam =>
          dispatch("listSharedBatches", {
            examId: exam.id,
            courseId: courseId
          })
        )
      );
      return exams;
    },
    async get({ commit }, { id, course_id, owner_id }) {
      const exam = await ExamApi.getById(id, course_id, owner_id);
      commit("setExam", exam);
      return exam;
    },
    async update({ dispatch }, { exam, courseId, ownerId }) {
      await ExamApi.update(exam, courseId, ownerId);
      return dispatch("get", { id: exam.examDetails.id });
    },
    async delete({ dispatch }, { examId, courseId }) {
      await ExamApi.removeExam(examId, courseId);
      dispatch("list", { courseId });
    },

    async shareExam(ctx, { examId, batchId, startDate, endDate, courseId }) {
      await ExamApi.shareExam(examId, batchId, courseId, startDate, endDate);
      ctx.state.exams[examId].d_lock = 1;
      await ctx.dispatch("listSharedBatches", { examId, courseId });
    },
    async listSharedBatches(ctx, { examId, courseId }) {
      const shares = await ExamApi.listSharedBatches(examId, courseId);
      ctx.commit("setShares", { examId, shares });
    },
    async unshareExam(ctx, { examId, batchId, courseId }) {
      await ExamApi.unshareExam(examId, batchId, courseId);
      await ctx.dispatch("listSharedBatches", { examId, courseId });
    },
    async getStudentExams(ctx, { examId, batchId, courseId }) {
      return ExamApi.getStudentExams(examId, batchId, courseId);
    },
    async getExamHistoryDetails(ctx, { studentExamId }) {
      return await ExamApi.getStudentExamDetails(studentExamId);
    },
    async setScore(ctx, { questionId, score, studentExamId }) {
      return await ExamApi.setScore(questionId, score, studentExamId);
    },
    async setEvaluation(ctx, { studentExamId }) {
      return await ExamApi.setEvaluation(studentExamId);
    },
    async publishScore(ctx, { batchId }) {
      return await ExamApi.publishScore(batchId);
    }
  }
};
export const ExamModule_old = {
  namespaced: true,
  state: {
    courseworks: {},
    allExams: {},
    addedExams: {},
    examShares: {},
    lastFetched: -1
  },
  getters: {
    [_types.GET_COURSEWORKS]: state => Object.values(state.courseworks),
    [_types.GET_COURSEWORK]: state => id => state.courseworks[id]
  },
  mutations: {
    setAllExams(state, exams) {
      state.allExams = _.keyBy(exams, "id");
    },
    setAddedExams(state, exams) {
      state.addedExams = _.keyBy(exams, "id");
    },
    setShares(state, { examId, shares }) {
      const examMap = keyBy(shares, "batch_id");
      Vue.set(state.examShares, examId, examMap);
    },
    [_types.SET_COURSEWORK](state, payload) {
      Vue.set(state.courseworks, payload.cid, payload.coursework);
    },
    [_types.SET_COURSEWORKS](state, payload) {
      state.courseworks = payload;
    },
    [_types.REMOVE_COURSEWORK](state, payload) {
      Vue.delete(state.courseworks, payload.cid);
    },
    setLastFetched(state, value) {
      state.lastFetched = value;
    }
  },
  actions: {
    async listAllExams(context) {
      const exams = await ExamApi.listExams();
      context.commit("setAllExams", exams);
      return exams;
    },
    async listAdded(context, { courseId }) {
      const exams = await ExamApi.listAddedExams(courseId);
      context.commit("setAddedExams", exams);
      await Promise.all(
        exams.map(exam =>
          context.dispatch("listSharedBatches", {
            examId: exam.id,
            courseId: courseId
          })
        )
      );
      return exams;
    },
    async addExam(ctx, { examId, courseId }) {
      await ExamApi.addExam(examId, courseId);
      await ctx.dispatch("listAdded");
    },
    async removeExam(ctx, { examId, courseId }) {
      await ExamApi.removeExam(examId, courseId);
      await ctx.dispatch("listAdded", { courseId });
    },
    async shareExam(ctx, { examId, batchId, startDate, endDate, courseId }) {
      await ExamApi.shareExam(examId, batchId, courseId, startDate, endDate);
      await ctx.dispatch("listSharedBatches", { examId, courseId });
    },
    async listSharedBatches(ctx, { examId, courseId }) {
      const shares = await ExamApi.listSharedBatches(examId, courseId);
      ctx.commit("setShares", { examId, shares });
    },
    async unshareExam(ctx, { examId, batchId, courseId }) {
      await ExamApi.unshareExam(examId, batchId, courseId);
      await ctx.dispatch("listSharedBatches", { examId, courseId });
    }
  }
};
