var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { style: _vm.styles.main[_vm.mainStyle] },
    [
      _c("top-bar"),
      _c("new-app-navigation-drawer", {
        on: { logout: _vm.logout, collapse: _vm.handleCollapse },
      }),
      _c("router-view", { key: _vm.$route.fullPath }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }