<template>
  <v-container class="browse-courses pa-0">
    <!--    <v-row justify="center">-->
    <!--      <v-col align="center" class="py-0">-->
    <!--        <v-text-field-->
    <!--          v-model="searchQuery"-->
    <!--          rounded-->
    <!--          outlined-->
    <!--          color="primary"-->
    <!--          prepend-inner-icon="search"-->
    <!--          placeholder="What do you want to learn today?..."-->
    <!--          style="max-width: 600px;"-->
    <!--          @keyup.enter="fetchSearchResult"-->
    <!--        ></v-text-field>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <div v-if="loadSearchResult === true">
      <v-container class="search-results pa-2">
        <v-row>
          <!--          <v-col class="pb-0 px-1" cols="10">-->
          <div style="font-size: 18px">
            <p v-if="!$language" class="pl-3">
              Showing {{ courses.length }} Results For
              <span style="color: #0099dc;">"{{ searchQuery }}"</span>
            </p>
            <p v-else class="pl-3">
              <span style="color: #0099dc;">{{ courses.length }}</span> টি
              অনুসন্ধানকৃত ফলাফল
            </p>
          </div>
        </v-row>
        <!-- <v-divider></v-divider> -->
        <v-row>
          <div class="overflow-y" >
            <course-list
              v-model="selected"
              :courses="courses"
              :search-query="searchQuery"
            ></course-list>
          </div>
          <!-- </v-col> -->
          <!-- <v-divider vertical class="hidden-sm-and-down"></v-divider> -->
          <v-col
            class="overflow-x-auto py-0 hidden-sm-and-down"
            style="max-height: 70vh;"
          >
            <m-course-details
              v-if="selectedCourse"
              :course="selectedCourse"
            ></m-course-details>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import CourseList from "#ef/course/components/CourseList";
import courseService from "@ef/modules/course/services/CourseService";
import MCourseDetails from "@ef/modules/course/components/MCourseDetails";

export default {
  name: "MBrowseCoursesP",
  components: { CourseList, MCourseDetails },
  data: () => ({
    item: {},
    searchQuery: "",
    courseId: undefined,
    partnerCode: undefined,
    batchId: undefined,
    loadSearchResult: false,
    selected: undefined,
    showCourseDetails: undefined,
    courses: [],
  }),
  computed: {
    selectedCourse() {
      return this.courses[this.selected];
    },
  },
  watch: {
    async selected() {
      if (this.selected !== undefined && this.$vuetify.breakpoint.smAndDown) {
        const course = this.courses[this.selected];
        await this.$router.push({
          name: "course-details-student",
          params: {
            courseId: course.course_id,
            course: course,
          },
        });
      }
    },
  },
  async created() {
    if (this.$route.query.q) {
      this.searchQuery = this.$route.query.q;
      await this.fetchSearchResult();
    }
  },
  methods: {
    async fetchSearchResult() {
      try {
        this.fetchingCourses = true;
        this.courses = await courseService.searchCourse(this.searchQuery);
        this.loadSearchResult = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingCourses = false;
      }
    },
    async fetchBatchInfo() {
      try {
        // this.fetchingCourses = true;
        this.courses = await courseService.searchCourse(this.searchQuery);
        this.loadSearchResult = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        // this.fetchingCourses = false;
      }
    },
  },
};
</script>
<style lang="scss">
.browse-courses {
  .v-text-field--rounded > .v-input__control > .v-input__slot {
    background: white;
  }
}
.search-results {
  background: #ffffff !important;
  //box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) !important;
  border-radius: 7px !important;
}
</style>
