import moment from "moment";
import { checkStatus, loadDataWithContent } from "/global/utils/helpers";
import ExamCardImg from "@ef/modules/exam/assets/img_exam.svg";
import { Backend } from "~/utils/backend";

const backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
const bolt_backend = new Backend(process.env.VUE_APP_BACKEND_BOLT_STUDENTS_URL);

const ExamMapper = {
  fromRemote(data) {
    let exam = data.exams[0];
    let share = data.shares[0];
    let has_sections = data.has_sections;
    data = exam;
    data.image_url = ExamCardImg;
    data.duration = exam.total_duration;
    data.points = exam.total_points;
    data.start_date = share.start_date;
    data.end_date = share.end_date;
    data.has_sections = has_sections;
    return data;
  }
};

export const ExamApi = {
  list(batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    return backend
      .get("students_list_shared_exams", {
        params: { batch_id },
        ...opts,
        timeout: 3000
      })
      .then(checkStatus)
      .then(r => r.data.content.exams);
  },

  get(exam_id, batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    let data = { exam_id, batch_id };
    if (opts["details_only"]) data["details_only"] = opts["details_only"];
    return backend
      .get("students_get_exam", {
        params: data,
        ...opts
      })
      .then(checkStatus)
      .then(r => ExamMapper.fromRemote(r.data.content));
  },
  getCurrentTime() {
    return bolt_backend
      .get("students_get_current_time", {
        params: {}
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  getStudentExam(id, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return bolt_backend
      .get("students_get_student_exam", {
        params: { id },
        ...opts
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  startExam(examId, batchId) {
    return backend
      .post("students_start_exam", {
        exam_id: examId,
        batch_id: batchId
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  getExamQuestions(categoryId) {
    return bolt_backend
      .post("students_get_exam_questions", { category_id: categoryId })
      .then(checkStatus)
      .then(({ data }) => data.content.questions);
  },
  async recordAnswer(studentExamId, questionId, answerId) {
    let file = answerId.file || undefined;
    let val = answerId.value
      ? answerId.value
      : answerId.file
      ? undefined
      : answerId;
    return bolt_backend
      .post("students_record_answer", {
        student_exam_id: studentExamId,
        question_id: questionId,
        ...(val ? { answer_id: val } : {}),
        ...(file ? { file: file.name } : {})
      })
      .then(checkStatus)
      .then(({ data }) =>
        file
          ? bolt_backend.upload_to_s3(data.content.file_info, file)
          : data.content
      );
  },
  terminateExam(studentExamId, status) {
    return bolt_backend
      .post("students_terminate_exam", {
        student_exam_id: studentExamId,
        status
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  getExamHistory(batchId) {
    return bolt_backend
      .post("students_get_exam_history", {
        batch_id: batchId
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  getExamHistoryDetails(studentExamId) {
    return bolt_backend
      .post("students_get_exam_result_details", {
        id: studentExamId
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  studentAttendedExam(exam_id, batch_id) {
    return bolt_backend
      .post("student_has_attended_exam", {
        batch_id: batch_id,
        exam_id: exam_id
      })
      .then(checkStatus)
      .then(({ data }) => data.content);
  },
  getQuestionEquation(exam_id, question_id, index) {
    return bolt_backend
      .post("students_get_question_equation", {
        exam_id,
        question_id,
        index
      })
      .then(checkStatus)
      .then(loadDataWithContent);
  }
};
