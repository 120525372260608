import { keyBy } from "lodash";
import Vue from "vue";
import { ExamApi } from "./api";
import { download } from "/global/utils/helpers";

export const StudentExamModule = {
  namespaced: true,
  state: { exams: {}, lastFetched: {} },
  getters: {
    getExams: state => Object.values(state.exams),
    getExam: state => id => state.exams[id]
  },
  mutations: {
    setExams(state, exams) {
      state.exams = keyBy(exams, "id");
    },
    setExamsExamId(state, exams) {
      state.exams = keyBy(exams, "exam_id");
    },
    setExam(state, _package) {
      Vue.set(state.exams, _package.id, _package);
    }
  },
  actions: {
    async list(ctx, { batchId }) {
      const res = await ExamApi.list(batchId);
      const exams = {};
      res.exams.forEach(t => (exams[t.cid] = t));
      ctx.commit("setExams", exams);
      return exams;
    },
    async get(ctx, { id, batchId }) {
      const exam = await ExamApi.get(id, batchId);
      ctx.commit("setExam", exam);
      return exam;
    },
    async getDetails(ctx, { id, batchId }) {
      const exam = await ExamApi.get(id, batchId, { details_only: true });
      ctx.commit("setExam", exam);
      return exam;
    },
    async getStudentExam(ctx, { studentExamId }) {
      return await ExamApi.getStudentExam(studentExamId);
    },
    async getCurrentTime() {
      return await ExamApi.getCurrentTime();
    },
    async studentHasAttendedExam(ctx, { examId, batchId }) {
      return ExamApi.studentAttendedExam(examId, batchId);
    },
    async start(ctx, { examId, batchId }) {
      return ExamApi.startExam(examId, batchId);
    },
    async finish(ctx, { studentExamId }) {
      return ExamApi.terminateExam(studentExamId, "finished");
    },
    async cancel(ctx, { studentExamId }) {
      return ExamApi.terminateExam(studentExamId, "cancelled");
    },
    async getQuestions(ctx, { categoryId }) {
      return ExamApi.getExamQuestions(categoryId);
    },
    async recordAnswer(ctx, { studentExamId, questionId, answerId }) {
      return ExamApi.recordAnswer(studentExamId, questionId, answerId);
    },
    async getExamHistory(ctx, { batchId }) {
      return ExamApi.getExamHistory(batchId);
    },
    async getExamHistoryDetails(ctx, { studentExamId }) {
      return ExamApi.getExamHistoryDetails(studentExamId);
    },
    async getEquationForQuestion(ctx, { examId, questionId, index }) {
      const resp = await ExamApi.getQuestionEquation(examId, questionId, index);
      return await download(resp.url);
    }
  }
};
