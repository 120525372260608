import { keyBy } from "lodash";

export const StudentDashboardModule = {
  namespaced: true,
  state: {
    enrolledCourses: undefined,
    teacherCourses: undefined,
    completedCourses: undefined,
    upcoming: [],
    ongoing: [],
    submissionStats: undefined,
    loadTimeStamp: undefined,
  },
  getters: {},
  mutations: {
    setLoadTimeStamp(state, timestamp) {
      state.loadTimeStamp = timestamp;
    },
    setEnrolledCourses(state, courses) {
      state.enrolledCourses = keyBy(courses, "batch_id");
    },
    setCompletedCourses(state, courses) {
      state.completedCourses = keyBy(courses, "batch_id");
    },
    setTeacherCourses(state, courses) {
      state.teacherCourses = keyBy(courses, "course_id");
    },
    setUpcoming(state, tasks) {
      state.upcoming = tasks;
    },
    setOngoing(state, tasks) {
      state.ongoing = tasks;
    },
    setSubmissionStats(state, { batchId, stats }) {
      state.submissionStats = {
        ...(state.submissionStats || {}),
        [batchId]: stats,
      };
    },
  },
  actions: {},
};
