<template>
  <div
    class="m-base-card-info d-flex align-start"
    :class="{ 'flex-column': $vuetify.breakpoint.mdAndUp }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="info-label">{{ `${label}${suffix}` }}</span>
    <slot>
      <span class="info-text ml-1">{{ value }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  name: "MBaseCardInfo",
  props: {
    label: String,
    value: [String, Number],
  },
  data() {
    return {};
  },
  computed: {
    suffix() {
      return this.$vuetify.breakpoint.smAndDown ? ":" : "";
    },
  },
  created() {},
  methods: {},
};
</script>

<style>
.m-base-card-button {
  background-color: transparent;
}
</style>
