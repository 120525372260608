import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
const backend = new Backend(process.env.VUE_APP_BACKEND_ACCT_URL);
import { checkStatus, Logger } from "/global/utils/helpers";

export default {
  find(partner_code, courseId, opts = {}) {
    return $backend
      .get("teachers_student_details", {
        params: { partner_code: partner_code, course_id: courseId },
        ...opts
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  listOrg(partner_code, opts = {}) {
    return backend.$axios
      .get("partners/users", {
        params: { partner_code, access: "student" },
        headers: {
          Authorization: `Bearer ${Backend.authToken}`,
          "Channel-Name": "get-users"
        }
      })
      .then(checkStatus)
      .then(r => r.data);
  },
  async getBatchStudents(batch_id, course_id, partner_code, headers = {}) {
    return $backend
      .post("teachers_get_batch_students", {
        batch_id,
        course_id,
        partner_code
      })
      .then(checkStatus)
      .then(({ data }) => data.content)
      .then(({ subscriptions, users }) =>
        subscriptions.reduce(
          (prev, current) => ({
            ...prev,
            [current.student_id]: {
              ...current,
              name: users[current.student_id]
            }
          }),
          {}
        )
      );
  }
};
