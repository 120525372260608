import { _types } from "./user-types";
import userService from "./services/UserService";
import authService from "../auth/services/AuthService";

export default {
  namespaced: true,
  state: {
    userDetails: {}
  },
  getters: {
    [_types.GET_USER_EMAIL]: state => state.userDetails.email,
    [_types.GET_USER_NAME]: state => state.userDetails.name,
    [_types.GET_USER_DETAILS]: state => state.userDetails,
    [_types.SIGNUP_PAGE_URL]: () =>
      process.env.VUE_APP_AUTH_DOMAIN + "/auth/signup",
    isTeacher: state =>
      state.userDetails.authorized_views.includes("TEACHER_VIEW"),
    isStudent: state =>
      state.userDetails.authorized_views.includes("STUDENT_VIEW")
  },
  mutations: {
    [_types.SET_USER_DETAILS](state, details) {
      state.userDetails = details;
    }
  },
  actions: {
    async [_types.UPDATE_PASSWORD](ctx, payload) {
      return await userService.updatePassword(
        payload.old_password,
        payload.new_password
      );
    },
    async sendVerifyLink() {
      await userService.sendEmailVerificationLink();
    },
    async verifyEmail(ctx, { token }) {
      return userService.verifyEmail(token);
    },
    async getTeacherProfile(ctx) {
      return await userService.getTeacherProfile();
    },
    async updateTeacherProfile(ctx, { profile }) {
      return await userService.updateTeacherProfile(profile);
    },
    async updateAvatar(ctx, { policy, fileBlob }) {
      return await userService.updateAvatar(policy, fileBlob);
    },
    async getStudentProfile(ctx, { type }) {
      return await userService.studentRetreiveProfile(type);
    },
    async deleteAccount(ctx, data){
      return await userService.deleteAccount(data);
    }
  }
};
