var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "pl-8" },
        [
          _c(
            "v-col",
            { attrs: { lg: "10", md: "10", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "my-10", attrs: { align: "center" } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "organizations" } } },
                    [_c("v-icon", [_vm._v("keyboard_backspace")])],
                    1
                  ),
                  _c(
                    "h1",
                    {
                      staticClass:
                        "ml-4 flex-grow-1 headline font-weight-medium",
                    },
                    [_vm._v(" Organization Settings ")]
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "m-account-card", attrs: { flat: "" } },
                [
                  _c("v-card-title", { staticClass: "mb-10" }, [
                    _vm._v(" Details "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-row", { staticClass: "pl-10 blue--text" }, [
                        _vm._v("Organization Name"),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                staticClass: "ml-10",
                                attrs: {
                                  outlined: "",
                                  readonly: !_vm.editingOrganizationName,
                                  flat: "",
                                  solo: "",
                                  label: "Name",
                                },
                                model: {
                                  value: _vm.newOrganization.partner_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newOrganization,
                                      "partner_name",
                                      $$v
                                    )
                                  },
                                  expression: "newOrganization.partner_name",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.editingOrganizationName
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "ml-4 mb-8",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editOrganizationName()
                                    },
                                  },
                                },
                                [_vm._v("edit")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  staticClass: "ml-4 mb-8",
                                  on: {
                                    click: function ($event) {
                                      return _vm.save()
                                    },
                                  },
                                },
                                [_vm._v("checked")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-card-title", [_vm._v(" Users ")]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        attrs: {
                          items: _vm.users,
                          headers: _vm.tableHeaders,
                          "items-per-page": -1,
                          "hide-default-footer": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.action",
                            fn: function ({ item }) {
                              return [
                                _vm.editingUserRole.includes(item.user_id)
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "red accent-3",
                                          dark: "",
                                          text: "",
                                          small: "",
                                          icon: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveUserRole(
                                              item.user_id,
                                              item.access
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v("check")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Save Role")])]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "red accent-3",
                                          dark: "",
                                          text: "",
                                          small: "",
                                          icon: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editUserRole(
                                              item.user_id,
                                              item.authorized_views,
                                              item.name
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v("edit")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Edit Role")])]
                                        ),
                                      ],
                                      1
                                    ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "red accent-3",
                                      dark: "",
                                      text: "",
                                      small: "",
                                      icon: "",
                                      loading: _vm.removingUser[item.user_id],
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDeleteDialog(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { right: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: "#ff5252",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("delete")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Remove User")])]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.access",
                            fn: function ({ item }) {
                              return [
                                _vm.editingUserRole.includes(item.user_id)
                                  ? _c(
                                      "span",
                                      [
                                        _c("v-select", {
                                          staticStyle: {
                                            padding: "0px",
                                            "margin-top": "20px",
                                          },
                                          attrs: {
                                            outlined: "",
                                            multiple: "",
                                            items: _vm.roles[item.user_id],
                                            label: "Role(s)",
                                            placeholder:
                                              "Enter one or more roles",
                                            chips: "",
                                            "deletable-chips": "",
                                            "append-icon": "",
                                            rules: [
                                              (v) => _vm.required(v, "Role"),
                                            ],
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "tab",
                                                  9,
                                                  $event.key,
                                                  "Tab"
                                                )
                                              )
                                                return null
                                              return _vm.updateTags.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.access,
                                            callback: function ($$v) {
                                              _vm.$set(item, "access", $$v)
                                            },
                                            expression: "item.access",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(item.access.join(", "))),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.userRemoveDialog
            ? _c("dialog-delete", {
                attrs: {
                  deleting: _vm.deleating,
                  item: _vm.userToRemove,
                  "item-text": _vm.userToRemove.name,
                },
                on: {
                  "confirm-remove": function ($event) {
                    return _vm.removeUser(_vm.userToRemove.user_id)
                  },
                },
                model: {
                  value: _vm.userRemoveDialog,
                  callback: function ($$v) {
                    _vm.userRemoveDialog = $$v
                  },
                  expression: "userRemoveDialog",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }