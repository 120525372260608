//import $backend from "@af/utils/backend";
import $backend from "../../../utils/backend.js";
import checkStatus from "/global/utils/helpers";

export default {
  URL: "users",
  SERVICES_URL: "services",
  VIEWS_URL: "userviews",
  CHANGE_PASSWORD_URL: "userchangepassword",
  EMAIL_VERIFICATION_URL: "verifyemail",
  PROFILE_URL: "profiles",

  /**
   * @return Promise<AxiosResponse>
   * @param {object} params
   * @param opts
   */
  find(params = {}, opts = {}) {
    return $backend.get(this.URL, { params, ...opts }).then(checkStatus);
  },

  async studentRetreiveProfile(action_type) {
    //data = {"action_type" : "retrieve"}
    return await $backend
      .post(this.PROFILE_URL, { action_type })
      .then(checkStatus)
      .then(r => r.data);
  },

  /**
   * Updates the category in the server
   * @return Promise<AxiosResponse>
   * @param model
   * @param opts
   */
  update(model, opts = {}) {
    const data = {
      user_id: model.user_id,
      name: model.name,
      user_type: model.user_type,
      phone: model.phone
    };

    return $backend.put(this.URL, data, opts).then(checkStatus);
  },

  async updatePassword(old_password, new_password) {
    return await $backend
      .post(this.CHANGE_PASSWORD_URL, { old_password, new_password })
      .then(res => {
        return res;
      });
  },

  create(model, opts = {}) {
    const data = {
      email: model.email,
      password: model.password,
      name: model.name,
      user_type: model.user_type,
      phone: model.phone
    };

    return $backend.post(this.URL, data, opts).then(checkStatus);
  },

  /**
   * @return Promise<AxiosResponse>
   * @param model
   * @param {object} [opts={}] opts Extra configuration options for the request
   */
  delete(model, opts = {}) {
    let config = { param: { user_id: model.user_id }, ...opts };
    return $backend.delete(this.URL, config).then(checkStatus);
  },

  getServices(service_ids) {
    // return $backend
    //   .get(this.SERVICES_URL)
    //   .then(checkStatus)
    //   .then(res => res.data.data);
    return new Promise(resolve => {
      setTimeout(() => {
        resolve([
          {
            service_id: "9660d20c-bfdb-4546-ad89-0157bea8a4d6",
            name: "Mouchak Classroom",
            description: "Your online education partner."
          }
        ]);
      }, 500);
    });
  },

  getAvailableViews() {
    return $backend
      .get(this.VIEWS_URL)
      .then(checkStatus)
      .then(res => res.data.views);
  },
  sendEmailVerificationLink() {
    return $backend.post(this.EMAIL_VERIFICATION_URL, {}).then(checkStatus);
  },
  verifyEmail(token) {
    return $backend
      .get(this.EMAIL_VERIFICATION_URL, { params: { token } })
      .then(checkStatus);
  },
  getTeacherProfile() {
    return $backend
      .post(this.PROFILE_URL, { action_type: "retrieve" })
      .then(checkStatus)
      .then(r => r.data);
  },
  updateTeacherProfile(data) {
    return $backend
      .post(this.PROFILE_URL, { action_type: "modify", ...data })
      .then(checkStatus)
      .then(r => r.data);
  },
  deleteAccount(data){
    return $backend.delete(this.URL, {data} ).then(checkStatus).then(r=>r.data.content);
  },
  async updateAvatar(policy, data) {
    return await $backend.upload_to_s3(policy, data).then(checkStatus);
  },
  async studentUpdateProfile(action_type, updatedProfileData) {
    return await $backend
      .post(this.PROFILE_URL, { action_type, ...updatedProfileData })
      .then(checkStatus)
      .then(r => r.data);
  }
};
