import MBrowseCourses from "@ef/routes/course/MBrowseCourses";

const Courses = () => import("./course/MCourses");
const Coursework = () => import("./assignment/MCoursework");
const Assessment = () => import("./assignment/MAssessment");
const Lectures = () => import("./lecture/MLectureList");
const Exam = () => import("./exam/MExam");
const ExamHistory = () => import("./exam/ExamHistory");
const ExamHistoryDetails = () => import("./exam/ExamHistoryDetails");
const ExamDetails = () => import("./exam/ExamDetails.vue");
const ExamAppear = () => import("./exam/AppearExam");
const Project = () => import("./project/ProjectListPage");
const ProjectDetails = () => import("./project/ProjectDetailsPage");
const Grades = () => import("./grades/Grades");
const Cart = () => import("./cart/Cart");
const Certificate = () => import("./certification/MCertificate");
const Checkout = () => import("./cart/Checkout");
const Orders = () => import("./cart/Orders");
const Announcement = () => import("./announcement/Announcements");
const AnnouncementDetails = () => import("./announcement/AnnouncementDetails");

const IeltsDashboard = () => import("./dashboard/Dashboard_fluent");
const CourseDetails = () => import("./course/CourseDetails");
const PaymentsAndTransactions = () =>
  import("./payment-transaction/PaymentsTransaction");
const ExamEntry = () => import("./exam/ExamEntry.vue");
const SectionBreak = () => import("./exam/SectionBreak.vue");

import { globalData } from "root-src/main";

const ExamAppearHisotryLayoutEF = () =>
  import("@ef/routes/layouts/ExamAppearHistoryLayout.vue");

/*export const routesEFSelected = [
  {
    path: prefix_selected_routes,
    redirect: "lectures",
  },
  {
    path: prefix_selected_routes.concat("/lectures"),
    name: "lecturesStudent",
    component: Lectures,
    props: true,
    meta: {title: "Lectures"},
  },
  {
    path: prefix_selected_routes.concat("/assignments"),
    name: "assignmentsStudent",
    component: Coursework,
    props: true,
    meta: {title: "Coursework"},
  },
  {
    path: prefix_selected_routes.concat("/assessment/:type/:object_id/:index"),
    name: "assessment",
    component: Assessment,
    props: true,
    meta: {title: "Assessment"},
  },
  {
    path: prefix_selected_routes.concat("/exams"),
    name: "exams-student",
    component: Exam,
    props: true,
    meta: {title: "Exam"},
  },
  {
    path: "exams/exam-history/:batchId",
    name: "exam-history",
    component: ExamHistory,
    props: true,
    meta: {title: "ExamHistory"},
  },
  {
    path: "exam-history/:examId",
    name: "exam-history-details",
    component: ExamHistoryDetails,
    props: true,
    meta: {title: "Exam History Details"},
  },
  {
    path: "exams/:examId",
    name: "exam-summary",
    component: ExamDetails,
    props: true,
    meta: {title: "Exam Details"},
  },
  {
    path: "exams-appear/:studentExamId",
    name: "exam-appear",
    component: ExamAppear,
    props: true,
    meta: {title: "Exam Start"},
  },
  {
    path: "projects",
    name: "projectsStudent",
    component: Project,
    props: true,
    meta: {title: "Project"},
  },
  {
    path: "projects/:project_id/",
    name: "project-details",
    component: ProjectDetails,
    props: true,
    meta: {title: "Project Details"},
  },
  {
    path: "grades",
    name: "grades",
    component: Grades,
    props: true,
    meta: {title: "Grades"},
  },
];
export const routesEFUnselected = [
  {
    path: "/students",
    redirect: "dashboard",
  },
  {
    path: "/students/announcements",
    component: Announcement,
    meta: {title: "Announcements"},
  },
  {
    path:
      "/students/announcement-details/:announcement_id/:course_id/:partner_code",
    name: "announcement-details-students",
    component: AnnouncementDetails,
    meta: {title: "AnnouncementDetails"},
    props: true,
  },
  {
    path: "/students/dashboard",
    name: "dashboard",
    component: IeltsDashboard,
    meta: {title: "Landing"},
    props: true,
  },
  {
    path: "/students/achievements",
    component: Certificate,
    meta: {title: "Achievements"},
  },
  {
    path: "/students/cart",
    component: Cart,
    meta: {title: "Cart"},
  },
  {
    path: "/students/checkout/:orderId",
    component: Checkout,
    props: true,
    meta: {title: "Checkout"},
  },
  {
    path: "/students/orders",
    component: Orders,
    meta: {title: "My Orders"},
  },
  {
    path: "/students/browse-courses",
    component: MBrowseCourses,
    meta: {title: "Browse Courses"},
  },
  /!* {
    path: "/browse-courses/:courseId",
    name: "course-details",
    component: MBrowseCourses,
    props: true,
    meta: { title: "Course Details" },
  }, *!/
  {
    path: "/students/course-details/:partner_code/:course_id",
    name: "course-details-student",
    component: CourseDetails,
    props: true,
  },
  {
    path: "/students/payment-transaction",
    name: "student-payment-transaction-page",
    component: PaymentsAndTransactions,
    props: true,
  },
  {
    path: "/students/bill-payment",
    name: "bill-payment",
    component: MakePayments,
    props: true,
  },

];*/

export const FluentStudentRoutes = [
  {
    path: "/students",
    redirect: "home"
  },
  {
    path: "/students/home",
    name: "home",
    component: IeltsDashboard,
    meta: { title: "Landing" },
    props: true
  },
  {
    path: "/students/announcements",
    name: "announcements",
    component: Announcement,
    meta: { title: "Announcements" }
  },
  {
    path:
      "/students/announcement-details/:announcement_id/:course_id/:partner_code",
    name: "announcement-details-students",
    component: AnnouncementDetails,
    meta: { title: "AnnouncementDetails" },
    props: true
  },
  {
    path: "/students/achievements",
    component: Certificate,
    meta: { title: "Achievements" }
  },
  {
    path: "/students/cart",
    component: Cart,
    meta: { title: "Cart" }
  },
  {
    path: "/students/checkout/:orderId",
    component: Checkout,
    props: true,
    meta: { title: "Checkout" }
  },
  {
    path: "/students/orders",
    component: Orders,
    meta: { title: "My Orders" }
  },
  {
    path: "/students/payment-transaction",
    name: "student-payment-transaction-page",
    component: PaymentsAndTransactions,
    props: true
  },
  {
    path: "/students/notes",
    name: "lecturesStudent",
    component: Lectures,
    props: true,
    meta: { title: "Lectures" }
  },
  {
    path: "/students/assignments",
    name: "assignmentsStudent",
    component: Coursework,
    props: true,
    meta: { title: "Coursework" }
  },
  {
    path: "/students/assessment/:type/:object_id/:index",
    name: "assessment",
    component: Assessment,
    props: true,
    meta: { title: "Assessment" }
  },
  {
    path: "/students/tests",
    name: "exams-student",
    component: Exam,
    props: true,
    meta: { title: "Exam" }
  },
  {
    path: "/students/exams/exam-history/:batchId",
    name: "exam-history",
    component: ExamHistory,
    props: true,
    meta: { title: "ExamHistory" }
  },
  {
    path: "/students/exam-history/:examId",
    name: "exam-history-details",
    component: ExamHistoryDetails,
    props: true,
    meta: { title: "Exam History Details" }
  },
  {
    path: "/students/exams/:examId",
    name: "exam-summary",
    component: ExamDetails,
    props: true,
    meta: { title: "Exam Details" }
  },
  {
    path: "/students/exams/enter/:examId",
    name: "entry-exam",
    component: ExamEntry,
    props: true
  },

  {
    path: "/students/exams-appear/:studentExamId",
    name: "exam-appear",
    component: ExamAppear,
    props: true,
    meta: { title: "Exam Start" }
  },
  {
    path: "/students/projects",
    name: "projectsStudent",
    component: Project,
    props: true,
    meta: { title: "Project" }
  },
  {
    path: "/students/projects/:project_id/",
    name: "project-details",
    component: ProjectDetails,
    props: true,
    meta: { title: "Project Details" }
  },
  {
    path: "/students/grades",
    name: "grades",
    component: Grades,
    props: true,
    meta: { title: "Grades" }
  }
];

export const FluentStudentExamRoutes = [
  {
    path: "/students/tests/:appear/:testId/break",
    name: "section-break",
    component: SectionBreak,
    props: true
  },
  {
    path: "/students/tests/:appear/:testId",
    name: "studentsAppearExamRoutes",
    component: ExamAppearHisotryLayoutEF,
    props: true
  },
  {
    path: "/students/tests/:appear/:testId/section?:sectionId",
    name: "studentsAppearSectionExamRoutes",
    component: ExamAppearHisotryLayoutEF,
    props: true
  }
];
