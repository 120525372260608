<template>
  <div
    :class="{
      'container-xl': $vuetify.breakpoint.xlOnly,
      'container-lg': $vuetify.breakpoint.lgOnly,
      'container-tab': $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
      'container-mobile': $vuetify.breakpoint.xsOnly
    }"
  >
    <div class="summary-title">Your order summary</div>
    <div class="d-flex summary-sub-title">
      <img
        :src="require('root-src/assets/mouchak_favicon.svg')"
        alt="logo"
      /><span>Advanced All Feature</span>
    </div>

    <div class="subscription-text">Subscription</div>
    <div class="payment-info">
      <div class="payment-plan">
        {{
          info.selectedPlan.charAt(0).toUpperCase() +
            info.selectedPlan.substring(1)
        }}
      </div>
      <div
        class="payment-amount"
        :class="{ 'm-strikthrough': info.discount > 0 }"
      >
        BDT {{ amount }} TK /{{ unit }}
      </div>
    </div>

    <div class="student-count">
      Students to be enrolled : <span>{{ info.numOfStudents }} Students</span>
    </div>

    <div v-if="info.discount > 0" class="offer-info">
      <div class="one">SPECIAL OFFER</div>
      <div class="two">
        OFF {{ info.discount
        }}{{ info.discountType === "percentage" ? "%" : "TK" }}
      </div>
      <div class="three">BDT {{ amount - discount }} TK /{{ unit }}</div>
    </div>
    <hr />

    <div class="subtotal">
      <div>Subtotal</div>
      <div>BDT {{ amount - discount }} TK /{{ unit }}</div>
    </div>

    <div class="total">
      <div>TOTAL</div>
      <div>BDT {{ amount - discount }} TK /{{ unit }} <span>+ Tax</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSummary",
  props: ["info"],
  computed: {
    unit() {
      if (this.info.selectedPlan === "monthly") return "mo";
      if (this.info.selectedPlan === "annually") return "yr";
      return "qt";
    },
    amount() {
      if (this.info.selectedPlan === "monthly")
        return 20 * this.info.numOfStudents;
      if (this.info.selectedPlan === "annually")
        return 240 * this.info.numOfStudents;
      return 60 * this.info.numOfStudents;
    },
    discount() {
      if (this.info.discountType === "flat") return this.info.discount;
      return (this.amount * this.info.discount) / 100.0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@af/modules/payment/styles/order-summary.scss";
</style>
