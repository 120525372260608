import Vue from "vue";
import OrganizationApi from "@af/modules/organization/OrganizationApi";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";

function generateGuid() {
  var result, i, j;
  result = "";
  for (j = 0; j < 32; j++) {
    if (j == 8 || j == 12 || j == 16 || j == 20) result = result + "-";
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result = result + i;
  }
  return result;
}

const joinRequestModule = {
  namespaced: true,
  state: { incomingRequests: [] },
  mutations: {
    setIncomingRequests(state, requests) {
      state.incomingRequests = requests;
    },
    removeIncomingRequestsOfUser(state, { user_id, partner_code }) {
      state.incomingRequests = state.incomingRequests.filter(
        r => r.user_id !== user_id && r.partner_code !== partner_code
      );
    }
  },
  actions: {
    async listIncomingRequests(context) {
      let requests = await OrganizationApi.listIncomingJoinRequests();
      if (isEmpty(requests)) requests = [];
      context.commit("setIncomingRequests", requests);
      return requests;
    },
    async approve(context, { user_id, partner_code, access }) {
      await OrganizationApi.resolveJoinRequest(
        partner_code,
        user_id,
        access,
        "Accept"
      );
      context.commit("removeIncomingRequestsOfUser", { partner_code, user_id });
    },
    async decline(context, { user_id, partner_code, access }) {
      await OrganizationApi.resolveJoinRequest(
        partner_code,
        user_id,
        access,
        "Reject"
      );
      context.commit("removeIncomingRequestsOfUser", { partner_code, user_id });
    },
    async updateMemberRole(context, { user_id, partner_code, access }) {
      return await OrganizationApi.updateMemberRole(
        partner_code,
        user_id,
        access,
        "Accept"
      );
    }
  }
};

export default {
  modules: {
    joinRequest: joinRequestModule
  },
  namespaced: true,
  state: { partners: {}, users: {} },
  getters: {
    // Partners will be created rarely. So, much optimization is unnecessary
    myOrganizations(state) {
      return sortBy(
        Object.values(state.partners).filter(p => p.access.includes("owner")),
        "partner_name"
      );
    },
    joinedOrganizations(state) {
      return sortBy(
        Object.values(state.partners).filter(p => !p.access.includes("owner")),
        "partner_name"
      );
    }
  },
  mutations: {
    setOrganizations(state, { organizations, access }) {
      if (access) {
        organizations.forEach(org => {
          org.access = access[org.partner_code];
        });
      }
      state.partners = organizations.reduce((prev, org) => {
        prev[org.partner_code] = org;
        return prev;
      }, {});
    },
    setOrganizationUsers(state, { partner_code, users }) {
      Vue.set(state.users, partner_code, users);
    },
    removeOrganization(state, partner_code) {
      Vue.delete(state.partners, partner_code);
    }
  },
  actions: {
    async create(context, { partner_name }) {
      await OrganizationApi.create(partner_name);
      // await OrganizationApi
      await context.dispatch("list");
    },
    async list(context) {
      const organizations = await OrganizationApi.list();
      context.commit("setOrganizations", { organizations });
      return organizations;
    },
    async getJoinedOrganizations() {
      const organizations = await OrganizationApi.getJoinedOrganizations();
      return organizations;
    },
    async join(context, { partner_code, role }) {
      await OrganizationApi.join(partner_code, role);
    },
    async requestSent(context) {
      return await OrganizationApi.requestSent();
    },
    async update(context, payload) {
      await OrganizationApi.update(payload.organizationCode, payload.newName);
      await context.dispatch("list");
    },
    async generateCode(context, { partner_code }) {
      await OrganizationApi.generateCode(partner_code);
      await context.dispatch("list");
    },
    async getUsers(context, { partner_code }) {
      let users = await OrganizationApi.getUsers(partner_code);
      if (isEmpty(users)) users = [];
      context.commit("setOrganizationUsers", { partner_code, users });
    },
    async removeUser({ dispatch }, { userId, partner_code }) {
      await OrganizationApi.removeUser(partner_code, userId);
      return dispatch("getUsers", { partner_code });
    },
    async leave(ctx, { partner_code }) {
      await OrganizationApi.leave(partner_code, generateGuid());
      return ctx.commit("removeOrganization", partner_code);
    }
  }
};
