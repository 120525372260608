<template>
  <v-container :style="`width: ${sizes.container.width}`">
    <v-row  v-if="fetchingOrganizations" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-row v-else-if="myJoinedOrgs.length === 0" justify="center">
      <span> You have yet to join any organization </span>
    </v-row>
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              Organization Name
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="org in myJoinedOrgs" :key="org.partner_name">
            <td>
              <v-list-item-avatar
                color="#cef0ff"
                size="46"
              ></v-list-item-avatar>
            </td>
            <td>{{ orgNameContent(org) }}</td>
            <td>
              <v-btn
                color="red"
                rounded
                outlined
                small
                @click="prepareLeave(org.partner_code, org.partner_name)"
                ><v-icon>exit_to_app</v-icon>Leave</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <alert-dialog
      v-model="confirmDialog"
      title="Leaving"
      :sub-title="
        'Are you sure you want to leave organization ' + orgToLeave.name + '?'
      "
      show-cancel
      ok-text="Leave"
      @action="alertAction"
    ></alert-dialog>
  </v-container>
</template>

<script>
import AlertDialog from "@af/app-shell/AlertDialog.vue";
export default {
  name: "JoinedOrganizationList",
  components: { AlertDialog },
  props: {},
  created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    try{
      this.getJoinedOrganizations(this.$store.state.userAF.userDetails.user_id);
    } catch (e) {
      this.$root.$emit("alert", ["Alert", e.message])
    }
  },
  data() {
    return {
      leaving: {},
      myJoinedOrgs: [],
      confirmDialog: false,
      fetchingOrganizations: false,
      orgToLeave: { code: undefined, name: undefined }
    };
  },
  methods: {
    isMyOrg(partner_code) {
      return partner_code === this.myOwnPartnerCode;
    },
    async getJoinedOrganizations(user_id) {
      try {
        this.fetchingOrganizations = true;
        this.myJoinedOrgs = await this.$store.dispatch(
          "organizationAF/getJoinedOrganizations",
          { user_id }
        );
        for (let i = 0; i < this.myJoinedOrgs.length; i++) {
          if (this.isMyOrg(this.myJoinedOrgs[i]["partner_code"])) {
            this.myJoinedOrgs.splice(i, 1);
            break;
          }
        }
        this.fetchingOrganizations = false;
      } catch(e) { 
        this.$root.$emit("alert", ["Alert", e.message])
      }
      
    },
    prepareLeave(partner_code, name) {
      this.orgToLeave.code = partner_code;
      this.orgToLeave.name = name;
      this.confirmDialog = true;
    },
    async leave(partner_code, name) {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.$set(this.leaving, partner_code, true);
        await this.$store.dispatch("organizationAF/leave", { partner_code });
        this.$root.$emit("leave");
        await this.getJoinedOrganizations(
          this.$store.state.userAF.userDetails.user_id
        );
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          e.response.data.error || "Failed to leave organization"
        ]);
      } finally {
        this.leaving[partner_code] = false;
      }
    },
    async alertAction(leaveConfirm) {
      if (leaveConfirm) {
        await this.leave(this.orgToLeave.code, this.orgToLeave.name);
      }
      this.confirmDialog = false;
    },
    orgNameContent(org) {
      return org.partner_name
        ? org.partner_name
        : "Go to settings to give your organization a name";
    },
    organizationSettingsRoute(orgId) {
      return {
        name: "organization-settings",
        params: { organizationId: orgId }
      };
    }
  },
  computed: {
    myOwnPartnerCode() {
      let myOrg = this.$store.getters["organizationAF/myOrganizations"];
      if (myOrg.length > 0) {
        return myOrg[0].partner_code;
      } else return null;
    },
    sizes() {
      return {
        container: {
          width: "840px",
        },
      };
    },
  }
};
</script>

<style></style>
