<template>
  <v-hover v-slot:default="{ hover }">
    <div
      :elevation="hover ? 12 : 2"
      :class="{ 'on-hover': hover }"
      class="px-0 pb-0 mk-base-card"
    >
      <div class="px-3 py-2 d-flex justify-space-between align-center">
        <h4>
          <slot name="title"></slot>
        </h4>
        <v-col class="py-0 d-flex justify-end">
          <slot name="card-actions"></slot>
        </v-col>
      </div>
      <v-divider class="my-0 mk-divider"></v-divider>
      <v-row class="ma-0">
        <v-col class="px-0 mk-card-grey-col hidden-sm-and-down" md="3">
          <slot name="col-1"></slot>
        </v-col>
        <v-col cols="8" md="6">
          <slot name="col-2"></slot>
        </v-col>
        <v-col class="px-0 mk-card-grey-col" md="3">
          <slot name="col-3"></slot>
        </v-col>
      </v-row>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "MBaseCard",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";

.mk-base-card {
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  transition: all 0.2s;
  background-color: white;

  .mk-divider {
    border-color: #cfcfcf;
    border-width: 1px;
  }
  .mk-divider-col {
    border-color: #cfcfcf;
    border-width: 1px;
  }

  .mk-card-grey-col {
    background: #f5f5f5;
    border-bottom-left-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .on-hover {
    box-sizing: border-box;
    border: 1px solid $primary;
    border-left: 8px solid $primary;
    box-shadow: 2px 2px 7px #cfcfcf;
    transform: scaleX(1.02);

    .mk-divider {
      border-color: $primary;
      border-width: 0.5px;
    }
  }
}
</style>
