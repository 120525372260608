import Vue from "vue";
import {
  goToAuthPage,
  expireAuthCookie,
  expireRoleCookie,
  deleteAllCookies,
  Logger
} from "/global/utils/helpers";
import VueRouter from "vue-router";
import store from "./store";
import App from "./App.vue";
import routes from "./router";
import _ from "lodash";
import "~ecf/components/_global";
import vuetify from "./plugins/vuetify";
import VueTagManager from "vue-tag-manager";
import VueQuillEditor from "vue-quill-editor";
import VuetifyAsyncValidation from "vuetify-async-validation";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import katex from "katex";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg";

import { PaymentApi } from "@ef/modules/cart/api.js";
// import TransactionService from "@ecf/modules/payment-transation/services/TransactionService";
import TransactionService from "#ef/payment-transaction/services/TransactionService";

export let router = undefined;
window.katex = katex;

Vue.config.productionTip = false;
Vue.use(VueQuillEditor /* { default global options } */);
// Vue.prototype.$language = true;
Vue.use(VuetifyAsyncValidation);

export let globalData = new Vue({
  data: {
    $language: false,
    $ielts_course_id: "",
    $ielts_partner_code: "",
    $ielts_batch_id: ""
  }
});

Vue.mixin({
  computed: {
    $language: {
      get: function() {
        return globalData.$data.$language;
      },
      set: function(newLanguage) {
        globalData.$data.$language = false;
      }
    },
    $ielts_batch_id: {
      get: function() {
        return globalData.$data.$ielts_batch_id;
      },
      set: function(value) {
        globalData.$data.$ielts_batch_id = value;
      }
    },
    $ielts_course_id: {
      get: function() {
        return globalData.$data.$ielts_course_id;
      },
      set: function(value) {
        globalData.$data.$ielts_course_id = value;
      }
    },
    $ielts_partner_code: {
      get: function() {
        return globalData.$data.$ielts_partner_code;
      },
      set: function(value) {
        globalData.$data.$ielts_partner_code = value;
      }
    }
  }
});

const TEACHER_VIEW = "TEACHER_VIEW";
const STUDENT_VIEW = "STUDENT_VIEW";

if (process.env.VUE_APP_RUN_ENV === "production") {
  Vue.use(VueTagManager, {
    gtmId: "GTM-K6442LL"
  });
}

function bootstrap() {
  if (process.env.VUE_APP_RUN_ENV === "development") {
    store.subscribeAction((action, state) => {
      Logger.log("Action", `[${new Date().toLocaleTimeString()}]`, action);
    });
    store.subscribe((mutation, state) => {
      Logger.log("Mutation", `[${new Date().toLocaleTimeString()}]`, mutation);
    });
  }
}

String.prototype.wrap = function(length, symbol = "...") {
  if (this.length > length) {
    return this.slice(0, length) + symbol;
  }
  return this;
};

String.prototype.htmlToText = function() {
  return this.replace(/<[^>]*>?/gm, "");
};

async function main() {
  await assignDefaultOrganizationCourseBatchValues();
  const sessionExists = await store.dispatch("user/doesSessionExist");
  if (sessionExists) {
    const check = await store.dispatch("user/refreshSessionData");

    if (!check) {
      // indicates that refreshSessionData failed to refresh existing token
      deleteAllCookies();
      goToAuthPage();
    }

    try {
      await setupUserAndOrganization();
      await store.dispatch("checkRole");
    } catch (e) {
      await store.dispatch("user/expireCookie");
      expireRoleCookie();
    }

    await processIncomingTransaction();
    const user_details = store.getters[store.$types.GET_USER_DETAILS];
    const authorized_views = user_details?.authorized_views;
    if (
      !authorized_views.includes(TEACHER_VIEW) &&
      authorized_views.includes(STUDENT_VIEW)
    ) {
      const teachersRoutesUnselected = routes.findIndex(
        route => route.name === "teachersRoutesUnselected"
      );
      if (teachersRoutesUnselected !== -1) {
        routes.splice(teachersRoutesUnselected, 1);
        const teachersRoutesSelected = routes.findIndex(
          route => route.name === "teachersRoutesSelected"
        );
        if (teachersRoutesSelected !== -1) {
          routes.splice(teachersRoutesSelected, 1);
        }
      }
    }
  } else {
    expireRoleCookie();
    let lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;
    lang =
      lang ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage;
    globalData.$data.$language = false;
/*    if (lang === "bn") {
      globalData.$data.$language = true;
    } else {
      globalData.$data.$language = false;
    }*/
    const teachersRoutesUnselected = routes.findIndex(
      route => route.name === "teachersRoutesUnselected"
    );
    const teachersRoutesSelected = routes.findIndex(
      route => route.name === "teachersRoutesSelected"
    );
    const studentsRoutesEF = routes.findIndex(
      route => route.name === "studentsRoutes"
    );
    const accountsRoutes = routes.findIndex(
      route => route.name === "accountsRoutes"
    );
    _.pullAt(
      routes,
      teachersRoutesUnselected,
      teachersRoutesSelected,
      studentsRoutesEF,
      accountsRoutes
    );
  }

  const routerInstance = new VueRouter({
    mode: "history",
    routes
  });
  router = routerInstance;
  mountApp(routerInstance);
}

async function assignDefaultOrganizationCourseBatchValues() {
  let data = await store.dispatch("getDefaultOrganizationValues");
  if (data !== undefined) {
    globalData.$data.$ielts_batch_id = data.batch_id;
    globalData.$data.$ielts_course_id = data.course_id;
    globalData.$data.$ielts_partner_code = data.partner_code;
  }
}

async function setupUserAndOrganization() {
  const user = await store.dispatch("user/getLoggedInUser");
  globalData.$data.$language = false;
  const sessionExists = await store.dispatch("auth/doesSessionExist");
  if (sessionExists) {
    await store.dispatch("auth/refreshSessionData");
    await store.dispatch("auth/getLoggedInUser");
  }
/*  if (user.lang_preference === "BN") {
    globalData.$data.$language = true;
  } else {
    globalData.$data.$language = false;
  }*/

  try {
    await store.dispatch(store.$types.AUTHENTICATE, {
      token: store.state.auth.token
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      expireAuthCookie();
      expireRoleCookie();
      goToAuthPage();
    } else {
      Swal.fire({
        title: "Alert",
        text: JSON.stringify(e.response ? e.response.data : e),
        imageUrl: alertIcon
      });
    }
  }

  store.commit("organization/setOrganizations", user.organizations);
  if (user.organizations.length >= 1)
    store.commit(
      "organization/setCurrentOrganization",
      user.organizations[0].partner_code
    );
}

async function processIncomingTransaction() {
  try {
    const search = new URLSearchParams(window.location.search);
    const transaction = Boolean(search.get("transaction"));
    if (!transaction) return;

    const transaction_id = search.get("id");
    const transaction_type = search.get("type");
    const transaction_status = search.get("status");

    if (transaction_id) {
      let resp = await TransactionService.verifyPlanPurchase(
        transaction_id,
        transaction_type,
        ["cancel", "aborted"].includes(transaction_status.toLocaleLowerCase())
          ? "aborted"
          : ["failure", "failed"].includes(
              transaction_status.toLocaleLowerCase()
            )
          ? "failure"
          : "success"
      );
      if (resp["tr_status"] === "failed") {
        Vue.prototype.$notify(resp.message, { type: "error" });
      }
    }
  } catch (e) {
    Logger.log(e);
    Vue.prototype.$notify(e.message, { type: "error" });
  }
}

Vue.prototype.$notify = (msg, { type = "success" }) => {
  if (type === "error") {
    Swal.fire({
      title: "Alert",
      text: msg,
      imageUrl: alertIcon
    });
  } else {
    Swal.fire({
      title: "Alert",
      text: msg,
      imageUrl: alertIcon
    });
  }
};

Vue.prototype.$logger = Logger;

function mountApp(router) {
  return new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
}

bootstrap();
main().catch(e => {
  Logger.log("The following error occurred inside main(): ", e.message);
  Logger.log("The error response: ", e.response);
});
