import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import answerMapper from "./answer-mapper";
import { download } from "/global/utils/helpers";

const DIFFICULTY_CODES = { Easy: 0, Medium: 1, Hard: 2 };
const DIFFICULTY = ["Easy", "Medium", "Hard"];
export default {
  localToRemote(question, questionId = undefined) {
    const details = question.questionDetails;
    const images = {
      question: question.questionDetails.image.remote
        ? undefined
        : question.questionDetails.image.file,
      answers: question.answers
        ? question.answers.map(a => (a.image.remote ? undefined : a.image.file))
        : undefined
    };

    return {
      images,
      topics: question.questionDetails.topics,
      question: omitBy(
        {
          file: question.questionDetails.image.remote
            ? undefined
            : question.questionDetails.image?.file?.name,
          image_unchanged: question.questionDetails.image.remote ?? false,
          id: questionId,
          owner_id: question.owner_id,
          item_text: details.text,
          difficulty: DIFFICULTY_CODES[details.difficulty],
          essay_id: question.essay_id,
          type: details.questionType,
          ...(question.answers
            ? {
                answers: question.answers.map(a =>
                  answerMapper.localToRemote(a)
                )
              }
            : {}),
          ...(question.answerPreference
            ? {
                answer_preference: question.answerPreference
              }
            : {}),
          course_id: question.courseId
        },
        isNil
      )
    };
  },

  async duplicateForRemote(question, questionId = undefined) {
    const details = question.questionDetails;
    const images = {
      question: question.questionDetails.image.remote
        ? await download(question.questionDetails.image.imageURL)
        : question.questionDetails.image.file,
      answers: question.answers
        ? question.answers.map(async a =>
            a.image.remote ? await download(a.image.imageURL) : a.image.file
          )
        : undefined
    };

    return {
      images,
      topics: question.questionDetails.topics,
      question: omitBy(
        {
          file: question.questionDetails.image?.file?.name,
          image_unchanged: question.questionDetails.image.remote ?? false,
          id: questionId,
          owner_id: question.owner_id,
          item_text: details.text,
          difficulty: DIFFICULTY_CODES[details.difficulty],
          essay_id: question.essay_id,
          type: details.questionType,
          ...(question.answers
            ? {
                answers: question.answers.map(a =>
                  answerMapper.duplicateForRemote(a)
                )
              }
            : {}),
          ...(question.answerPreference
            ? {
                answer_preference: question.answerPreference
              }
            : {}),
          course_id: question.courseId
        },
        isNil
      )
    };
  },

  remoteToLocal(questionData) {
    return {
      id: questionData.id,
      question_id: questionData.id,
      questionDetails: {
        text: questionData.item_text,
        difficulty: DIFFICULTY[questionData.difficulty],
        topics: questionData.topics ?? [],
        d_status: questionData.d_status,
        s3_loc: questionData.file_url,
        file_url: questionData.file_url,
        type: questionData.type,
        created_at: questionData.created_at,
        questionType: questionData.type,
        image: {
          imageURL: questionData.file_url ?? null,
          remote: true,
          file: questionData.file_url
            ? {
                name: questionData.file_url
                  .split("?")[0]
                  .split("/")
                  .pop()
              }
            : null
        }
      },
      ...(questionData.answer_preference
        ? { answerPreference: { ...questionData.answer_preference } }
        : {}),
      ...(questionData.answers
        ? {
            answers: questionData.answers.map(a =>
              answerMapper.remoteToLocal(a)
            )
          }
        : {}),
      coordinates: questionData.coordinates,
      essay_id: questionData.essay_id,
      collection_id: questionData.collection_id,
      collection_order: questionData.collection_order,
      section_id: questionData.section_id,
      points: questionData.points,
      order: questionData.order
    };
  }
};
