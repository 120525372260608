import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
import { checkStatus } from "/global/utils/helpers";

export const CertificateApi = {
  list(batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("students_list_certificates", {
        params: {},
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content)
      .then(({ certificates, courses }) => {
        for (let certificate of certificates) {
          const course = courses[certificate.course_id];
          certificate.course_title = course.course_title;
          certificate.course_description = course.course_description;
        }
        return certificates;
      });
  },
  get(course_id, batch_id = undefined, opts = {}) {
    const params = { course_id };
    if (batch_id) params.batch_id = batch_id;
    // todo maybe do some transformation. Not needed for now
    return $backend
      .get("students_get_certificate", {
        params,
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content.certificate);
  },
};
