import { addNamespace } from "/global/utils/helpers";
const MODULE_NAME = "userAF";

export const _types = {
  SET_USER_DETAILS: "setUserDetails",
  // getters
  GET_USER_DETAILS: "getUserDetails",
  GET_USER_EMAIL: "getUserEmail",
  GET_USER_NAME: "getUserName",
  // actions
  UPDATE_PASSWORD: "updatePassword"
};

const __types = { ..._types };
addNamespace(MODULE_NAME, __types);
export const UserTypes = __types;
