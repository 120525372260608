import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";

export default {
  localToRemote(essay, essayId = undefined) {
    return omitBy(
      {
        item_text: essay.text,
        id: essayId,
        d_lock: essay.d_lock,
        ...(essay.image.file ? { file: essay.image.file.name } : {})
      },
      isNil
    );
  },

  remoteToLocal(essayData) {
    return {
      essay_id: essayData.id,
      text: essayData.item_text,
      d_lock: essayData.d_lock,
      files: essayData.files,
      question_group: essayData.question_group,
    };
  }
};
