var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "mk-navigation-drawer",
      style: _vm.smallScreen ? "width: 50px" : "width: 256px",
      attrs: {
        app: "",
        clipped: "",
        color: "#ffffff",
        floating: "",
        permanent: "",
      },
    },
    [
      _vm._t("drawer-items", function () {
        return [
          _c(
            "v-list",
            [
              _c(
                "v-list-item-group",
                [
                  _c(
                    "v-list-item",
                    { attrs: { exact: "", to: "/courses" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("dashboard")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Courses")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }