<template>
  <v-container>
    <v-row class="pl-8">
      <v-col lg="10" md="10" sm="12">
        <v-row align="center" class="my-10">
          <router-link :to="{ name: 'organizations' }">
            <v-icon>keyboard_backspace</v-icon>
          </router-link>

          <h1 class="ml-4 flex-grow-1 headline font-weight-medium">
            Organization Settings
          </h1>
        </v-row>
        <v-card flat class="m-account-card">
          <v-card-title class="mb-10"> Details </v-card-title>
          <v-card-text>
            <v-row class="pl-10 blue--text">Organization Name</v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  class="ml-10"
                  outlined
                  :readonly="!editingOrganizationName"
                  flat
                  solo
                  label="Name"
                  v-model="newOrganization.partner_name"
                ></v-text-field>
              </v-col>
              <v-icon
                v-if="!editingOrganizationName"
                class="ml-4 mb-8"
                @click="editOrganizationName()"
                >edit</v-icon
              >
              <v-icon v-else class="ml-4 mb-8" @click="save()">checked</v-icon>
            </v-row>
          </v-card-text>
          <v-card-title> Users </v-card-title>
          <v-card-text>
            <v-data-table
              :items="users"
              :headers="tableHeaders"
              :items-per-page="-1"
              hide-default-footer
              
            >
              <template v-slot:item.action="{ item }">
                <v-btn
                  color="red accent-3"
                  dark
                  text
                  small
                  icon
                  v-if="editingUserRole.includes(item.user_id)"
                  @click="saveUserRole(item.user_id, item.access)"
                  ><v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on"
                        >check</v-icon
                      >
                    </template>
                    <span>Save Role</span>
                  </v-tooltip>
                </v-btn>
                <v-btn
                  color="red accent-3"
                  dark
                  text
                  small
                  icon
                  v-else
                  @click="
                    editUserRole(item.user_id, item.authorized_views, item.name)
                  "
                  ><v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on"
                        >edit</v-icon
                      >
                    </template>
                    <span>Edit Role</span>
                  </v-tooltip>
                </v-btn>
                <v-btn
                  color="red accent-3"
                  dark
                  text
                  small
                  icon
                  :loading="removingUser[item.user_id]"
                  @click="openDeleteDialog(item)"
                  ><v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#ff5252" v-bind="attrs" v-on="on"
                        >delete</v-icon
                      >
                    </template>
                    <span>Remove User</span>
                  </v-tooltip>
                </v-btn>
              </template>
              <template v-slot:item.access="{ item }">
                <span v-if="editingUserRole.includes(item.user_id)">
                  <v-select
                    v-model="item.access"
                    style="padding: 0px; margin-top: 20px"
                    outlined
                    multiple
                    :items="roles[item.user_id]"
                    label="Role(s)"
                    placeholder="Enter one or more roles"
                    chips
                    deletable-chips
                    append-icon=""
                    :rules="[v=>required(v, 'Role')]"
                    @keyup.tab="updateTags"
                  >
                  </v-select>
                </span>
                <span v-else>{{ item.access.join(", ") }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <dialog-delete
        v-if="userRemoveDialog"
        v-model="userRemoveDialog"
        :deleting="deleating"
        :item="userToRemove"
        :item-text="userToRemove.name"
        @confirm-remove="removeUser(userToRemove.user_id)"
      ></dialog-delete>
    </v-row>
  </v-container>
</template>

<script>
import {
  JoinRequestService,
  OrganizationService
} from "@af/modules/organization/OrganizationService";
import OrganizationApi from "@af/modules/organization/OrganizationApi";
import DialogDelete from "./DialogDelete.vue";
import { FieldValidations } from "/global/utils/validations.js";

const USER_TABLE_HEADERS = [
  { text: "", value: "avatar" },
  { text: "User's Name", value: "name" },
  { text: "Role", value: "access" },
  { text: "Action", value: "action", width: "20%" }
];

export default {
  name: "OrganizationSettings",
  components: { DialogDelete },
  props: {
    organizationId: String
  },
  mixins: [FieldValidations],
  beforeCreate() {
    this.organizationService = new OrganizationService({ store: this.$store });
    this.joinRequestService = new JoinRequestService({ store: this.$store });
  },
  async created() {
    await this.organizationService.list();
    await this.organizationService.getUsers(this.organizationId);
  },
  data() {
    return {
      editingOrganizationName: false,
      newOrganization: {
        partner_name: undefined
      },
      updatingDetails: false,
      tableHeaders: USER_TABLE_HEADERS,
      editingUserRole: [],
      roles: {},
      removingUser: {},
      userToRemove: undefined,
      userRemoveDialog: undefined,
      deleating: false
    };
  },
  watch: {
    organization: {
      immediate: true,
      handler(org) {
        if (org) {
          this.newOrganization.partner_name = org.partner_name;
        }
      }
    }
  },
  methods: {
    required(value) {
      if (value.length == 0) {
        return "Role is required";
      }
      return !!value;
    },
    editOrganizationName() {
      this.editingOrganizationName = true;
    },
    async save() {
      try {
        this.updatingDetails = true;
        await this.organizationService.update(
          this.organizationId,
          this.newOrganization.partner_name
        );
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Failed to edit organization. Please try again later"
        ]);
      } finally {
        this.editingOrganizationName = false;
        this.updatingDetails = false;
      }
    },
    async editUserRole(userId, authorized_views, access) {
      this.editingUserRole.push(userId);
      let roleOptions = ["student"];
      if (authorized_views.includes("TEACHER_VIEW")) {
        roleOptions.push("teacher");
      }
      this.roles[userId] = roleOptions;
    },
    async saveUserRole(userId, access) {
      if (access.length < 1) return;
      try {
        return await OrganizationApi.updateMemberRole(
          this.organization.partner_code,
          userId,
          access
        );
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Failed to update member role. Please try again later"
        ]);
      } finally {
        this.editingUserRole = this.editingUserRole.filter(
          item => item !== userId
        );
      }
    },

    async removeUser(userId) {
      try {
        this.$set(this.removingUser, userId, true);
        this.deleating = true;
        await this.organizationService.removeUser(this.organizationId, userId);
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          e.response.data.error ||
            "Failed to remove user from organization. Please try again later"
        ]);
      } finally {
        this.removingUser[userId] = false;
        this.userRemoveDialog = false;
        this.deleating = false;
      }
    },

    openDeleteDialog(user) {
      this.userToRemove = user;
      this.userRemoveDialog = true;
    }
  },
  computed: {
    organization() {
      return this.organizationService.organizations[this.organizationId];
    },
    users() {
      return this.organizationService.users[this.organizationId];
    },
    dataChanged() {
      if (!this.organization) return false;
      return (
        this.organization.partner_name !== this.newOrganization.partner_name
      );
    }
  }
};
</script>

<style>
.tag-select {
  min-height: 20px !important;
  margin-top: 10px;
}
</style>
