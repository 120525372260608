// const OrganizationListPage = import(
//   "./pages/organization-list-page/OrganizationListPage"
// );
import OrganizationListPage from "./organization-list-page";
import OrganizationSettingsPage from "./organization-settings-page";

export default [
  {
    path: "/organizations",
    name: "organizations",
    component: OrganizationListPage
  },
  {
    path: "/organizations/:organizationId/settings",
    name: "organization-settings",
    component: OrganizationSettingsPage,
    props: true
  }
];
