import { addNamespace } from "/global/utils/helpers";
const MODULE_NAME = "service";

export const _types = {
  // mutations
  SET_SERVICES: "setServices",
  // getters
  GET_SERVICES: "getServices",
  // actions
  FETCH_SERVICES: "fetchServices"
};

const __types = { ..._types };
addNamespace(MODULE_NAME, __types);
export const ServiceTypes = __types;
