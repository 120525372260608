// file to standardize all the necessary user input validation that we use throughout Mouchak

const combinedFileSize = function (v) {
  return {
    size: v.reduce((total, current) => total + current.size, 0)
  };
};

const sizeUnitCoversion = function (sizeLimit) {
  let mult = "";
  let value = sizeLimit;
  if (sizeLimit >> 30) {
    mult = "G";
    value = sizeLimit >> 30;
  } else if (sizeLimit >> 20) {
    mult = "M";
    value = sizeLimit >> 20;
  } else if (sizeLimit >> 10) {
    mult = "K";
    value = sizeLimit >> 10;
  }
  return `${value} ${mult}B`;
};

export const FieldValidations = {
  computed: {
    // basic rules

    required() {
      return (v, label) => !!v || `This ${label ?? "field"} is required`;
    },

    requiredSelectField() {
      return (v, label) =>
        v.length > 0 || `This ${label ?? "field"} is required`;
    },

    maxValue() {
      return (v, value, label) =>
        v === undefined ||
        v <= value ||
        `${label ?? "Input"} must be less than or equal ${value}`;
    },

    minValue() {
      return (v, value, label) =>
        v === undefined ||
        v >= value ||
        `${label ?? "Input"} must be greater than or equal ${value}`;
    },

    betweenValue() {
      return (v, valueMin, valueMax, inclusive = true, label = null) =>
        v === undefined ||
        (inclusive
          ? v >= valueMin && v <= valueMax
          : v > valueMin && v < valueMax) ||
        `${label ?? "Input"} must be between ${valueMin} and ${valueMax}${inclusive
          ? ""
          : ", (" + valueMin + " and " + valueMax + ") not allowed"
        }`;
    },

    maxLength() {
      return (v, length, label) =>
        v === undefined ||
        v === null ||
        v.length <= length ||
        `${label ??
        "Input"} length must be less than or equal ${length} characters`;
    },

    minLength() {
      return (v, length, label) =>
        v === undefined ||
        v === null ||
        v.length >= length ||
        `${label ??
        "Input"} length must be greater than or equal ${length} characters`;
    },

    listNotEmpty() {
      return (v, label) =>
        v.length > 0 || `${label ?? "List"} can not be empty`
    },

    // standard specialized field rules

    emailValidation() {
      return v => !v || /.+@.+/.test(v) || `E-mail must be valid`;
    },

    phoneValidation() {
      return v =>
        /^\+?[-(*)\d]{5,15}$/.test(v) // /^(?:\+880|00880)?(01[3-9]\d{8})$/ for bangladesh
          ? true
          : `Phone number length should be 5-15 digits, - ( ) are acceptable`;
    },

    passwordValidation() {
      // This password validation regex can be made more compact. Keeping this to save time now.
      return v =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}|(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/.test(
          v
        ) ||
        "Password must contain at least eight characters, one uppercase letter, one lowercase letter and one number";
    },

    passwordMatch() {
      return (v, password) => v === password || "Passwords not matched";
    },

    // type specifications

    floatOnly() {
      return (v, label = null) =>
        /^[+-]?\d+(\.\d+)?$/.test(v) ||
        `${label ?? "This field"} can only take a floating point number.`;
    },

    digitsOnly() {
      return (v, label) =>
        /^[0-9]+$/.test(v) || `${label ?? "This field"} can only have digits.`;
    },

    alphaNumericOnly() {
      return (v, label) =>
        /([a-zA-Z0-9 _-]+)$/.test(v) ||
        `${label ?? "This field"} can only take alphanumeric.`;
    },

    // date validations

    // Date.parse(v) would accept valid date and time strings

    validateDate() {
      return v => !isNaN(Date.parse(v)) || `This field must be a valid date.`;
    },

    dateGreaterThan() {
      return (v, date) =>
        Date.parse(v) > Date.parse(date) ||
        `The date must be greater than ${date}`;
    },

    dateGreaterThanOrEqual() {
      return (v, date) =>
        Date.parse(v) >= Date.parse(date) ||
        `The date must be greater than or equal to ${date}`;
    },

    dateLessThan() {
      return (v, date) =>
        Date.parse(v) < Date.parse(date) ||
        `The date must be less than ${date}`;
    },

    dateLessThanOrEqual() {
      return (v, date) =>
        Date.parse(v) <= Date.parse(date) ||
        `The date must be less than or equal to ${date}`;
    },

    dateBetween() {
      return (v, fromDate, toDate) =>
        (fromDate <= Date.parse(v) && toDate >= Date.parse(v)) ||
        `The date must be within ${Date.parse(fromDate)} to ${Date.parse(
          toDate
        )}`;
    },

    // file validations

    fileSizeLessThan() {
      return (v, sizeUpperLimit, label = null, unit = null) =>
        (v?.size ?? 0) < sizeUpperLimit ||
        `${label ?? "The file size"} must be less than ${sizeUnitCoversion(
          sizeUpperLimit
        )}`;
    },

    fileSizeLessThanOrEqual() {
      return (v, sizeUpperLimit, label = null) =>
        (v?.size ?? 0) <= sizeUpperLimit ||
        `${label ??
        "The file size"} must be less than or equal to ${sizeUnitCoversion(
          sizeUpperLimit
        )}`;
    },

    fileSizeGreaterThan() {
      return (v, sizeLowerLimit, label = null) =>
        (v?.size ?? 0) > sizeLowerLimit ||
        `${label ?? "The file size"} must be greater than ${sizeUnitCoversion(
          sizeLowerLimit
        )}`;
    },

    fileSizeGreaterThanOrEqual() {
      return (v, sizeLowerLimit, label = null) =>
        (v?.size ?? 0) >= sizeLowerLimit ||
        `${label ??
        "The file size"} must be greater than or equal to ${sizeUnitCoversion(
          sizeLowerLimit
        )}`;
    },

    combinedFileSizeLessThan() {
      return (v, sizeUpperLimit, label = null, unit = null) =>
        combinedFileSize(v).size < sizeUpperLimit ||
        `${label ?? "Combined file size"} must be less than ${sizeUnitCoversion(
          sizeUpperLimit
        )}`;
    },

    combinedFfileSizeLessThanOrEqual() {
      return (v, sizeUpperLimit, label = null) =>
        combinedFileSize(v).size <= sizeUpperLimit ||
        `${label ??
        "Combined file size"} must be less than or equal to ${sizeUnitCoversion(
          sizeUpperLimit
        )}`;
    },

    combinedFileSizeGreaterThan() {
      return (v, sizeLowerLimit, label = null) =>
        combinedFileSize(v).size > sizeLowerLimit ||
        `${label ??
        "Combined file size"} must be greater than ${sizeUnitCoversion(
          sizeLowerLimit
        )}`;
    },

    combinedFileSizeGreaterThanOrEqual() {
      return (v, sizeLowerLimit, label = null) =>
        combinedFileSize(v).size >= sizeLowerLimit ||
        `${label ??
        "Combined file size"} must be greater than or equal to ${sizeUnitCoversion(
          sizeLowerLimit
        )}`;
    },

    fileFormatRestriction() {
      return (v, allowedFormats, label = null) =>
        allowedFormats.includes(v.type.split("/")[1]) ||
        `${label ??
        "The file type"} must be among the following: ${allowedFormats}`;
    },

    // image dimension related validation rules are async functions and will not work unless invoked from a async context (form, field)

    imageDimensionGreater() {
      /**
       * will not work unless invoked from a async context
       */
      return async (v, minWidth, minHeight, label = null) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(v);
        await new Promise(r => setTimeout(r, 100)); // this sleep is necessary to get the actual height and width
        return (
          (v.width > minWidth && v.height > minHeight) ||
          `${label ??
          "The height and width"} must be greater than: ${minWidth} x ${minHeight} pixels`
        );
      };
    },

    imageDimensionGreaterOrEqual() {
      /**
       * will not work unless invoked from a async context
       */
      return async (v, minWidth, minHeight, label = null) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(v);
        await new Promise(r => setTimeout(r, 100)); // this sleep is necessary to get the actual height and width
        return (
          (img.width >= minWidth && img.height >= minHeight) ||
          `${label ??
          "The height and width"} must be greater than or equal to: ${minWidth} x ${minHeight} pixels`
        );
      };
    },

    imageDimensionSmaller() {
      /**
       * will not work unless invoked from a async context
       */
      return async (v, maxWidth, maxHeight, label = null) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(v);
        await new Promise(r => setTimeout(r, 100)); // this sleep is necessary to get the actual height and width
        return (
          (img.width < maxWidth && img.height < maxHeight) ||
          `${label ??
          "The height and width"} must be less than: ${maxWidth} x ${maxHeight} pixels`
        );
      };
    },

    imageDimensionSmallerOrEqual() {
      /**
       * will not work unless invoked from a async context
       */
      return async (v, maxWidth, maxHeight, label = null) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(v);
        await new Promise(r => setTimeout(r, 100)); // this sleep is necessary to get the actual height and width
        return (
          (img.width <= maxWidth && img.height <= maxHeight) ||
          `${label ??
          "The height and width"} must be less than or equal to: ${maxWidth} x ${maxHeight} pixels`
        );
      };
    }
  }
};
