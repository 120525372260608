var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-main",
        { style: _vm.styles.main[_vm.mainStyle] },
        [
          _c("the-top-bar", {
            attrs: { services: _vm.services },
            on: { logout: _vm.logout },
          }),
          _vm.isTeacher
            ? _c("teacher-app-navigation-drawer", {
                on: { collapse: _vm.collapseHandler, logout: _vm.logout },
              })
            : _c("new-app-navigation-drawer", {
                on: { logout: _vm.logout, collapse: _vm.handleCollapse },
              }),
          _c("router-view"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }