const MLogin = () => import("./views/MLogin");
const MSignup = () => import("./views/MSignup");
const MForgotPassword = () => import("./views/MForgotPassword");
const MResetPassword = () => import("./views/MResetPassword");
const ConfirmMessage = () => import("./views/MConfirmationMessage");

export default [
  {
    path: "",
    redirect: "signin"
  },
  {
    path: "/auth/signin",
    name: "login",
    props: true,
    component: MLogin,
    meta: { title: "Sign In" }
  },
  {
    path: "/auth/register",
    props: true,
    component: MSignup,
    meta: { title: "Create Account" }
  },
  {
    path: "/auth/register/success",
    name: "register-success",
    props: true,
    component: ConfirmMessage
  },
  {
    path: "/auth/forgot-password",
    component: MForgotPassword,
    meta: { title: "Forgot Password" }
  },
  {
    path: "/auth/reset-password",
    component: MResetPassword,
    meta: { title: "Reset Password" },
    props: route => ({ token: route.query.token })
  }
];
