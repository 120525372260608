<template>
  <v-app class="mk-education-console">
    <router-view></router-view>
    <alert-dialog
      v-model="error.show"
      :title="error.title"
      :sub-title="error.subTitle"
      :to-signup="error.toSignup"
      @action="error.show = false"
    ></alert-dialog>
    <access-alter-dialog
      v-bind="accessAlertBinds"
      @exit="v => (accessAlertBinds.show = v)"
    ></access-alter-dialog>
  </v-app>
</template>

<script>
// import Types from "./store-types";
import Types from "@af/app-shell/store-types.js";
import userService from "@af/modules/user/services/UserService";
import defaultServiceIcon from "@af/app-shell/assets/img/round_menu_book_black.png";
import AlertDialog from "@af/app-shell/AlertDialog.vue";
import AccessAlterDialog from "../global/components/AccessAlterDialog.vue";
import moment from "moment";

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "1s",
    ss: "%ss",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY"
  }
});
export default {
  name: "App",
  components: { AlertDialog, AccessAlterDialog },
  data: () => ({
    services: [],
    error: { show: false, title: undefined, subTitle: "...", toSignup: false },
    accessAlertBinds: {
      show: false,
      cause: "",
      message: undefined,
      url: undefined
    },
    drawer: false,
    snackbar: false,
    showSplashScreen: false,
    authenticating: false,
    pendingRequestPanel: null
  }),
  created() {
    if (this.$store.getters[Types.IS_AUTHENTICATED]) {
      this.fetchServices();
    }
  },
  mounted() {
    this.$root.$on("alert", error => {
      this.raiseAlert(error);
    });
    this.$root.$on("alert-access", payload => {
      this.raiseAccessAlert(payload);
    });
  },
  methods: {
    async fetchServices() {
      try {
        const services = await userService.getServices();

        this.services = services.map(s => ({
          name: s.name,
          iconUrl: s.icon_url || defaultServiceIcon,
          serviceUrl: s.service_url
        }));
      } catch (e) {
        this.$root.$emit("alert", ["Alert", JSON.parse(e.message)]);
      }
    },

    raiseAlert(errorObject) {
      this.error.show = true;
      this.error.title = errorObject[0];
      this.error.subTitle = errorObject[1];
      this.error.toSignup = errorObject.length > 2 ? errorObject[2] : false;
    },

    raiseAccessAlert(payload) {
      this.accessAlertBinds.cause = payload.cause;
      this.accessAlertBinds.message = payload.message;
      this.accessAlertBinds.url = payload.url;
      this.accessAlertBinds.show = true;
    }
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: left; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
</style>
