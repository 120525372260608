<template>
  <v-container>
    <course-search-result-summary
      v-for="course in courses"
      :key="course.course_id"
      :course="course"
      :search-query="searchQuery"
    ></course-search-result-summary>
  </v-container>
</template>

<script>
import CourseSearchResultSummary from "#ef/course/components/CourseSearchResultSummary";
export default {
  name: "CourseList",
  components: { CourseSearchResultSummary },
  props: {
    courses: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: undefined,
    },
    searchQuery: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">

</style>
