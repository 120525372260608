var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm._l(_vm.batches, function (batch, idx) {
        return _c("m-batch-card", {
          key: batch.id,
          attrs: { batch: batch },
          on: {
            "show-details": function ($event) {
              return _vm.showDialog(idx)
            },
          },
        })
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "650px" },
          model: {
            value: _vm.createDialog,
            callback: function ($$v) {
              _vm.createDialog = $$v
            },
            expression: "createDialog",
          },
        },
        [
          _c("m-batch-details-dialog", {
            attrs: {
              batch: _vm.batches[_vm.selected_index],
              "is-subscribed": _vm.isSubscribed,
              course: _vm.course,
            },
            on: { cancel: _vm.hideDialog },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }