import TransactionService from "@ecf/modules/payment-transation/services/TransactionService.js";
import Vue from "vue";
import { download, blobToString } from "/global/utils/helpers";

export default {
  namespaced: true,
  state: {
    studentSubscriptionData: {},
    smsSubcriptionData: {},
    smsSubcriptionHistoryData: {},
    disbersmentList: {},
    disbersmentPages: undefined,
    selfTransactionList: {},
    selfTransactionPages: undefined,
    studentTransactionList: {},
    studentTransactionPages: undefined,
    dueStudentList: {},
    dueStudentPages: undefined,
    incomeData: {},
    totalDueStudents: 0,
    lastTab: undefined
  },
  getters: {
    subscription: state => {
      return state.studentSubscriptionData;
    },
    smsSubscription: state => {
      return state.smsSubcriptionData;
    },
    smsSubscriptionHistory: state => {
      return state.smsSubcriptionHistoryData;
    },
    disbersements: state => {
      return state.disbersmentList;
    },
    myTransactions: state => {
      return state.selfTransactionList;
    },
    studentTransactions: state => {
      return state.studentTransactionList;
    },
    countDueStudents: state => {
      return state.totalDueStudents.count;
    },
    dueStuents: state => {
      return state.dueStudentList;
    },
    totalIncome: state => {
      return state.incomeData.total;
    },
    monthlyIncome: state => {
      return state.incomeData.thisMonth;
    },
    lastSelectedTab: state => {
      return state.lastTab;
    }
  },
  mutations: {
    setStudentSubscription(state, data) {
      state.studentSubscriptionData = data;
    },
    setMessageSubscription(state, data) {
      state.smsSubcriptionData = data;
    },
    setMessageSubscriptionhistory(state, data) {
      state.smsSubcriptionHistoryData = data;
    },
    setIncomeData(state, data) {
      state.incomeData = data;
    },
    setDisbersement(state, { page, data }) {
      Vue.set(state.disbersmentList, page, data);
    },
    setOwnTransactionPageCount(state, { data }) {
      state.selfTransactionPages = data["pages"];
    },
    setOwnTransactions(state, { page, data }) {
      let items = data["transactions"].sort(function (x, y) {
        return y.time_stamp - x.time_stamp;
      });
      Vue.set(state.selfTransactionList, page, items);
    },
    setStudentTransactionPageCount(state, { data }) {
      state.studentTransactionPages = data["pages"];
    },
    setStudentTransactions(state, { page, data }) {
      let items = data["transactions"].sort(function (x, y) {
        return y.time_stamp - x.time_stamp;
      });
      Vue.set(state.studentTransactionList, page, items);
    },
    setDueStudentsPageCount(state, { data }) {
      state.dueStudentPages = data["pages"];
    },
    setDueStudents(state, { page, data }) {
      let items = data["purchases"];
      Vue.set(state.dueStudentList, page, items);
    },
    setDueStudentCount(state, { data }) {
      state.totalDueStudents = data;
    },
    switchTab(state, { data }) {
      state.lastTab = data;
    }
  },
  actions: {
    async getSubscription(ctx) {
      let data = await TransactionService.getSubscription();
      ctx.commit("setStudentSubscription", data);
      return data;
    },
    async getSMS(ctx) {
      let data = await TransactionService.getSMS();
      ctx.commit("setMessageSubscription", data);
      return data;
    },
    async getSMSHistory(ctx) {
      let data = await TransactionService.getSMSSubscriptionHistory();
      ctx.commit("setMessageSubscriptionhistory", data);
      return data;
    },
    async getIncomeInformation(ctx) {
      let data = await TransactionService.getIncomeInformation();
      ctx.commit("setIncomeData", data);
      return data;
    },
    async listDisbursemnet(ctx, { page }) {
      let pageNumber = String(page);
      if (!(pageNumber in ctx.state.disbersmentList)) {
        let data = await TransactionService.getDisbersements(page);
        ctx.commit("setDisbersemetList", { pageNumber, data });
        return data;
      } else {
        return ctx.getters["disbersements"][pageNumber];
      }
    },
    async pagesTransaction(ctx) {
      let data = await TransactionService.getOwnTransactionPages();
      ctx.commit("setOwnTransactionPageCount", { data });
      return data;
    },
    async listTransactions(ctx, { page }) {
      let pageNumber = String(page);
      if (!(pageNumber in ctx.state.selfTransactionList)) {
        let data = await TransactionService.getOwnTransactions(page);
        ctx.commit("setOwnTransactions", { page: pageNumber, data });
        return data;
      } else {
        return ctx.getters["myTransactions"][pageNumber];
      }
    },
    async pagesStuedentTransaction(ctx) {
      let data = await TransactionService.getStudentTransactionPages();
      ctx.commit("setStudentTransactionPageCount", { data });
      return data;
    },
    async listStudentTransactions(ctx, { page }) {
      let pageNumber = String(page);
      if (!(pageNumber in ctx.state.studentTransactionList)) {
        let data = await TransactionService.getStudentTransactions(page);
        ctx.commit("setStudentTransactions", { page: pageNumber, data });
        return data;
      } else {
        return ctx.getters["studentTransactions"][pageNumber];
      }
    },
    async getTotalDueStudents(ctx) {
      let data = await TransactionService.getTotalDueStudents();
      ctx.commit("setDueStudentCount", { data });
      return data;
    },
    async pagesDueStudents(ctx) {
      let data = await TransactionService.getDueStudentPages();
      ctx.commit("setDueStudentsPageCount", { data, type: "due" });
      return data;
    },
    async listDueStudents(ctx, { page }) {
      let pageNumber = String(page);
      if (!(pageNumber in ctx.state.dueStudentList)) {
        let data = await TransactionService.getDueStudents(page);
        ctx.commit("setDueStudents", { page: pageNumber, data });
        return data;
      } else {
        return ctx.getters["dueStuents"][pageNumber];
      }
    },
    async toggleAutoRenew(ctx, { status }) {
      return await TransactionService.toggleAutoRenew(status);
    },
    async purchaseSubscription(ctx, { numStudents, paymentMethod }) {
      return await TransactionService.purchaseSubscription(
        numStudents,
        paymentMethod
      );
    },
    async updateSubscription(ctx, { numStudents, paymentMethod }) {
      return await TransactionService.updateSubsciption(
        numStudents,
        paymentMethod
      );
    },
    async buySms(ctx, { countMessages, paymentMethod }) {
      return await TransactionService.buySMS(countMessages, paymentMethod);
    },
    async changePaymentSatus(ctx, { data }) {
      return await TransactionService.updatePurchaseStatus(data);
    },
    async changeActiveStatus(ctx, { data }) {
      return await TransactionService.updateActiveStatus(data);
    },
    async requestReceiptDownlaod(
      ctx,
      { transactionId }
    ) {
      return await TransactionService.getReceipt(
        transactionId, true
      );
    },
    async requestReceiptView(
      ctx,
      { transactionId }
    ) {
      const resp = await TransactionService.getReceipt(
        transactionId, false
      );
      let blob = await download(resp.receipt);
      let text = await blobToString(blob)
      let dom = new DOMParser().parseFromString(text, "text/html");
      return dom.body.innerHTML.trim();
      // return resp.receipt
    }
  }
};
