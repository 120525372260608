// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#account-section .v-list-item__icon {\n  margin-left: 0px;\n}\n.sidenav {\n  max-width: 56px;\n}\n#profile {\n  display: flex;\n  flex-direction: column-reverse;\n}\n@media screen and (min-width: 960px) {\n#profile .v-icon {\n    margin: auto;\n}\n#courses .v-icon {\n    margin: -18px;\n}\n#account-section .v-icon {\n    margin: auto;\n}\n}\n.v-list-item__icon {\n  margin: auto;\n}\n@media screen and (max-width: 960px) {\n.v-list-item {\n    padding: 0 8px;\n}\n}\n.v-list-group__header__prepend-icon {\n  display: none !important;\n}\n.mk-navigation-drawer {\n  transition: max-width 0.5s ease;\n}\n.mk-navigation-drawer .v-list-item--active {\n  color: #0080b8;\n}\n.mk-navigation-drawer .v-list-item--active .v-icon {\n  color: #0080b8 !important;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
