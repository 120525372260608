<template>
  <div class="mk-page-title" style="max-width: 1115px">
    <div class="d-flex align-center">
      <slot name="left"></slot>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="previousLocation"
            class="mr-2"
            color="black"
            icon
            large
            v-bind="attrs"
            @click="previousPage"
            v-on="on"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
      <slot name="title">
        <div class="page-title">{{ pageTitle }}</div>
      </slot>
      <slot name="action"></slot>
    </div>
    <!--    <v-divider class="mt-4 primary"></v-divider>-->
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    pageTitle: String,
    previousLocation: {
      type: [Boolean, String, Object],
      default: false
    }
  },
  methods: {
    async previousPage() {
      if (typeof this.previousLocation === "boolean") await this.$router.go(-1);
      else await this.$router.push(this.previousLocation);
    }
  }
  /* computed:{
    getFontSize(){
      let widch = this.$Vuetify.breakpoint.width
      let size = 
    }
  } */
};
</script>

