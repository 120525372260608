var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    _vm._l(_vm.courses, function (course) {
      return _c("course-search-result-summary", {
        key: course.course_id,
        attrs: { course: course, "search-query": _vm.searchQuery },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }