<template>
  <v-navigation-drawer
    app
    clipped
    color="#ffffff"
    floating
    permanent
    class="mk-navigation-drawer"
    :style="smallScreen ? 'width: 50px' : 'width: 256px'"
  >
    <slot name="drawer-items">
      <v-list>
        <v-list-item-group>
          <v-list-item exact to="/courses">
            <v-list-item-action>
              <v-icon>dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Courses</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </slot>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppNavigationDrawer",
  computed: {
    auth_url() {
      return process.env.VUE_APP_ACCOUNTS_FRONTEND_URL;
    },
    smallScreen() {
      return this.$vuetify.breakpoint.width <= 800 ? true : false;
    }
  },
  methods: {
    switchView() {
      window.location.href =
        process.env.VUE_APP_STUDENTS_FRONTEND_URL ||
        "https://classroom.mouchak.com";
    }
  }
};
</script>

<style lang="scss" scoped>
.mk-navigation-drawer {
  .v-list-item {
    height: 60px;
  }
  .v-list-item--active {
    color: $orange;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: inset 0px 2px 12px #00000029;
    font-weight: 600;
    &:hover::before,
    &::before {
      opacity: 0;
    }
    .v-icon {
      color: $orange !important;
    }
    //.v-list-item__icon.v-list-group__header__append-icon {
    //  color: #0089cb !important;
    //}
  }
}
</style>
