<template>
    <v-card
      class="mt-3"
      :min-height="sizes.courseCardSize.height"
      :width="sizes.courseCardSize.width"
      style="box-shadow: none !important;"
      @click="seeCourseDetails(course.course_id, course.partner_code)"
    >
      <v-row no-gutters 
        :style="`
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
          border-radius: 10px !important;
          border: solid #0099dc 1px;`">
        <v-col :cols="cols.cardImage">
          <div v-if="course.image_url"
           style="
              background: #377dff;
              filter: blur(0.6px) brightness(120%);
              border-radius: 10px 10px 10px 10px !important;
              opacity: 0.7;">
            <v-img
              :style="`border-radius: ${sizes.imageSize.br1}px ${sizes.imageSize.br2}px ${sizes.imageSize.br3}px ${sizes.imageSize.br4}px;`"
              :height="`${sizes.imageSize.height}px`"
              :width="`${sizes.imageSize.width}px`"
              :src="course.image_url"
            ></v-img>
          </div>
          <div
            v-else
            :style="
              `background: #666666;
              width: ${sizes.imageSize.width}px;
              height: ${sizes.imageSize.height}px;
              border-radius: ${sizes.imageSize.br1}px ${sizes.imageSize.br2}px ${sizes.imageSize.br3}px ${sizes.imageSize.br4}px;
              display: flex;
              align-items: center;
              justify-content: center;`
            "
          >
            <div :style="`border: 2px solid #fff;
                  border-radius: 50%;
                  width: ${sizes.titleImage.width}px;
                  height: ${sizes.titleImage.height}px;
                  background: #ffffff;
                  color: #333;
                  text-align: center;
                  font-size: ${sizes.titleImage.fontSize}px;
                  line-height: ${sizes.titleImage.lineHeight}rem;`">
              {{ getCourseImageFromTitle(course.course_title) }}
            </div>
          </div>
        </v-col>
        <v-col :cols="cols.infoSection">
          <div style="min-height: 190px; display: flex; flex-direction: column">
            <v-row
              no-gutters
              class="pa-2"
              style="display: flex; flex-direction: column"
            >
              <h3 style="font-size: 16px">{{ course.course_title }}</h3>
              <p class="mt-1 mb-0" style="font-size: 10px;">
                <b>Course Description: </b>
                <span
                  style="text-overflow: ellipsis; height: auto;
                    overflow: hidden; display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; white-space: pre-wrap;"
                  v-html="course.course_description"
                ></span>
              </p>
            </v-row>
            <v-row no-gutters justify="space-between" class="pa-2">
              <v-col cols="0">
                <v-row no-gutters>
                  <v-avatar
                    v-if="course.teacher.avatar"
                    class="pa-2"
                    size="40"
                    rounded="8"
                  >
                    <v-img :src="course.teacher.avatar"></v-img>
                  </v-avatar>
                  <user-avatar
                    class="pa-4 mt-1"
                    :user-name="course.teacher.name"
                    size="29px"
                  >
                  </user-avatar>
                  <p class="pa-2" style="color: #0074a7; margin-bottom: 0px">
                    {{ course.teacher.name }}
                  </p>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-row no-gutters style="display: flex; flex-direction: column">
          <v-row
            no-gutters
            justify="end"
            style="display: flex; flex-direction: column"
          >
            <v-col :cols="cols.enrollButton">
              <v-card-actions>
                <v-btn
                  width="120px"
                  height="35px"
                  color="primary"
                  elevation="0"
                  class="ml-2"
                  style="border-radius: 5px;"
                  @click.stop="
                    seeCourseDetails(
                      course.course_id,
                      course.partner_code,
                      true,
                    )
                  "
                  >Enroll
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-row>
      </v-row>
    </v-card>
</template>

<script>
import UserAvatar from "../../../../core/components/UserAvatar.vue";
export default {
  name: "CourseSearchResultSummary",
  comments: { UserAvatar },
  components: { UserAvatar },
  props: {
    course: {
      type: Object,
      requires: true,
    },
    searchQuery: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      heartClicked: false,
    };
  },
  computed:{
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    isSmall() {
      if(this.$vuetify.breakpoint.width < 600){
        return true;
      }else{
        return false;
      }
    },
    cols(){
      return {
        cardImage: this.breakPointValues("12", "0", "0", "0"),
        infoSection: this.breakPointValues("12", "6", "7", "8"),
        enrollButton: this.breakPointValues("8","4","4","4"),
      }
    },
    sizes(){
      return {
        courseCardSize:{
          height: this.breakPointValues(400, 190, 190, 190),
          width: this.breakPointValues(270, 700, 1000, 1000),
        },
        imageSize:{
          height: this.breakPointValues(150,190, 190, 190),
          width: this.breakPointValues(268, 170, 150, 150),
          br1: this.breakPointValues(8, 8, 8, 8),
          br2: this.breakPointValues(8, 0, 0, 0),
          br3: this.breakPointValues(0, 0, 0, 0),
          br4: this.breakPointValues(0, 8, 8, 8),
        },
        titleImage: {
          fontSize: this.breakPointValues(60, 40, 40, 40),
          lineHeight: this.breakPointValues(8.5, 5, 5, 5),
          height: this.breakPointValues(120, 80, 80, 80),
          width: this.breakPointValues(120, 80, 80, 80)
        },
        cols:{

        }
      }
    }
  },
  methods: {
    getCourseImageFromTitle(courseTitle) {
      let words = courseTitle.split(" ");
      return words
        .map(w => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    seeCourseDetails(courseId, partnerCode, is_enrolled = false) {
      if (is_enrolled) {
        this.$router.push({
          name: "course-details-student",
          params: {
            course_id: courseId,
            partner_code: partnerCode,
            is_enroll_trigger: is_enrolled,
          },
        });
      } else {
        this.$router.push({
          name: "course-details-student",
          params: { course_id: courseId, partner_code: partnerCode },
        });
      }
    },
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.course-search-result-summary {
  width: 900px;
  height: 400px;
  max-height: 190px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px !important;
  border: solid #0099dc 1px;

  .card-img {
    max-width: 100%;
    max-height: 100%;
    background: #377dff;
    filter: blur(0.6px) brightness(120%);
    border-radius: 10px 0px 0px 10px !important;
    opacity: 0.7;
  }
}

#courseNameImage {
  border: 2px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #ffffff;
  color: #333;
  text-align: center;
  font-size: 30px;
  line-height: 3.5rem;
}
</style>
