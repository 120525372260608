// import { encode } from "@msgpack/msgpack";
import axios from "axios";
import _, { isEmpty } from "lodash";
import { checkStatus, Logger } from "/global/utils/helpers";

const API_URL = process.env.VUE_APP_BACKEND_ACCT_URL;

let $axios = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: { "Content-Type": "application/json" }
});

async function makeRequest(
  channel,
  data,
  options = { path: "", requestType: "application/json", baseUrl: API_URL }
) {
  const rn = window.crypto.getRandomValues(new Uint32Array(1))[0];
  const requestId = `${channel}-${rn}`;
  // const shouldEncode = !_.isEmpty(data.params);
  // let payload = shouldEncode ? encode(data.params) : "";
  let payload = JSON.stringify(data.params || "");
  await new Promise((resolve, reject) => {
    let oReq = new XMLHttpRequest();
    oReq.open("POST", options.baseUrl + options.path);
    oReq.setRequestHeader("Channel-Name", channel);
    oReq.setRequestHeader("Request-Id", requestId);
    oReq.setRequestHeader("Content-Type", "application/json");
    // oReq.setRequestHeader("Allow-Block", 1);
    oReq.setRequestHeader(
      "Authorization",
      $axios.defaults.headers["Authorization"]
    );
    oReq.onload = resolve;
    oReq.onerror = reject;
    oReq.send(payload);
  });
  return requestId;
}

function loadResponse(channel, requestId, path = "", baseUrl = "") {
  return $axios.get(baseUrl + path, {
    headers: {
      "Channel-Name": channel,
      "Request-Id": requestId
    }
  });
}

async function get(
  channel,
  data = { delay: 1000, path: "", baseUrl: API_URL }
) {
  const path = data.path;
  return await makeRequest(channel, data, {
    path,
    baseUrl: data.baseUrl
  });
}

async function post(
  channel,
  data = {},
  options = { delay: 1000, returnResponse: false, baseUrl: API_URL }
) {
  return await makeRequest(channel, data, {
    baseUrl: options.baseUrl
  });
}

function formData(data, url = "uploads/") {
  const formdata = new FormData();
  Object.keys(data).forEach(k => formdata.append(k, data[k]));
  return $axios.post(url, formdata);
}

export const backendAsync = {
  post,
  get: get,
  put: post,
  delete: post,
  formData,
  headers: $axios.defaults.headers,
  interceptors: $axios.interceptors,
  all: axios.all
};

async function upload_to_s3(file_info, file) {
  const fields = file_info.fields;
  const formData = new FormData();
  formData.append("key", fields.key);
  Object.entries(fields).forEach(entry => {
    if (entry[0] !== "key") formData.append(entry[0], entry[1]);
  });
  formData.append("file", file);
  return await axios.post(file_info.url, formData).then(checkStatus);
}

export default {
  post: $axios.post,
  get: $axios.get,
  put: $axios.put,
  delete: $axios.delete,
  upload_to_s3: upload_to_s3,
  headers: $axios.defaults.headers,
  interceptors: $axios.interceptors
};

const transformToMsgPack = function transformToMsgPack(data) {
  return !isEmpty(data) ? data : "";
};

export class Backend {
  static authToken;
  static partner_code;

  constructor(baseUrl) {
    this.$axios = axios.create({
      baseURL: baseUrl,
      timeout: 100000,
      headers: {
        "Content-Type": "application/json",
        Authorization: $axios.defaults.headers["Authorization"],
        common: {
          Authorization: $axios.defaults.headers["Authorization"]
        }
      }
    });
    this.headers = this.$axios.defaults.headers;
    this.interceptors = this.$axios.interceptors;
  }

  async _call_backend(channel, data, options) {
    if(!window.navigator.onLine) throw new Error("There might be an internet connection issue on your system");
    if (_.get(options, "shouldSendPartnerCode", true)) {
      data.partner_code = Backend.partner_code;
    }
    const timeout = _.get(options, "timeout", 3000);
    Logger.log(`[${channel} Payload: `, data);
    const rn = global.crypto.getRandomValues(new Uint32Array(1))[0];
    const requestId = `${channel}-${rn}`;
    return await this._make_request(channel, requestId, data);
    // await delayPromise(timeout);
    // return await this._get_status(channel, requestId);
  }

  _make_request(channel, request_id, data = "") {
    const requestConfig = {
      headers: {
        "Channel-Name": channel,
        "Request-Id": request_id,
        "Content-Type": "application/json",
        // "Allow-Block": 1,
        Authorization: $axios.defaults.headers["Authorization"]
      },
      transformRequest: data => JSON.stringify(data)
    };

    Logger.log(requestConfig, data);
    return this.$axios.post("", data, requestConfig);
  }

  _get_status(channel, request_id) {
    return this.$axios.get("", {
      headers: {
        "Channel-Name": channel,
        "Request-Id": request_id,
        Authorization: `Bearer ${Backend.authToken}`
      }
    });
  }

  post(channel, data = {}, _opts = {}) {
    return this._call_backend(channel, data, _opts);
  }

  get(channel, data = {}) {
    return this._call_backend(channel, _.get(data, "params", {}), data);
  }

  put(channel, data, _opts = {}) {
    return this._call_backend(channel, data, _opts);
  }

  delete(channel, data = {}) {
    return this._call_backend(channel, data.data, data);
  }
}
