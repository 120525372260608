import TransactionService from "@ef/modules/payment-transaction/services/TransactionService.js";
import Vue from "vue";
import { download, blobToString } from "/global/utils/helpers";

// const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);

export default {
  namespaced: true,
  state: {
    transactionList: {},
    transactionPages: undefined,
    subscriptionPlanList: [],
    subscriptionPlanFeatures: {}
  },
  getters: {
    transactions: state => {
      return state.transactionList;
    },
    plans: state => {
      return state.subscriptionPlanList;
    },
    features: state => {
      return state.subscriptionPlanFeatures;
    }
  },
  mutations: {
    setOwnTransactionPageCount(state, { data }) {
      state.transactionPages = data["pages"];
    },
    setOwnTransactions(state, { page, data }) {
      let items = data["transactions"];
      Vue.set(state.transactionList, page, items);
    },
    setSubscriptionPlans(state, { data }) {
      state.subscriptionPlanList = data["items"];
    },
    setSubscriptionPlanFeatures(state, { planId, data }) {
      Vue.set(state.subscriptionPlanFeatures, planId, data["item"]);
    }
  },
  actions: {
    async pagesTransaction(ctx) {
      let data = await TransactionService.getOwnTransactionPages();
      ctx.commit("setOwnTransactionPageCount", { data });
      return data;
    },
    async listTransactions(ctx, { page }) {
      let pageNumber = String(page);
      if (!(pageNumber in ctx.state.transactionList)) {
        let data = await TransactionService.getOwnTransactions(page);
        ctx.commit("setOwnTransactions", { page: pageNumber, data });
        return data;
      } else {
        return ctx.getters["transactions"][pageNumber];
      }
    },
    async listPlans(ctx) {
      let data = await TransactionService.getSubscriptionPlans();
      ctx.commit("setSubscriptionPlans", { data });
      return data;
    },
    async getFeatures(ctx, { planId }) {
      if (!(planId in ctx.state.subscriptionPlanFeatures)) {
        let data = await TransactionService.getSubscriptionPlanFeatures(planId);
        ctx.commit("setSubscriptionPlanFeatures", { planId, data });
        return data;
      } else {
        return ctx.getters["features"][planId];
      }
    },
    async makePayment(
      ctx,
      { type, packageId, courseId, batchId, method, amount }
    ) {
      return await TransactionService.makePayment(
        type,
        packageId,
        courseId,
        batchId,
        method,
        amount
      );
    },
    async purchasePlan(ctx, { planId, redirectTo }) {
      let resp = await TransactionService.purchasePlan(planId, redirectTo);
      // return stripe.redirectToCheckout({ sessionId: resp.id });
    },
    async getReceipt(ctx, { transactionId }) {
      return await TransactionService.getReceipt(transactionId, true);
    },
    async showReceipt(ctx, { transactionId }) {
      const resp = await TransactionService.getReceipt(transactionId, false);
      let blob = await download(resp.receipt);
      let text = await blobToString(blob);
      let dom = new DOMParser().parseFromString(text, "text/html");
      return dom.body.innerHTML.trim();
      // return resp.receipt;
    }
  }
};
