<template>
  <v-container>
    <v-row justify="start" style="max-width: 1100px;">
      <v-col cols="10">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="5"
          circle
          style="justify-content: start;"
        ></v-pagination>
      </v-col>
      <v-col>
        <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          hint="Showing 5 of 100"
          type="number"
          min="-1"
          max="15"
          persistent-hint
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MExamPagination",
  props: {
    page: Number,
    pageCount: Number,
    itemsPerPage: Number
  },
  methods: {}
};
</script>

<style lang="scss"></style>
