var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticStyle: { "min-height": "280px" } },
        [
          _c("div", { staticClass: "col-sm-12 col-md5" }, [
            _c("h2", [
              _vm._v(
                " " +
                  _vm._s(
                    `${_vm.course.course_key}: ${_vm.course.course_title}`
                  ) +
                  " "
              ),
            ]),
            _c("p", [_vm._v("by " + _vm._s(_vm.course.teacher.name))]),
            _c("h4", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(!_vm.$language ? "Description" : "বিবরণ")),
            ]),
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.course.course_description) },
            }),
          ]),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center",
              class: { "image-with-button": _vm.isLargeScreen },
            },
            [
              _vm.batches.length > 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "enroll-btn",
                      attrs: {
                        rounded: "",
                        large: "",
                        color: "primary",
                        disabled: _vm.course.is_subscribed,
                      },
                      on: { click: _vm.scrollToBatchList },
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "mt-3 flex-wrap" }, [
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("h4", { staticClass: "mb-2" }, [
            _vm._v(
              " " +
                _vm._s(!_vm.$language ? "How it works" : "এটি কীভাবে কাজ করে") +
                " "
            ),
          ]),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.course.how_it_works) },
          }),
        ]),
        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
          _c("h4", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(!_vm.$language ? "Syllabus" : "সিলেবাস")),
          ]),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.course.syllabus) } }),
        ]),
      ]),
      _c(
        "v-row",
        { attrs: { id: "batchlist" } },
        [
          _c(
            "v-col",
            [
              _c("h4", { staticClass: "mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.$language ? "List of Batches" : "ব্যাচের তালিকা"
                    ) +
                    " "
                ),
              ]),
              !_vm.$language
                ? _c("p", [
                    _vm._v(" All the batches that are available in this "),
                    _c("strong", [_vm._v("Course")]),
                    _vm._v(" are here. "),
                  ])
                : _c("p", [
                    _vm._v(
                      " এই কোর্সের আওতাভুক্ত সকল ব্যচের তালিকা এখানে আছে "
                    ),
                  ]),
              _c("m-batch-table-p", {
                attrs: {
                  batches: _vm.batches,
                  course: _vm.course,
                  "is-subscribed": _vm.course.is_subscribed,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }