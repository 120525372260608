import StudentListApi from "@ecf/modules/student/StudentListApi";
import Vue from "vue";
import get from "lodash/get";
import { shouldRefreshCache } from "@ecf/global/helpers";
import sortBy from "lodash/sortBy";
import { Logger } from "/global/utils/helpers";

export default {
  namespaced: true,
  state: {
    studentMap: {},
    studentBatchMap: {},
    lastFetched: {}
  },
  getters: {
    students: state => {
      const courseIds = Object.keys(state.studentMap);
      const mapToList = studentMap =>
        sortBy(Object.values(studentMap), "student_id");
      return courseIds.reduce((prev, code) => {
        prev[code] = mapToList(state.studentMap[code]);
        return prev;
      }, {});
    }
  },
  mutations: {
    setStudent(state, { courseId, batchId, students }) {
      if (batchId) Vue.set(state.studentBatchMap, batchId, students);
      else Vue.set(state.studentMap, courseId, students);
    },
    setLastFetched(state, { value, course_id }) {
      state.lastFetched[course_id] = value;
    }
  },
  actions: {
    async list(ctx, { courseId, partner_code, refresh = false }) {
      const now = new Date().getTime();
      const lastFetched = get(ctx.state.lastFetched, courseId, -1);
      if (refresh || shouldRefreshCache(now, lastFetched)) {
        const students = await StudentListApi.find(partner_code, courseId);
        //
        //
        ctx.state.lastFetched[courseId] = now;
        ctx.commit("setStudent", { courseId, students });
        return students;
      } else {
        return ctx.getters["students"][courseId];
      }
    },
    async listBatchStudents(ctx, { courseId, partner_code, batchId }) {
      const students = await StudentListApi.getBatchStudents(
        batchId,
        courseId,
        partner_code
      );
      ctx.commit("setStudent", { courseId, batchId, students });
      return students;
    },
    async listOrg(ctx, { partner_code }) {
      const users = await StudentListApi.listOrg(partner_code);
      return users;
    }
  }
};
