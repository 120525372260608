<template>
  <v-card>
    <!-- removing  :mini-variant.sync="mini" for getting all collapsed icon in mini version -->
    <v-navigation-drawer
      fixed
      touchless
      clipped
      v-model="drawer"
      app
      floating
      permanent
      :class="['psudo-side-nav', { sidenav: mini }]"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'padding: 6px; padding-left: 8px;'
          : 'padding: 12px;'
      "
    >
      <slot name="drawer-items">
        <v-layout justify-space-between column fill-height>
          <v-list
            subheader
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-top: 11px'
                : 'margin-top: 22px'
            "
          >
            <v-list-item v-if="$vuetify.breakpoint.smAndDown">
              <v-list-item-icon style="margin-left: -6px">
                <v-app-bar-nav-icon
                  v-if="mini"
                  @click="collapseHandler()"
                ></v-app-bar-nav-icon>
                <v-icon
                  style="margin-left: 4px"
                  color="orange"
                  v-else
                  @click="collapseHandler()"
                  >mdi-close</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  style="font-size: 14px; margin: 0px"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              exact
              to="/teachers"
              style="padding-top: 0px; margin: 0px"
            >
              <v-list-item-icon style="margin-right: 16px">
                <v-icon color="#272727">mdi-view-dashboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px; margin: 0px">{{
                  !$language ? "Dashboard" : "ড্যাশবোর্ড"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              no-action
              v-model="courseActive"
              @click="profileActive = false"
              append-icon="mdi-chevron-down"
              id="courses"
            >
              <template v-slot:activator>
                <v-list-item-action style="margin-right: 16px">
                  <v-img
                    v-if="courseActive !== true"
                    :src="icons.Courses"
                  ></v-img>
                  <v-img
                    v-if="courseActive === true"
                    :src="icons.CoursesColor"
                  ></v-img>
                </v-list-item-action>
                <v-list-item-title style="font-size: 14px">{{
                  !$language ? "Courses" : "সকল কোর্স"
                }}</v-list-item-title>
              </template>
              <v-list-item
                :style="mini ? 'padding-left: 8px' : 'padding-left: 36px'"
                to="/teachers/courses/?tab=myCourse"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/teachers/courses/?tab=myCourse'"
                    :src="icons.MyCourses"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/teachers/courses/?tab=myCourse'"
                    :src="icons.MyCoursesColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title style="font-size: 12px">{{
                  !$language ? "My Courses" : "আমার কোর্সগুলি"
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :style="mini ? 'padding-left: 8px' : 'padding-left: 36px'"
                to="/teachers/courses/?tab=orgCourse"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/teachers/courses/?tab=orgCourse'"
                    :src="icons.OrganizationCourses"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/teachers/courses/?tab=orgCourse'"
                    :src="icons.OrganizationCoursesColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title style="font-size: 12px">{{
                  !$language
                    ? "Organization Courses"
                    : "অর্গানাইজেশনের কোর্সগুলি"
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :style="mini ? 'padding-left: 8px' : 'padding-left: 36px'"
                to="/teachers/courses/?tab=package"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/teachers/courses/?tab=package'"
                    :src="icons.Package"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/teachers/courses/?tab=package'"
                    :src="icons.PackageColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title style="font-size: 12px">{{
                  !$language ? "My Packages" : "আমার প্যাকেজ"
                }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item-group>
              <v-list-item to="/teachers/announcements">
                <v-list-item-action style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/teachers/announcements'"
                    :src="icons.Announcement"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/teachers/announcements'"
                    :src="icons.AnnouncementColor"
                  ></v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">{{
                    !$language ? "Announcements" : "নোটিশ বোর্ড"
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item exact to="/teachers/payment-transaction">
                <v-list-item-action style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/teachers/payment-transaction'"
                    :src="icons.Payment"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/teachers/payment-transaction'"
                    :src="icons.PaymentColor"
                  ></v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">{{
                    !$language ? "Payments & Transactions" : "আর্থিক তথ্য সমূহ"
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/teachers/sms-email">
                <v-list-item-action style="margin-right: 16px">
                  <v-img
                    v-if="currentRoute !== '/teachers/sms-email'"
                    :src="icons.SmsEmail"
                  ></v-img>
                  <v-img
                    v-if="currentRoute === '/teachers/sms-email'"
                    :src="icons.SmsEmailColor"
                  ></v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">{{
                    !$language ? "SMS & Email" : "এসএমএস ও ইমেইল"
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list subheader>
            <!-- <v-list-item exact to="" link>
              <v-list-item-action style="margin-right: 16px">
                <v-img
                  v-if="currentRoute !== ''"
                  :src="icons.Notification"
                ></v-img>
                <v-img
                  v-if="currentRoute === ''"
                  :src="icons.NotificationColor"
                ></v-img>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px"
                  >Notifications</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item exact to="" v-if="isStudent" :href="studentUrl">
              <v-list-item-action style="margin-right: 16px">
                <a :href="studentUrl">
                  <v-img
                    :src="swichImg ? icons.SwitchStudent : icons.SwitchTeacher"
                    v-model="swichImg"
                    @click="swichImg = true"
                  ></v-img
                ></a>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{
                  !$language
                    ? "Switch to Student Portal"
                    : "শিক্ষার্থী পোর্টালে স্যুইচ করুন"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
                no-action
                v-model="profileActive"
                @click="courseActive = false"
                style="padding-left: 0px; margin: 0"
                class="pl-0"
                id="profile"
                append-icon="mdi-chevron-up"
            >
              <template v-slot:activator>
                <v-list-item style="padding-left: 0px" class="pl-0">
                  <v-list-item-avatar
                      style="margin-right: 12px; padding: 0px; margin-left: -8px"
                      height="32"
                      width="32"
                  >
                    <user-avatar
                        style="padding: 0px 0px; margin: 0"
                        :userName="user.name"
                        size="32"
                        color="primary"
                    ></user-avatar>
                    <!-- <v-img
                    style="padding: 0px 0px"
                    src="https://randomuser.me/api/portraits/women/85.jpg"
                  ></v-img> -->
                  </v-list-item-avatar>
                  <v-list-item-content style="padding: 0px; margin: -4px">
                    <v-list-item-title
                        style="font-size: 14px"
                        :style="profileActive ? 'color: #0080b8' : ''"
                    >
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 10px">{{
                        user.email
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item
                  link
                  :style="mini ? 'padding-left: 8px' : 'padding-left: 30px'"
                  to="/accounts"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                      v-if="currentRoute !== '/accounts'"
                      :src="icons.AccountDetails"
                  ></v-img>
                  <v-img
                      v-if="currentRoute === '/accounts'"
                      :src="icons.AccountDetailsColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title style="font-size: 14px">{{
                    !$language ? "Account Details" : "অ্যাকাউন্টের বিবরণ"
                  }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  link
                  :style="mini ? 'padding-left: 8px' : 'padding-left: 30px'"
                  to="/organizations"
              >
                <v-list-item-icon style="margin-right: 16px">
                  <v-img
                      v-if="currentRoute !== '/organizations'"
                      :src="icons.Organization"
                  ></v-img>
                  <v-img
                      v-if="currentRoute === '/organizations'"
                      :src="icons.OrganizationColor"
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-title style="font-size: 14px">{{
                    !$language ? "Organizations" : "অর্গানাইজেশন"
                  }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  exact
                  to=""
                  @click="$emit('logout')"
                  :style="mini ? 'padding-left: 8px' : 'padding-left: 30px'"
                  link
              >
                <v-list-item-action style="margin-right: 16px">
                  <v-img :src="icons.LogOut"></v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">{{
                      !$language ? "Log out" : "লগ আউট"
                    }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list></v-layout
        >
      </slot>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import AnnouncementIcon from "@af/global/assets/Teacher_Dashboard_Icon/Announcement_off.svg";
import AnnouncementIconColor from "@af/global/assets/Teacher_Dashboard_Icon/Announcement_on.svg";
import PaymentIcon from "@af/global/assets/Teacher_Dashboard_Icon/PAYMENT_OFF.svg";
import PaymentIconColor from "@af/global/assets/Teacher_Dashboard_Icon/PAYMENT_ON.svg";
import SmsEmailIcon from "@af/global/assets/Teacher_Dashboard_Icon/SMS_OFF.svg";
import SmsEmailIconColor from "@af/global/assets/Teacher_Dashboard_Icon/SMS_ON.svg";

import CoursesIcon from "@af/global/assets/Teacher_Dashboard_Icon/courses_off.svg";
import CoursesIconColor from "@af/global/assets/Teacher_Dashboard_Icon/courses_on.svg";
import NotificationIcon from "@af/global/assets/Teacher_Dashboard_Icon/Notification_Off.svg";
import NotificationIconColor from "@af/global/assets/Teacher_Dashboard_Icon/Notification_On.svg";

import MyCoursesIcon from "@af/global/assets/Teacher_Dashboard_Icon/my_courses_off.svg";
import MyCoursesIconColor from "@af/global/assets/Teacher_Dashboard_Icon/my_courses_on.svg";
import OrganizationCoursesIcon from "@af/global/assets/Teacher_Dashboard_Icon/ORG_Courses_Off.svg";
import OrganizationCoursesIconColor from "@af/global/assets/Teacher_Dashboard_Icon/Org_courses_on.svg";
import PackageIcon from "@af/global/assets/Teacher_Dashboard_Icon/Package_off.svg";
import PackageIconColor from "@af/global/assets/Teacher_Dashboard_Icon/Package_on.svg";

import ProfileIcon from "@af/global/assets/Teacher_Dashboard_Icon/profile_off.svg";
import ProfileIconColor from "@af/global/assets/Teacher_Dashboard_Icon/profile_on.svg";

import AccountDetailsIcon from "@af/global/assets/Teacher_Dashboard_Icon/Account_details_off.svg";
import AccountDetailsIconColor from "@af/global/assets/Teacher_Dashboard_Icon/Account_details_on.svg";
import OrganizationIcon from "@af/global/assets/Teacher_Dashboard_Icon/Organizations_Off.svg";
import OrganizationIconColor from "@af/global/assets/Teacher_Dashboard_Icon/Organizations_On.svg";
import PaymentMethodIcon from "@af/global/assets/Teacher_Dashboard_Icon/payment_method_off.svg";
import PaymentMethodIconColor from "@af/global/assets/Teacher_Dashboard_Icon/payment_method_on.svg";

import LogOutIcon from "@af/global/assets/Teacher_Dashboard_Icon/Log_out.svg";
import SwitchTeacherIcon from "@af/global/assets/Teacher_Dashboard_Icon/Switch_Icon_teacher.svg";
import SwitchStudentIcon from "@af/global/assets/Teacher_Dashboard_Icon/Switch_Icon_student.svg";

import UserAvatar from "../../../core/components/UserAvatar.vue";

export default {
  components: { UserAvatar },
  name: "TeacherAppNavigationDrawer",

  data() {
    return {
      drawer: true,
      mini: false,
      profileActive: false,
      courseActive: false,
      myAccountActive: false,
      swichImg: false
    };
  },

  mounted() {
    if (this.$vuetify.breakpoint.name === "xs") {
      this.mini = true;
    }
    if (
      this.currentRoute === "/courses" ||
      this.currentRoute === "/courses/?tab=myCourse" ||
      this.currentRoute === "/courses/?tab=orgCourse" ||
      this.currentRoute === "/courses/?tab=package"
    ) {
      this.courseActive = true;
    }
    this.$emit("collapse", this.mini);
  },
  watch: {
    smallScreen(currentValue, oldValue) {
      if (!currentValue && this.mini) {
        this.mini = false;
      }
      if (currentValue && !this.mini) {
        this.mini = true;
      }
      this.$emit("collapse", this.mini);
    },
    tabScreen(currentValue, oldValue) {
      if (!currentValue && this.mini) {
        this.mini = false;
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.userAF.userDetails;
    },
    studentUrl() {
      return "/students" || "";
    },
    isStudent() {
      return this.$store.getters["userAF/isStudents"];
    },
    auth_url() {
      return "/auth";
    },
    smallScreen() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    tabScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    icons() {
      return {
        Announcement: AnnouncementIcon,
        AnnouncementColor: AnnouncementIconColor,
        Payment: PaymentIcon,
        PaymentColor: PaymentIconColor,
        SmsEmail: SmsEmailIcon,
        SmsEmailColor: SmsEmailIconColor,
        MyCourses: MyCoursesIcon,
        MyCoursesColor: MyCoursesIconColor,
        OrganizationCourses: OrganizationCoursesIcon,
        OrganizationCoursesColor: OrganizationCoursesIconColor,
        Package: PackageIcon,
        PackageColor: PackageIconColor,
        Courses: CoursesIcon,
        CoursesColor: CoursesIconColor,
        Notification: NotificationIcon,
        NotificationColor: NotificationIconColor,
        Profile: ProfileIcon,
        ProfileColor: ProfileIconColor,
        AccountDetails: AccountDetailsIcon,
        AccountDetailsColor: AccountDetailsIconColor,
        Organization: OrganizationIcon,
        OrganizationColor: OrganizationIconColor,
        PaymentMethod: PaymentMethodIcon,
        PaymentMethodColor: PaymentMethodIconColor,
        LogOut: LogOutIcon,
        SwitchTeacher: SwitchTeacherIcon,
        SwitchStudent: SwitchStudentIcon
      };
    },
    currentRoute() {
      return this.$route.fullPath;
    },
    urlConsoleClassroom() {
      return "/teachers";
    }
  },
  methods: {
    collapseHandler() {
      this.mini = !this.mini;
      this.$emit("collapse", this.mini);
    },
  }
};
</script>

<style lang="scss" scoped>
#account-section .v-list-item__icon {
  margin-left: 0px;
}

.sidenav {
  width: 56px !important;
}

#profile {
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 960px) {
  #profile .v-icon {
    margin: auto;
  }
  #courses .v-icon {
    margin: -18px;
  }
  #account-section .v-icon {
    margin: auto;
  }
}

.v-list-item__icon {
  margin: auto;
}

@media screen and (max-width: 960px) {
  .v-list-item {
    padding: 0 8px;
  }
}

.v-list-group__header__prepend-icon {
  display: none !important;
}

.mk-navigation-drawer {
  transition: width 0.5s ease;
  .v-list-item--active {
    color: #0080b8;
    .v-icon {
      color: #0080b8 !important;
    }
  }
}
</style>
