<template>
  <m-base-card>
    <template v-slot:title>
      {{ batch.batch_title }}
    </template>
    <template v-slot:col-1>
      <div>
        <p class="text-center my-3">
          {{ !$language ? "Type" : "ব্যাচ টাইপ" }}: &nbsp;
          {{ batchType }}
        </p>
      </div>
    </template>
    <template v-slot:col-2>
      <v-row :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }">
        <v-col>
          <m-base-card-info
            :label="!$language ? 'Price' : 'মূল্য'"
            :value="
              !$language
                ? `${batch.price ||
                    batch.amount} ${batch.currency.toUpperCase()}`
                : `${batch.price || batch.amount} ${currencyBN}`
            "
          ></m-base-card-info>
        </v-col>
        <v-col>
          <m-base-card-info :label="!$language ? 'Session' : 'সেশন'">
            {{
              !$language
                ? batch.begin_date
                : new Date(batch.begin_date).toLocaleString("bn-BD", {
                    dateStyle: "short",
                  })
            }}
            {{ !$language ? "to" : "থেকে" }}
            {{
              !$language
                ? batch.end_date
                : new Date(batch.end_date).toLocaleString("bn-BD", {
                    dateStyle: "short",
                  })
            }}
          </m-base-card-info>
        </v-col>
      </v-row>
    </template>
    <template v-slot:col-3>
      <m-base-card-button @click="$emit('show-details')">{{
        !$language ? "DETAILS" : "বিস্তারিত"
      }}</m-base-card-button>
    </template>
  </m-base-card>
</template>

<script>
import MBaseCard from "~ef/components/MBaseCard";
import MBaseCardButton from "~ef/components/MBaseCardButton";
import MBaseCardInfo from "~ef/components/MBaseCardInfo";
export default {
  name: "MBatchCard",
  components: { MBaseCardInfo, MBaseCardButton, MBaseCard },
  props: {
    batch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    batchType() {
      if (this.$language) {
        if (this.batch.batch_type == "Online") {
          return "অনলাইন";
        } else if (this.batch.batch_type == "In Person") {
          return "ব্যক্তিগত ভাবে";
        }
      }
      return this.batch.batch_type;
    },
    currencyBN() {
      if (this.batch.currency.toLowerCase() === "bdt") return "টাকা";
      else return "";
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.mk-batch-card {
  border: 1px solid #cfcfcf;
  max-width: 80%;
  border-radius: 5px;
  transition: all 0.2s;
  background-color: white;
  @media screen and (max-width: 1920px) {
    max-width: 100%;
  }

  .mk-divider {
    border-color: #cfcfcf;
    border-width: 1px;
  }
  .mk-divider-col {
    border-color: #cfcfcf;
    border-width: 1px;
  }

  .mk-card-col1 {
    background: #f5f5f5;
    border-bottom-left-radius: 5px;
    padding: 16px 32px;
    width: unset;
  }
  .mk-card-col2 {
    background: #f5f5f5;
    padding: 16px 48px;
    border-bottom-right-radius: 5px;
    width: unset;
  }
}
</style>
