import TopBar from "./TopBar";
import MSidebar from "./MSidebar";
import PageTitle from "./PageTitle";
import MExamPagination from "./MExamPagination";
import LazyLoad from "./LazyLoad";
import GridCol from "./GridCol";
import AlertDialog from "./AlertDialog";

export default {
  TopBar: TopBar,
  MSidebar,
  PageTitle,
  LazyLoad,
  MExamPagination,
  GridCol,
  AlertDialog
};
