<template>
  <v-app>
    <v-dialog v-model="visible" :width="maxWidth" :persistent="persist">
      <template v-if="$scopedSlots.activator" v-slot:activator="scope">
        <slot name="activator" v-bind="scope"> </slot>
      </template>
      <v-card class="mk-card-radius pa-4">
        <v-card-title class="justify-center">
          <slot name="title">
            <div class="d-flex flex-column align-center">
              <v-icon
                v-if="title == 'Info'"
                color="#f59e19"
                size="100"
                class="material-icons-round"
                >info</v-icon
              >
              <v-icon v-else-if="title == 'Success'" color="primary" size="100"
                >check_circle</v-icon
              >
              <alert-icon v-else></alert-icon>
              <div style="height: 15px"></div>
              <p class="alert-title">{{ title }}</p>
            </div>
          </slot>
        </v-card-title>
        <v-card-text class="alert-text">
          <slot>{{ subTitle }}</slot>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="showCancel"
            text
            color="primary"
            @click="$emit('action', 0)"
            >{{ cancelText }}</v-btn
          >
          <m-rounded-button color="primary" @click="$emit('action', 1)">{{
            okText
          }}</m-rounded-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import AlertIcon from "~ecf/components/AlertIcon";
export default {
  name: "AlertDialog",
  components: { AlertIcon },
  props: {
    value: { type: Boolean, required: true },
    title: { type: String, default: "Error" },
    subTitle: { type: String, required: true },
    cancelText: { type: String, default: "Cancel" },
    okText: { type: String, default: "OK" },
    showCancel: { type: Boolean, default: false },
    maxWidth: { type: String, default: "500px" },
    persist: { type: Boolean, default: true }
  },
  data() {
    return {
      visible: this.value
    };
  },
  watch: {
    value() {
      this.visible = this.value;
    },
    visible() {
      this.$emit("input", this.visible);
    }
  }
};
</script>

<style>
.alert-title {
  font-size: 26px;
  font-weight: 500;
}
.alert-text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  color: #3d3d3d;
}
</style>
