import LectureApi from "@ecf/modules/lecture/LectureApi";
import Vue from "vue";
import get from "lodash/get";
import { delayPromise, shouldRefreshCache } from "@ecf/global/helpers";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";

export default {
  namespaced: true,
  state: {
    lectureMap: {},
    lectureShareMap: {},
    listFetched: {},
    shareFetched: {}
  },
  getters: {
    lectures: state => {
      const courseIds = Object.keys(state.lectureMap);
      // const mapToList = lectureMap =>
      //   sortBy(Object.values(lectureMap), "title");
      const mapToList = lectureMap => Object.values(lectureMap);
      return courseIds.reduce((prev, courseId) => {
        prev[courseId] = mapToList(state.lectureMap[courseId]);
        return prev;
      }, {});
    }
  },
  mutations: {
    setLectures(state, { courseId, lectures }) {
      const lectureMap = lectures.reduce((prev, lecture) => {
        prev[lecture.object_id] = lecture;
        return prev;
      }, {});
      Vue.set(state.lectureMap, courseId, lectureMap);
    },
    setShares(state, { lectureId, shares }) {
      const lectureMap = keyBy(shares, "batch_id");
      Vue.set(state.lectureShareMap, lectureId, lectureMap);
    },
    removeLecture(state, payload) {
      Vue.delete(state.lectures, payload.cid);
    },
    setListFetched(state, { value, courseId }) {
      state.listFetched[courseId] = value;
    }
  },
  actions: {
    async list(ctx, { courseId, refresh = false }) {
      const now = new Date().getTime();
      const listFetched = get(ctx.state.listFetched, courseId, -1);
      if (refresh || shouldRefreshCache(now, listFetched)) {
        const lectures = await LectureApi.find(courseId);
        ctx.commit("setListFetched", { courseId, value: now });
        ctx.commit("setLectures", { lectures, courseId });
        await Promise.all(
          lectures.map(lecture =>
            ctx.dispatch("getShares", {
              lectureId: lecture.object_id,
              courseId: lecture.course_id
            })
          )
        );
      } else {
        return ctx.getters["lectures"][courseId];
      }
    },
    async getShares(ctx, { lectureId, courseId }) {
      const shares = await LectureApi.getSharedBatches(lectureId, courseId);
      ctx.commit("setShares", { lectureId, shares });
    },
    async create(ctx, { courseId, lectureData }) {
      await LectureApi.save(courseId, lectureData);
      await delayPromise(2000);
      ctx.dispatch("list", { courseId, refresh: true });
    },
    async share(ctx, { lectureId, batchId }) {},
    async remove(ctx, { lectureId, courseId }) {
      await LectureApi.remove(lectureId, courseId);
      ctx.dispatch("list", { courseId, refresh: true });
    }
  }
};
