var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { style: `width: ${_vm.sizes.container.width}` },
    [
      _vm.fetchingOrganizations
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _c("v-simple-table", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th"),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(" Organization Name "),
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v(" Actions "),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.organizations, function (org) {
                        return _c("tr", { key: org.partner_code }, [
                          _c(
                            "td",
                            [
                              _c("v-list-item-avatar", {
                                attrs: { color: "#cef0ff", size: "46" },
                              }),
                            ],
                            1
                          ),
                          _c("td", [
                            _vm._v(" " + _vm._s(_vm.orgNameContent(org)) + " "),
                            _c("br"),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    rounded: "",
                                    outlined: "",
                                    small: "",
                                    to: _vm.organizationSettingsRoute(
                                      org.partner_code
                                    ),
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("settings")]),
                                  _vm._v("Settings"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }