import Vue from "vue";
import VueRouter from "vue-router";
import IeltsFrontend from "@/routes/layouts/IeltsSite.vue";
import ieltsRoutesFrontend from "@/routes/ieltsIndex";
import { routesECFSelected, routesECFUnselected } from "@ecf/routes/index";
import CourseUnselectedLayout from "@ecf/routes/layouts/CourseUnselectedLayout";
import CourseSelectedLayout from "@ecf/routes/layouts/CourseSelectedLayout";
import IeltsLayout from "@ef/routes/layouts/Layout";
import { FluentStudentExamRoutes, FluentStudentRoutes } from "@ef/routes";
import ExamLayoutEF from "@ef/routes/layouts/FluentExamLayout";
import { routesAF } from "@af/app-shell/router";
import Authenticated from "@af/app-shell/layouts/Authenticated";
import authRoutes from "@af/modules/auth/routes";

const Auth = () => import("@af/modules/auth/layouts/MAuthLayout");

Vue.use(VueRouter);

const routes = [
  {
    path: "/teachers",
    name: "teachersRoutesUnselected",
    component: CourseUnselectedLayout,
    props: true,
    children: routesECFUnselected
  },
  {
    path: "/teachers/:partner_code/courses/:courseId",
    name: "teachersRoutesSelected",
    component: CourseSelectedLayout,
    props: true,
    meta: { title: "Teacher Courses" },
    children: routesECFSelected
  },
  {
    path: "/students",
    name: "studentsRoutes",
    component: IeltsLayout,
    props: true,
    children: FluentStudentRoutes
  },
  {
    path: "/students/tests",
    name: "studentsExamRoutes",
    component: ExamLayoutEF,
    props: true,
    children: FluentStudentExamRoutes
  },
  {
    path: "/accounts",
    name: "accountsRoutes",
    component: Authenticated,
    children: routesAF
  },
  {
    path: "/auth",
    name: "authRoutes",
    component: Auth,
    meta: { showNavigation: false },
    children: authRoutes
  },
  {
    path: "/",
    name: "ieltsRoutes",
    component: IeltsFrontend,
    children: ieltsRoutesFrontend,
    props: true
  }
];
export default routes;
