import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    announcements: [],
    last_fetch_time: undefined
  },
  mutations: {
    SET_ANNOUNCEMENTS(state, payload) {
      state.announcements = payload.announcements;
      state.last_fetch_time = payload.last_fetch_time;
    }
  },
  actions: {
    setAnnouncements({ commit }, { announcements, last_fetch_time }) {
      const payload = { announcements, last_fetch_time };
      commit("SET_ANNOUNCEMENTS", payload);
    }
  },
  getters: {
    allAnnouncements(state) {
      return state.announcements;
    }
  }
};
