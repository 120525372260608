import lectureService from "./services/LectureService";
import Vue from "vue";
import { _types } from "@ef/modules/lecture/store-types";
import _ from "lodash";
import Swal from "sweetalert2";
import alertIcon from "/global/assets/alertDialogIcons/Icon_alert.svg"
const MESSAGES = {
  LECTURE_CREATED: "The lecture has been created",
};
export default {
  state: {
    lectures: {},
    lastFetched: -1,
  },
  getters: {
    [_types.GET_LECTURES]: state => Object.values(state.lectures),
    [_types.GET_LECTURE]: state => id => state.lectures[id],
  },
  mutations: {
    [_types.SET_LECTURE](state, payload) {
      Vue.set(state.lectures, payload.cid, payload.lecture);
    },
    [_types.SET_LECTURES](state, payload) {
      state.lectures = payload;
    },
    [_types.REMOVE_LECTURE](state, payload) {
      Vue.delete(state.lectures, payload.cid);
    },
    setLastFetched(state, value) {
      state.lastFetched = value;
    },
  },
  actions: {
    async [_types.FETCH_LECTURES](ctx, payload = {}) {
      if (payload.cid && ctx.state.lectures[payload.cid]) {
        return true;
      }
      if (ctx.getters[_types.GET_LECTURES].length > 0) {
        return true;
      }

      const res = await lectureService.find(payload);
      if (payload.cid) {
        const lecture = res.lecture;
        ctx.commit(_types.SET_LECTURE, { cid: payload.cid, lecture });
        return lecture;
      } else {
        const lectures = {};
        res.lectures.forEach(t => (lectures[t.cid] = t));
        ctx.commit(_types.SET_LECTURES, lectures);
        return lectures;
      }
    },
    async [_types.CREATE_LECTURE](ctx, payload) {
      const res = await lectureService.save(payload);
      const cid = res.cid;
      _.delay(_i => {
        try {
          ctx.dispatch(_types.FETCH_LECTURES, { cid });
        } catch (e) {
          Swal.fire({
            title: "Alert",
            text: e,
            imageUrl: alertIcon
          });
        }
      }, 300);
      ctx.dispatch(ctx.$types.SHOW_MESSAGE, {
        message: MESSAGES.LECTURE_CREATED,
      });
      return cid;
    },
    async [_types.UPDATE_LECTURE](ctx, payload) {
      await lectureService.update(payload);
      const cid = payload.cid;
      _.delay(_i => {
        try {
          ctx.dispatch(_types.FETCH_LECTURES, { cid });
        } catch (e) {
          Swal.fire({
            title: "Alert",
            text: e,
            imageUrl: alertIcon
          });
        }
      }, 300);
      return true;
    },
    async [_types.DELETE_LECTURE](ctx, payload) {
      await lectureService.delete(payload);
      const cid = payload.cid;
      ctx.commit(_types.REMOVE_LECTURE, { cid });
      return true;
    },
  },
};
