<template>
  <v-main :style="styles.main[mainStyle]">
    <top-bar></top-bar>
    <new-app-navigation-drawer @logout="logout" @collapse="handleCollapse"></new-app-navigation-drawer>
    <router-view :key="$route.fullPath"></router-view>
  </v-main>
</template>

<script>
import NewAppNavigationDrawer from "../../app-shell/components/NewAppNavigationDrawer.vue";
import TopBar from "@ef/app-shell/components/TheTopBar.vue";
export default {
  name: "IeltsLayout",
  components: { NewAppNavigationDrawer, TopBar },
  data() {
    return {
      mainStyle: 0,
    };
  },
  computed: {
    styles() {
      return {
        main: ["", "padding-left: 56px"],
      };
    },
  },
  created(){
    this.handleCollapse();
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
    },
    handleCollapse(payload) {
      this.mainStyle = payload || this.$vuetify.breakpoint.width < 850 ? 1 : 0;
    },
  },
};
</script>
